import { IntlShape } from 'react-intl'

export const removeSepcialChar = (str: string) => str.replace(/[&/\\#,+()$~%.'":*?<>{}_-]/g, ' ')
export function GetStatus(intl: IntlShape, status = 'enabled') {
  const badgeClass = {
    enabled: 'badge-light-success',
    disabled: 'badge-light-warning',
  }[status]

  return (
    <span className={`badge status-button badge-sm ${badgeClass}`}>
      {status === 'enabled'
        ? intl.formatMessage({ id: 'Active' })
        : intl.formatMessage({ id: 'Inactive' })}
    </span>
  )
}
