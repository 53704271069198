import campaignNeg from '../../../../media/studio/images/campaignNeg.png'
import campaignPos from '../../../../media/studio/images/campaignPos.png'
import squareBG from '../../../../media/studio/images/squareBG.png'
import squareBGNIF from '../../../../media/studio/images/squareBGNIF.png'

export class Manifest {
  static colors = {
    white: '#FFFFFF',
    black: '#000000',
    blue: '#21285C',
  }

  static images = {
    squareBG,
    squareBGNIF,
    campaignNeg,
    campaignPos,
  }

  static uneditedImages = {
    Badmington: {
      src: 'https://org.stotte.no/media/marketing/some/unedited/square/Badmington.jpg',
      id: 'Badmington',
    },
    Boxing: {
      src: 'https://org.stotte.no/media/marketing/some/unedited/square/box.jpg',
      id: 'Boxing',
    },
    Eldrepar: {
      src: 'https://org.stotte.no/media/marketing/some/unedited/square/Eldrepar.png',
      id: 'Eldrepar',
    },
    Fjelltur2: {
      src: 'https://org.stotte.no/media/marketing/some/unedited/square/Fjelltur 2.png',
      id: 'Fjelltur2',
    },
    Fjelltur1: {
      src: 'https://org.stotte.no/media/marketing/some/unedited/square/Fjelltur1.png',
      id: 'Fjelltur1',
    },
    Fotball: {
      src: 'https://org.stotte.no/media/marketing/some/unedited/square/Fotball.jpg',
      id: 'Fotball',
    },
    Fotballgladgutt: {
      src: 'https://org.stotte.no/media/marketing/some/unedited/square/Fotballgladgutt.png',
      id: 'Fotballgladgutt',
    },
    Handball: {
      src: 'https://org.stotte.no/media/marketing/some/unedited/square/Handball.jpg',
      id: 'Handball',
    },
    Hockey: {
      src: 'https://org.stotte.no/media/marketing/some/unedited/square/Hockey.png',
      id: 'Hockey',
    },
    Langrenn: {
      src: 'https://org.stotte.no/media/marketing/some/unedited/square/Langrenn.jpg',
      id: 'Langrenn',
    },
    Leiavsalgsdugnader1: {
      src: 'https://org.stotte.no/media/marketing/some/unedited/square/Leiavsalgsdugnader1.png',
      id: 'Leiavsalgsdugnader1',
    },
    Leiavsalgsdugnader2: {
      src: 'https://org.stotte.no/media/marketing/some/unedited/square/Leiavsalgsdugnader2.png',
      id: 'Leiavsalgsdugnader2',
    },
    Turnjente: {
      src: 'https://org.stotte.no/media/marketing/some/unedited/square/Turnjente.jpg',
      id: 'Turnjente',
    },
    Turnmatte: {
      src: 'https://org.stotte.no/media/marketing/some/unedited/square/Turnmatte.jpg',
      id: 'Turnmatte',
    },
    turnringer: {
      src: 'https://org.stotte.no/media/marketing/some/unedited/square/turnringer.jpg',
      id: 'turnringer',
    },
  }

  static uneditedImagesArr = Object.keys(Manifest.uneditedImages).map(
    (key) => Manifest.uneditedImages[key]
  )
}
