/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */

import axios from 'axios'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import toastr from '../../../toaster'

function VerifyEmail() {
  const { id, token } = useParams()
  const navigate = useNavigate()
  const verifyemailToken = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BACKEND_API_URL}/auth/join_system_and_verify/${id}/${token}`,
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
          },
        }
      )
      if (response) {
        const lsValue = JSON.stringify(response.data.data)
        localStorage.setItem('eierportalen-auth-react-v', lsValue)

        window.location.href = `${window.location.origin.toString()}/dashboard/`
      }
    } catch (error: any) {
      toastr.error(error?.response?.data?.message)
    }
  }
  return (
    <div className='d-flex flex-column flex-root'>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
          backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')`,
        }}
      >
        <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
          <a href='/dashboard' className='mb-10 pt-lg-20'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-3.svg')}
              className='h-50px mb-5'
            />
          </a>
          <div className='pt-lg-10 mb-10'>
            <Outlet />
            <div className='text-center'>
              <button className='btn btn-lg btn-primary fw-bolder' onClick={verifyemailToken}>
                Verify and login
              </button>
            </div>
          </div>
          <div
            className='
          d-flex
          flex-row-auto
          bgi-no-repeat
          bgi-position-x-center
          bgi-size-contain
          bgi-position-y-bottom
          min-h-100px min-h-lg-350px
        '
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')`,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export { VerifyEmail }
