/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-vars */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { useMainContext } from '../../../context'
import { useAuth } from '../../modules/auth'
import { handleGetRequest, handlePatchRequest, handlePostRequest } from '../../services'
import UploadModal from './UploadModal'

interface IChildOrganization {
  id: string | null
  label: string
}

const SettingsMiansp: React.FC = () => {
  const { currentUser, logout } = useAuth()
  const intl = useIntl()
  const { loading, setLoading } = useContext(LoadingContext)
  const [showImageCroppingModal, setShowImageCroppingModal] = useState(false)
  const [filetype, setfiletype] = useState('png')
  const [userConsent, setUserConsent] = useState<boolean>(false)
  const [ssnActivation, setSsnActivation] = useState<boolean>(false)
  const [profilePic, setProfilePic] = useState<any>()
  const [orgDetails, setOrgDetails] = useState<any>(null)
  const [desc, setDesc] = useState<any>(null)
  const [showConsentWarningModal, setShowConsentWarningModal] = useState(false)
  const [showFeeIncreaseConsentModal, setShowFeeIncreaseConsentModal] = useState(false)

  const checkboxDescription = {
    toActivate: intl.formatMessage({ id: 'activation.Getting.SSN' }),
    alreadyActivated: intl.formatMessage({ id: 'deactivation.Getting.SSN' }),
  }
  const [showSwitchToChildOrg, setShowSwitchToChildOrg] = useState(false)
  const [otherOrgs, setOtherOrgs] = useState<IChildOrganization[]>([])
  const [tempSwitchOrg, setTempSwitchOrg] = useState<IChildOrganization>()

  const handleClose = () => setShowConsentWarningModal(false)
  const handleCloseFeeIncreaseModal = () => setShowFeeIncreaseConsentModal(false)
  const handleCloseAndcheckTheBox = () => {
    setUserConsent(false)
    handleClose()
  }
  const handleCloseAndActivateGettingSSN = () => {
    setSsnActivation(true)
    setShowFeeIncreaseConsentModal(false)
  }
  const handleCheckboxChange = (e: any) => {
    if (e.target.checked) {
      setUserConsent(true)
    } else {
      setShowConsentWarningModal(true)
    }
  }

  const ssnHandleCheckboxChange = (e: any) => {
    if (e.target.checked) {
      setShowFeeIncreaseConsentModal(true)
    } else {
      setSsnActivation(false)
    }
  }
  const [inputs, setInputs] = useState<any>({})
  const { setOrganisationLogo } = useMainContext()

  const handleChange = (event: any) => {
    const { name } = event.target
    const { value } = event.target
    setInputs((values: any) => ({ ...values, [name]: value }))
  }

  const ChangeProfilePic = (e: any) => {
    if (!e.target.files[0]) {
      toast.error('Opplastning feilet')
      return
    }
    const fileExtensions = ['png', 'jpg', 'jpeg']
    const fileName = e.target.files[0].name
    const fileExtension = fileName.split('.').pop()
    const fileSize = e.target.files[0].size
    const maxFileSize = 2 * 1024 * 1024 // 2MB in bytes

    if (!fileExtensions.includes(fileExtension)) {
      toast.error('Tillatt filtype: jpg, jpeg, png.')
      return
    }
    setfiletype(fileExtension)

    if (fileSize > maxFileSize) {
      toast.error('Filstørrelsen må være mindre enn 2MB.')
      return
    }
    setProfilePic(e.target.files[0])
    setShowImageCroppingModal(true)
  }
  const setCroppedImage = (image: string) => {
    if (!image) {
      setShowImageCroppingModal(false)
      setProfilePic(null)
      return
    }
    setShowImageCroppingModal(false)
    fetch(image)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], profilePic.name, { type: profilePic.type })
        setProfilePic(file)
      })
      .catch((error) => {
        console.error(error)
      })
  }
  const handleClick = async () => {
    const formData = new FormData()
    if (profilePic) formData.append('logo', profilePic)
    if (desc) {
      formData.append('description', desc)
    } else {
      formData.append('description', '')
    }
    formData.append('user_consent_sharing_enabled', String(userConsent))
    formData.append('ssn_tax_refund_enabled', String(ssnActivation))

    if (inputs?.invoice_email) {
      formData.append('invoice_email', inputs?.invoice_email)
    }
    const { success } = await handlePatchRequest(
      `/organisation/${currentUser?.user?.organisation_id?._id}`,
      formData
    )(setLoading)
    if (success) {
      getOrgDetails()
    } else {
      setLoading(false)
    }
    if (profilePic)
      await handlePostRequest(`/organisation/update-org-logo-b64`, {
        id: currentUser?.user?.organisation_id?._id,
      })(setLoading)
  }

  const getOrgDetails = async () => {
    const response = await handleGetRequest(
      `/organisation/${currentUser?.user?.organisation_id?._id}`
    )(setLoading)
    if (response) {
      setOrgDetails(response.data)
      setUserConsent(response.data?.user_consent_sharing_enabled)
      setSsnActivation(response.data?.ssn_tax_refund_enabled)
      setOrganisationLogo(response.data?.logo)
      if (
        (response.data?.child_organisations?.length > 0 ||
          (!!response.data?.parent_organisation && response.data?.parent_organisation !== '')) &&
        currentUser.user.can_switch_organisation
      ) {
        setShowSwitchToChildOrg(true)

        const childOrgs: IChildOrganization[] = [
          {
            id: response.data._id,
            label: `${response.data.org_name} - ${response.data.organisation_number}`,
          },
        ]
        if (response.data?.child_organisations?.length > 0) {
          // Fetch child organisations
          for (const org of response.data.child_organisations) {
            // eslint-disable-next-line no-await-in-loop
            const { data: orgData } = await handleGetRequest(`/organisation/${org}`)(setLoading)
            if (orgData && childOrgs.find((c) => c.id === orgData._id) === undefined) {
              childOrgs.push({
                id: orgData._id,
                label: `${orgData.org_name} - ${orgData.organisation_number}`,
              })
            }
          }
        } else {
          const { data: orgData } = await handleGetRequest(
            `/organisation/${response.data?.parent_organisation}`
          )(setLoading)
          if (orgData && childOrgs.find((c) => c.id === orgData._id) === undefined) {
            childOrgs.push({
              id: orgData._id,
              label: `${orgData.org_name} - ${orgData.organisation_number}`,
            })
          }
        }
        setOtherOrgs(childOrgs)
      }
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    setDesc(orgDetails?.description)
  }, [orgDetails?.description])

  useEffect(() => {
    if (!currentUser || currentUser.user.user_type !== 'admin') {
      toast.error('Du har ikke autorisasjon til å få tilgang til denne siden.')
      setTimeout(() => {
        window.location.href = '/home'
      }, 1000)
    }
    getOrgDetails()
  }, [currentUser?.user?.organisation_id?._id, setLoading])

  const delProfilePic = async () => {
    try {
      const { success } = await handlePatchRequest(
        `/organisation/${currentUser?.user?.organisation_id?._id}`,
        { logo: '' }
      )(setLoading)
      if (success) {
        getOrgDetails()
        await handlePostRequest(`/organisation/update-org-logo-b64`, {
          id: currentUser?.user?.organisation_id?._id,
        })(setLoading)
      }
    } catch (error: any) {
      toast.error(JSON.stringify(error?.response?.data))
      setLoading(false)
    }
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      {profilePic && showImageCroppingModal && (
        <UploadModal
          setModalImage={setCroppedImage}
          imagetype={filetype}
          image={URL.createObjectURL(profilePic)}
        />
      )}
      <div className='card-header border-0 cursor-pointer '>
        <div className='card-title m-0 '>
          {intl.formatMessage({ id: 'information.about.the.organization' })}
        </div>
        <div className='card-title m-0 float-right' />
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-semibold fs-6'>
                {intl.formatMessage({ id: 'Logo' })}
              </label>
              <div className='col-lg-9'>
                <div
                  className='image-input image-input-outline image-input-placeholder'
                  data-kt-image-input='true'
                >
                  <div
                    className='image-input-wrapper w-125px h-125px p-1 d-flex align-items-center'
                    style={{ border: '1px solid #c6e0ec' }}
                  >
                    <img
                      src={
                        profilePic
                          ? URL.createObjectURL(profilePic)
                          : orgDetails?.logo
                            ? orgDetails?.logo
                            : orgDetails?.org_logo_base64
                              ? `data:image/png;base64, ${orgDetails?.org_logo_base64}`
                              : 'https://via.placeholder.com/150'
                      }
                      alt='Profile pic'
                      style={{
                        width: '100%',
                        height: '9rem',
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                  {currentUser?.user?.user_type !== 'manager' && (
                    <>
                      <label
                        className='btn btn-icon btn-circle btn-active-color-primary
                       w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='change'
                        data-bs-toggle='tooltip'
                        aria-label='Change avatar'
                        data-bs-original-title='Change avatar'
                        data-kt-initialized={1}
                      >
                        <i className='bi bi-pencil-fill fs-7' />
                        <input
                          type='file'
                          name='picture'
                          accept='.png, .jpg, .jpeg'
                          onChange={(e) => ChangeProfilePic(e)}
                        />
                      </label>
                      <label
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        style={{ position: 'absolute', top: 112, left: 112 }}
                        onClick={() => delProfilePic()}
                      >
                        <i className='bi bi-x-lg fs-7' />
                      </label>
                    </>
                  )}
                </div>
                <div className='form-text'>
                  {intl.formatMessage({ id: 'allowed.file.type' })}: jpg, jpeg, png.
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                {intl.formatMessage({ id: 'name.of.the.organization' })}:{' '}
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  style={{ borderRadius: '8px' }}
                  disabled
                  type='text'
                  value={orgDetails?.org_name}
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>{intl.formatMessage({ id: 'org.numb' })}:</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  style={{ borderRadius: '8px' }}
                  disabled
                  value={orgDetails?.organisation_number}
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>{intl.formatMessage({ id: 'org.description' })}:</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <CKEditor
                  editor={ClassicEditor}
                  data={desc || ''}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'blockQuote',
                      'numberedList',
                      'bulletedList',
                      '|',
                      'undo',
                      'redo',
                    ],
                  }}
                  onChange={(event, editor) => {
                    const data: any = editor.getData()
                    setDesc(data)
                  }}
                />
                {/* <textarea
                style={{borderRadius: '8px'}}
                  disabled={currentUser?.user?.user_type === 'manager'}
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  className='form-control form-control-lg form-control-solid'
                /> */}
                <span>
                  {intl.formatMessage({ id: 'the.information.you.post.here.will.be.displayed' })}
                </span>
              </div>
            </div>
            {/* <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className='required'>Styreleder:</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                style={{borderRadius: '8px'}}
                  disabled={true}
                  type='text'
                  value={orgDetails?.address}
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                />
              </div>
            </div> */}
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>{intl.formatMessage({ id: 'email.general.manager' })}:</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  style={{ borderRadius: '8px' }}
                  disabled
                  value={orgDetails?.email}
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>{intl.formatMessage({ id: 'email.invoice' })}:</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  style={{ borderRadius: '8px' }}
                  name='invoice_email'
                  type='text'
                  defaultValue={orgDetails?.invoice_email}
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>{intl.formatMessage({ id: 'mobile.general.manager' })}:</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  style={{ borderRadius: '8px' }}
                  disabled
                  value={orgDetails?.mobile_phone}
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>{intl.formatMessage({ id: 'website' })}:</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  style={{ borderRadius: '8px' }}
                  disabled
                  value={orgDetails?.home_page}
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                <span className=''>{intl.formatMessage({ id: 'address' })}:</span>
              </label>

              <div className='col-lg-9 fv-row'>
                <input
                  style={{ borderRadius: '8px' }}
                  disabled
                  value={orgDetails?.address_line1}
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                />
                <input
                  style={{ borderRadius: '8px', marginTop: '16px' }}
                  disabled
                  // eslint-disable-next-line no-useless-concat
                  value={orgDetails?.post_code || `${'' + '  '}${orgDetails?.city}` || ''}
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                />
              </div>
            </div>

            {orgDetails?.msn_date && (
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span className=''>{intl.formatMessage({ id: 'vipps.onboarding' })}:</span>
                </label>
                <div className='col-lg-9 fv-row'>
                  <input
                    style={{ borderRadius: '8px' }}
                    disabled
                    value={`${orgDetails?.msn_status}${` - ${moment.utc(orgDetails?.msn_date).format('DD.MM.YYYY')}`}`}
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=''
                  />
                </div>
              </div>
            )}
            {/* <div className='row mb-6'>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                Varlser og kommunikasjon{' '}
              </label>

              <div className='col-lg-9 fv-row'>
                <div className='d-flex align-items-center mt-3'>
                  <label className='form-check form-check-inline form-check-solid me-5'>
                    <input
                      disabled={true}
                      className='form-check-input'
                      name='communication[]'
                      type='checkbox'
                    />
                    <span className='fw-bold ps-2 fs-6'>e-post</span>
                  </label>

                  <label className='form-check form-check-inline form-check-solid'>
                    <input
                      disabled={true}
                      className='form-check-input'
                      name='communication[]'
                      type='checkbox'
                    />
                    <span className='fw-bold ps-2 fs-6'>Mobil</span>
                  </label>
                </div>
              </div>
            </div> */}
          </div>

          <div className='card-body' style={{ opacity: 0.6 }}>
            <div className='row mb-6 d-flex justify-content-center align-items-center '>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                {intl.formatMessage({ id: 'user.consent.given' })}:
              </label>
              <div className='col-lg-9 fv-row'>
                <div>
                  <label className='form-check form-check-inline form-check-solid me-5'>
                    <input
                      disabled={loading}
                      className='form-check-input'
                      name='userConsent'
                      type='checkbox'
                      checked={userConsent}
                      onChange={() => {}}
                    />
                    <span className='fw-bold ps-2 fs-6'>
                      {' '}
                      {intl.formatMessage({ id: 'user.consent.label' })}
                    </span>
                  </label>
                </div>

                <span className='ms-11'>
                  {intl.formatMessage({
                    id: 'user.is.able.to.give.their.consent.and.share.their.information',
                  })}
                </span>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className='row mb-6 d-flex justify-content-center align-items-center '>
              <label className='col-lg-3 col-form-label fw-bold fs-6'>
                {intl.formatMessage({ id: 'tax.refund' })}:
              </label>

              <div className='col-lg-9 fv-row mt-6'>
                <div className={`${orgDetails?.ssn_tax_refund_enabled ? 'disabled' : ''}`}>
                  <label className='form-check form-check-inline form-check-solid me-5'>
                    <input
                      disabled={loading || orgDetails?.ssn_tax_refund_enabled}
                      className='form-check-input'
                      name='userConsent'
                      type='checkbox'
                      checked={ssnActivation}
                      onChange={ssnHandleCheckboxChange}
                    />
                    <span className='fw-bold ps-2 fs-6'>
                      {intl.formatMessage({ id: 'tax.refund.activation' })}
                    </span>
                  </label>
                </div>

                <div className={`ms-11 ${orgDetails?.ssn_tax_refund_enabled ? 'text-muted' : ''}`}>
                  {orgDetails?.ssn_tax_refund_enabled
                    ? checkboxDescription.alreadyActivated
                    : checkboxDescription.toActivate}
                </div>
              </div>
            </div>
          </div>
        </form>

        {showSwitchToChildOrg && (
          <>
            <div className='card-body'>
              <div className='row mb-6 d-flex justify-content-center align-items-center '>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Endre organisasjon:</label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <select
                      className='form-select form-select-lg form-select-solid'
                      onChange={(e) => {
                        const selectedOrg = otherOrgs.find((o) => o.id === e.target.value)
                        if (selectedOrg && selectedOrg.id !== orgDetails._id) {
                          setTempSwitchOrg(selectedOrg)
                        }
                      }}
                    >
                      {otherOrgs.map((org) => (
                        <option key={org.id} value={org.id || ''}>
                          {org.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div
              className='card-footer d-flex justify-content-end py-6 px-9'
              style={{ borderTop: 'none', marginTop: '-4%' }}
            >
              <button
                type='button'
                className='btn btn-lg btn-primary authbgcolor'
                disabled={loading}
                onClick={async () => {
                  if (tempSwitchOrg) {
                    const body = { organisation_id: tempSwitchOrg.id }
                    await handlePatchRequest(
                      `/organisation_user/update_org_user_switch_org/${currentUser.user?._id}`,
                      body,
                      {}
                    )(setLoading)
                    localStorage.removeItem('division')
                    localStorage.removeItem('Selected_Org')
                    logout()
                    localStorage.clear()
                    window.location.reload()
                  }
                }}
              >
                {!loading && 'Bytt organisasjon'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {intl.formatMessage({ id: 'please.wait' })}...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                  </span>
                )}
              </button>
            </div>
          </>
        )}
      </div>
      <div
        className='card-footer d-flex justify-content-end py-6 px-9 mt-4'
        style={{ borderTop: 'none', marginTop: '-4%' }}
      >
        <button
          type='button'
          className='btn btn-lg btn-primary authbgcolor'
          disabled={loading}
          onClick={handleClick}
        >
          {!loading && intl.formatMessage({ id: 'Update' })}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              {intl.formatMessage({ id: 'please.wait' })}...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          )}
        </button>
      </div>
      <Modal show={showConsentWarningModal} onHide={handleClose} size='lg' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title> {intl.formatMessage({ id: 'warning' })}</Modal.Title>
        </Modal.Header>

        <div>
          <Modal.Body>
            <div className='mb-6 fs-3 faqmodal'>
              {intl.formatMessage({ id: 'turn.off.user.consent.modal' })}
            </div>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <div className='row  w-100' style={{ marginTop: '-10px' }}>
            <div className='d-flex justify-content-between'>
              <button type='button' className='btn btn-light me-3' onClick={handleClose}>
                {intl.formatMessage({ id: 'Cancel' })}
              </button>
              <button
                type='button'
                onClick={handleCloseAndcheckTheBox}
                className='btn btn-lg btn-primary authbgcolor'
              >
                {intl.formatMessage({ id: 'Confirm.of.turning.off' })}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showFeeIncreaseConsentModal}
        onHide={handleCloseFeeIncreaseModal}
        size='lg'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{intl.formatMessage({ id: 'verification.of.activation' })}</Modal.Title>
        </Modal.Header>

        <div>
          <Modal.Body>
            <div className='mb-6 fs-4 faqmodal'>
              {intl.formatMessage({ id: 'description.of.SSN.activation' })}
            </div>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <div className='row  w-100' style={{ marginTop: '-10px' }}>
            <div className='d-flex justify-content-between'>
              <button
                type='button'
                className='btn btn-light me-3'
                onClick={handleCloseFeeIncreaseModal}
              >
                {intl.formatMessage({ id: 'Cancel' })}
              </button>
              <button
                type='button'
                onClick={handleCloseAndActivateGettingSSN}
                className='btn btn-lg btn-primary authbgcolor'
              >
                {intl.formatMessage({ id: 'confirm.and.activate.it' })}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default SettingsMiansp
