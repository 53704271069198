import { useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import UploadModal from '../../../settings/UploadModal'
import { Manifest } from '../StudioManifest'
import { StudioUtils } from '../StudioUtils'

export function SelectImageDialog(props: any) {
  const [imageSource, setImageSource] = useState('predefined')
  const [filetype, setfiletype] = useState('png')
  const [uploadedPicture, setUploadedPicture] = useState<any>()
  const [showModal, setShowModal] = useState(false)

  const uploadPicture = (e: any) => {
    if (!e.target.files[0]) {
      toast.error('Opplastning feilet')
      return
    }
    const fileExtensions = ['png', 'jpg', 'jpeg']
    const fileName = e.target.files[0].name
    const fileExtension = fileName.split('.').pop()
    const fileSize = e.target.files[0].size
    const maxFileSize = 2 * 1024 * 1024 // 2MB in bytes

    if (!fileExtensions.includes(fileExtension)) {
      toast.error('Tillatt filtype: jpg, jpeg, png.')
      return
    }
    setfiletype(fileExtension)

    if (fileSize > maxFileSize) {
      toast.error('Filstørrelsen må være mindre enn 2MB.')
      return
    }
    setUploadedPicture(e.target.files[0])
    setShowModal(true)
  }

  const setCroppedImage = (image: string) => {
    if (!image) {
      setShowModal(false)
      setUploadedPicture(null)
      return
    }
    setShowModal(false)
    fetch(image)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = () => {
          const base64 = reader.result as string
          props.setImage({
            base64,
          })
          props.onHide()
        }
      })
  }

  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Velg bilde</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div key='image-radio' className='mb-3'>
          <Form.Check
            inline
            label='Predefinerte bilder'
            name='group1'
            type='radio'
            checked={imageSource === 'predefined'}
            id='image-radio-1'
            onChange={() => {
              setImageSource('predefined')
            }}
          />
          <Form.Check
            inline
            label='Last opp bilde'
            name='group1'
            type='radio'
            id='image-radio-2'
            checked={imageSource === 'upload'}
            onChange={() => {
              setImageSource('upload')
            }}
          />
        </div>
        {imageSource === 'predefined' && (
          <div className='select-image'>
            {Manifest.uneditedImagesArr.map((image, index) => (
              <button
                key={index}
                className='image-container'
                onClick={async () => {
                  const base64 = await StudioUtils.imageUrlToBase64(image.src, () => {})
                  props.setImage({
                    base64,
                  })
                  props.onHide()
                }}
              >
                <Spinner animation='border' />
                <img key={index} src={image.src} alt={image.title} />
              </button>
            ))}
          </div>
        )}
        {imageSource === 'upload' && (
          <Form.Group controlId='formFile'>
            <Form.Label>Last opp bilde</Form.Label>
            <Form.Control
              type='file'
              accept='.png, .jpg, .jpeg'
              className='mb-3 editor-upload-image'
              onChange={(e) => {
                uploadPicture(e)
              }}
            />
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Lukk</Button>
      </Modal.Footer>
      {uploadedPicture && showModal && (
        <UploadModal
          setModalImage={setCroppedImage}
          imagetype={filetype}
          image={URL.createObjectURL(uploadedPicture)}
        />
      )}
    </Modal>
  )
}
