import React, { useEffect, useState } from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { getOrgByName } from '../core/_requests'

type OptionType = {
  value: number | null
  label: string
}

const NifLogin: React.FC = () => {
  const [value, onChange] = useState<OptionType | null>()
  let hasMore = true
  let searchTerm = ''

  useEffect(() => {
    // add background to body tag
    document.body.classList.add('bg-nif')
    document.body.style.background = `url(${toAbsoluteUrl('/media/bg/LoginBG.png')})`
    return () => {
      // remove background from body tag
      document.body.classList.remove('bg-nif')
      document.body.style.background = ''
    }
  }, [])

  const loadOptions = async (search: string) => {
    let orgs = []

    if (search) {
      const pageSize = 50

      try {
        const response: any = await getOrgByName(search, pageSize)
        orgs = response.data.data.map((org: any) => ({
          value: org?.org_id,
          label: org.org_name,
        }))

        hasMore = false
      } catch (error) {
        searchTerm = ''
      }
    }
    return {
      options: [...orgs],
      hasMore,
    }
  }

  const handleNifLogin = () => {
    localStorage.setItem('orgId', value?.value?.toString() || '')
    window.location.href =
      'https://auth.nif.buypass.no/auth/realms/nif/protocol/openid-connect/auth?scope=openid&response_type=code&client_id=stotteapparatet-client&redirect_uri=https://org.stotte.no/auth&state=3c725e0151db3&response_mode=query '
  }

  useEffect(() => {
    if (value?.value) handleNifLogin()
  }, [value])

  return (
    <main className='nif-card card card-flush d-flex align-items-center col-md-5 col-lg-4 col-11 '>
      <img
        src={toAbsoluteUrl('/media/logos/logo-nif.svg')}
        alt='logo'
        className='id-logo img-responsive col-6 col-xs-8 col-sm-8 '
      />
      <div className=' col-lg-10 col-md-10 col-sm-10 col-xs-10 col-10 '>
        <AsyncPaginate
          value={value}
          onChange={onChange}
          loadOptions={loadOptions}
          debounceTimeout={500}
          components={{
            DropdownIndicator: null,
          }}
          placeholder='Skriv inn klubbnavn...'
          styles={{
            control: (provided) => ({
              ...provided,
              border: ' 1px solid #E6E7E9',
              borderRadius: '8px',
              height: '50px',
            }),
          }}
          noOptionsMessage={() => (searchTerm ? 'Ingen resultater' : null)}
        />
      </div>

      <p className='mt-4 text-center col-md-8 px-4 pt-2 '>
        Søk etter klubben din og klikk på klubbnavnet du ønsker å logge inn for.
      </p>
    </main>
  )
}

export default NifLogin
