import { Label } from 'zimjs'
import { AbstractContainer } from './AbstractContainer'

interface ITextConfig {
  text: string
  size: number
  color: string
  font: 'Dosis' | 'Roboto'
}
export class CenteredText extends AbstractContainer {
  private text: Label | undefined

  private textConfig: ITextConfig | undefined

  constructor(width: number, height: number, textConfig: ITextConfig) {
    super(width, height)
    this.textConfig = textConfig
  }

  private updateTextLayout() {
    this.updateLayout()
  }

  // eslint-disable-next-line class-methods-use-this
  updateLayout() {}

  setText(text: string, color?: string) {
    if (this.textConfig) {
      if (this.text) {
        this.text.text = text
        this.text.color = color || this.textConfig.color
      } else
        this.text = new Label({
          text,
          color: color || this.textConfig.color,
          font: this.textConfig.font,
          align: 'center',
          valign: 'center',
          padding: 0,
          size: this.textConfig.size,
        }).addTo(this)

      this.updateTextLayout()
    }
  }
}
