import React from 'react'
import { Modal } from 'react-bootstrap'
import CampaignRecruiterForm from './creation/CampaignRecruiterForm'

type Props = {
  showModalConfirm: boolean
  setShowModalConfirm: (show: boolean) => void
  handleUpload: (
    recruiters: { name: string; email: string }[],
    closeDialog: boolean
  ) => Promise<void>
}

const CampaignRecruiterUploadModal: React.FC<Props> = ({
  showModalConfirm,
  setShowModalConfirm,
  handleUpload,
}) => {
  return (
    <Modal
      show={showModalConfirm}
      onHide={() => setShowModalConfirm(false)}
      size='lg'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className='modal-title'>Legg til deltagere</h5>
        </Modal.Title>
      </Modal.Header>
      <div className='modal-body'>
        <CampaignRecruiterForm
          submitForm={() => {
            setShowModalConfirm(false)
          }}
          handleUpload={handleUpload}
          stage='edit'
        />
      </div>
    </Modal>
  )
}

export default CampaignRecruiterUploadModal
