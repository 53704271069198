import clsx from 'clsx'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { addBearer, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useMainContext } from '../../../../context'
import { useAuth } from '../core/Auth'
import { setAuth, setCurrentUser, setRefreshToken, setToken } from '../core/AuthHelpers'
import { verifyOtp } from '../core/_requests'

const initialValues = {
  otp: '',
}

const otpSchema = Yup.object().shape({
  otp: Yup.string().required('OTP is required'),
})

export function VerifyOTP() {
  const [loading, setLoading] = useState(false)
  const { saveAuth } = useAuth()
  const navigate = useNavigate()
  const { setCompanyonPortal } = useMainContext()
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const formik = useFormik({
    initialValues,
    validationSchema: otpSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const id = localStorage.getItem('otp_id')
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        verifyOtp(id, values.otp.trim())
          .then((auth) => {
            setHasErrors(false)
            setLoading(false)
            setCurrentUser(auth.data.data)
            setAuth(auth.data.data)
            setRefreshToken(addBearer(auth.headers.authorization))
            setToken(addBearer(auth.data.data.access_token))
            localStorage.setItem('currentUser', JSON.stringify(auth.data.data))
            // eslint-disable-next-line eqeqeq
            if (auth.data.data.user.user_type === 'admin') {
              setCompanyonPortal(null, 'NO', auth.data.data?.user?.organisation_id.org_name)
            }
            localStorage.removeItem('otp_id')
            window.location.href = '/dashboard'
            // navigate('/dashboard')
          })
          .catch(() => {
            saveAuth(undefined)
            setHasErrors(true)
            setLoading(false)
            setTimeout(() => {
              navigate('/auth/login')
            }, 3000)
            setSubmitting(false)
            setStatus('OTP not verified')
          })
      }, 1000)
    },
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className=' mb-10'>
        {/* begin::Title */}

        <div className=' mb-10 d-flex ' style={{ alignItems: 'center' }}>
          <img
            src={toAbsoluteUrl('/media/logos/S-logo-full-dark.png')}
            alt='logo'
            className='svg-icon-cufs mb-14'
            style={{ maxHeight: '40px' }}
          />
          {/* <h2 className='text-dark mb-14 mx-2'>Støtte AS</h2> */}
        </div>

        <h1 className='authtext'>Bekreft pålogging</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400'>
          Skriv inn engangskoden (OTP) som du har mottatt på din registrerte e-postadresse for å
          fullføre påloggingen
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}

      {hasErrors === true && (
        <div className='mb-10 bg-light-success p-8 rounded'>
          <div className='text-dark'>
            Engangspassordet du har skrevet er ikke gyldig. Vennligst prøv igjen med ny kode.
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Engangspassord (OTP)</label>
        <input
          type='text'
          placeholder='Engangspassord'
          autoComplete='off'
          {...formik.getFieldProps('otp')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.otp && formik.errors.otp,
            }
            // {
            //   'is-invalid': formik.touched.password && formik.errors.password,
            // },
            // {
            //   'is-valid': formik.touched.password && !formik.errors.password,
            // }
          )}
        />
        {/* {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )} */}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='row d-flex justify-content-between' style={{ marginBottom: '140px' }}>
        <Link to='/auth/login' style={{ width: 'auto' }}>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light me-3'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Avbryt
          </button>
        </Link>{' '}
        <button
          style={{ width: '160px' }}
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-lg btn-primary authbgcolor mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          <span className='indicator-progress'>
            Logg inn
            {loading && <span className='spinner-border spinner-border-sm align-middle ms-2' />}
          </span>
        </button>
      </div>

      {/* end::Form group */}
    </form>
  )
}
