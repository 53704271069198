import ApexCharts, { ApexOptions } from 'apexcharts'
import { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { ChartData } from '../DashboardPage'

const DashboardChartCell = ({
  chartData,
  displayAsLinkToReporting,
}: {
  chartData: ChartData[] | undefined
  displayAsLinkToReporting?: boolean
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartData))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, chartData])
  if (displayAsLinkToReporting)
    return (
      <Link
        className='card card card-xl-stretchcard_borderC'
        to='/org/reporting'
        style={{ backgroundColor: '#ffff', width: '100%', height: '100%', textAlign: 'center' }}
      >
        {/* begin::Body */}
        <div className='card-body d-flex flex-column align-items-center'>
          <span className='text-dark fs-2hx '>Støtte mottatt de siste 3 månedene</span>
          <span className='text-dark fs-4 mb-1'>Trykk her for mer detaljer</span>
          <div ref={chartRef} style={{ width: '100%' }} />
        </div>
      </Link>
    )
  return (
    <div
      className='card card card-xl-stretchcard_borderC'
      style={{ backgroundColor: '#ffff', width: '100%', height: '100%', textAlign: 'center' }}
    >
      {/* begin::Body */}
      <div className='card-body d-flex flex-column align-items-center'>
        <span className='text-dark fs-2hx mb-3'>Støtte mottatt de siste 3 månedene</span>
        <div ref={chartRef} style={{ width: '100%' }} />
      </div>
    </div>
  )
}

const chartOptions = (chartData: ChartData[] | undefined): ApexOptions => {
  const weeks = chartData?.map((d) => d.week) || []
  return {
    series: [
      {
        name: 'Støtte',
        data: chartData?.map((d) => d.totalAmount) || [],
      },
    ],

    chart: {
      height: '90%',
      type: 'line',

      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      colors: ['#21285c'],
    },
    markers: {
      size: 4,
      colors: ['#21285c'],
    },
    yaxis: {
      min: 0,
      forceNiceScale: true,
      title: {
        text: 'Støtte mottatt ',
        style: {
          color: '#000000',
        },
      },
    },

    xaxis: {
      categories: weeks,
      title: {
        text: 'Uker',
        style: {
          color: '#000000',
        },
      },
      tooltip: {
        enabled: true,
        formatter: (val, opts: any) => {
          return opts?.series[0][+val - 1]
        },
      },
    },
  }
}

export { DashboardChartCell }
