/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'

type Props = {
  className: string
}

const tabelData: any = [
  {
    id: 1,
    src: toAbsoluteUrl('/media/logos/VikingFKLogo.png'),
    Selskap: 'Viking FK - Jenter 10',
    Selskaplabel: '129',
    Orgnummer: '97',
    Aksjeandel: '97',
    Askjepris: '1267,-',
  },
  {
    id: 2,
    src: toAbsoluteUrl('/media/logos/VikingFKLogo.png'),
    Selskap: 'Viking FK - Jenter 17',
    Selskaplabel: '129',
    Orgnummer: '97',
    Aksjeandel: '97',
    Askjepris: '1267,-',
  },
  {
    id: 3,
    src: toAbsoluteUrl('/media/logos/VikingFKLogo.png'),
    Selskap: 'Viking FK - Jenter 15',
    Selskaplabel: '129',
    Orgnummer: '97',
    Aksjeandel: '97',
    Askjepris: '1267,-',
  },
  {
    id: 4,
    src: toAbsoluteUrl('/media/logos/VikingFKLogo.png'),
    Selskap: 'Viking FK - Gutter 16',
    Selskaplabel: '129',
    Orgnummer: '97',
    Aksjeandel: '97',
    Askjepris: '1267,-',
  },
  {
    id: 5,
    src: toAbsoluteUrl('/media/logos/VikingFKLogo.png'),
    Selskap: 'Viking FK - Jenter 16',
    Selskaplabel: '129',
    Orgnummer: '97',
    Aksjeandel: '97',
    Askjepris: '1267,-',
  },
]

const CPTable1: React.FC<Props> = ({ className }) => (
  <div className={`card ${className}`}>
    {/* begin::Header */}
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>Utbetalinger</span>
        <span className='text-muted mt-1 fw-bold fs-7'>
          Oversikt over tilgjengelige og utforte utbetalinger
        </span>
      </h3>

      <div className='card-toolbar'>
        {/* begin::Menu */}
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
        </button>
        {/* begin::Menu 2 */}
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
          data-kt-menu='true'
        >
          {/* begin::Menu item */}
          <div className='menu-item px-3'>
            <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>Quick Actions</div>
          </div>
          {/* end::Menu item */}
          {/* begin::Menu separator */}
          <div className='separator mb-3 opacity-75' />
          {/* end::Menu separator */}
          {/* begin::Menu item */}
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-3'>
              New Ticket
            </a>
          </div>
          {/* end::Menu item */}
          {/* begin::Menu item */}
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-3'>
              New Customer
            </a>
          </div>
          {/* end::Menu item */}
          {/* begin::Menu item */}
          <div
            className='menu-item px-3'
            data-kt-menu-trigger='hover'
            data-kt-menu-placement='right-start'
            data-kt-menu-flip='left-start, top'
          >
            {/* begin::Menu item */}
            <a href='#' className='menu-link px-3'>
              <span className='menu-title'>New Group</span>
              <span className='menu-arrow' />
            </a>
            {/* end::Menu item */}
            {/* begin::Menu sub */}
            <div className='menu-sub menu-sub-dropdown w-175px py-4'>
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3'>
                  Admin Group
                </a>
              </div>
              {/* end::Menu item */}
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3'>
                  Staff Group
                </a>
              </div>
              {/* end::Menu item */}
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3'>
                  Member Group
                </a>
              </div>
              {/* end::Menu item */}
            </div>
            {/* end::Menu sub */}
          </div>
          {/* end::Menu item */}
          {/* begin::Menu item */}
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-3'>
              New Contact
            </a>
          </div>
          {/* end::Menu item */}
          {/* begin::Menu separator */}
          <div className='separator mt-3 opacity-75' />
          {/* end::Menu separator */}
          {/* begin::Menu item */}
          <div className='menu-item px-3'>
            <div className='menu-content px-3 py-3'>
              <a className='btn btn-primary btn-sm px-4' href='#'>
                Generate Reports
              </a>
            </div>
          </div>
          {/* end::Menu item */}
        </div>
        {/* end::Menu 2 */}
        {/* end::Menu */}
      </div>
    </div>
    {/* end::Header */}
    {/* begin::Body */}
    <div className='card-body py-3'>
      {/* begin::Table container */}
      <div className='table-responsive'>
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted bg-light'>
              <th className='ps-4 rounded-start'>Formål</th>
              <th className=''>Belop</th>
              <th className=''>Status</th>
              <th className=''>Dato utbetaling</th>

              <th className=''>Utført av</th>
              <th className=' text-end rounded-end px-4'>Støtte mottatt</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {tabelData.map((tbData: any) => (
              <tr key={tbData.id}>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label bg-light'>
                        <img src={tbData.src} className='h-75 align-self-end' alt='' />
                      </span>
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {tbData.Selskap}
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    {tbData.Askjepris}
                  </a>
                </td>
                <td>
                  <div className='badge badge-light-success fw-bolder'>Utbetalt</div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    09.09.2022
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    Eirik Bjørnø
                  </a>
                </td>
                <td className='text-end'>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    <button type='submit' className=' btn  badge badge-light-success  '>
                      Kvittering
                    </button>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      {/* end::Table container */}
    </div>
    {/* begin::Body */}
  </div>
)

export { CPTable1 }
