//  React-bootstrap Modal

import $ from 'jquery'
import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

interface Props {
  show: boolean
  handleClose: () => void
}

const FAQModal: React.FC<Props> = ({ show, handleClose }) => {
  const intl = useIntl()
  useEffect(() => {
    // eslint-disable-next-line func-names
    $(document).on('click', '.faqmodal .collapsible.py-3.toggle', function (this: HTMLElement) {
      $('.faqmodal').find('.collapse').removeClass('show')
      $(this).siblings('.collapse').addClass('show')
    })

    // removeClass('show')
  }, [])

  return (
    <Modal show={show} onHide={handleClose} size='lg' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Ofte stilte spørsmål</Modal.Title>
      </Modal.Header>

      <div>
        <Modal.Body>
          <div className='row mb-12 faqmodal'>
            <div className='col-md-12 pe-md-10 mb-10 mb-md-0'>
              <div className='m-0'>
                <div className='d-flex align-items-center collapsible py-3 toggle mb-0'>
                  <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                    <i className='bi bi-dash-square' />
                  </div>

                  <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>
                    {intl.formatMessage({ id: 'faq.modal.text1' })}
                  </h4>
                </div>

                <div id='kt_job_4_1' className='collapse show fs-6 ms-1'>
                  <div className='mb-4 text-gray-600 fw-semibold fs-6 ps-10'>
                    {intl.formatMessage({ id: 'faq.modal.text2' })}
                  </div>
                </div>

                <div className='separator separator-dashed' />
              </div>

              <div className='m-0'>
                <div
                  className='d-flex align-items-center collapsible py-3 toggle collapsed mb-0'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_job_4_2'
                >
                  <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                    <i className='bi bi-dash-square' />
                  </div>

                  <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>
                    {intl.formatMessage({ id: 'faq.modal.text3' })}
                  </h4>
                </div>

                <div id='kt_job_4_2' className='collapse fs-6 ms-1'>
                  <div className='mb-4 text-gray-600 fw-semibold fs-6 ps-10'>
                    {intl.formatMessage({ id: 'faq.modal.text4' })}
                  </div>
                </div>

                <div className='separator separator-dashed' />
              </div>

              <div className='m-0'>
                <div
                  className='d-flex align-items-center collapsible py-3 toggle collapsed mb-0'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_job_4_3'
                >
                  <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                    <i className='bi bi-dash-square' />
                  </div>

                  <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>
                    {intl.formatMessage({ id: 'faq.modal.text5' })}
                  </h4>
                </div>

                <div id='kt_job_4_3' className='collapse fs-6 ms-1'>
                  <div className='mb-4 text-gray-600 fw-semibold fs-6 ps-10'>
                    {intl.formatMessage({ id: 'faq.modal.text6' })}
                  </div>
                </div>

                <div className='separator separator-dashed' />
              </div>

              <div className='m-0'>
                <div
                  className='d-flex align-items-center collapsible py-3 toggle collapsed mb-0'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_job_4_4'
                >
                  <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                    <i className='bi bi-dash-square' />
                  </div>

                  <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>
                    {intl.formatMessage({ id: 'faq.modal.text7' })}
                  </h4>
                </div>

                <div id='kt_job_4_4' className='collapse fs-6 ms-1'>
                  <div className='mb-4 text-gray-600 fw-semibold fs-6 ps-10'>
                    {intl.formatMessage({ id: 'faq.modal.text8' })}
                  </div>
                </div>
                <div className='separator separator-dashed' />
              </div>

              <div className='m-0'>
                <div
                  className='d-flex align-items-center collapsible py-3 toggle collapsed mb-0'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_job_4_5'
                >
                  <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                    <i className='bi bi-dash-square' />
                  </div>

                  <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>
                    {intl.formatMessage({ id: 'faq.modal.text9' })}
                  </h4>
                </div>

                <div id='kt_job_4_5' className='collapse fs-6 ms-1'>
                  <div className='mb-4 text-gray-600 fw-semibold fs-6 ps-10'>
                    {intl.formatMessage({ id: 'faq.modal.text10' })}
                  </div>
                </div>
                <div className='separator separator-dashed' />
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  )
}

export default FAQModal
