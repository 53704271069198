/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'

import Table1 from './Table1'
import Table2 from './Table2'

const MarketingMain = () => {
  return (
    <>
      <div className='row gy-5 g-xl-10'>
        {/* begin::Col */}
        <div className='col-xl-6'>
          <div
            className='card card-xl-stretch mb-xl-10 card_borderC'
            style={{
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${toAbsoluteUrl('/media/misc/marketing.png')})`,
            }}
          >
            {/* begin::Body */}
            <div className='card-body d-flex flex-column'>
              {/* begin::Wrapper */}
              <div className='d-flex flex-column flex-grow-1'>
                {/* begin::Title                    */}
                <a href='#' className=' text-hover-primary fw-bolder fs-3 text-white'>
                  Spre ordet
                </a>
                <p className=' mt-1 fw-bold fs-6 text-white'>
                  I Støtteapparatet har vi tenkt gjennom hele prosessen og gjort det enkelt for
                  organisasjoner å spre ordet om sin klubb og sine formål. Under finner du
                  tilgjengelig materiale du kan anvende for markedsføring og deling.
                </p>
              </div>

              <div className='pt-5'>
                <button type='button' className='btn btn-sm btn-danger'>
                  Kom i gang
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='col-xl-3'>
          <div className='card card-xl-stretch mb-xl-10 card_borderC p-5'>
            <div className='d-flex align-items-center mb-7'>
              {/* begin::Avatar */}
              <div className='symbol symbol-50px me-3'>
                <KTSVG
                  path='/media/icons/duotune/communication/com003.svg'
                  className='svg-icon-dark svg-icon-2hx'
                />
              </div>
              {/* end::Avatar */}
              {/* begin::Text */}
              <div className='flex-grow-1'>
                <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                  Tilgjengelig for deling
                </a>
              </div>
              {/* end::Text */}
            </div>
            <div className='d-flex align-items-center mb-3'>
              <div className='form-check form-check-custom form-check-solid mx-3'>
                <input className='form-check-input' type='checkbox' value='' />
              </div>
              {/* end::Checkbox */}
              {/* begin::Description */}
              <div className='flex-grow-1'>
                <a href='#' className='text-muted text-hover-primary fw-bolder fs-6'>
                  Create FireStone Logo
                </a>
              </div>
              {/* end::Description */}
              <span className='text-muted fs-8 fw-bolder'>44</span>
            </div>
            <div className='d-flex align-items-center mb-3'>
              <div className='form-check form-check-custom form-check-solid mx-3'>
                <input className='form-check-input' type='checkbox' value='' />
              </div>
              {/* end::Checkbox */}
              {/* begin::Description */}
              <div className='flex-grow-1'>
                <a href='#' className='text-muted text-hover-primary fw-bolder fs-6'>
                  Create FireStone Logo
                </a>
              </div>
              {/* end::Description */}
              <span className='text-muted fs-8 fw-bolder'>44</span>
            </div>
            <div className='d-flex align-items-center mb-3'>
              <div className='form-check form-check-custom form-check-solid mx-3'>
                <input className='form-check-input' type='checkbox' value='' />
              </div>
              {/* end::Checkbox */}
              {/* begin::Description */}
              <div className='flex-grow-1'>
                <a href='#' className='text-muted text-hover-primary fw-bolder fs-6'>
                  Create FireStone Logo
                </a>
              </div>
              {/* end::Description */}
              <span className='text-muted fs-8 fw-bolder'>44</span>
            </div>
          </div>
        </div>

        <div className='col-xl-3'>
          <div className='card card-xl-stretch mb-xl-10 card_borderC p-5'>
            <div className='d-flex align-items-center mb-7'>
              {/* begin::Avatar */}
              <div className='symbol symbol-50px me-3'>
                <KTSVG
                  path='/media/icons/duotune/communication/com003.svg'
                  className='svg-icon-dark svg-icon-2hx'
                />
              </div>
              {/* end::Avatar */}
              {/* begin::Text */}
              <div className='flex-grow-1'>
                <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                  Stats på deling
                </a>
              </div>
              {/* end::Text */}
            </div>
            <div className='d-flex align-items-center mb-3'>
              <div className='form-check form-check-custom form-check-solid mx-3'>
                <input className='form-check-input' type='checkbox' value='' />
              </div>
              {/* end::Checkbox */}
              {/* begin::Description */}
              <div className='flex-grow-1'>
                <a href='#' className='text-muted text-hover-primary fw-bolder fs-6'>
                  Create FireStone Logo
                </a>
              </div>
              {/* end::Description */}
              <span className='text-muted fs-8 fw-bolder'>44</span>
            </div>
            <div className='d-flex align-items-center mb-3'>
              <div className='form-check form-check-custom form-check-solid mx-3'>
                <input className='form-check-input' type='checkbox' value='' />
              </div>
              {/* end::Checkbox */}
              {/* begin::Description */}
              <div className='flex-grow-1'>
                <a href='#' className='text-muted text-hover-primary fw-bolder fs-6'>
                  Create FireStone Logo
                </a>
              </div>
              {/* end::Description */}
              <span className='text-muted fs-8 fw-bolder'>44</span>
            </div>
            <div className='d-flex align-items-center mb-3'>
              <div className='form-check form-check-custom form-check-solid mx-3'>
                <input className='form-check-input' type='checkbox' value='' />
              </div>
              {/* end::Checkbox */}
              {/* begin::Description */}
              <div className='flex-grow-1'>
                <a href='#' className='text-muted text-hover-primary fw-bolder fs-6'>
                  Create FireStone Logo
                </a>
              </div>
              {/* end::Description */}
              <span className='text-muted fs-8 fw-bolder'>44</span>
            </div>
          </div>
        </div>
      </div>
      <Table1 className='mb-5 mb-xl-8' />
      <Table2 className='mb-5 mb-xl-8' />
    </>
  )
}

export { MarketingMain }
