import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { AsideMenu } from './AsideMenu'
// import {AsideUserMenu} from '../../../partials'
import { useIntl } from 'react-intl'
import { useAuth } from '../../../../app/modules/auth'

function AsideDefault() {
  const { classes } = useLayout()
  const { logout } = useAuth()
  const intl = useIntl()
  const isInTest = window.location.hostname === 'testorg.stotte.no'
  const navigate = useNavigate()
  return (
    <div
      id='kt_aside'
      className={clsx('aside py-9', classes.aside.join(' '), isInTest ? 'test-indicator' : '')}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width='300px'
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      {/* begin::Brand */}
      <div className='d-flex ' style={{ alignItems: 'center', marginLeft: '25px' }}>
        <Link to='/dashboard'>
          <img
            src={toAbsoluteUrl('/media/logos/S-logo-full-dark.png')}
            alt='logo'
            className='svg-icon-cufs mb-14'
            style={{ maxHeight: '40px' }}
          />
        </Link>
        {/* <h2 className='text-dark mb-14 mx-2'>Støtte AS</h2> */}
      </div>
      {/* <div className='aside-logo flex-column-auto px-3 mb-1' id='kt_aside_logo'>
        <Link to='/dashboard'>
          <img
            alt='Logo'
            className='h-50px logo'
            src={toAbsoluteUrl('/media/logos/SlogoNew.jpg')}
          />
        </Link>
      </div> */}
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div
        id='kt_aside_menu'
        style={{ marginTop: '-10px' }}
        className='aside-menu flex-column-fluid ps-5 pe-3'
      >
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      <div className='aside-footer  px-5'>
        {/* <AsideUserMenu /> */}
        <div className='menu-item'>
          <div className={clsx('menu-link')} id='kt_activities_toggle'>
            <span className='menu-icon'>
              <i className='fa-solid fa-headset text-primary' />
              {/* <KTSVG
                path='fa-duotone fa-lock-keyhole'
                className='svg-icon-4 customSizeMenuIcon'
                svgClassName=' '
              /> */}
            </span>
            <span className='menu-title '>Støtte support</span>
          </div>
          <div
            className={clsx('menu-link')}
            onClick={() => {
              localStorage.removeItem('division')
              localStorage.removeItem('Selected_Org')
              logout()
              localStorage.clear()
              window.location.reload()
            }}
          >
            <span className='menu-icon'>
              <i className='fa-light fa-lock-keyhole' />
              {/* <KTSVG
                path='fa-duotone fa-lock-keyhole'
                className='svg-icon-4 customSizeMenuIcon'
                svgClassName=' '
              /> */}
            </span>
            <span className='menu-title '> {intl.formatMessage({ id: 'logout' })}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export { AsideDefault }
