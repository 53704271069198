const packagesNO = (hasVideo) => [
  {
    _id: '6501f8498d16cf5d833daef4',
    id: 1,
    object: {
      id: 1,
      title: 'Registrering',
      subTitle: 'Kom i gang',
      card: [
        {
          id: 1,
          title: 'Mandag',
          description: 'Send ut epost til trener,\n                  admin og tillitsvalgte',
          button: 'Kopier tekst',
          title1: 'Onboarding',
          description2: 'Epost til trenere, administrasjon og tillitsvalgte',
          icon: '/media/icons/address-card-solid.svg',
          column: 'Epost mal',
          button1: '',
          button2: '',
          modalData: {
            cardType: 'card1',
            mainTitle: 'Onboarding: Epost til trenere, administrasjon og tillitsvalgte',
            subTitle:
              'Start med å sende ut en e-post til trenere, administrasjon og tillitsvalgte der dere informerer om Støtte-appen og at dere nå har registrert klubben som mottaker.',
            input1: 'Gladmelding: Nå kan vi motta støtte digitalt! Hele året!',
            input2:
              'Hei xxx<br/><br/>Hurra - vi lar teknologien overta deler av salgsdugnaden! Støtte er en digital kronerullingstjeneste som gjør at vi slipper å fylle opp bilen med sokker og dopapir. Appen er utviklet i samarbeid med Norges Idrettsforbund. <br/><br/>Den er svært enkel i bruk og koples sammen med din Vipps-konto: Et valgfritt beløp blir trukket fra givers konto etter utført handel (minimum 2 kroner). Det vil bety en jevn kontantstrøm for klubben, uavhengig av dugnader og andre inntektsbringende aktiviteter.<br/><br/>Last ned appen her: <a href="https://stotte.no/spre-budskapet/">https://stotte.no/spre-budskapet</a><br/><br/> Vi har registrert klubben og opprettet følgende formål: <br/><br/>- Formål A<br/>- Formål B<br/><br/>I løpet av de nærmeste dagene sender vi ut informasjon til alle våre medlemmer, og deler det glade budskapet i sosiale medier. Fint om du som trener/tillitsvalgt i forkant leser mer om appen her: <a href="https://stotte.no/for-organisasjoner/">https://stotte.no/for-organisasjoner</a><br/><br/>Og ikke minst: fortell alle du kjenner om Støtte!<br/><br/>TUSEN TAKK for hjelpen! Det betyr enormt mye for både klubbens fremtid og alle barn og unge vi til daglig aktiviserer.',
          },
          modalData1: {},
          modalData2: {},
        },
      ],
    },
  },
  {
    _id: '6501fa068d16cf5d833daef5',
    id: 2,
    object: {
      id: 2,
      title: 'Uke 1',
      subTitle: 'Info om Støtteappen',
      card: [
        {
          id: 1,
          title: 'Mandag',
          description: hasVideo
            ? "Send videosnutt fra 'Klubb til medlemmer'"
            : "Send epost fra 'Klubb til medlemmer'",
          button: 'Kopier tekst',
          title1: 'Uke 1, del 1: Epost til medlemmer',
          description2: 'Epost fra klubb til medlemmer',
          icon: '/media/icons/envelope-solid.svg',
          column: 'Epost mal',
          button1: '',
          button2: '',
          modalData: {
            cardType: 'card2_1',
            mainTitle: 'Uke 1: Epost til medlemmer',
            subTitle:
              '<p>Send videosnutten «Fra klubb til medlemmer» på e-post til alle medlemmer.<br><br>NB! Av personvernhensyn anbefaler vi at du legger til medlemmenes e-post-adresser i blindkopi-feltet (Bcc), slik at de som mottar ikke ser alle andre medlemmenes navn. I To-/Til-feltet legger du inn klubbens e-postadresse.</p>',
            input1: 'STØTTE-appen: Har du fått med deg denne muligheten?',
            input2: `${hasVideo ? '<p>Se vedlagte film (ca. 50 sek) og ta action! Mer info nederst i denne e-posten,\n           men se filmen først.<br>LINK: <a href="video_url">video_display_url</a><br><br>' : ''}Vi setter stor pris på alle våre medlemmer og er så takknemlige \n           for den støtten vi mottar. Nå håper vi du griper muligheten til å gi din støtte digitalt gjennom \n           den nyutviklede appen Støtte.<br><br>\n          Appen gjør det mulig for alle som har Vipps gi et lite kronebeløp som går til\n           klubben hver gang du bruker penger fra en valgt konto. I hvilken som helst butikk,\n            helt automatisk! Det betyr at klubben får litt penger fra veldig mange – jevnt og trutt.\n            \n            Og det beste av alt – du merker det så vidt. Men for oss betyr det all verden for å kunne\n             gi deg og alle våre medlemmer det aller beste tilbudet.<br><br>Du finner klubben vår \n             (lag/organisasjon) enkelt i appen, og vi har opprettet ulike formål du kan velge å støtte. \n             Du bestemmer hvor mange kroner – f.eks. 2, 5 eller 10 – som du vil gi hver gang du betaler noe.\n              Ja, du kan endre beløp eller sette på pause når som helst.<br><br>Last ned Støtte-appen og bli en verdsatt støttespiller \n              du også!&nbsp;<br>LINK: <a href='https://stotte.no/spre-budskapet'>https://stotte.no/spre-budskapet</a><br><br>\n              På forhånd TUSEN TAKK, det betyr enormt mye for både klubbens fremtid og alle barn og unge vi til daglig aktiviserer.</p>`,
          },
          modalData1: {},
          modalData2: {},
        },
        hasVideo
          ? {
              id: 2,
              title: 'Torsdag',
              description:
                'Post videoen på klubbens \n          hjemmeside og/eller på\n          SOME Trykk på knappen under for\n          tekst til posten.\n          ',
              button: 'Kopier tekst',
              title1: 'Uke 1, del 2: Innlegg sosiale medier',
              description2: 'Tekst mal for innlegg i sosial medier',
              icon: '/media/icons/hashtag-solid.svg',
              column: 'Epost mal',
              button1: '',
              button2: '',
              modalData: {
                cardType: 'card2_2',
                mainTitle: 'Uke 1: Innlegg sosiale medier',
                subTitle:
                  'Samtidig poster du videoen på klubbens hjemmeside, Facebook-side, Spond og andre sosiale medier dere bruker i kommunikasjon med medlemmene.',
                input1: `<p><strong>STØTT KLUBBEN DIN DIGITALT MED STØTTE-APPEN</strong><br><br>I denne lille filmsnutten (50 sek.) forklares det geniale Støtte-konseptet som betyr at du kan gi litt og titt for å støtte klubben din, og det beste av alt: Du merker det så vidt. Men for oss som klubb kommer dette til å bli avgjørende for fremtiden!<br><br>Vi oppfordrer derfor alle våre medlemmer til å laste ned appen og at flest mulig blir støttespillere. Enklere kan det ikke bli!${hasVideo ? "<br><br>LINK: <a href='video_url'>video_display_url</a></p>" : ''}`,
              },
              modalData1: {},
              modalData2: {},
            }
          : undefined,
      ],
    },
  },
  {
    _id: '6501fa0e8d16cf5d833daef6',
    id: 3,
    object: {
      id: 3,
      title: 'Uke 2',
      subTitle: 'Påminnelse',
      card: [
        {
          id: 1,
          title: 'Mandag',
          description: 'Publiser på SOME',
          button: 'Kopier tekst',
          title1: 'Uke 2, del 1: Innlegg 1 sosiale medier - påminnelse',
          description2: 'Tekst mal for innlegg i sosial medier',
          icon: '/media/icons/hashtag-solid.svg',
          column: 'Tekst mal',
          button1: '',
          button2: '',
          modalData: {
            cardType: 'card3_1',
            mainTitle: 'Uke 2: Innlegg 1 sosiale medier - påminnelse',
            subTitle:
              'Legg ut innlegg i sosiale medier som en påminnelse for å laste ned og ta i bruk\n          Støtte appen.',
            input1:
              "<p>Bli støttespiller og last ned Støtte-appen!<br>Aldri har det vært enklere å støtte klubben du bryr deg om.<br><br>LINK: <a href='https://stotte.no/spre-budskapet'>https://stotte.no/spre-budskapet</a><br>&nbsp;</p>",
          },
          modalData1: {},
          modalData2: {},
        },
        {
          id: 2,
          title: 'Torsdag',
          description: 'Publiser på SOME',
          button: 'Kopier tekst',
          title1: 'Uke 2, del 2: Innlegg 2 sosiale medier',
          description2: 'Tekst mal for innlegg i sosiale medier',
          icon: '/media/icons/hashtag-solid.svg',
          column: 'Tekst mal',
          button1: '',
          button2: '',
          modalData: {
            cardType: 'card3_2',
            mainTitle: 'Uke 2: Innlegg 1 sosiale medier - påminnelse',
            subTitle:
              'Legg ut innlegg i sosiale medier som en påminnelse for å laste ned og ta i bruk\n          Støtte appen.',
            input1:
              "<p>Bli støttespiller og last ned Støtte-appen!<br>Aldri har det vært enklere å støtte klubben du bryr deg om.<br><br>LINK: <a href='https://stotte.no/spre-budskapet'>https://stotte.no/spre-budskapet</a><br>&nbsp;</p>",
          },
          modalData1: {},
          modalData2: {},
        },
      ],
    },
  },
  {
    _id: '6501fa168d16cf5d833daef7',
    id: 4,
    object: {
      id: 4,
      title: 'Uke 3',
      subTitle: 'Status/oppdatering',
      card: [
        {
          id: 1,
          title: 'Mandag',
          description: 'Publiser på SOME',
          button: 'Kopier tekst',
          title1: 'Uke 3, Alternativ 1: Takk til alle støttespillere',
          description2: 'Tekst mal for innlegg i sosiale medier',
          icon: '/media/icons/hashtag-solid.svg',
          column: 'Tekst mal',
          button1: '',
          button2: '',
          modalData: {
            cardType: 'card4_1',
            mainTitle: 'Uke 3: Innlegg sosiale medier - alternativ 1',
            subTitle:
              'Det har nå gått 2 uker siden dere informerte om appen. Hvor mange har tatt appen i bruk? Sjekk antallet i organisasjonspanelet ditt eller i appen! Lag et innlegg i sosiale medier (Fb, Spond o.l.) om hvilke(t) formål dere har opprettet, og hvor mange som har blitt støttespillere på disse 2 ukene.',
            input1:
              '<p>TAKK TIL ALLE NYE STØTTESPILLERE! X antall innovative medlemmer har etter kun to uker valgt å gi oss sin støtte til klubben ved å benytte Støtte-appen! Det lover godt, og vi takker og bukker. Til dere andre – last ned appen du også og bli støttespiller! Det er superenkelt å gi litt og titt, det merkes så vidt. <br><br>LINK FOR NEDLASTING AV APP: <a href="https://stotte.no/spre-budskapet">https://stotte.no/spre-budskapet</a><br><br>Følgende formål kan du velge å støtte:<br>- Navn på formål1<br>- Navn på formål2</p>',
          },
          modalData1: {},
          modalData2: {},
        },
        {
          id: 2,
          title: 'og/eller',
          description:
            'Lag et innelgg i SOME om\n          hvilke(t) formål dere har\n          opprettet og hvor mange \n          som har blitt støttespillere\n          på disse 2 ukene.',
          button: 'Kopier tekst',
          title1: 'Uke 3, Alternativ 2: Innlegg for sosiale medier',
          description2: 'Tekst mal for innlegg i sosial medier',
          icon: '/media/icons/hashtag-solid.svg',
          column: 'Tekst mal',
          button1: '',
          button2: '',
          modalData: {
            cardType: 'card4_2',
            mainTitle: 'Uke 3: Innlegg sosiale medier - alternativ 2',
            subTitle:
              'Det har nå gått 2 uker siden dere informerte om appen. Hvor mange har tatt appen i bruk? Sjekk antallet i organisasjonspanelet ditt eller i appen! Lag et innlegg i sosiale medier (Fb, Spond o.l.) om hvilke(t) formål dere har opprettet, og hvor mange som har blitt støttespillere på disse 2 ukene.',
            input1:
              ' <p>TUSEN TAKK<br>For engasjementet. For givergleden. For støtten.<br>Det betyr så mye for klubbens fremtid<br>og vårt tilbud til alle barn og unge. <br><br>Last ned Støtte-appen og bli en verdsatt støttespiller du også! <br>LINK: <a href="https://stotte.no/spre-budskapet">https://stotte.no/spre-budskapet</a></p> ',
          },
          modalData1: {},
          modalData2: {},
        },
      ],
    },
  },
  {
    _id: '6501fa1d8d16cf5d833daef8',
    id: 5,
    object: {
      id: 5,
      title: 'Uke 4',
      subTitle: 'Spre budskapet videre',
      card: [
        {
          id: 1,
          title: 'Mandag',
          description:
            'Oppfordre medlemmene til å rekruttere familie og venner til appen ved å bruke delingsfunksjoen i appen. Samtidig kan de som ikke har lastet ned appen naturligvis gjøre det når de får denne meldingen.',
          title1: 'Uke 4, del 1: Spre budskapet med SMS',
          description2: 'SMS mal 1 for deling med familie og venner',
          icon: '/media/icons/comment-sms-solid.svg',
          column: 'SMS mal',
          button: 'Kopier tekst',
          button1: '',
          button2: '',
          modalData: {
            cardType: 'card5_1',
            mainTitle: 'Uke 4: Spre budskapet videre via SMS - fra medlemmer til andre',
            subTitle:
              'Nå som forhåpentligvis mange medlemmer selv har lastet ned appen og gitt sin støtte, er det på tide å få medlemmene til å påvirke sine venner, slekt og bekjente til å gjøre det samme.\nFor å gjøre det enkelt, send videosnutten «Fra medlem til venner» på sms til alle medlemmer og be dem videresende til sine kontakter med følgende tekst:',
            input1:
              ' <p>Som du sikkert vet har vi tatt i bruk appen Støtte; som gjør det mulig ­– og veldig enkelt – for alle som har Vipps å gi et lite kronebeløp som går til klubben hver gang de bruker penger fra en valgt konto (minimum støttebeløp er <br> 2 kr) I hvilken som helst butikk, helt automatisk!<br><br>Vi oppfordrer deg til å ta i bruk appen og videresende til alle i din kontaktliste slik at enda flere kan være med å støtte klubben. Sjekk ut delingsmuligheten i appen!<br><br>Støtte-appen kan lastes ned her <br>LINK: <a href="https://stotte.no/spre-budskapet">https://stotte.no/spre-budskapet</a></p> ',
            input2:
              " <p>Husk å del appen Støtte med dine venner i din kontaktliste slik at enda flere kan være med å støtte klubben!&nbsp;<br><br>Bruk delingsfunksjonen i appen; det er superenkelt, og kan bety mye for klubben din!<br><br>Støtte-appen kan lastes ned her <br>LINK: <a href='https://stotte.no/spre-budskapet'>https://stotte.no/spre-budskapet</a></p>",
          },
          modalData1: {
            cardType: 'card5_2',
            mainTitle: 'Uke 4: Spre budskapet videre via epost - fra medlemmer til andre',
            subTitle:
              'Nå som forhåpentligvis mange medlemmer selv har lastet ned appen og gitt sin støtte, er det på tide å få medlemmene til å påvirke sine venner, slekt og bekjente til å gjøre det samme.',
            input1: 'Kjære medlem! Vi trenger hjelp til å spre det glade budskap',
            input2:
              "<p>Videresend denne videoen til alle i din kontaktliste, slik at enda flere kan være med å støtte klubben. Takk for hjelpen! Her er tekst du kan ta med\n           når du videresender til dine kontakter –endre emne-feltet til: Dette må du bare sjekke ut!<br><br>Hei! Gjør som meg og gi din støtte til klubben du bryr deg om med den geniale Støtte-appen! Snurr \n           film 🎬<br><br>Litt info om appen:<br>Appen gjør det mulig for deg over 15 år å gi et lite kronebeløp som går til klubben hver gang du bruker penger fra en valgt konto. I hvilken som helst butikk, helt automatisk! Du bestemmer hvor mange kroner ­– f.eks. 2, 5 eller \n           10 – som du du vil gi. Ja, du kan endre beløp eller sette på pause når som helst.<br><br>Det betyr at klubben får litt penger fra veldig mange – jevnt og trutt. Og det beste av alt – du merker det så vidt. Men for klubben betyr det all verden i en tøff tid for å \n           kunne gi medlemmene det aller beste tilbudet.<br><br>Du finner klubben (lag/organisasjon) enkelt i appen, og det er opprettet ulike formål du kan velge å støtte.<br><br>Last ned Støtte-appen og bli støttespiller du også!<br><br>Last ned Støtte-appen og bli støttespiller du også!\n           <a href='https://stotte.no/spre-budskapet'>https://stotte.no/spre-budskapet</a><br><br>På forhånd TUSEN TAKK, det betyr enormt mye – både for klubben og alle idrettsglade barn og unge.</p>",
          },
          modalData2: {
            cardType: 'card5_3',
            mainTitle: 'Uke 4: Spre budskapet videre via sosiale medier - fra medlemmer til andre',
            input1:
              'Lag et innlegg på Facebook, Spond eller andre sosiale medier, hvor du oppfordrer medlemmer til å dele appen med andre. \n\n          Del gjerne videosnutten ‘Medlem til venner’ som en del av innlegget ditt ved å kopiere lenken under.\n          \n          Samtidig kan du formidle hvilke formål dere har opprettet i appen og hvor mange støttespillere dere nå har fått.\n          \n          Du kan også gjøre det enkelt ved å benytte uke 3 og uke 4 sitt innholdsforslag (gjerne med noen dagers mellomrom).',
            input2: 'video_url',
          },
        },
      ],
    },
  },
  {
    _id: '6501fa298d16cf5d833daef9',
    id: 6,
    object: {
      id: 6,
      title: 'Ukene fremover',
      subTitle: 'Spre budskapet videre',
      card: [
        {
          id: 1,
          title: 'Mandag',
          description:
            'Lag poster et par ganger i måneden, gjerne ukentlig. Bruk bilder fra bildegalleri og egenkomponert tekst, eller teksteksempler fra vårt marketing kit.',
          button: 'Kopier tekst',
          title1: 'Onboarding',
          description2: 'Epost til trenere, administrasjon og tillitsvalgte',
          icon: '/media/icons/comment-sms-solid.svg',
          column: 'Epost mal',
          button1: '',
          button2: '',
          modalData: {
            cardType: 'card6_1',
            mainTitle: 'Onboarding: Epost til trenere',
            subTitle:
              'Start med å sende ut en e-post til trenere, administrasjon og tillitsvalgte der dere informerer om Støtte-appen og at dere nå har registrert klubben som mottaker.',
            input1: 'Gladmedling',
            input2: 'Det er utviklet',
          },
          modalData1: {},
          modalData2: {},
        },
      ],
    },
  },
]

const packagesEn = () => [
  {
    _id: '6501f8498d16cf5d833daef4',
    id: 1,
    object: {
      id: 1,
      title: 'Registrering',
      subTitle: 'Kom i gang',
      card: [
        {
          id: 1,
          title: 'Mandag',
          description: 'Send ut epost til trener,\n                  admin og tillitsvalgte',
          button: 'Kopier tekst',
          title1: 'Onboarding',
          description2: 'Epost til trenere, administrasjon og tillitsvalgte',
          icon: '/media/icons/address-card-solid.svg',
          column: 'Epost mal',
          button1: '',
          button2: '',
          modalData: {
            cardType: 'card1',
            mainTitle: 'Onboarding: Epost til trenere, administrasjon og tillitsvalgte',
            subTitle:
              'Start med å sende ut en e-post til trenere, administrasjon og tillitsvalgte der dere informerer om Støtte-appen og at dere nå har registrert klubben som mottaker.',
            input1: 'Gladmelding: Nå kan vi motta støtte digitalt! Hele året!',
            input2:
              'Hei xxx<br/><br/>Hurra - vi lar teknologien overta deler av salgsdugnaden! Støtte er en digital kronerullingstjeneste som gjør at vi slipper å fylle opp bilen med sokker og dopapir. Appen er utviklet i samarbeid med Norges Idrettsforbund. <br/><br/>Den er svært enkel i bruk og koples sammen med din Vipps-konto: Et valgfritt beløp blir trukket fra givers konto etter utført handel (minimum 2 kroner). Det vil bety en jevn kontantstrøm for klubben, uavhengig av dugnader og andre inntektsbringende aktiviteter.<br/><br/>Last ned appen her: <a href="https://stotte.no/spre-budskapet/">https://stotte.no/spre-budskapet</a><br/><br/> Vi har registrert klubben og opprettet følgende formål: <br/><br/>- Formål A<br/>- Formål B<br/><br/>I løpet av de nærmeste dagene sender vi ut informasjon til alle våre medlemmer, og deler det glade budskapet i sosiale medier. Fint om du som trener/tillitsvalgt i forkant leser mer om appen her: <a href="https://stotte.no/for-organisasjoner/">https://stotte.no/for-organisasjoner</a><br/><br/>Og ikke minst: fortell alle du kjenner om Støtte!<br/><br/>TUSEN TAKK for hjelpen! Det betyr enormt mye for både klubbens fremtid og alle barn og unge vi til daglig aktiviserer.',
          },
          modalData1: {},
          modalData2: {},
        },
      ],
    },
  },
  {
    _id: '6501fa068d16cf5d833daef5',
    id: 2,
    object: {
      id: 2,
      title: 'Uke 1',
      subTitle: 'Info om Støtteappen',
      card: [
        {
          id: 1,
          title: 'Mandag',
          description: "Send videosnutt fra 'Klubb til medlemmer'",
          button: 'Kopier tekst',
          title1: 'Uke 1, del 1: Epost til medlemmer',
          description2: 'Epost fra klubb til medlemmer',
          icon: '/media/icons/envelope-solid.svg',
          column: 'Epost mal',
          button1: '',
          button2: '',
          modalData: {
            cardType: 'card2_1',
            mainTitle: 'Uke 1: Epost til medlemmer',
            subTitle:
              '<p>Send videosnutten «Fra klubb til medlemmer» på e-post til alle medlemmer.<br><br>NB! Av personvernhensyn anbefaler vi at du legger til medlemmenes e-post-adresser i blindkopi-feltet (Bcc), slik at de som mottar ikke ser alle andre medlemmenes navn. I To-/Til-feltet legger du inn klubbens e-postadresse.</p>',
            input1: 'STØTTE-appen: Har du fått med deg denne muligheten?',
            input2:
              '<p>Se vedlagte film (ca. 50 sek) og ta action! Mer info nederst i denne e-posten,\n           men se filmen først.<br>LINK: <a href="video_url">video_display_url</a><br><br>Vi setter stor pris på alle våre medlemmer og er så takknemlige \n           for den støtten vi mottar. Nå håper vi du griper muligheten til å gi din støtte digitalt gjennom \n           den nyutviklede appen Støtte.<br><br>\n          Appen gjør det mulig for alle som har Vipps gi et lite kronebeløp som går til\n           klubben hver gang du bruker penger fra en valgt konto. I hvilken som helst butikk,\n            helt automatisk! Det betyr at klubben får litt penger fra veldig mange – jevnt og trutt.\n            \n            Og det beste av alt – du merker det så vidt. Men for oss betyr det all verden for å kunne\n             gi deg og alle våre medlemmer det aller beste tilbudet.<br><br>Du finner klubben vår \n             (lag/organisasjon) enkelt i appen, og vi har opprettet ulike formål du kan velge å støtte. \n             Du bestemmer hvor mange kroner – f.eks. 2, 5 eller 10 – som du vil gi hver gang du betaler noe.\n              Ja, du kan endre beløp eller sette på pause når som helst.<br><br>Last ned Støtte-appen og bli en verdsatt støttespiller \n              du også!&nbsp;<br>LINK: <a href=\'https://stotte.no/spre-budskapet\'>https://stotte.no/spre-budskapet</a><br><br>\n              På forhånd TUSEN TAKK, det betyr enormt mye for både klubbens fremtid og alle barn og unge vi til daglig aktiviserer.</p>',
          },
          modalData1: {},
          modalData2: {},
        },
        {
          id: 2,
          title: 'Torsdag',
          description:
            'Post videoen på klubbens \n          hjemmeside og/eller på\n          SOME Trykk på knappen under for\n          tekst til posten.\n          ',
          button: 'Kopier tekst',
          title1: 'Uke 1, del 2: Innlegg sosiale medier',
          description2: 'Tekst mal for innlegg i sosial medier',
          icon: '/media/icons/hashtag-solid.svg',
          column: 'Epost mal',
          button1: '',
          button2: '',
          modalData: {
            cardType: 'card2_2',
            mainTitle: 'Uke 1: Innlegg sosiale medier',
            subTitle:
              'Samtidig poster du videoen på klubbens hjemmeside, Facebook-side, Spond og andre sosiale medier dere bruker i kommunikasjon med medlemmene.',
            input1:
              "<p><strong>STØTT KLUBBEN DIN DIGITALT MED STØTTE-APPEN</strong><br><br>I denne lille filmsnutten (50 sek.) forklares det geniale Støtte-konseptet som betyr at du kan gi litt og titt for å støtte klubben din, og det beste av alt: Du merker det så vidt. Men for oss som klubb kommer dette til å bli avgjørende for fremtiden!<br><br>Vi oppfordrer derfor alle våre medlemmer til å laste ned appen og at flest mulig blir støttespillere. Enklere kan det ikke bli!<br><br>LINK: <a href='video_url'>video_display_url</a></p>",
          },
          modalData1: {},
          modalData2: {},
        },
      ],
    },
  },
  {
    _id: '6501fa0e8d16cf5d833daef6',
    id: 3,
    object: {
      id: 3,
      title: 'Uke 2',
      subTitle: 'Påminnelse',
      card: [
        {
          id: 1,
          title: 'Mandag',
          description: 'Publiser på SOME',
          button: 'Kopier tekst',
          title1: 'Uke 2, del 1: Innlegg 1 sosiale medier - påminnelse',
          description2: 'Tekst mal for innlegg i sosial medier',
          icon: '/media/icons/hashtag-solid.svg',
          column: 'Tekst mal',
          button1: '',
          button2: '',
          modalData: {
            cardType: 'card3_1',
            mainTitle: 'Uke 2: Innlegg 1 sosiale medier - påminnelse',
            subTitle:
              'Legg ut innlegg i sosiale medier som en påminnelse for å laste ned og ta i bruk\n          Støtte appen.',
            input1:
              "<p>Bli støttespiller og last ned Støtte-appen!<br>Aldri har det vært enklere å støtte klubben du bryr deg om.<br><br>LINK: <a href='https://stotte.no/spre-budskapet'>https://stotte.no/spre-budskapet</a><br>&nbsp;</p>",
          },
          modalData1: {},
          modalData2: {},
        },
        {
          id: 2,
          title: 'Torsdag',
          description: 'Publiser på SOME',
          button: 'Kopier tekst',
          title1: 'Uke 2, del 2: Innlegg 2 sosiale medier',
          description2: 'Tekst mal for innlegg i sosiale medier',
          icon: '/media/icons/hashtag-solid.svg',
          column: 'Tekst mal',
          button1: '',
          button2: '',
          modalData: {
            cardType: 'card3_2',
            mainTitle: 'Uke 2: Innlegg 1 sosiale medier - påminnelse',
            subTitle:
              'Legg ut innlegg i sosiale medier som en påminnelse for å laste ned og ta i bruk\n          Støtte appen.',
            input1:
              "<p>Bli støttespiller og last ned Støtte-appen!<br>Aldri har det vært enklere å støtte klubben du bryr deg om.<br><br>LINK: <a href='https://stotte.no/spre-budskapet'>https://stotte.no/spre-budskapet</a><br>&nbsp;</p>",
          },
          modalData1: {},
          modalData2: {},
        },
      ],
    },
  },
  {
    _id: '6501fa168d16cf5d833daef7',
    id: 4,
    object: {
      id: 4,
      title: 'Uke 3',
      subTitle: 'Status/oppdatering',
      card: [
        {
          id: 1,
          title: 'Mandag',
          description: 'Publiser på SOME',
          button: 'Kopier tekst',
          title1: 'Uke 3, Alternativ 1: Takk til alle støttespillere',
          description2: 'Tekst mal for innlegg i sosiale medier',
          icon: '/media/icons/hashtag-solid.svg',
          column: 'Tekst mal',
          button1: '',
          button2: '',
          modalData: {
            cardType: 'card4_1',
            mainTitle: 'Uke 3: Innlegg sosiale medier - alternativ 1',
            subTitle:
              'Det har nå gått 2 uker siden dere informerte om appen. Hvor mange har tatt appen i bruk? Sjekk antallet i organisasjonspanelet ditt eller i appen! Lag et innlegg i sosiale medier (Fb, Spond o.l.) om hvilke(t) formål dere har opprettet, og hvor mange som har blitt støttespillere på disse 2 ukene.',
            input1:
              '<p>TAKK TIL ALLE NYE STØTTESPILLERE! X antall innovative medlemmer har etter kun to uker valgt å gi oss sin støtte til klubben ved å benytte Støtte-appen! Det lover godt, og vi takker og bukker. Til dere andre – last ned appen du også og bli støttespiller! Det er superenkelt å gi litt og titt, det merkes så vidt. <br><br>LINK FOR NEDLASTING AV APP: <a href="https://stotte.no/spre-budskapet">https://stotte.no/spre-budskapet</a><br><br>Følgende formål kan du velge å støtte:<br>- Navn på formål1<br>- Navn på formål2</p>',
          },
          modalData1: {},
          modalData2: {},
        },
        {
          id: 2,
          title: 'og/eller',
          description:
            'Lag et innelgg i SOME om\n          hvilke(t) formål dere har\n          opprettet og hvor mange \n          som har blitt støttespillere\n          på disse 2 ukene.',
          button: 'Kopier tekst',
          title1: 'Uke 3, Alternativ 2: Innlegg for sosiale medier',
          description2: 'Tekst mal for innlegg i sosial medier',
          icon: '/media/icons/hashtag-solid.svg',
          column: 'Tekst mal',
          button1: '',
          button2: '',
          modalData: {
            cardType: 'card4_2',
            mainTitle: 'Uke 3: Innlegg sosiale medier - alternativ 2',
            subTitle:
              'Det har nå gått 2 uker siden dere informerte om appen. Hvor mange har tatt appen i bruk? Sjekk antallet i organisasjonspanelet ditt eller i appen! Lag et innlegg i sosiale medier (Fb, Spond o.l.) om hvilke(t) formål dere har opprettet, og hvor mange som har blitt støttespillere på disse 2 ukene.',
            input1:
              ' <p>TUSEN TAKK<br>For engasjementet. For givergleden. For støtten.<br>Det betyr så mye for klubbens fremtid<br>og vårt tilbud til alle barn og unge. <br><br>Last ned Støtte-appen og bli en verdsatt støttespiller du også! <br>LINK: <a href="https://stotte.no/spre-budskapet">https://stotte.no/spre-budskapet</a></p> ',
          },
          modalData1: {},
          modalData2: {},
        },
      ],
    },
  },
  {
    _id: '6501fa1d8d16cf5d833daef8',
    id: 5,
    object: {
      id: 5,
      title: 'Uke 4',
      subTitle: 'Spre budskapet videre',
      card: [
        {
          id: 1,
          title: 'Mandag',
          description:
            'Oppfordre medlemmene til å rekruttere familie og venner til appen ved å bruke delingsfunksjoen i appen. Samtidig kan de som ikke har lastet ned appen naturligvis gjøre det når de får denne meldingen.',
          title1: 'Uke 4, del 1: Spre budskapet med SMS',
          description2: 'SMS mal 1 for deling med familie og venner',
          icon: '/media/icons/comment-sms-solid.svg',
          column: 'SMS mal',
          button: 'Kopier tekst',
          button1: '',
          button2: '',
          modalData: {
            cardType: 'card5_1',
            mainTitle: 'Uke 4: Spre budskapet videre via SMS - fra medlemmer til andre',
            subTitle:
              'Nå som forhåpentligvis mange medlemmer selv har lastet ned appen og gitt sin støtte, er det på tide å få medlemmene til å påvirke sine venner, slekt og bekjente til å gjøre det samme.\nFor å gjøre det enkelt, send videosnutten «Fra medlem til venner» på sms til alle medlemmer og be dem videresende til sine kontakter med følgende tekst:',
            input1:
              ' <p>Som du sikkert vet har vi tatt i bruk appen Støtte; som gjør det mulig ­– og veldig enkelt – for alle som har Vipps å gi et lite kronebeløp som går til klubben hver gang de bruker penger fra en valgt konto (minimum støttebeløp er <br> 2 kr) I hvilken som helst butikk, helt automatisk!<br><br>Vi oppfordrer deg til å ta i bruk appen og videresende til alle i din kontaktliste slik at enda flere kan være med å støtte klubben. Sjekk ut delingsmuligheten i appen!<br><br>Støtte-appen kan lastes ned her <br>LINK: <a href="https://stotte.no/spre-budskapet">https://stotte.no/spre-budskapet</a></p> ',
            input2:
              " <p>Husk å del appen Støtte med dine venner i din kontaktliste slik at enda flere kan være med å støtte klubben!&nbsp;<br><br>Bruk delingsfunksjonen i appen; det er superenkelt, og kan bety mye for klubben din!<br><br>Støtte-appen kan lastes ned her <br>LINK: <a href='https://stotte.no/spre-budskapet'>https://stotte.no/spre-budskapet</a></p>",
          },
          modalData1: {
            cardType: 'card5_2',
            mainTitle: 'Uke 4: Spre budskapet videre via epost - fra medlemmer til andre',
            subTitle:
              'Nå som forhåpentligvis mange medlemmer selv har lastet ned appen og gitt sin støtte, er det på tide å få medlemmene til å påvirke sine venner, slekt og bekjente til å gjøre det samme.',
            input1: 'Kjære medlem! Vi trenger hjelp til å spre det glade budskap',
            input2:
              "<p>Videresend denne videoen til alle i din kontaktliste, slik at enda flere kan være med å støtte klubben. Takk for hjelpen! Her er tekst du kan ta med\n           når du videresender til dine kontakter –endre emne-feltet til: Dette må du bare sjekke ut!<br><br>Hei! Gjør som meg og gi din støtte til klubben du bryr deg om med den geniale Støtte-appen! Snurr \n           film 🎬<br><br>Litt info om appen:<br>Appen gjør det mulig for deg over 15 år å gi et lite kronebeløp som går til klubben hver gang du bruker penger fra en valgt konto. I hvilken som helst butikk, helt automatisk! Du bestemmer hvor mange kroner ­– f.eks. 2, 5 eller \n           10 – som du du vil gi. Ja, du kan endre beløp eller sette på pause når som helst.<br><br>Det betyr at klubben får litt penger fra veldig mange – jevnt og trutt. Og det beste av alt – du merker det så vidt. Men for klubben betyr det all verden i en tøff tid for å \n           kunne gi medlemmene det aller beste tilbudet.<br><br>Du finner klubben (lag/organisasjon) enkelt i appen, og det er opprettet ulike formål du kan velge å støtte.<br><br>Last ned Støtte-appen og bli støttespiller du også!<br><br>Last ned Støtte-appen og bli støttespiller du også!\n           <a href='https://stotte.no/spre-budskapet'>https://stotte.no/spre-budskapet</a><br><br>På forhånd TUSEN TAKK, det betyr enormt mye – både for klubben og alle idrettsglade barn og unge.</p>",
          },
          modalData2: {
            cardType: 'card5_3',
            mainTitle: 'Uke 4: Spre budskapet videre via sosiale medier - fra medlemmer til andre',
            input1:
              'Lag et innlegg på Facebook, Spond eller andre sosiale medier, hvor du oppfordrer medlemmer til å dele appen med andre. \n\n          Del gjerne videosnutten ‘Medlem til venner’ som en del av innlegget ditt ved å kopiere lenken under.\n          \n          Samtidig kan du formidle hvilke formål dere har opprettet i appen og hvor mange støttespillere dere nå har fått.\n          \n          Du kan også gjøre det enkelt ved å benytte uke 3 og uke 4 sitt innholdsforslag (gjerne med noen dagers mellomrom).',
            input2: 'video_url',
          },
        },
      ],
    },
  },
  {
    _id: '6501fa298d16cf5d833daef9',
    id: 6,
    object: {
      id: 6,
      title: 'Ukene fremover',
      subTitle: 'Spre budskapet videre',
      card: [
        {
          id: 1,
          title: 'Mandag',
          description:
            'Lag poster et par ganger i måneden, gjerne ukentlig. Bruk bilder fra bildegalleri og egenkomponert tekst, eller teksteksempler fra vårt marketing kit.',
          button: 'Kopier tekst',
          title1: 'Onboarding',
          description2: 'Epost til trenere, administrasjon og tillitsvalgte',
          icon: '/media/icons/comment-sms-solid.svg',
          column: 'Epost mal',
          button1: '',
          button2: '',
          modalData: {
            cardType: 'card6_1',
            mainTitle: 'Onboarding: Epost til trenere',
            subTitle:
              'Start med å sende ut en e-post til trenere, administrasjon og tillitsvalgte der dere informerer om Støtte-appen og at dere nå har registrert klubben som mottaker.',
            input1: 'Gladmedling',
            input2: 'Det er utviklet',
          },
          modalData1: {},
          modalData2: {},
        },
      ],
    },
  },
]

export { packagesEn, packagesNO }
