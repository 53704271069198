/* eslint-disable react/jsx-no-constructed-context-values */
import { Suspense, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay-ts'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { MasterInit } from '../_metronic/layout/MasterInit'
import SmallSize from '../_metronic/layout/components/SmallSize'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import LoadingContext from '../_metronic/layout/core/Loading'
import TourContext from '../_metronic/layout/core/tour'
import MainContextState from '../context/MainContext/MainContextState'
import { AuthInit } from './modules/auth'
import ResolutionWarning from './services/ResolutionWarning'

function App() {
  const [loading, setLoading] = useState(false)
  const [tour, setTour] = useState(false)
  const [showResolutionWarning, setShowResolutionWarning] = useState(false)

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <ResolutionWarning
        handleReturn={(val: boolean) => {
          setShowResolutionWarning(val)
        }}
      />

      {showResolutionWarning && <SmallSize />}
      <LoadingContext.Provider value={{ loading, setLoading }}>
        <LoadingOverlay
          spinner={<Spinner animation='border' variant='primary' title='Laster inn...' />}
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'rgba(255, 255, 255, 0.5)',
              height: '100vh',
            }),
          }}
        >
          <TourContext.Provider value={{ tour, setTour }}>
            <I18nProvider>
              <MainContextState>
                <LayoutProvider>
                  <AuthInit>
                    <Outlet />
                    <MasterInit />
                  </AuthInit>
                </LayoutProvider>
              </MainContextState>
            </I18nProvider>
          </TourContext.Provider>
        </LoadingOverlay>
      </LoadingContext.Provider>
    </Suspense>
  )
}

export { App }
