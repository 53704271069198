/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Frame } from 'zimjs/ts-src/typings/zim'
import LoadingContext from '../../../../_metronic/layout/core/Loading'
import { useAuth } from '../../../modules/auth'
import { handleGetRequest, handlePostRequest } from '../../../services'
import { ICampaign } from '../../campaign/CampaignTable'
import { StudioEditor } from './StudioEditor'
import { StudioSidebar } from './StudioSidebar'
import { StudioUtils } from './StudioUtils'

export interface IDocStorage {
  template: 'standard' | 'campaign'
  topHeader: string
  bottomHeader: string
  code: string
  displayOrgLogo: boolean
  printVersion: boolean
  orgLogo: string
  qrCode?: string
  image?: {
    base64?: string
  }
  logoType: 'støtte' | 'støtte/nif'
  getCanvas?: () => HTMLCanvasElement | undefined
}
export interface IDocContext {
  doc: IDocStorage
  setDoc: (doc: IDocStorage) => void
  hasBeenInitialized: boolean
}
export const DocContext = createContext<IDocContext>({
  doc: {
    topHeader: 'Bli en verdifull støttespiller',
    bottomHeader: 'Last ned appen Støtte!',
    displayOrgLogo: true,
    printVersion: false,
    logoType: 'støtte',
    template: 'standard',
    orgLogo: '',
    image: {
      base64: '',
    },
    qrCode: '',
    code: '',
  },
  setDoc: () => {},
  hasBeenInitialized: false,
})

export const StudioApp = ({
  onSave,
  isCampaign,
  campaignId,
  generateOnReady,
}: {
  onSave?: ((doc: IDocStorage) => void) | ((doc: IDocStorage) => Promise<void>)
  isCampaign?: boolean
  campaignId?: string
  generateOnReady?: boolean
}) => {
  const { currentUser } = useAuth()
  const { setLoading } = useContext(LoadingContext)
  const { id, userCode, orgID, header, subheader } = useParams()
  const [campaign, setCampaign] = useState<ICampaign | undefined>()
  const [template] = useState<'standard' | 'campaign'>(
    isCampaign || window.location.href.includes('campaign') ? 'campaign' : 'standard'
  )

  const [doc, setDoc] = useState<IDocStorage>({
    topHeader: header ? StudioUtils.decodeText(header) : 'Bli en verdifull støttespiller',
    bottomHeader: subheader ? StudioUtils.decodeText(subheader) : 'Last ned appen Støtte!',
    displayOrgLogo: true,
    printVersion: false,
    logoType: 'støtte',
    orgLogo: '',
    image: {},
    template: 'standard',
    qrCode: '',
    code: '',
  })
  const frameRef = useRef<Frame | null>(null)
  const [hasBeenInitialized, setHasBeenInitialized] = useState(false)
  const preloadData = async () => {
    const isCampaignInUrl = isCampaign || window.location.href.includes('campaign')
    const orgLogoData = await handlePostRequest(
      `/organisation/org-logo-b64`,
      {
        id: orgID || currentUser?.user?.organisation_id?._id,
      },
      undefined,
      true
    )(setLoading)
    const b64Image = orgLogoData?.data?.image
    if (isCampaignInUrl && generateOnReady && userCode) {
      const qrCode = await StudioUtils.urlToQRCodeB64(
        `https://app.stotte.no/login/kode/${userCode}`
      )
      setDoc({
        ...doc,
        template: 'campaign',
        code: userCode,
        qrCode,
        orgLogo: b64Image,
      })
    } else if (isCampaignInUrl) {
      const { data } = await handleGetRequest(`/campaign/single/${campaignId || id}`)(setLoading)
      if (data) {
        // eslint-disable-next-line no-restricted-syntax
        for await (const recruiter of (data as ICampaign).recruiters) {
          recruiter.b64QRCode = await StudioUtils.urlToQRCodeB64(
            `https://app.stotte.no/login/kode/${recruiter.short_user_id}`
          )
        }
        setCampaign(data)
        const qrCode = await StudioUtils.urlToQRCodeB64('app.stotte.no/login/kode')
        const header = (data as ICampaign)?.flyer?.header
          ? StudioUtils.decodeText((data as ICampaign).flyer!.header)
          : `Støtt ${currentUser?.user?.organisation_id?.org_name}!`

        const subheader = (data as ICampaign)?.flyer?.subheader
          ? StudioUtils.decodeText((data as ICampaign).flyer!.subheader)
          : (data as ICampaign).title
        setDoc({
          ...doc,
          template: 'campaign',
          code: '<Kode>',
          topHeader: header,
          bottomHeader: subheader,
          qrCode,
          orgLogo: b64Image,
        })
      }
    } else if (b64Image) setDoc({ ...doc, orgLogo: b64Image })
    setHasBeenInitialized(true)
  }

  useEffect(() => {
    preloadData()
  }, [])

  useEffect(() => {
    if (generateOnReady && doc?.getCanvas && doc.getCanvas()) {
      // wait 1 second for canvas to render
      setTimeout(() => {
        const canvas = doc.getCanvas?.()
        if (canvas) {
          const link = document.getElementById('link')
          if (!link) {
            return
          }
          link.setAttribute('download', `${doc.topHeader}.png`)
          link.setAttribute(
            'href',
            canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
          )
          link.click()
          window.close()
        }
      }, 1000)
    }
  }, [generateOnReady, doc?.getCanvas && doc.getCanvas()])

  return (
    <DocContext.Provider
      value={{
        doc,
        setDoc,
        hasBeenInitialized,
      }}
    >
      <div className='editor'>
        <div className='editor-canvas'>
          <div id='holder' />

          {/* Include Zim  code from outside StrictMode */}
          <StudioEditor frameRef={frameRef} isCampaign={isCampaign} />
        </div>

        {!generateOnReady && (
          <StudioSidebar
            template={template}
            campaign={campaign}
            frameRef={frameRef}
            onSave={onSave}
          />
        )}
        <a id='link' style={{ display: 'none' }} />
      </div>
    </DocContext.Provider>
  )
}
