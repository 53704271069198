import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const DashboardEditorCell = () => {
  return (
    <div
      className='card card card-xl-stretchcard_borderC'
      style={{ backgroundColor: '#ffff', width: '100%', height: '100%', textAlign: 'center' }}
    >
      {/* begin::Body */}
      <div className='card-body d-flex flex-column align-items-center'>
        <span className='text-dark fs-2hx mb-3'>Lag profilmateriell</span>
        <span className='fs-3 mb-3'>
          Vi har laget ett verktøy slik at du enkelt kan lage lage profilmateriell med dine egne
          bilder og logoer. Disse kan enkelt deles på sosiale medier eller lastes ned for bruk i
          andre sammenhenger.
        </span>
        <img
          src={toAbsoluteUrl('/media/misc/dashboard-editor-cell.jpeg')}
          style={{ objectFit: 'contain', width: '60%', maxHeight: '60%' }}
          alt='marketing cell'
        />
      </div>
    </div>
  )
}

export { DashboardEditorCell }
