import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { SoMePosts, brandingMaterial } from './MaterialConstants'

const SupportBranding: React.FC = () => {
  const intl = useIntl()
  return (
    <>
      {/* <MarketingHeader /> */}
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>{intl.formatMessage({ id: 'profile.material' })}</h3>

        <div className='d-flex flex-wrap my-2'>
          <Link to='/marketing' style={{ color: 'white' }} className='btn btn-lg btn-orange mx-2'>
            {intl.formatMessage({ id: 'weekly.overview' })}
          </Link>
          {/* <button className='btn btn-sm btn-orange'>Ukentlig oversikt</button> */}
          {/* <FilterOptions /> */}
        </div>
      </div>

      <div className='card '>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='text-muted mt-1 fw-bold fs-5'>
              {intl.formatMessage({ id: 'support.branding.p1' })}
            </span>
          </h3>
          <div className='card-toolbar' />
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted' style={{ backgroundColor: '#E9F4FF' }}>
                  <th
                    className='ps-4 rounded-start'
                    style={{
                      width: '50%',
                    }}
                  >
                    {intl.formatMessage({ id: 'LOGO' })}
                  </th>
                  <th style={{ width: '270px' }}>{intl.formatMessage({ id: 'FORMAT' })}</th>
                  <th className=' text-end rounded-end px-4' />
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {brandingMaterial.map((item) => (
                  <tr key={item.id}>
                    <td className='d-flex align-items-center'>
                      <img
                        style={{
                          border: '1px solid #C6E0EC',
                          borderRadius: '8px',
                          objectFit: 'contain',
                        }}
                        src={item.iconFile}
                        alt='video'
                        className='h-60px w-90px'
                      />
                      <div className='ms-4 '>
                        <h5>{item.title}</h5>
                        <p className='text-gray-400'>{item.description}</p>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>{item.format}</div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end'>
                        <a
                          href={item.zipFile}
                          download={item.title || item.zipFile}
                          className='btn btn-sm btn-green px-10'
                        >
                          {intl.formatMessage({ id: 'DOWNLOAD' })}
                        </a>
                        {/* <button className='btn btn-sm btn-green px-10'>{item.button}</button> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>

          {/* end::Table container */}
        </div>
        <div>
          {SoMePosts.map((item) => (
            <div key={item.title} className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bolder text-muted' style={{ backgroundColor: '#E9F4FF' }}>
                      <th
                        className='ps-4 rounded-start'
                        style={{
                          width: '50%',
                        }}
                      >
                        {item.title}
                      </th>
                      <th style={{ width: '270px' }}>{intl.formatMessage({ id: 'FORMAT' })}</th>
                      <th className=' text-end rounded-end px-4' />
                    </tr>
                  </thead>
                  <tbody>
                    {item.images.map((imgItem) => (
                      <tr key={imgItem.id}>
                        <td className='d-flex align-items-center'>
                          <img
                            style={{
                              border: '1px solid #C6E0EC',
                              borderRadius: '8px',
                              objectFit: 'cover',
                            }}
                            src={imgItem.url}
                            alt='video'
                            className={`h-${(item.height || 100).toString()}px w-${(
                              item.width || 100
                            ).toString()}px`}
                          />
                          <div className='ms-4 '>
                            <h5>{imgItem.title}</h5>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>JPG</div>
                        </td>
                        <td>
                          <div className='d-flex justify-content-end'>
                            <a
                              href={imgItem.url}
                              download
                              className='btn btn-sm btn-green px-10'
                              rel='noreferrer'
                            >
                              {intl.formatMessage({ id: 'DOWNLOAD' })}
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
export default SupportBranding
