import clsx from 'clsx'
import React, { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

type Props = {
  to: string
  title: ReactNode
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  id?: string
  children?: any
}

const AsideMenuItem = ({ children, to, title, icon, hasBullet = false, id }: Props) => {
  const { pathname } = useLocation()
  // const isActive = checkIsActive(pathname, to)
  const [isActive, setIsActive] = React.useState(false)

  useEffect(() => {
    // get url from window
    const url = window.location.href

    // pathname is the current url
    if (url.includes(to)) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [pathname, to])

  return (
    <div
      id={id}
      className={clsx('menu-item my-1', { 'here show': isActive }, 'menu-accordion')}
      data-kt-menu-trigger='click'
    >
      {to ? (
        <Link className={clsx('menu-link ', { active: isActive })} to={to}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot' />
            </span>
          )}
          {icon && (
            <span className='menu-icon'>
              <i className={clsx('', icon)} />
              {/* <KTSVG path={icon} className='svg-icon-4 customSizeMenuIcon' svgClassName=' ' /> */}
            </span>
          )}
          <span className='menu-title'>{title}</span>
        </Link>
      ) : (
        <div className={clsx('menu-link ')}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot' />
            </span>
          )}
          {icon && (
            <span className='menu-icon'>
              <i className={clsx('', icon)} />
            </span>
          )}
          <span className='menu-title'>{title}</span>
        </div>
      )}

      {children}
    </div>
  )
}

export { AsideMenuItem }
