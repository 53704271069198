import { useFormik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import { addBearer, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useMainContext } from '../../../../context'
import { useAuth } from '../core/Auth'
import { setAuth, setCurrentUser, setRefreshToken, setToken } from '../core/AuthHelpers'
import { verifyOtp } from '../core/_requests'

const initialValues = {
  otp: '',
}

const otpSchema = Yup.object().shape({
  otp: Yup.string().required('OTP is required'),
})

export function LinkExpire() {
  const [, setLoading] = useState(false)
  const { saveAuth } = useAuth()
  const { setCompanyonPortal } = useMainContext()
  const [, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: otpSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const id = localStorage.getItem('otp_id')
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        verifyOtp(id, values.otp.trim())
          .then((auth) => {
            setHasErrors(false)
            setLoading(false)
            setCurrentUser(auth.data.data)
            setAuth(auth.data.data)
            setRefreshToken(addBearer(auth.headers.authorization))
            setToken(addBearer(auth.data.data.access_token))
            localStorage.setItem('currentUser', JSON.stringify(auth.data.data))
            // eslint-disable-next-line eqeqeq
            if (auth.data.data.user.user_type === 'admin') {
              setCompanyonPortal(null, 'NO', auth.data.data?.user?.organisation_id.org_name)
            }
            localStorage.removeItem('otp_id')
            window.location.href = '/dashboard'
          })
          .catch(() => {
            saveAuth(undefined)
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('OTP not verified')
          })
      }, 1000)
    },
  })
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className=' mb-10'>
        {/* begin::Title */}

        <div className=' mb-10 d-flex ' style={{ alignItems: 'center' }}>
          <img
            src={toAbsoluteUrl('/media/logos/S-logo-full-dark.png')}
            alt='logo'
            className='svg-icon-cufs mb-14'
            style={{ maxHeight: '40px' }}
          />
          {/* <h2 className='text-dark mb-14 mx-2'>Støtte AS</h2> */}
        </div>
      </div>

      <div style={{ height: '10rem' }} />

      <div className='mb-10 bg-light-success p-8 rounded' style={{ width: '81%' }}>
        <div className='text-dark fw-bold fs-3'>Link utløpt!</div>
        <div className='text-dark'>
          Linken du mottok er dessverre utløpt. Vennligst kontakt din leder eller Støtte Support for
          å få ny link.
        </div>
      </div>
    </form>
  )
}
