import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { IHeader, useLayout } from '../../core'
import { Topbar } from './Topbar'
import { DefaultTitle } from './page-title/DefaultTitle'

const calculateStickyOffset = (header: IHeader): string => {
  if (header.fixed.desktop && header.fixed.tabletAndMobile) {
    return '{default: "200px", lg: "300px"}'
  }

  if (header.fixed.desktop && !header.fixed.tabletAndMobile) {
    return '{lg: "300px"}'
  }

  if (header.fixed.tabletAndMobile && !header.fixed.desktop) {
    return '{default: "200px", lg: false}'
  }

  return ''
}

const calculateShowSticky = (header: IHeader): boolean =>
  (header.fixed.desktop && header.fixed.tabletAndMobile) ||
  (header.fixed.desktop && !header.fixed.tabletAndMobile) ||
  (header.fixed.tabletAndMobile && !header.fixed.desktop)

export function HeaderWrapper() {
  const { config, classes, attributes } = useLayout()
  const { header } = config
  const [stickyOffset, setStickyOffset] = useState<string>(calculateStickyOffset(header))
  const [showSticky, setShowSticky] = useState<boolean>(calculateShowSticky(header))
  useEffect(() => {
    setStickyOffset(calculateStickyOffset(header))
    setShowSticky(calculateShowSticky(header))
  }, [header])

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '))}
      {...attributes.headerMenu}
      data-kt-sticky={showSticky ? 'true' : 'false'}
      data-kt-sticky-name='header'
      data-kt-sticky-offset={stickyOffset}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex flex-stack gap-2 cardCustomstt max-widthc justify-content-between '
        )}
        id='kt_header_container'
      >
        <div>
          <DefaultTitle />
        </div>
        <div className='w-100'>
          <Topbar />
        </div>
      </div>
    </div>
  )
}
