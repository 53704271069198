export const brandingMaterial = [
  {
    id: 1,
    title: 'Støtte-logoer for trykk',
    description: 'Blå logo på hvit bakgrunn klar for nedlasting i zip-mappe',
    format: 'EPS, PDF',
    size: '3,5MB',
    button: 'LAST NED',
    iconFile: 'https://org.stotte.no/media/branding/thumb-logo-trykk.jpg',
    zipFile: 'https://org.stotte.no/media/branding/Stotte-logoer-trykk.zip',
  },
  {
    id: 2,
    title: 'Støtte-logoer for skjerm',
    description: 'Hvit logo på blå bakgrunn klar for nedlasting i zip-mappe.',
    format: 'AI, JPEG, PNG, SVG',
    size: '686KB',
    button: 'LAST NED',
    iconFile: 'https://org.stotte.no/media/branding/thumb-logo-skjerm.jpg',
    zipFile: 'https://org.stotte.no/media/branding/Stotte-logoer-skjerm.zip',
  },
  {
    id: 3,
    title: 'Plakat med QR kode',
    description: 'Plakat for utskrift. ',
    format: 'PDF',
    size: '4.5MB',
    isPortrait: true,
    button: 'LAST NED',
    iconFile: 'https://org.stotte.no/media/branding/poster-icon.jpeg',
    zipFile: 'https://org.stotte.no/media/branding/Informasjonsplakat_stotte.pdf',
  },
]
interface IFaceBookImage {
  id: number
  title: string
  size: string
  url: string
}
interface ISoMePost {
  images: IFaceBookImage[]
  title: string
  height?: number
  width?: number
}

export const BliEnVerdifullStøtteSpiller: IFaceBookImage[] = [
  {
    id: 1,
    title: 'Badminton',
    size: '972.4 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Badminton-1200x1200.jpg',
  },

  {
    id: 2,
    title: 'BMX',
    size: '1.2 MB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/BMX-1200x1200.jpg',
  },

  {
    id: 3,
    title: 'Boxing',
    size: '943.2 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Boxing-1200x1200.jpg',
  },

  {
    id: 4,
    title: 'Fjelltur',
    size: '849.4 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Fjelltur-1200x1200.jpg',
  },

  {
    id: 5,
    title: 'Fotball',
    size: '963.6 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Fotball-1200x1200.jpg',
  },

  {
    id: 6,
    title: 'Friidrett',
    size: '1.2 MB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Friidrett-1200x1200.jpg',
  },

  {
    id: 7,
    title: 'Golf',
    size: '580.2 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Golf-1200X1200.jpg',
  },

  {
    id: 8,
    title: 'Håndball',
    size: '907.4 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Haandball-1200x1200.jpg',
  },

  {
    id: 9,
    title: 'Hest',
    size: '1.1 MB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Hest-1200x1200.jpg',
  },

  {
    id: 10,
    title: 'Ishockey',
    size: '693.5 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Ishockey-1200x1200.jpg',
  },

  {
    id: 11,
    title: 'Klatring innendørs',
    size: '964.5 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Klatring-innendors-1200x1200.jpg',
  },

  {
    id: 12,
    title: 'Klatring vegg',
    size: '1.1 MB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Klatring-vegg-1200x1200.jpg',
  },

  {
    id: 13,
    title: 'Langrenn',
    size: '908.3 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Langrenn-1200x1200.jpg',
  },

  {
    id: 14,
    title: 'Motorcross',
    size: '837.4 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Motorcross-1200x1200.jpg',
  },

  {
    id: 15,
    title: 'Skihopp',
    size: '528.5 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Skihopp-1200x1200.jpg',
  },

  {
    id: 16,
    title: 'Skiskyting',
    size: '637.9 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Skiskyting-1200X1200.jpg',
  },

  {
    id: 18,
    title: 'Skøyter',
    size: '591.4 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Skoyter-2-1200x1200.jpg',
  },

  {
    id: 19,
    title: 'Svømming',
    size: '876.3 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Svomming-1200x1200.jpg',
  },

  {
    id: 20,
    title: 'Sykling',
    size: '637.3 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Sykling-1200x1200.jpg',
  },

  {
    id: 21,
    title: 'Taekwondo',
    size: '734.7 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Taekwondo-1200x1200.jpg',
  },

  {
    id: 22,
    title: 'Turn',
    size: '769.9 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Turn-1200x1200.jpg',
  },

  {
    id: 23,
    title: 'Turn, matte',
    size: '724.9 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Turn-matte-1200x1200.jpg',
  },

  {
    id: 24,
    title: 'Turn, ringer',
    size: '576.0 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/Turn-ringer-1200x1200.jpg',
  },
  {
    id: 27,
    title: 'Lagbilde 1',
    size: '576.0 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/lag1.png',
  },
  {
    id: 28,
    title: 'Lagbilde 2',
    size: '576.0 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/verdifull/lag2.png',
  },
]
const DenEnklesteMatenAgiOgMottaPengestotte: IFaceBookImage[] = [
  {
    id: 1,
    title: 'Eldre par',
    size: '949.5 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/enkleste/Eldre+par.jpg',
  },
  {
    id: 2,
    title: 'Fjelltur 1',
    size: '1.0 MB',
    url: 'https://org.stotte.no/media/marketing/some/edited/enkleste/Fjelltur+1.jpg',
  },
  {
    id: 3,
    title: 'Fjelltur 2',
    size: '1.3 MB',
    url: 'https://org.stotte.no/media/marketing/some/edited/enkleste/Fjelltur+2.jpg',
  },
  {
    id: 5,
    title: 'Fotball, gladgutt',
    size: '703.5 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/enkleste/Fotmall%2C+gladgutt.jpg',
  },
  {
    id: 6,
    title: 'Hockey',
    size: '1.0 MB',
    url: 'https://org.stotte.no/media/marketing/some/edited/enkleste/Hockey.jpg',
  },
  {
    id: 7,
    title: 'Langrenn',
    size: '903.1 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/enkleste/Langrenn.jpg',
  },
  {
    id: 8,
    title: 'Ski',
    size: '713.8 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/enkleste/Ski.jpg',
  },
]

const LeiAvSalgsDugnader: IFaceBookImage[] = [
  {
    id: 1,
    title: 'Lei av salgsdugnader 1',
    size: '635.9 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/salgsdugnader/Lei+av+salgsdugnader+1.jpg',
  },

  {
    id: 2,
    title: 'Lei av salgsdugnader 2',
    size: '959.9 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/salgsdugnader/Lei+av+salgsdugnader+2.jpg',
  },

  {
    id: 3,
    title: 'Lei av salgsdugnader 3',
    size: '940.2 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/salgsdugnader/Lei+av+salgsdugnader_.jpg',
  },
]

const Poster: IFaceBookImage[] = [
  {
    id: 2,
    title: 'Aldri har det vært enklere',
    size: '961.3 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/poster/1+Aldri+har+det+vart+enklere.png',
  },

  {
    id: 1,
    title: 'Det betyr så mye',
    size: '1.1 MB',
    url: 'https://org.stotte.no/media/marketing/some/edited/poster/2+Det+betyr+sa+mye.png',
  },

  {
    id: 3,
    title: 'Del, del, del',
    size: '698.7 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/poster/3+Del%2C+del%2C+del.png',
  },

  {
    id: 4,
    title: 'Så enkelt og gøy',
    size: '663.1 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/poster/4+Sa+enkelt+og+goy.png',
  },

  {
    id: 5,
    title: 'Gi litt og titt',
    size: '733.8 KB',
    url: 'https://org.stotte.no/media/marketing/some/edited/poster/5+Gi+litt+og+titt.png',
  },
]

export const SoMePosts: ISoMePost[] = [
  {
    images: DenEnklesteMatenAgiOgMottaPengestotte,
    title: 'Bilder: Den enkleste måten å gi og motta pengestøtte',
  },
  {
    images: LeiAvSalgsDugnader,
    title: 'Lei av salgsdugnader?',
  },
  {
    images: Poster,
    title: 'Poster',
    height: 120,
    width: 100,
  },
  {
    images: BliEnVerdifullStøtteSpiller,
    title: 'Bilder: Bli en verdifull støttespiller',
  },
]
