/* eslint-disable no-script-url */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import CustomPagination from '../../../CustomPagination'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { useMainContext } from '../../../context'
import { GetStatus } from '../../constants/utils'
import { useAuth } from '../../modules/auth'
import { handleGetRequest, handlePatchRequest, handlePostRequest } from '../../services'
import SportsCreationModal from './SportsCreationModal'

type Props = {
  className: string
}

interface IInitialValues {
  id?: string
  title: string
  short_description: string
  status: string
  is_general?: boolean
  sorting?: number
}

const SportsTable: React.FC<Props> = ({ className }) => {
  const { companyofPortal } = useMainContext()
  const { currentUser, saveUser } = useAuth()
  const intl = useIntl()
  const { setLoading } = useContext(LoadingContext)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isEdit, setisEdit] = useState<boolean>(false)
  const [sports, setSports] = useState<any>()
  const [PageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [initialValues, setInitialValues] = useState<IInitialValues>({
    id: '',
    title: '',
    short_description: '',
    status: '',
    is_general: true,
    sorting: -1,
  })

  const getSportsByOrg = async () => {
    const res = await handleGetRequest(
      `/organisation/get_org_sport?organisationId=${currentUser?.user?.organisation_id?._id}`
    )(setLoading)

    setSports(res.data)
  }

  const updateSport = async (data: IInitialValues) => {
    const res = await handlePatchRequest(`/organisation_sport/${data.id}`, {
      sports_category_name: data.title,
      description: data.short_description,
      organisation_id: currentUser?.user?.organisation_id?._id,
      status: data.status,
      sorting: data?.sorting,
    })(setLoading)
    const newSports = sports.map((sport: any) => (sport._id === data?.id ? res.data : sport))
    setSports(newSports)
    getSportsByOrg()
    updateUserSportsList(newSports)
  }

  const createNewGaol = async (data: any) => {
    const body = {
      organisation_id: currentUser?.user?.organisation_id?._id,
      organisation_sports_category_id: data?._id,
      title: 'Generell støtte',
      short_description: `Støtt - ${currentUser?.user?.organisation_id?.org_name} sin daglige drift og prosjektene de ønsker å realisere.`,
      target_amount: 0,
      start_date: new Date(),
      due_date: new Date('3024-01-01'),
      general_stotte: true,
    }

    await handlePostRequest(`/goal`, body, {})(setLoading)
  }

  const createSport = async (data: IInitialValues) => {
    const res = await handlePostRequest(`/organisation_sport`, {
      sports_category_name: data.title,
      description: data.short_description,
      organisation_id: currentUser?.user?.organisation_id?._id,
      status: data.status,
    })(setLoading)
    const newSports = [...sports, res.data]
    setSports(newSports)
    updateUserSportsList(newSports)
    getSportsByOrg()
    if (data?.is_general) {
      createNewGaol(res.data)
    }
  }

  const updateUserSportsList = (newSports: any) => {
    const user = currentUser
    if (newSports.length && !!newSports[0]) {
      user.user.sports_list = newSports.map((sport: any) => ({
        _id: sport._id,
        nif_sports_category_id: sport.nif_sports_category_id,
        sports_category_name: sport.sports_category_name,
        federation_name: sport.federation_name,
        status: sport.status,
        sorting: sport?.sorting || -1,
      }))

      user.user.sports_list = user.user.sports_list.sort((a, b) => b.sorting - a.sorting)
    }

    saveUser(user)
  }

  useEffect(() => {
    getSportsByOrg()
  }, [PageSize, currentPage, companyofPortal.company_id, currentUser?.user?.organisation_id._id])

  useEffect(() => {
    if (!showModal)
      setInitialValues({
        title: '',
        short_description: '',
        status: '',
        is_general: true,
        sorting: -1,
      })
  }, [showModal])

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {intl.formatMessage({ id: 'overview.units' })}
            </span>
            <span className='text-muted mt-1 fw-bold fs-7'>
              {intl.formatMessage({ id: 'overview.of.all.units.registered.in.the.organisation' })}
            </span>
          </h3>

          <div className='card-toolbar'>
            <div
              onClick={() => {
                setShowModal(true)
                setisEdit(false)
              }}
              className='btn btn-lg btn-primary authbgcolor'
            >
              {intl.formatMessage({ id: 'create.unit' })}
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted' style={{ backgroundColor: '#E9F4FF' }}>
                  <th className='ps-4 rounded-start'>{intl.formatMessage({ id: 'unit' })}</th>
                  <th className=''>{intl.formatMessage({ id: 'description' })}</th>
                  <th>{intl.formatMessage({ id: 'status' })}</th>
                  <th className=' text-end rounded-end px-4' />
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {sports?.length > 0 ? (
                  sports?.map((tbData: any) => (
                    <tr key={tbData?._id}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <span className='mx-2 text-dark fw-bolder d-block fs-6 left-text'>
                            {tbData?.sports_category_name}
                          </span>
                        </div>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-6'
                          style={{ cursor: 'auto' }}
                        >
                          {tbData?.description || tbData?.federation_name}
                        </a>
                      </td>

                      <td className=''>{GetStatus(intl, tbData?.status)}</td>
                      <td style={{ float: 'right' }}>
                        <div className='d-flex flex-shrink-0'>
                          <button
                            onClick={() => {
                              setInitialValues({
                                id: tbData?._id || undefined,
                                title: tbData?.sports_category_name || undefined,
                                short_description: tbData?.description || undefined,
                                status: tbData?.status,
                                sorting: tbData?.sorting || -1,
                              })
                              setisEdit(true)
                              setShowModal(true)
                            }}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-lg me-1'
                          >
                            <i className='fa-duotone fa-pencil' />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {intl.formatMessage({ id: 'status' })}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>

          <div className='row'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start' />
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <CustomPagination
                className='pagination-bar justify-content-md-end'
                currentPage={currentPage}
                totalCount={sports ? sports?.pagination?.totalRecords : 0}
                pageSize={PageSize}
                onPageChange={(page: any) => setCurrentPage(page)}
              />
            </div>
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>

      <SportsCreationModal
        showModal={showModal}
        setShowModal={setShowModal}
        initialValues={initialValues}
        isUpdate={isEdit}
        submitCallback={async (values: any) => {
          const isUpdate = !!values.id
          if (isUpdate) {
            await updateSport(values)
          } else {
            await createSport(values)
          }
          setShowModal(false)
        }}
      />
    </>
  )
}

export { SportsTable }
