/* eslint-disable prefer-const */
import clsx from 'clsx'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { Modal } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'

interface SportsCreationModalProps {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  submitCallback: (values: any) => void
  initialValues: {
    title: string
    short_description: string
    is_general?: boolean
    sorting?: number
  }
  isUpdate?: boolean
}

const SportsCreationModal: React.FC<SportsCreationModalProps> = ({
  showModal,
  setShowModal,
  initialValues,
  submitCallback,
  isUpdate,
}) => {
  const memberSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    short_description: Yup.string().required('Required'),
  })
  const isEditDialog = initialValues.title !== ''
  const [loading] = React.useState(false)
  const intl = useIntl()
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size='lg' keyboard={false}>
      <div className='modal-body'>
        <div>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={memberSchema}
            onSubmit={(values) => {
              submitCallback(values)
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div>
                  <h3 className='fw-bolder my-2'>
                    {isEditDialog ? 'Oppdater enhet' : 'Opprett ny enhet'}
                  </h3>
                </div>
                <div>
                  <span className='text-muted'>
                    Her kan du opprette eller oppdatere enheter som er tilknyttet din organisasjon.
                    <br />
                    Merk at når du oppdaterer status så vil det påvirke alle støtter som er
                    tilknyttet denne enheten. Hvis du deaktiverer enheten så vil alle støtter som er
                    tilknyttet denne enheten også bli pauset.
                  </span>
                </div>
                <div className='row mb-3 mt-7'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Tittel:</label>
                  <div className='col-lg-9 fv-row'>
                    <Field
                      style={{ borderRadius: '8px' }}
                      type='text'
                      name='title'
                      className={clsx(
                        'form-control form-control-lg form-control-solid inputClass',
                        {
                          'is-invalid': touched.title && errors.title,
                        }
                      )}
                      placeholder='Angi tittel på enhet (f.eks. Lokasjon, Avdeling, Kategori)'
                    />
                  </div>
                </div>
                <div className='row mb-3 mt-7'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Beskrivelse:</label>
                  <div className='col-lg-9 fv-row'>
                    <Field
                      style={{ borderRadius: '8px' }}
                      type='text'
                      name='short_description'
                      className={clsx(
                        'form-control form-control-lg form-control-solid inputClass',
                        {
                          'is-invalid': touched.short_description && errors.short_description,
                        }
                      )}
                      placeholder='Angi beskrivelse på enhet (f.eks. Forbund, Krets, Lokalavdeling)'
                    />
                  </div>
                </div>
                <div className='row mb-3  mt-7'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Status:</label>

                  <div className='col-lg-9'>
                    <Field name='status' className='form-select form-select-lg fw-bold' as='select'>
                      {/* "active", "completed", "paused", "canceled" */}
                      <option value='enabled'>Aktiv</option>
                      <option value='disabled'>Inaktiv</option>
                    </Field>
                  </div>
                </div>
                {!isUpdate && (
                  <div className='row  mb-3 mt-7'>
                    <div className='col-lg-3'>
                      <label className=' col-form-label fw-bold fs-6 '>Lag generell støtte:</label>
                    </div>
                    <div className='col-md-9'>
                      <Field type='checkbox' name='is_general' className='form-check-input mt-4 ' />
                      <div className='text-danger'>
                        <ErrorMessage name='is_general' />
                      </div>
                    </div>
                  </div>
                )}

                {isUpdate && (
                  <div className='row  mb-3 mt-7'>
                    <div className='col-lg-3'>
                      <label className=' col-form-label fw-bold fs-6 '>
                        {' '}
                        {intl.formatMessage({ id: 'sorting' })}:
                      </label>
                    </div>
                    <div className='col-md-9'>
                      <div className='d-flex '>
                        <Field
                          type='number'
                          name='sorting'
                          min={-1}
                          className='form-control form-control-lg form-control-solid inputClass me-2 '
                        />
                      </div>
                      <label className=' col-form-label fw-bold fs-6 '>
                        {intl.formatMessage({ id: 'This.sorting.is.reflected.in.the.app' })}
                      </label>
                    </div>
                  </div>
                )}
                <div className=' d-flex justify-content-between mt-2'>
                  <button
                    type='button'
                    className='btn btn-light me-3'
                    style={{ color: '#3F4254', marginLeft: '-4px' }}
                    onClick={() => setShowModal(false)}
                  >
                    Avbryt
                  </button>
                  <button
                    type='submit'
                    className='btn btn-lg btn-primary authbgcolor'
                    disabled={loading}
                  >
                    {!loading && (
                      <span className='indicator-label'>
                        {isEditDialog ? 'Oppdater' : 'Opprett'}
                      </span>
                    )}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2' />
                      </span>
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(SportsCreationModal)
