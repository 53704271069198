/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useIntl } from 'react-intl'
import FAQModal from './Marketing/FAQModal'

function MarketingHeader() {
  const [show, setShow] = React.useState(false)
  const intl = useIntl()
  const handleClick = () => {
    setShow(true)
  }
  return (
    <div className='row mb-5 mb-xl-10 mb-10'>
      <div className='col-lg-9'>
        <div className='card'>
          <div className='card-body'>
            <div className='d-flex flex-wrap flex-sm-nowrap'>
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <div className=' text-gray-800  fs-2 fw-bolder me-1'>
                        {' '}
                        {intl.formatMessage({ id: 'spread.the.word' })}
                      </div>
                    </div>

                    <div className='d-flex flex-wrap fw-bold fs-6'>
                      <div className='fontsizechfslogtime  text-gray-400  me-5 '>
                        {intl.formatMessage({ id: 'marketing.text.p' })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-3'>
        <div className='card '>
          <div className='card-body'>
            <div className='d-flex flex-wrap flex-sm-nowrap'>
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <div className=' text-gray-800  fs-2 fw-bolder me-1'>
                        {intl.formatMessage({ id: 'faq' })}
                      </div>
                    </div>

                    <div className='d-flex flex-wrap fw-bold fs-6'>
                      <div className=' fontsizechfslogtime text-gray-400  me-5 '>
                        {intl.formatMessage({ id: 'marketing.text.p2' })}{' '}
                        <span
                          className='link-primary fs-6 fw-bolder'
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleClick()}
                        >
                          {intl.formatMessage({ id: 'faq.userguide.link' })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='card mb-5 mb-xl-10'>
        <div className='card-body'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <div className=' text-gray-800  fs-2 fw-bolder me-1'>Mer informasjon</div>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6'>
                    <div className=' fontsizechfslogtime text-gray-400  me-5 '>
                      Klikk <a href="https://s2.q4cdn.com/170666959/files/Blank.pdf"
                      target='blank'
                      download={"https://s2.q4cdn.com/170666959/files/Blank.pdf"} className='ink-primary fs-6 fw-bolder'>her</a> for guide med
                      detaljerte forklaringer
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <FAQModal
        show={show}
        handleClose={() => {
          setShow(false)
        }}
      />
    </div>
  )
}

export default MarketingHeader
