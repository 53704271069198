/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useState } from 'react'

import { useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import { EnableSidebar } from '../../../_metronic/layout/core'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { useMainContext } from '../../../context'
import { useAuth } from '../../modules/auth'
import { handleGetRequest } from '../../services'
import FAQModal from '../Marketing/FAQModal'
import { DashboardCell } from './DashboardCell'
import VippsModal from './_modals/DashboardVippsModal'
import { DashboardChartCell } from './cells/DashboardChartCell'
import { DashboardEditorCell } from './cells/DashboardEditorCell'
import { DashboardMarketingCell } from './cells/DashboardMarketingCell'

export interface ChartData {
  count: number
  totalAmount: number
  year: number
  week: number
}

export interface IOrganizationDetails {
  __v: number
  _id: string
  abbreviation: string
  account_created: boolean
  account_no: string
  address: string
  address_line1: null
  address_line2: null
  city: string
  country: string
  country_id: string
  created_at: string
  deleted: boolean
  describing_name: string
  description: string
  email: string
  home_page: string
  latitude: number
  location: {
    type: string
    coordinates: number[]
  }
  logo: string
  longitude: number
  members: number
  mobile_phone: string
  msn: string
  msn_prefill_order_id: string
  msn_status: string
  org_id: number
  org_logo_base64: string
  org_name: string
  org_type_id: string
  organisation_number: string
  phone_no: string
  post_code: string
  reference_id: string
  registration_date: string
  updated_at: string
  vipps_msn_status: string
}

function DashboardPage() {
  const navigate = useNavigate()

  const [showVippsModal, setShowVippsModal] = useState(false)
  const [dashboardData, setDashboardData] = useState<{
    payments: ChartData[]
    pending: number
    totalSupport: {
      total_active_supports: number
      total_received_supports: number
    }
    usersWithExpiredConsentAndActiveGoalSupports: number
  }>()
  const { setLoading } = useContext(LoadingContext)
  const { currentUser } = useAuth()
  const [show, setShow] = useState(false)
  const [showVippsButton, setShowVippsButton] = useState(false)
  const [orgDetails, setOrgDetails] = useState<IOrganizationDetails | null>(null)
  const { setOrganisationLogo } = useMainContext()

  const intl = useIntl()

  useEffect(() => {
    const getOrgChartStats = async () => {
      const { data } = await handleGetRequest(
        `/organisation/dashboard/stats/${currentUser?.user?.organisation_id?._id}`
      )(setLoading)
      setDashboardData(data)
    }
    getOrgChartStats()
  }, [currentUser?.user?.organisation_id?._id, setLoading])
  useEffect(() => {
    const getOrgDetails = async () => {
      const response = await handleGetRequest(
        `/organisation/${currentUser?.user?.organisation_id?._id}`
      )(setLoading)
      if (response) {
        setOrgDetails(response.data)
        setOrganisationLogo(response.data?.logo)
      } else {
        setLoading(false)
      }
    }
    getOrgDetails()
  }, [currentUser?.user?.organisation_id?._id, setLoading, showVippsModal])

  useEffect(() => {
    if (orgDetails) setShowVippsButton(!(orgDetails?.msn_status === 'completed'))
  }, [orgDetails])

  if (currentUser?.user?.user_type === 'manager') {
    navigate('/home')
  }

  return (
    <>
      {showVippsButton && (
        <div
          className='w-100 mb-3'
          onClick={() => setShowVippsModal(true)}
          style={{ cursor: 'pointer' }}
        >
          <div className='card h-100' id='mer' style={{ background: '#ff5b24' }}>
            <div className='card-body'>
              <div className='d-flex flex-wrap flex-sm-nowrap'>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap'>
                    <div className='d-flex flex-column'>
                      <div className='fs-2 fw-bolder me-1' style={{ color: 'white' }}>
                        {orgDetails?.vipps_msn_status === 'ORDER_SUBMITTED'
                          ? intl.formatMessage({ id: 'vipps.processing' })
                          : intl.formatMessage({ id: 'set.up.Vipps.connection' })}
                      </div>

                      <div className='d-flex flex-wrap fw-bold fs-6'>
                        <div className='fontsizechfslogtime me-5' style={{ color: 'white' }}>
                          {orgDetails?.vipps_msn_status === 'ORDER_SUBMITTED'
                            ? intl.formatMessage({ id: 'vipps.processing.info' })
                            : intl.formatMessage({ id: 'must.be.performed.by.a.person' })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='dashboard-grid'>
        <div className='dashboard-cell-1'>
          <Link to='/marketing/studio'>
            <DashboardEditorCell />
          </Link>
        </div>
        <div className='dashboard-cell-2'>
          <Link to='/marketing'>
            <DashboardMarketingCell />
          </Link>
        </div>
        <div className='dashboard-cell-3'>
          <div className='dashboard-sub-grid'>
            <div className='dashboard-sub-cell-1'>
              <Link to='/org/support-player'>
                <DashboardCell
                  title={intl.formatMessage({ id: 'amount.supporters' })}
                  description={intl.formatMessage({ id: 'for.all.units' })}
                  numbertext={dashboardData?.totalSupport?.total_active_supports || '0'}
                />
              </Link>
            </div>
            <div className='dashboard-sub-cell-2'>
              <DashboardCell
                title={intl.formatMessage({ id: 'supporter.expired.consent' })}
                description={intl.formatMessage({ id: 'for.all.units' })}
                numbertext={dashboardData?.usersWithExpiredConsentAndActiveGoalSupports || '0'}
              />
            </div>
            <div className='dashboard-sub-cell-3'>
              <Link to='/org/goals/'>
                <DashboardCell
                  title={intl.formatMessage({ id: 'support.received.this.week' })}
                  description={intl.formatMessage({ id: 'for.all.units' })}
                  numbertext={dashboardData?.pending || '0'}
                />
              </Link>
            </div>
            <div className='dashboard-sub-cell-4'>
              <Link to='/org/goals/'>
                <DashboardCell
                  title={intl.formatMessage({ id: 'support.received.last.week' })}
                  description={intl.formatMessage({ id: 'for.all.units' })}
                  numbertext={
                    dashboardData?.payments?.[
                      dashboardData.payments.length - 1
                    ]?.totalAmount.toString() || '0'
                  }
                />
              </Link>
            </div>
            <div className='dashboard-sub-cell-5'>
              <Link to='/org/goals/'>
                <DashboardCell
                  title={intl.formatMessage({ id: 'support.received' })}
                  description={intl.formatMessage({ id: 'for.all.units' })}
                  numbertext={dashboardData?.totalSupport?.total_received_supports || '0'}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className='dashboard-cell-4'>
          <DashboardChartCell chartData={dashboardData?.payments} displayAsLinkToReporting />
        </div>
      </div>
      <VippsModal
        show={showVippsModal}
        handleClose={() => {
          setShowVippsModal(false)
        }}
        prefillID={orgDetails?.msn_prefill_order_id}
        orgDetails={orgDetails}
      />
      <FAQModal
        show={show}
        handleClose={() => {
          setShow(false)
        }}
      />
    </>
  )
}

const DashboardWrapper: FC = () => (
  // const intl = useIntl()
  <EnableSidebar>
    <DashboardPage />
  </EnableSidebar>
)

export { DashboardWrapper as DashboardPage }

// interface for VipsModalProps
