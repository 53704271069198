import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import { DrawerComponent, ScrollComponent, ToggleComponent } from '../../../assets/ts/components'
import { AsideMenuMain } from './AsideMenuMain'

type Props = {
  asideMenuCSSClasses: string[]
}

const AsideMenu: React.FC<Props> = ({ asideMenuCSSClasses }) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const { pathname } = useLocation()

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0
      }
    }, 50)
  }, [pathname])

  return (
    <div
      id='kt_aside_menu_wrapper'
      ref={scrollRef}
      className='w-100  d-flex pe-2 kt_aside_menu_wrapper'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: true, lg: true}'
      data-kt-scroll-height='150'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-wrappers='#kt_aside, #kt_aside_menu, #kt_aside_menu_wrapper'
      data-kt-scroll-offset='100'
    >
      <div
        id='#kt_aside_menu'
        data-kt-menu='true'
        className='menu menu-column menu-rounded fw-bold '
      >
        <AsideMenuMain />
      </div>
    </div>
  )
}

export { AsideMenu }
