import * as Sentry from '@sentry/react'
import axios from 'axios'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
// Apps
import { nb } from 'date-fns/locale'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { ToastContainer } from 'react-toastify'
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 * */
import 'react-toastify/dist/ReactToastify.css'
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/sass/style.scss'
import { AuthProvider, setupAxios } from './app/modules/auth'
import { AppRoutes } from './app/routing/AppRoutes'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 10% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.05, // This sets the sample rate at 5%
  replaysOnErrorSampleRate: 1.0, //  The sample rate of 100% when sampling sessions where errors occur.
  environment: import.meta.env.VITE_ENV,
})

// Locale for datepicker
registerLocale('nb', nb)
setDefaultLocale('nb')

const queryClient = new QueryClient()
const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <QueryClientProvider client={queryClient}>
    <ToastContainer />
    <MetronicI18nProvider>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </MetronicI18nProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)
