/* eslint-disable no-script-url */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/anchor-is-valid */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { numberSpacing, toAbsoluteUrl } from '../../../_metronic/helpers'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { handleDeleteRequest, handleGetRequest, handleGetRequestWithoutToken } from '../../services'

interface GoalData {
  _id: string
  organisation_id: string
  organisation_sports_category_id: string
  status: string
  banner_image: string
  image: string
  title: string
  short_description: string
  target_amount: number
  start_date: string
  due_date: string
  created_at: string
  updated_at: string
  support_total_amount: number
  support_total_reserved_amount: number
  support_total_pending_amount: number
  total_supporter_player: number
  total_active_supporter_player: number
  organisation_sport_name: string
  org_name: string
  org_logo: string
  general_stotte: boolean
}

export default function FormalSingle() {
  const { setLoading } = useContext(LoadingContext)
  const [organisation, setOrganisation] = useState<any>()
  const [formal, setFormal] = useState<GoalData>()
  const [statsData, setStatsData] = useState<any>()
  const [bannerImage, setBannerImage] = useState<any>()
  const { id } = useParams()
  const intl = useIntl()
  const navigation = useNavigate()
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
  const getOrgDetails = async (organisation_id: string) => {
    const { data } = await handleGetRequest(`/organisation/${organisation_id}`)(setLoading)
    setOrganisation(data)
    getLogo()
  }
  const getGoalDetails = async () => {
    const { data } = await handleGetRequestWithoutToken(`/goal/${id}`)(setLoading)
    getStats(data?.organisation_id)
    setBannerImage(data?.banner_image)
    getOrgDetails(data?.organisation_id)
    setFormal(data)
  }

  const getStats = async (organisation_id: string) => {
    const { data } = await handleGetRequestWithoutToken(`/organisation/stats/${organisation_id}`)(
      setLoading
    )
    setStatsData(data)
  }

  const handleDelete = async () => {
    const { success } = await handleDeleteRequest(`/goal/${id}`)(setLoading)
    if (success) navigation('/org/goals/')
  }

  const getDates = () => {
    if (formal) {
      const { start_date, due_date } = formal
      // if date is 3024-01-01T00:00:00.000+00:00 then don't return anything else start_date and due_date
      if (due_date === '3024-01-01T00:00:00.000Z' || due_date === null || due_date === '') {
        return moment(start_date).format('DD.MM.YYYY')
      }
      return `${moment(start_date).format('DD.MM.YYYY')} - ${moment(due_date).format('DD.MM.YYYY')}`
    }
  }

  const getTargetAmount = () => {
    if (formal?.target_amount) {
      const amount = numberSpacing(formal?.target_amount)

      return `${amount}`
    }
    return 0
  }

  const getLogo = (): string => {
    if (organisation?.logo) {
      return organisation?.logo
    }
    if (organisation?.org_logo_base64) {
      return `data:image/png;base64,${organisation?.org_logo_base64}`
    }
    return toAbsoluteUrl('/media/misc/logo-missing.jpeg')
  }

  const getHeaderImage = (): string =>
    `url(${bannerImage || toAbsoluteUrl('/media/misc/Header-1.jpg')})`

  useEffect(() => {
    getGoalDetails()
  }, [])
  const url = `${window.location.protocol}//${window.location.host}/parker/${organisation?.organisation_number}/${id}`
  const shareURL = () => {
    window.open(url, '_blank')
  }
  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>{intl.formatMessage({ id: 'goal.details' })}</h3>

        <div className='d-flex flex-wrap my-2'>
          <div className='me-4'>
            <Link to='/org/goals/' className='btn btn-primary btn-md bg-navy_blue'>
              {intl.formatMessage({ id: 'goal.overview' })}
            </Link>
          </div>
          <div className='me-4'>
            <button
              type='button'
              className='btn  btn-primary btn-md me-2 bg-navy_blue'
              style={{ height: '46px', borderRadius: '8px' }}
              onClick={() => navigation(`/formalEdit/${id} `)}
            >
              {intl.formatMessage({ id: 'Edit.goal' })}
            </button>
          </div>
          {formal && formal?.general_stotte === false && formal?.total_supporter_player < 1 && (
            <div className=''>
              <button
                className='btn btn-md  slett '
                onClick={() => {
                  setShowModalConfirm(true)
                }}
              >
                {intl.formatMessage({ id: 'Delete' })}
              </button>
            </div>
          )}

          {/* <a
            href='#'
            className='bg-white btn text-muted'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            Filter
            <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 mxC-3' />
          </a> */}
          {/* begin::Menu */}
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
            data-kt-menu='true'
          >
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <a className='menu-link px-3'>30 Days</a>
            </div>
            {/* end::Menu item */}

            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <a className='menu-link px-3'>90 Days</a>
            </div>
            {/* end::Menu item */}
          </div>
          {/* end::Menu */}
        </div>
      </div>
      <>
        <div className='card mb-5 mb-xl-8'>
          <div className=''>
            <div className='modal-body px-10 py-8'>
              <div
                className='card card-xl-stretch mb-xl-10 card_borderC'
                style={{
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: getHeaderImage(),
                  borderRadius: '8px',
                }}
              >
                {/* begin::Body */}
                <div className='p-5 d-flex flex-column'>
                  {/* begin::Wrapper */}
                  <div
                    className='d-flex flex-column flex-grow-1'
                    style={{ height: 100, minHeight: 100, width: '100%' }}
                  />

                  <div className='pt-5'>
                    <div className='d-flex ' style={{ alignItems: 'center' }}>
                      <div
                        className='card bg-white me-3 p-2'
                        style={{ border: '2px solid #F1F2F9', borderRadius: '8px' }}
                      >
                        <img
                          src={toAbsoluteUrl('/media/logos/SlogoNew.jpg')}
                          alt='logo'
                          className=' h-60px logo svg-icon-cufs '
                          style={{ width: '60px', objectFit: 'contain' }}
                        />
                      </div>

                      <div
                        className='card bg-white me-3 p-2'
                        style={{ border: '2px solid #F1F2F9', borderRadius: '8px' }}
                      >
                        <img
                          src={getLogo()}
                          alt='logo'
                          className=' h-60px logo svg-icon-cufs '
                          style={{ width: '60px', objectFit: 'contain' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='d-flex flex-wrap g-0 gap-0 mb-4 bottom-4'
                style={{ marginBottom: '3rem' }}
              >
                {/* begin::Col */}
                <div className='col-2 min-w-150px'>
                  <div className='d-flex '>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-40px me-3'>
                      <div className='d-flex align-items-center bg-white bg-opacity-50'>
                        <i className='fa-duotone  fa-bullseye fs-1' />
                      </div>
                    </div>
                    {/* end::Symbol */}

                    {/* begin::Title */}
                    <div>
                      <div
                        className='fs-14 text-dark fw-bolder lh-1'
                        style={{ color: '#000000', fontSize: '14px' }}
                      >
                        {intl.formatMessage({ id: 'Fundraising.goals' })}
                      </div>
                      <div
                        className='fs-14  fw-bold'
                        style={{ color: '#000000', marginTop: '5px', fontSize: '14px' }}
                      >
                        {getTargetAmount()}
                      </div>
                    </div>
                    {/* end::Title */}
                  </div>
                </div>
                {/* end::Col */}

                {/* begin::Col */}
                <div className='col-2 min-w-150px'>
                  <div className='d-flex'>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-40px me-3'>
                      <div className='d-flex align-items-center bg-white bg-opacity-50'>
                        <i className='fa-duotone  fa-wallet fs-1' />
                      </div>
                    </div>
                    {/* end::Symbol */}

                    {/* begin::Title */}
                    <div>
                      <div
                        className='fs-14 text-dark fw-bolder lh-1'
                        style={{ color: '#000000', fontSize: '14px' }}
                      >
                        {intl.formatMessage({ id: 'Collected' })}
                      </div>
                      <div
                        className='fs-14  fw-bold'
                        style={{ color: '#000000', marginTop: '5px', fontSize: '14px' }}
                      >
                        {formal?.support_total_amount}
                      </div>
                    </div>
                    {/* end::Title */}
                  </div>
                </div>
                {/* end::Col */}

                {/* begin::Col */}
                <div className='col-2 min-w-150px'>
                  <div className='d-flex'>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-40px me-3'>
                      <div className='d-flex align-items-center bg-white bg-opacity-50'>
                        <i className='fa-duotone fa-credit-card fs-1' />
                      </div>
                    </div>
                    {/* end::Symbol */}

                    {/* begin::Title */}
                    <div>
                      <div
                        className='fs-14 text-dark fw-bolder lh-1'
                        style={{ color: '#000000', fontSize: '14px' }}
                      >
                        {intl.formatMessage({ id: 'Outstanding.move' })}
                      </div>
                      <div
                        className='fs-14  fw-bold'
                        style={{ color: '#000000', marginTop: '5px', fontSize: '14px' }}
                      >
                        {formal?.support_total_pending_amount}
                      </div>
                    </div>
                    {/* end::Title */}
                  </div>
                </div>
                {/* end::Col */}

                {/* begin::Col */}
                <div className='col-2 min-w-150px'>
                  <div className='d-flex'>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-40px me-3'>
                      <div className='d-flex align-items-center bg-white bg-opacity-50'>
                        <i className='fa-duotone fa-circle-exclamation-check fs-1' />
                      </div>
                    </div>
                    {/* end::Symbol */}

                    {/* begin::Title */}
                    <div>
                      <div
                        className='fs-14 text-dark fw-bolder lh-1'
                        style={{ color: '#000000', fontSize: '14px' }}
                      >
                        {intl.formatMessage({ id: 'reserved.amount' })}
                      </div>
                      <div
                        className='fs-14  fw-bold'
                        style={{ color: '#000000', marginTop: '5px', fontSize: '14px' }}
                      >
                        {formal?.support_total_reserved_amount}
                      </div>
                    </div>
                    {/* end::Title */}
                  </div>
                </div>
                {/* end::Col */}

                {/* begin::Col */}
                <div className='col-2 min-w-150px'>
                  <div className='d-flex '>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-40px me-3'>
                      <div className='d-flex align-items-center bg-white bg-opacity-50'>
                        <i className='fa-duotone  fa-users fs-1' />
                      </div>
                    </div>
                    {/* end::Symbol */}

                    {/* begin::Title */}
                    <div>
                      <div
                        className='fs-14 text-dark fw-bolder lh-1 text-capitalize'
                        style={{ color: '#000000', fontSize: '14px' }}
                      >
                        {intl.formatMessage({ id: 'support.players.small' })}
                      </div>
                      <div
                        className='fs-14  fw-bold'
                        style={{ color: '#000000', marginTop: '5px', fontSize: '14px' }}
                      >
                        {formal?.total_active_supporter_player}
                      </div>
                    </div>
                    {/* end::Title */}
                  </div>
                </div>
                {/* end::Col */}

                {/* begin::Col */}
                <div className='col-2 min-w-150px'>
                  <div className='d-flex'>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-40px me-3'>
                      <div className='d-flex align-items-center bg-white bg-opacity-50'>
                        <i className='fa-duotone  fa-calendar-check fs-1' />
                      </div>
                    </div>
                    {/* end::Symbol */}

                    {/* begin::Title */}
                    <div>
                      <div
                        className='fs-14 text-dark fw-bolder lh-1'
                        style={{ color: '#000000', fontSize: '14px' }}
                      >
                        {intl.formatMessage({ id: 'Duration' })}
                      </div>
                      <div
                        className='fw-bold mt-1'
                        style={{
                          whiteSpace: 'nowrap',
                          color: '#000000',
                          marginTop: '5px',
                          fontSize: '14px',
                        }}
                      >
                        {getDates()}
                      </div>
                    </div>
                    {/* end::Title */}
                  </div>
                </div>
                {/* end::Col */}
              </div>
              <div className='row gy-5 g-xl-10'>
                {/* begin::Col */}
                <div className='col-xl-8'>
                  <h3
                    className='fw-bolder my-2'
                    style={{ marginBottom: '0.9rem', marginTop: '2rem' }}
                  >
                    {formal?.organisation_sport_name
                      ? `${formal?.organisation_sport_name}: ${formal?.title}`
                      : ''}
                  </h3>

                  <div
                    className=' fontsizechfslogtime d-flex align-items-center text-gray-600  me-5 my-2 presentation '
                    // dangerouslySetInnerHTML={{__html: formal?.short_description}}
                  >
                    <CKEditor
                      editor={ClassicEditor}
                      data={formal?.short_description ? formal.short_description : ''}
                      id='displayEditor'
                      disabled
                      config={{ toolbar: [] }}
                      // set height for editor
                    />
                  </div>
                  {/*
                  <div className='d-flex mt-20 app-logos'>

                    <img
                      src={toAbsoluteUrl('/media/misc/Play store logo.png')}
                      alt='logo'
                      className='logo svg-icon-cufs'
                      style={{
                        height: '38px',
                        width: '100%',
                      }}
                    />

                    <img
                      src={toAbsoluteUrl('/media/misc/App store logo.png')}
                      alt='logo'
                      className=' logo svg-icon-cufs ms-1'
                      style={{
                        height: '38px',
                        width: '100%',
                      }}
                    />
                  </div> */}
                </div>
                <div className='col-xl-4'>
                  <div className='col-lg-12 bg-light rounded-4 p-6 '>
                    <div className='d-flex align-items-center'>
                      <div
                        className='card svg-icon-1 svg-icon-dark me-4 rounded-3 border border-1 border-gray-300 d-flex justify-content-center align-items-center '
                        style={{
                          border: '2px solid #F1F2F9',
                          borderRadius: '12px',
                          height: '55px',
                          width: '55px',
                        }}
                      >
                        <img
                          src={getLogo()}
                          className=' logo svg-icon-cufs '
                          style={{
                            height: '40px',
                            width: '40px',
                            objectFit: 'contain',
                          }}
                          alt=''
                        />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fw-bolder  mb-1 fs-6'>
                          {organisation?.org_name}

                          {/* - {formal?.organisation_sport_name} */}
                        </span>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {intl.formatMessage({ id: 'org.no' })}:{' '}
                          {organisation?.organisation_number}
                        </span>
                      </div>
                    </div>
                    <div
                      className=' fontsizechfslogtime d-flex align-items-center text-gray-400 pt-5
                     presentation'
                    >
                      <CKEditor
                        editor={ClassicEditor}
                        data={organisation?.description ? organisation.description : ''}
                        id='displayEditor'
                        disabled
                        config={{ toolbar: [] }}
                        // set height for editor
                      />

                      {/* {organisation?.description} */}
                    </div>
                    <h6 className='fw-bolder mt-8 mb-4'>
                      {intl.formatMessage({ id: 'A.little.about.us' })}
                    </h6>
                    <div className='d-flex align-items-center  my-5'>
                      {/* begin::Symbol */}
                      <div className='symbol symbol-40px '>
                        <div className='symbol-label  w-100 h-100 '>
                          <div
                            // src={toAbsoluteUrl('/media/misc/group.png')}
                            className='card svg-icon-1 svg-icon-dark me-4 rounded-3 border border-1 border-gray-300
                            d-flex justify-content-center align-items-center
                            '
                            // alt=''
                            style={{
                              width: '55px',
                              height: '55px',
                            }}
                          >
                            <i className='fa-duotone fa-bullseye fs-1' />
                          </div>
                        </div>
                      </div>
                      {/* end::Symbol */}

                      {/* begin::Title */}
                      <div>
                        <div className='fs-12 text-dark fw-bolder lh-1'>
                          {intl.formatMessage({ id: 'Available.goals' })}
                        </div>
                        <h4 className='fs-20 text-gray-800 fw-bold'>
                          {statsData?.total_active_goals}
                        </h4>
                      </div>
                      {/* end::Title */}
                    </div>

                    <div className='d-flex align-items-center my-3'>
                      {/* begin::Symbol */}
                      <div className='symbol symbol-40px '>
                        <div className='symbol-label   w-100 h-100  '>
                          <div
                            // src={toAbsoluteUrl('/media/misc/group.png')}
                            className='card svg-icon-1 svg-icon-dark me-4 rounded-3 border border-1 border-gray-300
                            d-flex justify-content-center align-items-center
                            '
                            // alt=''
                            style={{
                              width: '55px',
                              height: '55px',
                            }}
                          >
                            <i className='fa-duotone fa-users fs-1' />
                          </div>
                        </div>
                      </div>
                      {/* end::Symbol */}

                      {/* begin::Title */}
                      <div>
                        <div className='fs-12 text-dark fw-bolder lh-1'>
                          {' '}
                          {intl.formatMessage({ id: 'total.goals' })}
                        </div>
                        <h4 className='fs-20 text-gray-800 fw-bold'>
                          {statsData?.total_active_supporters}
                        </h4>
                      </div>
                      {/* end::Title */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer footericons'>
              <div className='row footericonsrow'>
                <div className='col-md-5'>
                  <ul className='icons-ul'>
                    <li>
                      <a
                        href='https://apps.apple.com/no/app/stotte/id6467891480'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <i
                          className='fa-brands fa-apple'
                          style={{ color: 'black', fontSize: '20px' }}
                        />{' '}
                        {intl.formatMessage({ id: 'app.for.ios' })}
                      </a>
                    </li>
                    <li>
                      <a
                        className='item-2'
                        href='https://play.google.com/store/apps/details?id=com.stotte'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <i
                          className='fa-brands fa-google-play'
                          style={{ color: 'black', fontSize: '20px' }}
                        />{' '}
                        {intl.formatMessage({ id: 'app.for.andriod' })}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='col-md-5' />
                <div className='col-md-2'>
                  <ul className='icons-ul' style={{ float: 'right' }}>
                    <li>
                      <a className='item-2' onClick={() => shareURL()}>
                        <i
                          className='fa-light fa-share-nodes'
                          style={{ color: 'black', fontSize: '20px' }}
                        />{' '}
                        {intl.formatMessage({ id: 'delete.goal' })}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={showModalConfirm}
          onHide={() => setShowModalConfirm(false)}
          size='lg'
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className='modal-title'> {intl.formatMessage({ id: 'del.goal' })}</h5>
            </Modal.Title>
          </Modal.Header>
          <div className='modal-body'>
            {intl.formatMessage({ id: 'del.goal.confirm' })}
            <br />
            <br />
            <br />
            <button
              type='button'
              className='btn  avbryt me-3'
              onClick={() => {
                setShowModalConfirm(false)
              }}
            >
              {intl.formatMessage({ id: 'Cancel' })}
            </button>
            <button
              type='button'
              style={{ float: 'right' }}
              onClick={() => {
                handleDelete()
              }}
              className='btn slett '
            >
              {intl.formatMessage({ id: 'yes.delete' })}
            </button>
          </div>
        </Modal>
      </>
    </>
  )
}
