/* eslint-disable class-methods-use-this */
import { Frame } from 'zimjs'
import { IDocStorage } from '../StudioApp'
import { Manifest } from '../StudioManifest'
import { AbstractContainer } from '../containers/AbstractContainer'
import { Graphic } from '../containers/Graphic'
import { EditorImage } from '../containers/Image'
import { RoundedRectangleWithText } from '../containers/RoundedRectangle'

export class SquareTemplate {
  private width: number

  private height: number

  private parentFrame: Frame

  private backgroundGraphic: Graphic | undefined

  private topText: RoundedRectangleWithText | undefined

  private bottomText: RoundedRectangleWithText | undefined

  private foregroundImage: EditorImage | undefined

  private orgLogo: Graphic | undefined

  private doc: IDocStorage

  private imgString: string | undefined

  constructor(frame: Frame, width: number, height: number, doc: IDocStorage) {
    this.width = width
    this.height = height
    this.parentFrame = frame
    this.doc = doc
    this.initialize()
    this.addListeners()
  }

  initialize() {
    this.backgroundGraphic = this.addElement(new Graphic(this.width, this.height))
    this.bottomText = this.addElement(
      new RoundedRectangleWithText(295, 52, Manifest.colors.blue, {
        color: Manifest.colors.white,
        text: this.doc.bottomHeader,
        size: 220,
        font: 'Roboto',
        label: 'Nedre tekstfelt',
        y: 9,
      })
    )
    this.topText = this.addElement(
      new RoundedRectangleWithText(500, 65, 'white', {
        color: Manifest.colors.blue,
        text: this.doc.topHeader.toUpperCase(),
        size: 430,
        font: 'Dosis',
        y: 20,
        label: 'Øverste tekstfelt',
      })
    )
    this.foregroundImage = this.addElement(
      new EditorImage(this.width, this.height, this.overlayImage)
    )

    if (this.doc.orgLogo) {
      this.orgLogo = this.addElement(new Graphic(this.width / 5, this.height / 5, this.doc.orgLogo))
      this.orgLogo.vis(this.doc.displayOrgLogo)
    }

    this.updateLayout()
  }

  updateImageLayout() {
    this.backgroundGraphic!.setImage(this.bgImage || '')

    this.backgroundGraphic!.center()
  }

  updateLayout() {
    if (!this.imgString || this.imgString !== this.bgImage) {
      this.imgString = this.bgImage
      this.updateImageLayout()
    }
    if (this.orgLogo) {
      const margin = this.height / 15
      this.orgLogo.pos(margin, this.height - margin - this.orgLogo.height)
      this.orgLogo.vis(this.doc.displayOrgLogo)
    }

    this.foregroundImage!.setImage(this.overlayImage)

    this.backgroundGraphic!.center()
    this.topText!.pos(0, 73)
    this.topText!.setText(this.doc.topHeader.toUpperCase())
    this.bottomText!.pos(0, 145)
    this.bottomText!.setText(this.doc.bottomHeader)
    this.foregroundImage!.center()
    this.parentFrame?.stage.update()
  }

  addElement<T extends AbstractContainer>(element: T) {
    return element
  }

  addListeners() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.parentFrame.on('DocChange', (e: any) => {
      this.doc = e.detail as IDocStorage
      this.updateLayout()
    })
  }

  get overlayImage() {
    return this.doc.logoType === 'støtte' ? Manifest.images.squareBG : Manifest.images.squareBGNIF
  }

  get bgImage() {
    if (this.doc.image) {
      return this.doc.image.base64
    }
    return ''
  }
}
