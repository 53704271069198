export namespace ReportingUtils {
  export const getTotalAmount = (sportsData: any[], _id: string) => {
    return (
      sportsData?.find((sport) => sport.organisation_sports_category_id === _id)?.totalAmount || 0
    )
  }
  export const getVippsCount = (sportsData: any[], _id: string) => {
    return (
      sportsData?.find((sport) => sport.organisation_sports_category_id === _id)
        ?.numberOfVippsCharges || 0
    )
  }

  export const getVippsPrice = (sportsData: any[], _id: string) => {
    return round(
      (sportsData?.find((sport) => sport.organisation_sports_category_id === _id)
        ?.numberOfVippsCharges || 0) *
        0.38 +
        ReportingUtils.getTotalAmount(sportsData || [], _id) * 0.015
    )
  }

  export const getVippsPriceSport = (sportsData: any[], _id: string) => {
    return round(
      (sportsData?.find((sport) => sport.goal_id === _id)?.numberOfVippsCharges || 0) * 0.38 +
        ReportingUtils.getTotalAmount(sportsData || [], _id) * 0.015
    )
  }

  export const getSportTotalAmount = (sportsData: any[], _id: string) => {
    return sportsData?.find((sport) => sport.goal_id === _id)?.totalAmount || 0
  }
  export const getSportVippsCount = (sportsData: any[], _id: string) => {
    return sportsData?.find((sport) => sport.goal_id === _id)?.numberOfVippsCharges || 0
  }
  export const getVippsTotal = (totalAmount: number, vippsCount: number) => {
    return round(totalAmount * (1 - 0.015) - vippsCount * 0.38)
  }
  export const getStottePrice = (totalAmount: number) => {
    return round(totalAmount * 0.12)
  }
  export const getStottePriceMVA = (totalAmount: number) => {
    return round(totalAmount * 0.12 * 1.25)
  }
  export const getStotteTotal = (totalAmount: number, vippsCount: number) => {
    return round(totalAmount * (1 - 0.015 - 0.12) - vippsCount * 0.38)
  }
  export const getStotteTotalMVA = (totalAmount: number, vippsCount: number) => {
    return round(totalAmount * (1 - 0.015 - 0.15) - vippsCount * 0.38)
  }
  export const round = (value: number) => {
    return Math.round((value + Number.EPSILON) * 100) / 100
  }
}
