import ApexCharts, { ApexOptions } from 'apexcharts'
import React, { useEffect, useRef } from 'react'

const CampaignCompetition: React.FC = () => {
  const { campaign1, campaign2 } = {
    campaign1: {
      name: 'Guttaboys',
      supporters: 130,
      top5Recruiters: [
        { name: 'Ola Nordmann', recruits: 12 },
        { name: 'Kari Nordmann', recruits: 10 },
        { name: 'Per Hansen', recruits: 8 },
        { name: 'Kari Hansen', recruits: 7 },
        { name: 'Ola Hansen', recruits: 6 },
      ],
      supportersOverTime: [
        { week: 1, supporters: 5 },
        { week: 2, supporters: 22 },
        { week: 3, supporters: 12 },
        { week: 4, supporters: 30 },
        { week: 5, supporters: 2 },
      ],
    },
    campaign2: {
      name: 'Jenter 13',
      supporters: 223,
      top5Recruiters: [
        { name: 'Ola Nordmann', recruits: 12 },
        { name: 'Kari Nordmann', recruits: 10 },
        { name: 'Per Hansen', recruits: 8 },
        { name: 'Kari Hansen', recruits: 7 },
        { name: 'Ola Hansen', recruits: 6 },
      ],
      supportersOverTime: [
        { week: 1, supporters: 20 },
        { week: 2, supporters: 32 },
        { week: 3, supporters: 12 },
        { week: 4, supporters: 20 },
        { week: 5, supporters: 11 },
      ],
    },
  }
  const chartRef1 = useRef<HTMLDivElement | null>(null)
  const chartRef2 = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef1.current) {
      return
    }

    const chart = new ApexCharts(chartRef1.current, chartOptions(campaign1.supportersOverTime))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef1])
  useEffect(() => {
    if (!chartRef2.current) {
      return
    }

    const chart = new ApexCharts(chartRef2.current, chartOptions(campaign2.supportersOverTime))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef2])

  return (
    <div className='campaign-competition'>
      <h1>Kampanje oversikt</h1>
      <div className='campaign-columns'>
        {[
          { info: campaign1, ref: chartRef1 },
          { info: campaign2, ref: chartRef2 },
        ].map((campaign) => (
          <div className='campaign-column'>
            <h1>{campaign.info.name}</h1>
            <h2>Antall nye støttespillere: </h2>
            <h1>{campaign.info.supporters}</h1>
            <h2>Top 5 deltagere:</h2>
            <ul>
              {campaign.info.top5Recruiters.map((recruiter) => (
                <li>
                  {recruiter.name} - {recruiter.recruits}
                </li>
              ))}
            </ul>

            <div className='card-body d-flex flex-column align-items-center'>
              <div ref={campaign.ref} style={{ width: '100%' }} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
const chartOptions = (
  chartData: { supporters: number; week: number }[] | undefined
): ApexOptions => {
  const weeks = chartData?.map((d) => d.week) || []
  return {
    series: [
      {
        name: 'Støtte',
        data: chartData?.map((d) => d.supporters) || [],
      },
    ],

    chart: {
      height: '90%',
      type: 'line',

      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      colors: ['#21285c'],
    },
    markers: {
      size: 4,
      colors: ['#21285c'],
    },
    yaxis: {
      min: 0,
      forceNiceScale: true,
      title: {
        text: 'Antall støttespillere',
        style: {
          color: '#000000',
        },
      },
    },

    xaxis: {
      categories: weeks,

      tooltip: {
        enabled: true,
        formatter: (val, opts: any) => {
          return opts?.series[0][+val - 1]
        },
      },
    },
  }
}
export default CampaignCompetition
