import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { addBearer } from '../../_metronic/helpers'
import LoadingContext from '../../_metronic/layout/core/Loading'
import { useMainContext } from '../../context'
import { useAuth } from '../modules/auth/core/Auth'
import {
  setAuth,
  setCurrentUser,
  setRefreshToken,
  setToken,
} from '../modules/auth/core/AuthHelpers'
import { handleGetRequest } from '../services'

export function LoginFromAdmin() {
  const { token } = useParams()
  const { setLoading } = useContext(LoadingContext)

  const { saveAuth } = useAuth()
  const navigate = useNavigate()
  const { setCompanyonPortal } = useMainContext()

  const login = async () => {
    const { data } = await handleGetRequest(
      `/organisation_user/login_from_verified_access_token?token=${token}`
    )(setLoading)

    if (data) {
      setLoading(false)
      setCurrentUser(data)
      setAuth(data)
      setRefreshToken(addBearer(data.refresh_token))
      setToken(addBearer(data.access_token))
      localStorage.setItem('currentUser', JSON.stringify(data))
      // eslint-disable-next-line eqeqeq
      if (data.user.user_type === 'admin') {
        setCompanyonPortal(null, 'NO', data?.user?.organisation_id.org_name)
      }
      localStorage.removeItem('otp_id')
      window.location.href = '/dashboard'
    } else {
      saveAuth(undefined)
      setLoading(false)
      setTimeout(() => {
        navigate('/auth/login')
      }, 3000)
    }
  }

  useEffect(() => {
    login()
  }, [])
  return <div />
}
