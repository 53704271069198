/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { addBearer, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useMainContext } from '../../../../context'
import toastr from '../../../../toaster'
import { useAuth } from '../core/Auth'
import { setAuth, setRefreshToken, setToken } from '../core/AuthHelpers'
import { login, loginWithNif } from '../core/_requests'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}
export function Login() {
  const [loading, setLoading] = useState(false)
  const [fieldPass, setfieldPass] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const { setCompanyonPortal } = useMainContext()
  const navigate = useNavigate()
  const intl = useIntl()
  const usernamePasswordForm = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const auth = await login(values.email, values.password)

        if (auth) {
          localStorage.setItem('otp_id', auth.data.data.id)
          navigate('/auth/verify-otp')
        }
      } catch (error: any) {
        toastr.error(error?.response?.data?.message)
        saveAuth(undefined)
        setStatus('Innloggingsinformasjonen du har gitt er feil. Vennligst prøv igjen.')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const nifLogin = async () => {
    // get code from url params
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    if (code) {
      const orgId = localStorage.getItem('orgId')
      const splashScreen: any = window.document.getElementById('splash-screen')
      splashScreen.style.display = 'inherit'
      try {
        const auth: any = await loginWithNif(orgId!, code)

        if (auth) {
          setLoading(false)
          setCurrentUser(auth.data.data)
          setAuth(auth.data.data)
          setRefreshToken(addBearer(auth.headers.authorization))
          setToken(addBearer(auth.data.data.access_token))
          localStorage.setItem('currentUser', JSON.stringify(auth.data.data))
          // eslint-disable-next-line eqeqeq
          if (auth.data.data.user.user_type === 'admin') {
            setCompanyonPortal(null, 'NO', auth.data.data?.user?.organisation_id.org_name)
          }
          localStorage.removeItem('otp_id')
          window.location.href = '/dashboard'
        }
      } catch (error: any) {
        toastr.error(error?.response?.data?.message)
        saveAuth(undefined)
        setLoading(false)
        saveAuth(undefined)
      }
      splashScreen.style.display = ' none'
    }
  }

  useEffect(() => {
    const nfLogin = async () => {
      await nifLogin()
    }

    // eslint-disable-next-line no-console
    nfLogin().catch(console.error)
  }, [])

  // eslint-disable-next-line func-names
  const setNifLogin = async function () {
    window.location.href = '/auth/nif-login'
  }

  return (
    <form
      // className='form w-55'
      onSubmit={usernamePasswordForm.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='d-flex ' style={{ alignItems: 'center' }}>
        <img
          src={toAbsoluteUrl('/media/logos/S-logo-full-dark.png')}
          alt='logo'
          className='svg-icon-cufs mb-14'
          style={{ maxHeight: '40px' }}
        />
      </div>
      {/* begin::Heading */}
      {usernamePasswordForm.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{usernamePasswordForm.status}</div>
        </div>
      ) : (
        <div />
      )}
      <div className='fv-row'>
        <h1 style={{ color: '#001B5C' }}>{intl.formatMessage({ id: 'Log.in.register.Sports' })}</h1>
        <span style={{ color: '#181819' }}>
          {intl.formatMessage({ id: 'register.or.log.in.with.the.sport' })}
        </span>
        <button
          type='button'
          id='kt_sign_in_nif_submit'
          onClick={async () => {
            await setNifLogin()
          }}
          style={{ borderRadius: '8px', marginTop: '15px' }}
          className='btn btn-lg btn-primary w-100 authbgcolor mb-5'
        >
          <span className='indicator-label'>
            {intl.formatMessage({ id: 'login.with.sport.id' })}
          </span>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/Idrettens-ID-negativ.svg')}
            className='h-20px position-absolute ms-3'
          />
        </button>
      </div>
      <div className='fv-row  mt-15'>
        <h1 style={{ color: '#001B5C' }}>
          {' '}
          {intl.formatMessage({ id: 'Register.Other.organizations' })}
        </h1>
        <span style={{ color: '#181819' }}>
          {intl.formatMessage({ id: 'organization.if.it.does.not.fall.under.the.sports.category' })}
        </span>
        <Link
          type='button'
          to='/auth/registration'
          style={{ borderRadius: '8px', marginTop: '15px' }}
          className='btn btn-lg btn-primary w-100 authbgcolor mb-5'
        >
          <span className='indicator-label'>{intl.formatMessage({ id: 'register.org' })}</span>
        </Link>
      </div>
      <div className='separator my-10 border-2' />

      <div className='fv-row mb-10'>
        <p className='mb-5'>
          {' '}
          {intl.formatMessage({ id: 'log.in.with.your.username.and.password' })}
        </p>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({ id: 'Username' })}
        </label>
        <input
          style={{ borderRadius: '8px' }}
          placeholder='Brukernavn'
          {...usernamePasswordForm.getFieldProps('email')}
          className={clsx('form-control form-control-lg form-control-solid', {
            'is-invalid': usernamePasswordForm.touched.email && usernamePasswordForm.errors.email,
          })}
          type='email'
          name='email'
          autoComplete='off'
        />
      </div>
      {/* end::Form group */}
      {/* begin::Form group */}
      <div className='fv-row'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>
              {intl.formatMessage({ id: 'Password' })}
            </label>
            {/* end::Label */}
          </div>
        </div>
        <input
          style={{ borderRadius: '8px' }}
          placeholder='Passord'
          type={fieldPass ? 'text' : 'password'}
          autoComplete='off'
          {...usernamePasswordForm.getFieldProps('password')}
          className={clsx('form-control form-control-lg form-control-solid', {
            'is-invalid':
              usernamePasswordForm.touched.password && usernamePasswordForm.errors.password,
          })}
        />
        <span
          className='btn btn-sm btn-icon  end-0 me-n2'
          onClick={() => setfieldPass(!fieldPass)}
          style={{ position: 'relative', left: '92%', bottom: '40px' }}
        >
          <i className={`fa fa-eye${fieldPass ? '' : '-slash'}`} />
        </span>
      </div>
      {/* end::Form group */}
      <div className='row'>
        <div className='col-md-6'>
          <label style={{ marginLeft: '5px' }} className='form-label fs-5 mb-5'>
            <input type='checkbox' id='rememberPassword' />{' '}
            {intl.formatMessage({ id: 'Stay.signed.in' })}
          </label>
        </div>
        <div className='col-md-6' style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Link to='/auth/forgot-password' className='link-primary fs-6 fw-bolder' style={{}}>
            {intl.formatMessage({ id: 'Forgot.your.password' })}
          </Link>
        </div>
      </div>
      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5 authbgcolor mb-5'
          style={{ borderRadius: '8px' }}
          disabled={usernamePasswordForm.isSubmitting || !usernamePasswordForm.isValid}
        >
          <span className='indicator-label'>
            {intl.formatMessage({ id: 'Login' })}
            {loading && <span className='spinner-border spinner-border-sm align-middle ms-2' />}
          </span>
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
