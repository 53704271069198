/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { App } from '../App'
import { LoginFromAdmin } from '../loginfromadmin/LoginFromAdmin'
import { AuthPage, useAuth } from '../modules/auth'
import CampaignCompetition from '../modules/auth/components/CampaignCompetition'
import CampaignUserCard from '../modules/auth/components/CampaignUserCard'
import { ChangePassword } from '../modules/auth/components/ChangePassword'
import RedirectPage from '../modules/auth/components/RedirectPage'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import GoalDetailsMain from '../pages/GoalDetails/GoalDetailsMain'
import HeadlessStudioPage from '../pages/Marketing/studio/HeadlessStudioPage'
import SalesTermsMain from '../pages/SalesTerms/SalesTermsMain'
import { MemberInvite } from '../pages/emailhandle/MemberInvite'
import { VerifyEmail } from '../pages/emailhandle/VerifyEmail'
import { PrivateRoutes } from './PrivateRoutes'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const AppRoutes: FC = () => {
  const { auth } = useAuth()

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='verify_email/:id/:token' element={<VerifyEmail />} />
          <Route path='change_password/:id/:token' element={<ChangePassword />} />
          <Route path='vilkaar/:orgName/:orgNo' element={<SalesTermsMain />} />
          <Route path='parker/:orgId/:goalId' element={<GoalDetailsMain />} />
          <Route path='join_system_and_verify/:id/:token' element={<MemberInvite />} />
          <Route path='loged_from_admin/:token' element={<LoginFromAdmin />} />
          <Route path='competition/:campaignID1/:campaignID2' element={<CampaignCompetition />} />
          <Route
            path='campaigncard/:userCode/:orgID/:header/:subheader'
            element={<CampaignUserCard />}
          />
          <Route
            path='campaignflyer/:userCode/:orgID/:header/:subheader'
            element={<HeadlessStudioPage />}
          />
          <Route path='rd/:short_url' element={<RedirectPage />} />
          {/* <Route path='logout' element={<Logout />} /> */}
          {auth?.access_token ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
