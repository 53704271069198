import React, { useContext } from 'react'
import LoadingContext from '../../../../_metronic/layout/core/Loading'
import { handlePatchRequest } from '../../../services'
import './editor.scss'
import { StudioApp } from './StudioApp'
import { StudioUtils } from './StudioUtils'

const StudioPage: React.FC = () => {
  const isCampaign = window.location.href.includes('campaign')
  const { setLoading } = useContext(LoadingContext)

  return (
    <>
      {/* <MarketingHeader /> */}
      <div className='d-flex flex-wrap flex-stack '>
        <h3 className='fw-bolder my-2'>{isCampaign ? 'Lag flyere' : 'Lag profilmateriell'}</h3>
      </div>
      <div className='card '>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='text-muted fw-bold fs-5'>
              {isCampaign
                ? 'Generer flyere for hver deltager. Du kan enkelt lage nedlastbare versjoner eller sende ut web versjoner til hver deltager.'
                : 'Her kan du lage og tilpasse ditt eget profilmateriell. Last opp bilder, legg til tekst og logoer, og tilpass designet etter dine behov. Når du er ferdig, kan du laste ned filene og bruke dem i dine markedsføringskampanjer. Sørg for at personer på bildene har gitt samtykke til bruk. Bilder og logoer må være i png eller jpg-format.'}
            </span>
          </h3>
          <div className='card-toolbar' />
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <StudioApp
          onSave={async (doc) => {
            //  Fetch campaignID from url, its the last part of the url
            const url = window.location.href
            const campaignId = url.substring(url.lastIndexOf('/') + 1)

            await handlePatchRequest(`/campaign/${campaignId}`, {
              flyer: {
                header: StudioUtils.encodeText(doc.topHeader),
                subheader: StudioUtils.encodeText(doc.bottomHeader),
              },
            })(setLoading)
          }}
        />
      </div>
    </>
  )
}

export default StudioPage
