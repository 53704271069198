/* eslint-disable prefer-const */
import clsx from 'clsx'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { Modal } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import * as Yup from 'yup'

interface CampaignCreationModalProps {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  submitCallback: (values: { name: string; email: string }) => void
  initialValues: { name: string; email: string }
}

const CampaignEditRecruiterModal: React.FC<CampaignCreationModalProps> = ({
  showModal,
  setShowModal,
  initialValues,
  submitCallback,
}) => {
  const memberSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(70, 'Too Long!').required(),
    email: Yup.string().min(2, 'Too Short!').max(70, 'Too Long!').email().required(),
  })
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size='lg' keyboard={false}>
      <div className='modal-body'>
        <Formik
          initialValues={initialValues}
          validationSchema={memberSchema}
          onSubmit={async (values) => {
            submitCallback(values)
          }}
        >
          {({ errors, touched, values, submitForm }) => (
            <Form>
              <div>
                <h4 className=' mb-2'>Rediger deltager</h4>
              </div>

              <div className='row mb-3 mt-7'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Navn:</label>
                <div className='col-lg-9 fv-row'>
                  <Field
                    style={{ borderRadius: '8px' }}
                    type='text'
                    name='name'
                    className={clsx('form-control form-control-lg form-control-solid inputClass', {
                      'is-invalid': touched.name && errors.name,
                    })}
                    placeholder='Angi navn på rekutterer'
                  />
                  <ErrorMessage name='name' component='div' />
                </div>
              </div>
              <div className='row mb-3 mt-7'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>E-post:</label>
                <div className='col-lg-9 fv-row'>
                  <Field
                    style={{ borderRadius: '8px' }}
                    type='text'
                    name='email'
                    className={clsx('form-control form-control-lg form-control-solid inputClass', {
                      'is-invalid': touched.email && errors.email,
                    })}
                    placeholder='Angi e-post på deltager'
                  />
                  <ErrorMessage name='name' component='div' />
                </div>
              </div>
              <br />
              <button
                type='button'
                style={{ float: 'right' }}
                disabled={!!errors.name || !!errors.email || !values.name || !values.email}
                onClick={async () => {
                  await submitForm()
                }}
                className='btn btn-primary'
              >
                Lagre
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default React.memo(CampaignEditRecruiterModal)
