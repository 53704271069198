/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { KTSVG } from '../../../_metronic/helpers'

import { MixedWidget13 } from '../../../_metronic/partials/widgets'

import { CPTable1 } from './CPTable1'

const PaymentsMiansp: FC = () => (
  <>
    <div className='d-flex flex-wrap flex-stack mb-6'>
      <h3 className='fw-bolder my-2'>Nøkkeltall</h3>

      <div className='d-flex flex-wrap my-2'>
        {/* <div className='me-4'>
          <button type='button'  className='btn btn-sm btn-light-primary mx-1'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Registrer selskap
          </button>
        </div> */}
        <a
          href='#'
          className='btn btn-light btn-active-light btn-sm bg-white'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          Siste 12 mnd
          <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
        </a>

        {/* begin::Menu */}
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          {/* begin::Menu item */}
          <div className='menu-item px-3' style={{ cursor: 'pointer' }}>
            <a className='text-muted py-4 text-hover-primary'>Siste 30 dager</a>
          </div>
          {/* end::Menu item */}

          {/* begin::Menu item */}
          <div className='menu-item px-3 pt-2 ' style={{ cursor: 'pointer' }}>
            <a className='text-muted py-4 text-hover-primary'>Siste 12 måneder</a>
          </div>
          {/* end::Menu item */}
        </div>
        {/* end::Menu */}
      </div>
    </div>

    <div className='row gy-5 g-xl-10'>
      {/* begin::Col */}
      <div className='col-xl-6 px-2'>
        <MixedWidget13
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#F7D9E3'
          chartHeight='100px'
          title='Støtte mottatt'
          description=''
          numbertext='560 990,-'
          // updownVal='28'
        />
      </div>

      <div className='col-xl-6 px-2'>
        <MixedWidget13
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#CBF0F4'
          chartHeight='100px'
          title='Støtte utbetalt '
          description=''
          numbertext='260 490,-'
          // updownVal='18'
        />
      </div>
    </div>

    <CPTable1 className='mb-5 mb-xl-8' />
  </>
)

export { PaymentsMiansp }
