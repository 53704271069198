import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import parse from 'html-react-parser'
import $ from 'jquery'
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { useAuth } from '../../modules/auth'
import { handleGetRequest } from '../../services'

interface Props {
  show: boolean
  handleClose: () => void
  title: string
  modalData: any
}

const MarketingModal: React.FC<Props> = ({ show, modalData, handleClose }) => {
  const [orgVideoDetails, setorgVideoDetails] = useState<any>({
    video: {
      deleted: false,
      _id: '64fb47d042cacc15c590b7c5',
      id: 2,
      first_name: 'Aalesunds Svømme Og Livredningsklubb',
      landing_page_preview_url: 'https://hei.stotte.no/video-mangler',
      landing_page_url: 'https://stotte.no/spre-budskapet',
      api_data_club: 'Aalesunds Svømme Og Livredningsklubb',
      api_data_org_name: 'Aalesunds Svømme Og Livredningsklubb',
      api_data_organisation_number: 990615233,
    },
    video_details: null,
  })
  const { currentUser } = useAuth()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { loading, setLoading } = useContext(LoadingContext)

  const stringReplace = (str: any, find: any, replace: any) => {
    try {
      const data = str?.replaceAll(find, replace)

      return data?.replaceAll('video_url', replace)
    } catch (error) {
      return str
    }
  }

  const getOrgDetails = async () => {
    const response = await handleGetRequest(
      `/organisation/${currentUser?.user?.organisation_id?._id}`
    )(setLoading)
    if (response?.data) {
      const orgNo = response?.data?.organisation_number
      const { data } = await handleGetRequest(
        `/organisation/get_org_video_by_org_no?orgNo=${orgNo}`
      )(setLoading)
      if (data?.video) {
        setorgVideoDetails(data)
      }
    }
  }

  useEffect(() => {
    getOrgDetails()
  }, [modalData])

  useEffect(() => {
    $('.fade.modal').removeAttr('tabindex')
  }, [show])
  const handleCopy = (htmlString: string) => {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = htmlString

    // Set the contentEditable property to 'true' to preserve formatting
    tempElement.contentEditable = 'true'

    tempElement.style.backgroundColor = 'white'

    // Append the temporary element to the document body
    document.body.appendChild(tempElement)

    // Select the content of the temporary element
    const range = document.createRange()
    range.selectNodeContents(tempElement)
    const selection = window.getSelection()

    if (selection) {
      selection.removeAllRanges()
      selection.addRange(range)

      // Copy the selected content to the clipboard
      document.execCommand('copy')

      // Cleanup the temporary element
      document.body.removeChild(tempElement)
    }
  }

  const intl = useIntl()

  return (
    <Modal show={show} onHide={handleClose} size='lg' keyboard={false} dialogClassName='modalWidth'>
      <Modal.Header closeButton>
        <Modal.Title>{modalData.mainTitle}</Modal.Title>
      </Modal.Header>
      {(modalData.cardType === 'card1' ||
        modalData.cardType === 'card2_1' ||
        modalData.cardType === 'card5_2') && (
        <div>
          <Modal.Body>
            <div className='row'>
              <div className='col-md-3'>
                <label
                  className='col-form-label fw-bold fs-6 text-capitalize'
                  style={{ paddingTop: '0px' }}
                >
                  {intl.formatMessage({ id: 'Description' })}:
                </label>
              </div>
              <div className='col-md-9 fw-bold fs-6'>
                {parse(
                  stringReplace(
                    modalData.subTitle,
                    'video_display_url',
                    orgVideoDetails?.video?.landing_page_preview_url
                  )
                )}
              </div>
            </div>
            <div className='row d-flex align-items-center mt-4 '>
              <div className='col-md-3'>
                <label className='col-form-label fw-bold fs-6' style={{ paddingTop: '0px' }}>
                  {intl.formatMessage({ id: 'email.subject' })}:
                </label>
              </div>
              <div className='col-md-8'>
                <input
                  type='text'
                  style={{ width: '106%', marginLeft: '-4px' }}
                  className='form-control form-control-lg form-control-solid'
                  title=" 'Støtte-appen'"
                  readOnly
                  value={stringReplace(
                    modalData.input1,
                    'video_display_url',
                    orgVideoDetails?.video?.landing_page_preview_url
                  )}
                />
              </div>

              <div className='col-md-1 d-flex justify-content-end'>
                <button
                  title='file'
                  onClick={() => {
                    handleCopy(
                      stringReplace(
                        stringReplace(
                          modalData.input1,
                          'video_display_url',
                          orgVideoDetails?.video?.landing_page_preview_url
                        ),
                        'video_display_url',
                        orgVideoDetails?.video?.landing_page_preview_url
                      )
                    )
                    toast.success(intl.formatMessage({ id: 'Copy.data.link' }))
                  }}
                  className='btn btn-sm btn-primary authbgcolor btn-square p-3'
                >
                  <span style={{ marginTop: '1px' }} className='material-symbols-rounded'>
                    content_copy
                  </span>
                  {/* <FaFileAlt /> */}
                </button>
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-md-3'>
                <label className='col-form-label fw-bold fs-6' style={{ paddingTop: '0px' }}>
                  {' '}
                  {intl.formatMessage({ id: 'Message' })} :
                </label>
              </div>
              <div className='col-md-9'>
                <CKEditor
                  editor={ClassicEditor}
                  data={stringReplace(
                    modalData.input2,
                    'video_display_url',
                    orgVideoDetails?.video?.landing_page_preview_url
                  )}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'blockQuote',
                      'numberedList',
                      'bulletedList',
                      '|',
                      'undo',
                      'redo',
                    ],
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='row  w-100' style={{ marginTop: '-30px' }}>
              <div
                className='offset-md-2 d-flex justify-content-between col-md-10'
                style={{ marginLeft: '17%' }}
              >
                <button
                  type='button'
                  className='btn btn-light me-3'
                  style={{ marginLeft: '75px' }}
                  onClick={handleClose}
                >
                  {intl.formatMessage({ id: 'Cancel' })}
                </button>
                <button
                  type='button'
                  onClick={() => {
                    handleCopy(
                      stringReplace(
                        modalData.input2,
                        'video_display_url',
                        orgVideoDetails?.video?.landing_page_preview_url
                      )
                    )
                    toast.success(intl.formatMessage({ id: 'Copy.data.link' }))
                  }}
                  className='btn btn-lg btn-primary authbgcolor'
                  style={{ marginLeft: '37px' }}
                >
                  {intl.formatMessage({ id: 'Copy.content' })}
                </button>
              </div>
            </div>
          </Modal.Footer>
        </div>
      )}

      {(modalData.cardType === 'card2_2' ||
        modalData.cardType === 'card3_1' ||
        modalData.cardType === 'card3_2' ||
        modalData.cardType === 'card4_1' ||
        modalData.cardType === 'card4_2') && (
        <div>
          <Modal.Body>
            <div className='row'>
              <div className='col-md-3'>
                <label
                  className='col-form-label fw-bold fs-6 text-capitalize'
                  style={{ paddingTop: '0px' }}
                >
                  {intl.formatMessage({ id: 'Description' })}:
                </label>
              </div>
              <div className='col-md-9 fw-bold fs-6'>
                {stringReplace(
                  modalData.subTitle,
                  'video_display_url',
                  orgVideoDetails?.video?.landing_page_preview_url
                )}
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-md-3'>
                <label className='col-form-label fw-bold fs-6' style={{ paddingTop: '0px' }}>
                  {' '}
                  {intl.formatMessage({ id: 'Message' })}:
                </label>
              </div>
              <div className='col-md-9'>
                <CKEditor
                  editor={ClassicEditor}
                  data={stringReplace(
                    modalData.input1,
                    'video_display_url',
                    orgVideoDetails?.video?.landing_page_preview_url
                  )}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'blockQuote',
                      'numberedList',
                      'bulletedList',
                      '|',
                      'undo',
                      'redo',
                    ],
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='row  w-100' style={{ marginTop: '-30px' }}>
              <div
                className='offset-md-2 d-flex justify-content-between col-md-10'
                style={{ marginLeft: '17%' }}
              >
                <button
                  type='button'
                  className='btn btn-light me-3'
                  style={{ marginLeft: '75px' }}
                  onClick={handleClose}
                >
                  {intl.formatMessage({ id: 'Cancel' })}
                </button>
                <button
                  type='button'
                  onClick={() => {
                    handleCopy(
                      stringReplace(
                        modalData.input1,
                        'video_display_url',
                        orgVideoDetails?.video?.landing_page_preview_url
                      )
                    )
                    toast.success(intl.formatMessage({ id: 'Copy.data.link' }))
                  }}
                  className='btn btn-lg btn-primary authbgcolor'
                  style={{ marginLeft: '37px' }}
                >
                  {intl.formatMessage({ id: 'Copy.content' })}
                </button>
              </div>
            </div>
          </Modal.Footer>
        </div>
      )}

      {modalData.cardType === 'card5_1' && (
        <div>
          <Modal.Body>
            <div className='row'>
              <div className='col-md-3'>
                <label
                  className='col-form-label fw-bold fs-6 text-capitalize'
                  style={{ paddingTop: '0px' }}
                >
                  {intl.formatMessage({ id: 'Description' })}:
                </label>
              </div>
              <div className='col-md-9 fw-bold fs-6'>
                {stringReplace(
                  modalData.subTitle,
                  'video_display_url',
                  orgVideoDetails?.video?.landing_page_preview_url
                )}
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-md-3'>
                <label className='col-form-label fw-bold fs-6' style={{ paddingTop: '0px' }}>
                  {' '}
                  {intl.formatMessage({ id: 'Message' })} 1:
                </label>
              </div>
              <div className='col-md-9'>
                <CKEditor
                  editor={ClassicEditor}
                  data={stringReplace(
                    modalData.input1,
                    'video_display_url',
                    orgVideoDetails?.video?.landing_page_preview_url
                  )}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'blockQuote',
                      'numberedList',
                      'bulletedList',
                      '|',
                      'undo',
                      'redo',
                    ],
                  }}
                />
              </div>
            </div>
            <br />

            <div className='row w-100'>
              <div className='col-md-10' style={{ marginLeft: '18%' }}>
                <button
                  type='button'
                  onClick={() => {
                    handleCopy(
                      stringReplace(
                        modalData.input1,
                        'video_display_url',
                        orgVideoDetails?.video?.landing_page_preview_url
                      )
                    )
                    toast.success(intl.formatMessage({ id: 'Copy.data.link' }))
                  }}
                  style={{ float: 'right' }}
                  className='btn btn-lg btn-primary authbgcolor'
                >
                  {intl.formatMessage({ id: 'Copy.content' })}
                </button>
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-md-3'>
                <label className='col-form-label fw-bold fs-6' style={{ paddingTop: '0px' }}>
                  {' '}
                  {intl.formatMessage({ id: 'Message' })} 2:
                </label>
              </div>
              <div className='col-md-9'>
                <CKEditor
                  editor={ClassicEditor}
                  data={stringReplace(
                    modalData.input2,
                    'video_display_url',
                    orgVideoDetails?.video?.landing_page_preview_url
                  )}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'blockQuote',
                      'numberedList',
                      'bulletedList',
                      '|',
                      'undo',
                      'redo',
                    ],
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='row  w-100' style={{ marginTop: '-30px' }}>
              <div
                className='offset-md-2 d-flex justify-content-between col-md-10'
                style={{ marginLeft: '17%' }}
              >
                <button
                  type='button'
                  className='btn btn-light me-3'
                  style={{ marginLeft: '75px' }}
                  onClick={handleClose}
                >
                  {intl.formatMessage({ id: 'Cancel' })}
                </button>
                <button
                  type='button'
                  onClick={() => {
                    handleCopy(
                      stringReplace(
                        modalData.input2,
                        'video_display_url',
                        orgVideoDetails?.video?.landing_page_preview_url
                      )
                    )
                    toast.success(intl.formatMessage({ id: 'Copy.data.link' }))
                  }}
                  className='btn btn-lg btn-primary authbgcolor'
                  style={{ marginLeft: '37px' }}
                >
                  {intl.formatMessage({ id: 'Copy.content' })}
                </button>
              </div>
            </div>
          </Modal.Footer>
        </div>
      )}

      {modalData.cardType === 'card5_3' && (
        <div>
          <Modal.Body>
            <div className='row'>
              <div className='col-md-3'>
                <label
                  className='col-form-label fw-bold fs-6 text-capitalize'
                  style={{ paddingTop: '0px' }}
                >
                  {intl.formatMessage({ id: 'Description' })}:
                </label>
              </div>
              <div className='col-md-9 fw-bold fs-6'>
                {stringReplace(
                  modalData.input1,
                  'video_display_url',
                  orgVideoDetails?.video?.landing_page_preview_url
                )}
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-md-3'>
                <label className='col-form-label fw-bold fs-6' style={{ paddingTop: '0px' }}>
                  {' '}
                  {intl.formatMessage({ id: 'video.link' })}:
                </label>
              </div>
              <div className='col-md-9'>
                <input
                  type='text'
                  style={{ width: '99.9%', marginLeft: '-4px' }}
                  className='form-control form-control-lg form-control-solid'
                  title=" 'Støtte-appen'"
                  readOnly
                  value={stringReplace(
                    modalData.input2,
                    'video_display_url',
                    orgVideoDetails?.video?.landing_page_preview_url
                  )}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='row  w-100' style={{ marginTop: '-30px' }}>
              <div
                className='offset-md-2 d-flex justify-content-between col-md-10'
                style={{ marginLeft: '17%' }}
              >
                <button
                  type='button'
                  className='btn btn-light me-3'
                  style={{ marginLeft: '75px' }}
                  onClick={handleClose}
                >
                  {intl.formatMessage({ id: 'Cancel' })}
                </button>
                <button
                  type='button'
                  onClick={() => {
                    handleCopy(
                      stringReplace(
                        modalData.input2,
                        'video_display_url',
                        orgVideoDetails?.video?.landing_page_preview_url
                      )
                    )
                    toast.success(intl.formatMessage({ id: 'Copy.data.link' }))
                  }}
                  className='btn btn-lg btn-primary authbgcolor'
                  style={{ marginLeft: '37px' }}
                >
                  {intl.formatMessage({ id: 'copy.video.link' })}
                </button>
              </div>
            </div>
          </Modal.Footer>
        </div>
      )}

      {modalData.cardType === 'card10' && (
        <div>
          <Modal.Body>
            <div className='row'>
              <div className='col-md-3'>
                <label
                  className='col-form-label fw-bold fs-6 text-capitalize'
                  style={{ paddingTop: '0px' }}
                >
                  {intl.formatMessage({ id: 'Description' })}:
                </label>
              </div>
              <div className='col-md-9 fw-bold fs-6'>
                {stringReplace(
                  modalData.input1,
                  'video_display_url',
                  orgVideoDetails?.video?.landing_page_preview_url
                )}
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-md-3'>
                <label className='col-form-label fw-bold fs-6' style={{ paddingTop: '0px' }}>
                  {' '}
                  {intl.formatMessage({ id: 'mk.url' })}:
                </label>
              </div>
              <div className='col-md-9'>
                <input
                  type='text'
                  style={{ width: '99.9%', marginLeft: '-4px' }}
                  className='form-control form-control-lg form-control-solid'
                  title=" 'Støtte-appen'"
                  readOnly
                  value={stringReplace(
                    modalData.input2,
                    'video_display_url',
                    orgVideoDetails?.video?.landing_page_preview_url
                  )}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='row  w-100' style={{ marginTop: '-30px' }}>
              <div
                className='offset-md-2 d-flex justify-content-between col-md-10'
                style={{ marginLeft: '17%' }}
              >
                <button
                  type='button'
                  className='btn btn-light me-3'
                  style={{ marginLeft: '75px' }}
                  onClick={handleClose}
                >
                  {intl.formatMessage({ id: 'Cancel' })}
                </button>
                <button
                  type='button'
                  onClick={() => {
                    handleCopy(
                      stringReplace(
                        modalData.input2,
                        'video_display_url',
                        orgVideoDetails?.video?.landing_page_preview_url
                      )
                    )
                    toast.success(intl.formatMessage({ id: 'Copy.data.link' }))
                  }}
                  className='btn btn-lg btn-primary authbgcolor'
                  style={{ marginLeft: '37px' }}
                >
                  {intl.formatMessage({ id: 'copy.mk.url' })}
                </button>
              </div>
            </div>
          </Modal.Footer>
        </div>
      )}
    </Modal>
  )
}

export default MarketingModal
