import clsx from 'clsx'
import { useFormik } from 'formik'
import { useContext } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import LoadingContext from '../../../../_metronic/layout/core/Loading'
import { handlePostRequest } from '../../../services'

// eslint-disable-next-line no-useless-escape
const specialCharactersRegex = /^(?=.*[!@#\$%\^&\*])/
const loginSchema = Yup.object().shape({
  password: Yup.string()
    .required('Passord er påkrevd')
    .min(8, 'Passordet må inneholde minst 8 tegn')
    .matches(/^(?=.*[a-z])/, ' Må inneholde en liten bokstav')
    .matches(/^(?=.*[A-Z])/, '  Må inneholde ett stort tegn')
    .matches(/^(?=.*[0-9])/, ' Må inneholde ett tall')
    .matches(specialCharactersRegex, ' Må inneholde ett spesialkarakter'),
  password_confirmation: Yup.string()
    .required('Vennligs’t bekreft ditt passord')
    .when('password', {
      is: (val: string) => !!(val && val.length > 0),
      then: () => Yup.string().oneOf([Yup.ref('password')], 'Begge passordene må være like.'),
    }),
})

const initialValues = {
  password: '',
  password_confirmation: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function ChangePassword() {
  const { id, token } = useParams()
  const { loading, setLoading } = useContext(LoadingContext)
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      const { success } = await handlePostRequest(`/auth/reset-password/${id}/${token}`, {
        password: values.password,
        password_confirmation: values.password_confirmation,
      })(setLoading)

      if (success) navigate('auth')
    },
  })

  return (
    <div className='container'>
      <div className='row p-5 row d-flex justify-content-center text-center mt-5'>
        <form
          className='form w-500px'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {/* begin::Heading */}
          <div className=' mb-10'>
            <img
              alt='Logo'
              className='h-40px logo mb-13'
              src='/media/logos/logo-demo3-default.svg'
            />

            <h1 className='authtext'>Change Password</h1>
            <p className='text-dark'>Er du admin og mangler tilgang? </p>
            <p className='text-info'>Kontakt support for hjelp </p>
            <div className='text-gray-400'>
              New Here?{' '}
              <Link to='/auth/registration' className='link-primary fw-bolder'>
                Create an Account
              </Link>
            </div>
          </div>
          {/* begin::Heading */}

          {formik.status ? (
            <div className='mb-4 alert alert-danger bg-info border-0'>
              <div className='alert-text font-weight-bold text-white'>{formik.status}</div>
            </div>
          ) : (
            <div />
          )}

          {/* begin::Form group Password */}
          <div className='mb-10 fv-row' data-kt-password-meter='true'>
            <div className='mb-1'>
              {/* <label className='form-label fw-bolder text-dark fs-6'>Password</label> */}
              <div className='position-relative mb-3'>
                <input
                  type='password'
                  placeholder='Password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* begin::Meter */}
              <div
                className='d-flex align-items-center mb-3'
                data-kt-password-meter-control='highlight'
              >
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px' />
              </div>
              {/* end::Meter */}
            </div>
            <div className='text-muted'>
              Use 8 or more characters with a mix of letters, numbers & symbols.
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group Confirm password */}
          <div className='fv-row mb-5'>
            {/* <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label> */}
            <input
              type='password'
              placeholder='Password confirmation'
              autoComplete='off'
              {...formik.getFieldProps('password_confirmation')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid':
                    formik.touched.password_confirmation && formik.errors.password_confirmation,
                },
                {
                  'is-valid':
                    formik.touched.password_confirmation && !formik.errors.password_confirmation,
                }
              )}
            />
            {formik.touched.password_confirmation && formik.errors.password_confirmation && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password_confirmation}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_change_password_submit'
              className='btn btn-lg btn-primary w-100 mb-5 authbgcolor'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Change Password</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </form>
      </div>
    </div>
  )
}
