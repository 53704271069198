import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { IRecruiter } from '../../CampaignTable'

interface CampaignSendFormProps {
  recruiters: IRecruiter[]
  submitForm: (recruiters: IRecruiter[]) => Promise<void>
}

const CampaignSendForm: React.FC<CampaignSendFormProps> = ({ recruiters, submitForm }) => {
  const [selectedRecruiters, setSelectedRecruiters] = useState<IRecruiter[]>(recruiters)

  // Toggle individual recruiter
  const toggleRecruiter = (recruiter: IRecruiter) => {
    setSelectedRecruiters((prevSelected) => {
      const newSelected = prevSelected.some((selected) => selected.email === recruiter.email)
        ? prevSelected.filter((selected) => selected.email !== recruiter.email)
        : [...prevSelected, recruiter]
      return newSelected
    })
  }

  // Toggle all recruiters
  const toggleAll = () => {
    if (selectedRecruiters.length === recruiters.length) {
      setSelectedRecruiters([])
    } else {
      setSelectedRecruiters(recruiters)
    }
  }

  return (
    <div>
      <div>
        <span className='text-muted'>
          Her sender du QR-kode og link til kampanjen til deltagere via e-post. Hvis flere deltagere
          har samme e-postadresse, vil kun en e-post bli sendt.
        </span>
      </div>{' '}
      <div className='mb-10 mt-10'>
        <Form.Check
          type='checkbox'
          label='Velg alle'
          checked={selectedRecruiters.length === recruiters.length}
          onChange={toggleAll}
        />
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        {recruiters.map((recruiter, index) => (
          <Form.Check
            key={index}
            type='checkbox'
            style={{ marginBottom: '10px', marginRight: '10px' }}
            label={`${recruiter.name} (${recruiter.email})`}
            checked={selectedRecruiters.some((selected) => selected.email === recruiter.email)}
            onChange={() => toggleRecruiter(recruiter)}
          />
        ))}
      </div>
      <div className='d-flex justify-content-end mt-3'>
        <Button variant='primary' onClick={() => submitForm(selectedRecruiters)}>
          {selectedRecruiters.length > 0 ? 'Send og gå til kampanje' : 'Gå til kampanje'}
        </Button>
      </div>
    </div>
  )
}

export default CampaignSendForm
