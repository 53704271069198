import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { ActivityDrawerMain } from '../../app/pages/Drawer/ActivityDrawerMain'
import { NotificationDrawerMain } from '../../app/pages/Drawer/NotificationDrawerMain'
import { ProfileDrawerMain } from '../../app/pages/Drawer/ProfileDrawerMain'
import { MenuComponent } from '../assets/ts/components'
import { AsideDefault } from './components/aside/AsideDefault'
import { Content } from './components/Content'
import { ScrollTop } from './components/ScrollTop'
import { Sidebar } from './components/Sidebar'
import { PageDataProvider } from './core'

function MasterLayout() {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root'>
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            {/* <HeaderWrapper /> */}

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <Sidebar />
        </div>
      </div>
      <ActivityDrawerMain />
      <NotificationDrawerMain />
      <ProfileDrawerMain />

      <ScrollTop />
    </PageDataProvider>
  )
}

export { MasterLayout }
