import axios from 'axios'
import { toast } from 'react-toastify'
import { addBearer } from '../../_metronic/helpers'
import { getLanguage_locale, getRefreshToken, setToken } from '../modules/auth'

const checkCurrentLang = (object: any) => {
  try {
    const lang = getLanguage_locale()
    const currentLang = typeof lang === 'string' ? JSON.parse(lang) : lang
    if (currentLang?.lang === 'en') {
      return object?.data?.message_en
    }
    if (currentLang?.lang === 'no') {
      return object?.data?.message
    }
    return object?.data?.message
  } catch (error) {
    return 'Unknown error'
  }
}
export const GET_USER_BY_ACCESSTOKEN_URL = '/organisation_user/refresh-token'

export const handlePostRequest =
  (url: string, data: any, options = {}, ignoreToast = true) =>
  async (setLoading: any) => {
    setLoading(true)
    const fetchUrl = `${import.meta.env.VITE_BACKEND_API_URL}${url}`
    try {
      const response = await axios.post(fetchUrl, data, options)
      if (!ignoreToast)
        toast.success(checkCurrentLang(response) || 'Created successfully', { autoClose: 20000 })
      return response?.data
    } catch (error: any) {
      if (error?.response?.status === 401) return handleRefreshToken()
      if (!ignoreToast)
        toast.error(checkCurrentLang(error.response) || 'Something went wrong', {
          autoClose: 20000,
        })

      return error
    } finally {
      setLoading(false)
    }
  }

export const handlePostRequestWithoutMessage =
  (url: string, data: any, options = {}, ignoreToast = true) =>
  async (setLoading: any) => {
    setLoading(true)
    const fetchUrl = `${import.meta.env.VITE_BACKEND_API_URL}${url}`
    try {
      const response = await axios.post(fetchUrl, data, options)
      return response?.data
    } catch (error: any) {
      if (error?.response?.status === 401) return handleRefreshToken()
      if (!ignoreToast)
        toast.error(checkCurrentLang(error.response) || 'Something went wrong', {
          autoClose: 20000,
        })

      return error
    } finally {
      setLoading(false)
    }
  }

export const handleGetRequest =
  (url: string, options = {}, ignoreToast = true) =>
  async (setLoading: any) => {
    setLoading(true)
    const fetchUrl = `${import.meta.env.VITE_BACKEND_API_URL}${url}`
    try {
      const response = await axios.get(fetchUrl, options)
      return response.data
    } catch (error: any) {
      if (error.response?.status === 401) return handleRefreshToken()
      if (!ignoreToast)
        toast.error(checkCurrentLang(error.response) || 'Something went wrong', {
          autoClose: 20000,
        })
      return error
    } finally {
      setLoading(false)
    }
  }

export const handleGetRequestWithoutToken =
  (url: string, options = {}, ignoreToast = true) =>
  async (setLoading: any) => {
    setLoading(true)
    const fetchUrl = `${import.meta.env.VITE_BACKEND_API_URL}${url}`
    try {
      const response = await axios.get(fetchUrl, options)
      return response.data
    } catch (error: any) {
      if (!ignoreToast)
        toast.error(checkCurrentLang(error.response) || 'Something went wrong', {
          autoClose: 20000,
        })
      return error
    } finally {
      setLoading(false)
    }
  }

export const handleGetRequestWithoutRefresh =
  (url: string, ignoreToast = true) =>
  async (setLoading: any) => {
    setLoading(true)
    const fetchUrl = `${import.meta.env.VITE_BACKEND_API_URL}${url}`
    try {
      const response = await axios.get(fetchUrl, {
        headers: {
          Authorization: `${getRefreshToken()}`,
        },
      })
      return response.data
    } catch (error: any) {
      if (error?.response?.status === 401) return handleRefreshToken()
      if (!ignoreToast)
        toast.error(checkCurrentLang(error.response) || 'Noe gikk galt. Vennligst prøv igjen.', {
          autoClose: 20000,
        })
      return error
    } finally {
      setLoading(false)
    }
  }

export const handleGetRequestWithoutRefreshToken =
  (url: string, token: string, ignoreToast = true) =>
  async (setLoading: any) => {
    setLoading(true)
    const fetchUrl = `${import.meta.env.VITE_BACKEND_API_URL}${url}`
    try {
      const response = await axios.get(fetchUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response.data
    } catch (error: any) {
      if (!ignoreToast)
        toast.error(checkCurrentLang(error.response) || 'Something went wrong', {
          autoClose: 20000,
        })
      return error
    } finally {
      setLoading(false)
    }
  }

export const handlePutRequest =
  (url: string, data: any, options = {}, ignoreToast = true) =>
  async (setLoading: any) => {
    setLoading(true)
    const fetchUrl = `${import.meta.env.VITE_BACKEND_API_URL}${url}`
    try {
      const response = await axios.put(fetchUrl, data, options)
      if (!ignoreToast)
        toast.success(checkCurrentLang(response) || 'Updated successfully', { autoClose: 20000 })
      return response?.data
    } catch (error: any) {
      if (error?.response?.status === 401) return handleRefreshToken()
      if (!ignoreToast)
        toast.error(checkCurrentLang(error.response) || 'Something went wrong', {
          autoClose: 20000,
        })

      return error
    } finally {
      setLoading(false)
    }
  }

export const handleDeleteRequest =
  (url: string, options = {}, ignoreToast = true) =>
  async (setLoading: any) => {
    setLoading(true)
    const fetchUrl = `${import.meta.env.VITE_BACKEND_API_URL}${url}`

    try {
      const response = await axios.delete(fetchUrl, options)
      toast.success(checkCurrentLang(response) || 'Deleted successfully', { autoClose: 20000 })
      return response?.data
    } catch (error: any) {
      if (error?.response?.status === 401) return handleRefreshToken()
      if (!ignoreToast)
        toast.error(checkCurrentLang(error.response) || 'Something went wrong', {
          autoClose: 20000,
        })

      return error
    } finally {
      setLoading(false)
    }
  }

export const handlePatchRequest =
  (url: string, data: any, options = {}, ignoreToast = true) =>
  async (setLoading: any) => {
    setLoading(true)
    const fetchUrl = `${import.meta.env.VITE_BACKEND_API_URL}${url}`
    try {
      const response = await axios.patch(fetchUrl, data, options)
      if (!ignoreToast)
        toast.success(checkCurrentLang(response) || 'Updated successfully', { autoClose: 20000 })

      return response?.data
    } catch (error: any) {
      if (error?.response?.status === 401) return handleRefreshToken()
      if (!ignoreToast)
        toast.error(checkCurrentLang(error.response) || 'Something went wrong', {
          autoClose: 20000,
        })

      return error
    } finally {
      setLoading(false)
    }
  }

export const handlePatchRequestWithoutMessage =
  (url: string, data: any, options = {}, ignoreToast = true) =>
  async (setLoading: any) => {
    setLoading(true)
    const fetchUrl = `${import.meta.env.VITE_BACKEND_API_URL}${url}`
    try {
      const response = await axios.patch(fetchUrl, data, options)
      // toast.success(response?.data.message || 'Updated successfully',{autoClose: 20000})
      return response?.data
    } catch (error: any) {
      if (error?.response?.status === 401) return handleRefreshToken()
      if (!ignoreToast)
        toast.error(checkCurrentLang(error.response) || 'Something went wrong', {
          autoClose: 20000,
        })

      return error
    } finally {
      setLoading(false)
    }
  }

export const handlePatchRequestWihoutLoading =
  (url: string, data: any, options = {}, ignoreToast = true) =>
  async () => {
    const fetchUrl = `${import.meta.env.VITE_BACKEND_API_URL}${url}`
    try {
      const response = await axios.patch(fetchUrl, data, options)
      if (!ignoreToast)
        toast.success(checkCurrentLang(response) || 'Updated successfully', { autoClose: 20000 })
      return response?.data
    } catch (error: any) {
      if (error?.response?.status === 401) return handleRefreshToken()
      if (!ignoreToast)
        toast.error(checkCurrentLang(error.response) || 'Something went wrong', {
          autoClose: 20000,
        })

      return error
    }
  }

export const handleRefreshToken = async () => {
  const refreshToken = getRefreshToken()
  const refreshURL = `${import.meta.env.VITE_BACKEND_API_URL}${GET_USER_BY_ACCESSTOKEN_URL}`
  try {
    const responseRefresh = await fetch(refreshURL, {
      headers: {
        Authorization: refreshToken || '',
      },
    })
    if (!responseRefresh.ok) throw Error('Error')
    const data = await responseRefresh.json()
    setToken(addBearer(data?.data?.access_token))
    return data
  } catch (error: any) {
    localStorage.clear()
    window.location.reload()
  }
}
