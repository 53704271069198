/* eslint-disable prefer-destructuring */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { useIntl } from 'react-intl'
import * as xlsx from 'xlsx'
import CustomPagination from '../../../CustomPagination'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { useMainContext } from '../../../context'
import { GetStatus } from '../../constants/utils'
import { useAuth } from '../../modules/auth'
import { handleGetRequest } from '../../services'
import { ReportGoalTable } from './ReportGoalTable'
import { ReportingUtils } from './ReportingUtils'

type Props = {
  className: string
}

// Custom hook to manage date state
const useDateRange = (initialStartDate: Date, initialEndDate: Date) => {
  const [startDate, setStartDate] = useState(initialStartDate)
  const [endDate, setEndDate] = useState(initialEndDate)

  return { startDate, setStartDate, endDate, setEndDate }
}

// Custom hook to manage sports data state
const useSportsData = () => {
  const [sports, setSports] = useState<any>()
  const [sportsData, setSportsData] = useState<any[]>()

  return { sports, setSports, sportsData, setSportsData }
}
const ReportTable: React.FC<Props> = ({ className }) => {
  const { companyofPortal } = useMainContext()
  const { currentUser } = useAuth()
  const intl = useIntl()
  const { setLoading } = useContext(LoadingContext)

  // Initialize state using custom hooks
  const { startDate, setStartDate, endDate, setEndDate } = useDateRange(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
    new Date()
  )
  const { sports, setSports, sportsData, setSportsData } = useSportsData()

  const [PageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [showAllUnits, setShowAllUnits] = useState(false)
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedSport, setSelectedSport] = useState<string>()

  const getSportsByOrg = async () => {
    const res = await handleGetRequest(
      `/organisation/get_org_sport?organisationId=${currentUser?.user?.organisation_id?._id}`
    )(setLoading)
    setSports(res.data)

    const data = await handleGetRequest(
      `/organisation/reporting/${currentUser?.user?.organisation_id?._id}?start_date=${startDate?.toISOString() || new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)}&end_date=${endDate?.toISOString() || new Date(Date.now()).toISOString()}`
    )(setLoading)
    setSportsData(data.data || [])
  }

  useEffect(() => {
    getSportsByOrg()
  }, [
    PageSize,
    currentPage,
    companyofPortal.company_id,
    currentUser?.user?.organisation_id._id,
    startDate,
    endDate,
  ])
  const downloadReport = async () => {
    setLoading(true)

    const goalData: any[] = []
    const promises: any[] = []
    let longestUnitName = 0
    let longestGoalName = 0

    sports?.forEach((sport) => {
      promises.push(
        new Promise<void>(async (resolve) => {
          const params = {
            organisationId: currentUser?.user?.organisation_id?._id,
            sportId: sport._id,
            limit: 9999,
            page: 1,
          }
          const goals = await handleGetRequest('/goal/by_organisation', {
            params,
          })(setLoading)
          const payments = await handleGetRequest(
            `/organisation/reporting/goal/${currentUser?.user?.organisation_id?._id}?start_date=${startDate?.toISOString() || new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)}&end_date=${endDate?.toISOString() || new Date(Date.now()).toISOString()}&sportId=${sport._id || ''}`
          )(setLoading)
          goals.data?.forEach((goal: any) => {
            const goalPayments = payments?.data?.filter(
              (payment: any) => payment.goal_id === goal._id
            )
            if (goalPayments) goal.payments = goalPayments[0]
          })
          if (goals.data?.length > 0) {
            goals.data.forEach((goal: any) => {
              if (sport.sports_category_name?.length > longestUnitName)
                longestUnitName = sport.sports_category_name.length
              if (goal.title.length > longestGoalName) longestGoalName = goal.title.length
              if (goal.payments)
                goalData.push({
                  date: '',
                  unit: sport.sports_category_name,
                  title: goal.title,
                  totalAmount: goal.payments.totalAmount,
                  vippsTransactions: goal.payments.numberOfVippsCharges,
                  vippsCost:
                    goal.payments.numberOfVippsCharges * 0.38 + goal.payments.totalAmount * 0.015,
                  vippsTotal: ReportingUtils.getVippsTotal(
                    goal.payments.totalAmount,
                    goal.payments.numberOfVippsCharges
                  ),
                  stotteCost: goal.payments.totalAmount * 0.12,
                  stotteCostMVA: goal.payments.totalAmount * 0.15,
                  stotteTotal: ReportingUtils.getStotteTotal(
                    goal.payments.totalAmount,
                    goal.payments.numberOfVippsCharges
                  ),
                  stotteTotalMVA: ReportingUtils.getStotteTotalMVA(
                    goal.payments.totalAmount,
                    goal.payments.numberOfVippsCharges
                  ),
                })
              else
                goalData.push({
                  date: '',
                  unit: sport.sports_category_name,
                  title: goal.title,
                  totalAmount: 0,
                  vippsTransactions: 0,
                  vippsCost: 0,
                  vippsTotal: 0,
                  stotteCost: 0,
                  stotteCostMVA: 0,
                  stotteTotal: 0,
                  stotteTotalMVA: 0,
                })
            })
          }
          resolve()
        })
      )
    })
    await Promise.all(promises)
    const rangeStr = `${moment(startDate).format('DD.MM.YYYY')} - ${moment(endDate).format('DD.MM.YYYY')}`

    const excelHeader = [
      rangeStr,
      intl.formatMessage({ id: 'unit' }),
      intl.formatMessage({ id: 'goal' }),
      intl.formatMessage({ id: 'gross' }),
      intl.formatMessage({ id: 'number.of.vipps.transactions' }),
      intl.formatMessage({ id: 'net.vipps.price' }),
      intl.formatMessage({ id: 'net.vipps' }),
      `${intl.formatMessage({
        id: 'net.stotte.price',
      })}(${intl.formatMessage({ id: 'mva.excluded' })})`,
      `${intl.formatMessage({
        id: 'net.stotte.price',
      })}(${intl.formatMessage({ id: 'mva.included' })})`,
      intl.formatMessage({ id: 'net.stotte' }),
      `${intl.formatMessage({
        id: 'net.stotte',
      })}(${intl.formatMessage({ id: 'mva.included' })})`,
    ]

    const worksheet = xlsx.utils.json_to_sheet(goalData)
    const workbook = xlsx.utils.book_new()
    xlsx.utils.sheet_add_aoa(worksheet, [excelHeader], { origin: 'A1' })

    const wscols: { wch: number }[] = []
    excelHeader.forEach((arr, i) => {
      const columnWidth =
        i === 2
          ? longestGoalName
          : i === 1
            ? Math.max(longestUnitName, rangeStr.length)
            : arr.length
      wscols.push({ wch: columnWidth })
    })
    worksheet['!cols'] = wscols as any
    xlsx.utils.book_append_sheet(workbook, worksheet, 'Støtte')

    const fileName = `Stotte rapport ${moment(startDate).format('DD.MM.YYYY')} - ${moment(endDate).format('DD.MM.YYYY')}.xlsx`
    xlsx.writeFile(workbook, fileName, { compression: true })
  }

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {intl.formatMessage({ id: 'reporting' })}
            </span>
            <span className='text-muted mt-1 fw-bold fs-7'>
              {intl.formatMessage({ id: 'overview.of.all.units.registered.in.the.organisation' })}
            </span>
          </h3>
          <h3 className='card-title flex-row w-30'>
            <div className='p-3'>
              <span className='card-label fw-bold fs-6'>Vis alle enheter</span>
              <input
                className='form-check-input'
                type='checkbox'
                checked={showAllUnits}
                onChange={() => {
                  setShowAllUnits(!showAllUnits)
                }}
              />
            </div>
            <div>
              <span className='card-label fw-bold fs-6'>Vis detaljer</span>
              <input
                className='form-check-input'
                type='checkbox'
                checked={showDetails}
                onChange={() => {
                  setShowDetails(!showDetails)
                }}
              />
            </div>
          </h3>
          <h3 className='card-title flex-row w-30'>
            <span className='card-label fw-bold fs-6'>Tidsintervall</span>
            <ReactDatePicker
              dateFormat='dd.MM.yyyy'
              calendarStartDay={1}
              selected={startDate}
              className={clsx('form-control form-control-lg w-100  inputClass')}
              onChange={(date: Date) => {
                setStartDate(date)
              }}
            />
            <ReactDatePicker
              dateFormat='dd.MM.yyyy'
              calendarStartDay={1}
              selected={endDate}
              className={clsx('form-control form-control-lg w-100  inputClass')}
              onChange={(date: Date) => {
                setEndDate(date)
              }}
            />
          </h3>
          <div className='card-toolbar'>
            <div
              onClick={async () => {
                downloadReport()
              }}
              className='btn btn-lg btn-primary authbgcolor'
            >
              Last ned rapport
            </div>
          </div>
        </div>
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted' style={{ backgroundColor: '#E9F4FF' }}>
                  <th className='ps-4 rounded-start'>{intl.formatMessage({ id: 'unit' })}</th>
                  <th style={{ textAlign: 'center' }}>{intl.formatMessage({ id: 'status' })}</th>
                  <th style={{ textAlign: 'center' }}>{intl.formatMessage({ id: 'gross' })}</th>
                  {showDetails && (
                    <th style={{ textAlign: 'center', maxWidth: 67 }}>
                      {intl.formatMessage({ id: 'number.of.vipps.transactions' })}
                    </th>
                  )}
                  {showDetails && (
                    <th style={{ textAlign: 'center' }}>
                      {intl.formatMessage({ id: 'net.vipps.price' })}
                    </th>
                  )}
                  {showDetails && (
                    <th style={{ textAlign: 'center' }}>
                      <div>{intl.formatMessage({ id: 'net.vipps' })}</div>
                      <div>{intl.formatMessage({ id: 'net.vipps.info' })}</div>
                    </th>
                  )}
                  {showDetails && (
                    <th style={{ textAlign: 'center' }}>
                      <div>{intl.formatMessage({ id: 'net.stotte.price' })}</div>
                      <div>({intl.formatMessage({ id: 'mva.excluded' })})</div>
                    </th>
                  )}
                  {showDetails && (
                    <th style={{ textAlign: 'center' }}>
                      <div>{intl.formatMessage({ id: 'net.stotte.price' })}</div>
                      <div>({intl.formatMessage({ id: 'mva.included' })})</div>
                    </th>
                  )}
                  <th style={{ textAlign: 'center' }}>
                    <div>{intl.formatMessage({ id: 'net.stotte' })}</div>
                    <div>{intl.formatMessage({ id: 'net.stotte.info' })}</div>
                  </th>
                  {showDetails && (
                    <th style={{ textAlign: 'center' }}>
                      <div>{intl.formatMessage({ id: 'net.stotte' })}</div>
                      <div>{intl.formatMessage({ id: 'net.stotte.mva' })}</div>
                    </th>
                  )}
                  <th className=' text-end rounded-end px-4' />
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {sports?.length > 0 ? (
                  sports
                    ?.filter((s) => (showAllUnits ? true : s.status !== 'disabled'))
                    .map((tbData: any) => (
                      <tr key={tbData?._id}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <a
                              className='mx-2 text-dark fw-bolder text-hover-primary d-block fs-6 left-text'
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setSelectedSport(tbData?._id)
                                setShowModal(true)
                              }}
                            >
                              {tbData?.sports_category_name}
                            </a>
                          </div>
                        </td>
                        <td className=''>{GetStatus(intl, tbData?.status)}</td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold d-block mb-1 fs-6'
                            style={{ cursor: 'auto', textAlign: 'center' }}
                          >
                            {ReportingUtils.getTotalAmount(sportsData || [], tbData?._id)}
                          </a>
                        </td>
                        {showDetails && (
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bold d-block mb-1 fs-6'
                              style={{ cursor: 'auto', textAlign: 'center' }}
                            >
                              {ReportingUtils.getVippsCount(sportsData || [], tbData?._id)}
                            </a>
                          </td>
                        )}
                        {showDetails && (
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bold d-block mb-1 fs-6'
                              style={{ cursor: 'auto', textAlign: 'center' }}
                            >
                              {ReportingUtils.getVippsPrice(sportsData || [], tbData?._id)}
                            </a>
                          </td>
                        )}
                        {showDetails && (
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bold d-block mb-1 fs-6'
                              style={{ cursor: 'auto', textAlign: 'center' }}
                            >
                              {ReportingUtils.getVippsTotal(
                                ReportingUtils.getTotalAmount(sportsData || [], tbData?._id),
                                ReportingUtils.getVippsCount(sportsData || [], tbData?._id)
                              )}
                            </a>
                          </td>
                        )}
                        {showDetails && (
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bold d-block mb-1 fs-6'
                              style={{ cursor: 'auto', textAlign: 'center' }}
                            >
                              {ReportingUtils.getStottePrice(
                                ReportingUtils.getTotalAmount(sportsData || [], tbData?._id)
                              )}
                            </a>
                          </td>
                        )}
                        {showDetails && (
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bold d-block mb-1 fs-6'
                              style={{ cursor: 'auto', textAlign: 'center' }}
                            >
                              {ReportingUtils.getStottePriceMVA(
                                ReportingUtils.getTotalAmount(sportsData || [], tbData?._id)
                              )}
                            </a>
                          </td>
                        )}
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold d-block mb-1 fs-6'
                            style={{ cursor: 'auto', textAlign: 'center' }}
                          >
                            {ReportingUtils.getStotteTotal(
                              ReportingUtils.getTotalAmount(sportsData || [], tbData?._id),
                              ReportingUtils.getVippsCount(sportsData || [], tbData?._id)
                            )}
                          </a>
                        </td>
                        {showDetails && (
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bold d-block mb-1 fs-6'
                              style={{ cursor: 'auto', textAlign: 'center' }}
                            >
                              {ReportingUtils.getStotteTotalMVA(
                                ReportingUtils.getTotalAmount(sportsData || [], tbData?._id),
                                ReportingUtils.getVippsCount(sportsData || [], tbData?._id)
                              )}
                            </a>
                          </td>
                        )}
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {}
                      </div>
                    </td>
                  </tr>
                )}
                {sports?.length && (
                  <tr>
                    <td className='pt-10'>
                      <div className='d-flex align-items-center'>
                        <a className='mx-2 text-dark fw-bolder text-hover-primary d-block fs-4 left-text'>
                          {intl.formatMessage({ id: 'total' })}
                        </a>
                      </div>
                    </td>
                    <td />
                    <td className='pt-10'>
                      <a
                        href='#'
                        className='text-dark fw-bold d-block mb-1 fs-4'
                        style={{ cursor: 'auto', textAlign: 'center' }}
                      >
                        {ReportingUtils.round(
                          sports?.reduce(
                            (acc: number, curr: { _id: string }) =>
                              acc + ReportingUtils.getTotalAmount(sportsData || [], curr._id),
                            0
                          )
                        )}
                      </a>
                    </td>
                    {showDetails && (
                      <td className='pt-10'>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-4'
                          style={{ cursor: 'auto', textAlign: 'center' }}
                        >
                          {ReportingUtils.round(
                            sports?.reduce(
                              (acc: number, curr: { _id: string }) =>
                                acc + ReportingUtils.getVippsCount(sportsData || [], curr?._id),
                              0
                            )
                          )}
                        </a>
                      </td>
                    )}
                    {showDetails && (
                      <td className='pt-10'>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-4'
                          style={{ cursor: 'auto', textAlign: 'center' }}
                        >
                          {ReportingUtils.round(
                            sports?.reduce(
                              (acc: number, curr: { _id: string }) =>
                                acc + ReportingUtils.getVippsPrice(sportsData || [], curr?._id),
                              0
                            )
                          )}
                        </a>
                      </td>
                    )}
                    {showDetails && (
                      <td className='pt-10'>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-4'
                          style={{ cursor: 'auto', textAlign: 'center' }}
                        >
                          {ReportingUtils.round(
                            sports?.reduce(
                              (acc: number, curr: { _id: string }) =>
                                acc +
                                ReportingUtils.getVippsTotal(
                                  ReportingUtils.getTotalAmount(sportsData || [], curr?._id),
                                  ReportingUtils.getVippsCount(sportsData || [], curr?._id)
                                ),
                              0
                            )
                          )}
                        </a>
                      </td>
                    )}
                    {showDetails && (
                      <td className='pt-10'>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-4'
                          style={{ cursor: 'auto', textAlign: 'center' }}
                        >
                          {ReportingUtils.round(
                            sports?.reduce(
                              (acc: number, curr: { _id: string }) =>
                                acc +
                                ReportingUtils.getStottePrice(
                                  ReportingUtils.getTotalAmount(sportsData || [], curr?._id)
                                ),
                              0
                            )
                          )}
                        </a>
                      </td>
                    )}
                    {showDetails && (
                      <td className='pt-10'>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-4'
                          style={{ cursor: 'auto', textAlign: 'center' }}
                        >
                          {ReportingUtils.round(
                            sports?.reduce(
                              (acc: number, curr: { _id: string }) =>
                                acc +
                                ReportingUtils.getStottePriceMVA(
                                  ReportingUtils.getTotalAmount(sportsData || [], curr?._id)
                                ),
                              0
                            )
                          )}
                        </a>
                      </td>
                    )}
                    <td className='pt-10'>
                      <a
                        href='#'
                        className='text-dark fw-bold d-block mb-1 fs-4'
                        style={{ cursor: 'auto', textAlign: 'center' }}
                      >
                        {ReportingUtils.round(
                          sports?.reduce(
                            (acc: number, curr: { _id: string }) =>
                              acc +
                              ReportingUtils.getStotteTotal(
                                ReportingUtils.getTotalAmount(sportsData || [], curr?._id),
                                ReportingUtils.getVippsCount(sportsData || [], curr?._id)
                              ),
                            0
                          )
                        )}
                      </a>
                    </td>
                    {showDetails && (
                      <td className='pt-10'>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-4'
                          style={{ cursor: 'auto', textAlign: 'center' }}
                        >
                          {ReportingUtils.round(
                            sports?.reduce(
                              (acc: number, curr: { _id: string }) =>
                                acc +
                                ReportingUtils.getStotteTotalMVA(
                                  ReportingUtils.getTotalAmount(sportsData || [], curr?._id),
                                  ReportingUtils.getVippsCount(sportsData || [], curr?._id)
                                ),
                              0
                            )
                          )}
                        </a>
                      </td>
                    )}
                  </tr>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>

          <div className='row'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start' />
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <CustomPagination
                className='pagination-bar justify-content-md-end'
                currentPage={currentPage}
                totalCount={sports ? sports?.pagination?.totalRecords : 0}
                pageSize={PageSize}
                onPageChange={(page: any) => setCurrentPage(page)}
              />
            </div>
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
        }}
        size='xl'
        keyboard={false}
        contentClassName='wide'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className='modal-title'> {intl.formatMessage({ id: 'goal' })}</h5>
          </Modal.Title>
        </Modal.Header>
        <div className='modal-body' style={{ padding: 0 }}>
          <ReportGoalTable
            className=''
            selectedSport={selectedSport}
            selectedSportName={
              selectedSport && sports?.find((s) => s._id === selectedSport)?.sports_category_name
            }
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </Modal>
    </>
  )
}

export { ReportTable }
