import { FC } from 'react'
import CampaignInfo from './CampaignInfo'
import { CampaignTable } from './CampaignTable'

const CampaignMain: FC = () => {
  return (
    <>
      <CampaignInfo />
      <CampaignTable className='mb-5 mb-xl-8' />
    </>
  )
}

export { CampaignMain }
