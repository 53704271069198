import React from 'react'
import { useParams } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useGetSalesOrg } from '../../hooks/sales/useGetOrg'

const SalesTermsMain: React.FC = () => {
  const { orgNo } = useParams<{ orgName: string; orgNo: string }>()
  const { data: fetchData } = useGetSalesOrg(orgNo)

  return (
    <>
      <div className='w-100 sales-header'>
        <div className='sales-container container-sm'>
          <img src={toAbsoluteUrl('/media/logos/logo-square-white.svg')} alt='logo' />
        </div>
      </div>

      <main id='content' className='sales-content mx-auto mt-6 container-sm ' role='main'>
        <header className='page-header'>
          <h1 className='entry-title'>Salgsbetingelser</h1>{' '}
        </header>
        <div className='page-content'>
          <h2 className='h2 mt-6' id='takk-for-at-du-titter-innom'>
            <strong>Takk for at du titter innom!</strong>
          </h2>

          <p>
            Vi setter stor pris på besøk av deg, og at du viser interesse for våre tjenester og
            produkter. Vi ønsker at du skal føle deg trygg når du besøker våre sider, og forsikrer
            deg om at personlig informasjon behandles på en sikker måte av oss som
            behandlingsansvarlig.
          </p>

          <p>Innhold&nbsp;&nbsp;</p>

          <p>
            01. Avtalen
            <br />
            02. Partene
            <br />
            03. Priser
            <br />
            04. Avtaleinngåelsen
            <br />
            05. Ordrebekreftelse
            <br />
            06. Betaling
            <br />
            07. Levering m.v.
            <br />
            08. Risikoen for varen
            <br />
            09. Angrerett
            <br />
            10. Undersøkelsesplikt
            <br />
            11. Reklamasjon ved mangel og frist for å melde krav ved forsinkelse
            <br />
            12. Kjøperens rettigheter ved forsinkelse
            <br />
            13. Kjøperens rettigheter ved mangel
            <br />
            14. Selgerens rettigheter ved kjøperens mislighold
            <br />
            15. Faste betalinger/donasjoner
            <br />
            16. Garanti
            <br />
            17. Konfliktløsning
          </p>

          <p />

          <h2 className='h2 mt-6' id='innledning'>
            Innledning
          </h2>

          <p>
            Dette kjøpet er regulert av den nedenstående standard salgsbetingelser for forbrukerkjøp
            av varer over Internett. Med forbrukerkjøp menes her salg av vare til forbruker som ikke
            hovedsakelig handler som ledd i næringsvirksomhet, og når selgeren opptrer i
            næringsvirksomhet med salg av varer over internett. Kontrakten er utarbeidet og anbefalt
            brukt av Forbrukerombudet. Forbrukerkjøp over Internett reguleres hovedsakelig av
            avtaleloven, forbrukerkjøpsloven, markedsføringsloven, angrerettsloven og ehandelsloven,
            og disse lovene gir forbruker ufravikelige rettigheter. Vilkårene i kontrakten skal ikke
            forstås som noen begrensning i de lovbestemte rettighetene, men oppstiller partenes
            viktigste rettigheter og plikter for handelen.
          </p>

          <p />

          <h2 className='h2 mt-6' id='01-avtalen'>
            01. Avtalen
          </h2>

          <p>
            Avtalen mellom kjøper og selger består av opplysningene selgeren gir om kjøpet i
            bestillingsløsningen i nettbutikken (herunder blant annet opplysninger om varens art,
            mengde, kvalitet, andre egenskaper, pris og leveringsbetingelser), eventuell direkte
            korrespondanse mellom partene (for eksempel e-post) samt disse salgsbetingelsene.Ved
            motstrid mellom opplysningene selgeren har gitt om kjøpet i bestillingsløsningen i
            nettbutikken, direkte korrespondanse mellom partene og vilkårene i salgsbetingelsene,
            går direkte korrespondanse mellom partene og opplysningene gitt i bestillingsløsningen
            foran salgsbetingelsene, så fremt det ikke strider mot bindende lovgivning.
          </p>

          <h2 className='h2 mt-6' id='02-partene'>
            02. Partene
          </h2>

          <p>
            {fetchData?.data?.org_name}
            <br />
            Organisasjonsnavn: {fetchData?.data?.org_name}
            <br />
            Kontaktadresse: {fetchData?.data?.address}
          </p>

          <p>
            e-post:
            {fetchData?.data?.email}
          </p>

          <p>
            Organisasjonsnummer:
            {fetchData?.data?.org_no}
          </p>

          <p>Kjøper er den person som foretar bestillingen.</p>

          <h2 className='h2 mt-6' id='03-priser'>
            03. Priser
          </h2>

          <p>
            Prisene, som er oppgitt i nettbutikken, inkluderer merverdiavgift. Opplysninger om de
            totale kostnadene kjøperen skal betale, inklusive alle avgifter (merverdiavgift, toll,
            og lignende) og leveringskostnader (frakt/porto) samt spesifisering av de enkelte
            elementene i totalprisen, gis i bestillingsløsningen før bestilling er foretatt.
          </p>

          <h2 className='h2 mt-6' id='04-avtaleinngaelse'>
            04. Avtaleinngåelse
          </h2>

          <p>
            Avtalen er bindende for begge parter når kjøperens bestilling er mottatt av selgeren. En
            part er likevel ikke bundet av avtalen hvis det har forekommet skrive- eller tastefeil i
            tilbudet fra selgeren i bestillingsløsningen i nettbutikken eller i kjøperens
            bestilling, og den annen part innså eller burde ha innsett at det forelå en slik feil.
          </p>

          <h2 className='h2 mt-6' id='05-ordrebekreftelse'>
            05. Ordrebekreftelse
          </h2>

          <p>
            Når selgeren har mottatt kjøperens bestilling, skal selgeren uten ugrunnet opphold
            bekrefte ordren ved å sende en ordrebekreftelse til kjøperen. Det anbefales at kjøperen
            kontrollerer at ordrebekreftelsen stemmer overens med bestillingen med hensyn til
            antall, varetype, pris osv. Er det ikke samsvar mellom bestillingen og
            ordrebekreftelsen, bør kjøperen ta kontakt med selger så snart som mulig.
          </p>

          <h2 className='h2 mt-6' id='06-betaling'>
            06. Betaling
          </h2>

          <p>
            Selgeren kan kreve betaling for varen fra det tidspunkt den blir sendt fra selgeren til
            kjøperen. Dersom kjøperen bruker kredittkort eller debetkort ved betaling, kan selgeren
            reservere kjøpesummen på kortet ved bestillingen i inntil 4 dager fra bestillingen.
          </p>

          <p>01. Se lov av 19. juni 1969 nr. 66 om merverdiavgift § 16.</p>

          <p>
            02. Et kredittkort er et betalingskort hvor oppgjøret for kjøpet skjer i etterkant ved
            at kredittgiveren (kredittkortselskapet) sender kortholder faktura med krav om betaling.
          </p>

          <p>
            03. Et debetkort er et betalingskort knyttet til en innskuddskonto. Bruk av kortet
            medfører at brukerens konto blir belastet og beløpet blir overført til
            betalingsmottakerens konto.
          </p>

          <p>04. Ved betaling med kredittkort, vil lov om kredittkjøp m.m. komme til anvendelse.</p>

          <p>
            05. Tilbyr selgeren etterfakturering, skal fakturaen til kjøperen utstedes ved
            forsendelse av varen. Forfallsfristen skal settes til minimum 14 dager fra kjøperen
            mottar forsendelsen.Har selgeren særskilt behov for å kreve forskuddsbetaling fra
            kjøperen, for eksempel ved tilvirkningskjøp, kan selgeren kreve dette. Kjøpere under 18
            år kan kun betale direkte ved selgerens levering av varen eller ved utlevering av varen
            ved postoppkrav.
          </p>

          <h2 className='h2 mt-6' id='07-levering-m-v'>
            07. Levering M.V
          </h2>

          <p>
            Levering av varen fra selgeren til kjøperen skjer på den måte, på det sted og til det
            tidspunkt som er angitt i bestillingsløsningen i nettbutikken. Normert leveringstid er
            3-8 virkedager avhengig av hvor i landet man bor. Selgeren&nbsp;&nbsp;plikter å få varen
            fraktet til bestemmelsesstedet på egnet måte og på vanlige vilkår for slik transport.
            Bestemmelsesstedet er hos kjøperen med mindre annet er særskilt avtalt mellom partene.
          </p>

          <h2 className='h2 mt-6' id='where-we-send-your-data'>
            08 . Risikoen for varen
          </h2>

          <p>
            Levering av varen fra selgeren til kjøperen skjer på den måte, på det sted og til det
            tidspunkt som er angitt i bestillingsløsningen i nettbutikken. Normert leveringstid er
            3-8 virkedager avhengig av hvor i landet man bor. Selgeren&nbsp;&nbsp;plikter å få varen
            fraktet til bestemmelsesstedet på egnet måte og på vanlige vilkår for slik transport.
            Bestemmelsesstedet er hos kjøperen med mindre annet er særskilt avtalt mellom partene.
          </p>

          <h2 className='h2 mt-6' id='09-angrerett'>
            09. Angrerett
          </h2>

          <p>
            Kjøperen kan angre kjøpet av varen etter angrerettslovens bestemmelser. Angrerett
            innebærer at kjøperen uten grunn kan returnere varen til selgeren selv om det ikke er
            noen mangel ved den og selv om den ikke er levert.
          </p>

          <p>
            Kjøperen må gi selger melding om bruk av angreretten innen 14 dager etter at varen, de
            foreskrevne opplysninger om angreretten og angrerettsskjema er mottatt. Mottar kjøperen
            angreskjema og de nødvendige opplysningene på et senere tidspunkt enn ved levering av
            varen, begynner angrefristen å løpe fra den dagen kjøperen mottar angrerettsskjema og
            opplysningene. Har kjøperen ikke mottatt tilstrekkelig informasjon eller
            angrerettsskjema, vil angrefristen likevel gå ut 3 måneder etter at varen er mottatt.
            Dersom kjøperen ikke har fått informasjon om angrerett i det hele tatt, vil fristen være
            1 år.
          </p>

          <p>
            Meldingen fra kjøper til selger er skriftlig gjennom angrerettsskjema tilsendt på e-post
            eller i pakken med varer.
          </p>

          <p>
            Ved bruk av angreretten må varen leveres tilbake til selgeren innen rimelig tid.
            Selgeren er forpliktet til å tilbakebetale hele kjøpesummen til kjøperen innen 14 dager
            fra den dag selgeren mottar varen eller henteseddel eller varen er stilt til selgerens
            rådighet. Selgeren kan ikke fastsette gebyrer for kjøperens bruk av angreretten, men
            selgeren kan kreve at kjøperen skal betale kostnadene for returforsendelsen.
          </p>

          <p>
            Kjøperen kan undersøke produktet før han eller hun angrer på kjøpet. Varen må likevel
            kunne leveres tilbake til selgeren i tilnærmet samme stand og mengde som den var i da
            kjøperen mottok den. Kjøperen bør sende varen tilbake til selgeren i originalemballasjen
            hvis dette er mulig.
          </p>

          <p>
            Klikk her for å laste ned angrerettskjema:{' '}
            <a href='https://signform.no/dss/component/signform/?task=form.downloadFile&amp;id=6174'>
              Angrerettskjema
            </a>{' '}
            <br />
            {fetchData?.data?.org_name} tillater ikke returer av skreddersydde varer eller varer som
            er tilpasset/kappet i henhold til kundens behov/ønsker/instrukser.
          </p>

          <h2 className='h2 mt-6' id='10-undersokelse-av-varen'>
            10. Undersøkelse av varen
          </h2>

          <p>
            Når kjøperen mottar varen, anbefales det at han eller hun i rimelig utstrekning
            undersøker om den er i samsvar med bestillingen, om den har blitt skadet under
            transporten eller om den ellers har mangler.Hvis varen ikke samsvarer med bestillingen
            eller har mangler, må kjøperen melde fra til selgeren ved reklamasjon jf. kontraktens
            punkt 11.
          </p>

          <h2
            className='h2'
            id='11-reklamasjon-ved-mangel-og-frist-for-a-melde-krav-ved-forsinkelse'
          >
            11. Reklamasjon ved mangel og frist for å melde krav ved forsinkelse
          </h2>

          <p>
            Dersom det foreligger en mangel ved varen, må kjøperen innen rimelig tid etter at han
            eller hun oppdaget den, gi selgeren melding om at han eller hun vil påberope seg
            mangelen. Fristen kan aldri være kortere enn to måneder fra det tidspunkt da forbrukeren
            oppdaget mangelen. Reklamasjon må likevel skje senest to år etter at kjøperen overtok
            varen.
          </p>

          <p>
            Ved forsinkelse må krav rettes selger innen rimelig tid etter at leveringstiden er
            kommet og varen ikke er levert. Dersom varen er betalt med kredittkort, kan kjøperen
            også velge å reklamere og sende krav direkte til kredittyter
            (kredittkortselskapet).Meldingen til selgeren eller kredittyter bør være skriftlig
            (e-post, telefaks eller brev).
          </p>

          <h2 className='h2 mt-6' id='where-we-send-your-data'>
            12. Kjøperens rettigheter ved forsinkelse
          </h2>

          <p>
            Dersom selgeren ikke leverer varen eller leverer den for sent i henhold til avtalen
            mellom partene, og dette ikke skyldes kjøperen eller forhold på kjøperens side, kan
            kjøperen i henhold til reglene i forbrukerkjøpslovens kapittel 5 etter omstendighetene
            holde kjøpesummen tilbake, kreve oppfyllelse, heve avtalen og kreve erstatning fra
            selgeren.
          </p>

          <p>
            Oppfyllelse: Dersom selgeren ikke leverer varen på leveringstidspunktet, kan kjøperen
            fastholde kjøpet og sette en rimelig tileggsfrist for oppfyllelse fra selgeren. Kjøperen
            kan likevel ikke kreve oppfyllelse dersom det foreligger en hindring som selgeren ikke
            kan overvinne eller dersom oppfyllelse vil medføre en så stor ulempe eller kostnad for
            selgeren at det står i vesentlig misforhold til kjøperens interesse i at selgeren
            oppfyller. Faller vanskene bort innen rimelig tid, kan forbrukeren kreve oppfyllelse.
          </p>

          <p>
            Heving: Kjøperen kan heve avtalen med selgeren dersom forsinkelsen er vesentlig eller
            hvis selgeren ikke leverer varen innen den tilleggsfristen for oppfyllelse som kjøperen
            har fastsatt. Kjøperen kan likevel ikke heve avtalen mens tilleggsfristen løper, med
            mindre selgeren har sagt at han eller hun ikke vil oppfylle innen fristen.Erstatning:
            Kjøperen kan videre kreve erstatning for tap han eller hun lider som følge av
            forsinkelsen fra selgerens side jf. forbrukerkjøpslovens § 24.
            <br />
            Kjøperen må melde krav til selgeren ved reklamasjon jf. denne kontraktens punkt 11.
          </p>

          <h2 className='h2 mt-6' id='where-we-send-your-data'>
            13. Kjøperens rettigheter ved mangel
          </h2>

          <p>
            Dersom varen har en mangel og dette ikke skyldes kjøperen eller forhold på kjøperens
            side, kan kjøperen i henhold til reglene i forbrukerkjøpsloven kapittel 6 etter
            omstendighetene holde kjøpesummen tilbake, velge mellom retting og omlevering, kreve
            prisavslag, kreve avtalen hevet og erstatning fra selgeren.
          </p>

          <p>
            Retting eller omlevering: Dersom varen har en mangel, kan kjøperen kreve at selgeren
            retter mangelen eller omleverer tilsvarende vare. Selgeren kan motsette seg kjøperens
            krav dersom gjennomføringen av kravet er umulig eller volder selgeren urimelige
            kostnader.
          </p>

          <p>
            Selgeren skal foreta rettingen eller omleveringen innen rimelig tid. Retting eller
            omlevering skal foretas uten kostnad for kjøperen, uten risiko for at kjøperen ikke får
            dekket sine utlegg og uten vesentlig ulempe for kjøperen. Selgeren kan ikke foreta mer
            enn to forsøk på retting eller omlevering for samme mangel, med mindre det foreligger
            særlige grunner som gjør at ytterligere forsøk er rimelig.
          </p>

          <p>
            Selv om kjøperen verken krever retting eller omlevering, kan selgeren tilby retting
            eller omlevering dersom dette skjer uten opphold. Dersom selgeren sørger for slik
            retting eller omlevering, kan kjøperen ikke kreve prisavslag eller heving. Prisavslag:
            Dersom mangelen ikke rettes eller omleveres, kan kjøperen kreve forholdsmessig
            prisavslag.
          </p>

          <p>
            Heving: I stedet for prisavslag kan kjøperen heve avtalen, unntatt når mangelen er
            uvesentlig.
            <br />
            Erstatning: Kjøperen kan også kreve erstatning for økonomisk tap han eller hun lider som
            følge av at varen har en mangel jf. forbrukerkjøpslovens § 33.Kjøperen må melde krav til
            selgeren ved reklamasjon jf denne kontraktens punkt 11.
            <br />
            Reglene om reklamasjon gjelder i tillegg til, og uavhengig av, reglene om angrerett og
            eventuelle garantier stilt av selger.
          </p>

          <h2 className='h2 mt-6' id='where-we-send-your-data'>
            14. Selgerens rettigheter ved kjøperens mislighold
          </h2>

          <p>
            Dersom kjøperen ikke betaler eller oppfyller de øvrige pliktene etter avtalen, og dette
            ikke skyldes selgeren eller forhold på selgerens side, kan selgeren i henhold til
            reglene i forbrukerkjøpsloven kapittel 9 etter omstendighetene holde varen tilbake,
            kreve oppfyllelse av avtalen, kreve avtalen hevet samt erstatning fra kjøperen. Selgeren
            kan også etter omstendighetene kunne kreve renter ved forsinket betaling, inkassogebyr
            og gebyr ved ikke forskuddsbetalte uavhentede varer.
          </p>

          <p>
            Oppfyllelse: Dersom kjøperen ikke betaler, kan selgeren fastholde kjøpet og kreve at
            kjøperen betaler kjøpesummen (oppfyllelse). Er varen ikke levert, taper selgeren sin
            rett dersom han venter urimelig lenge med å fremme kravet.
          </p>

          <p>
            Heving: Ved vesentlig betalingsmislighold eller annet vesentlig mislighold fra kjøper,
            kan selgeren heve avtalen. Selgeren kan likevel ikke heve etter at kjøpesummen er
            betalt.
          </p>

          <p>
            Selgeren kan også heve kjøpet dersom kjøperen ikke betaler innen en rimelig
            tilleggsfrist for oppfyllelse som selgeren har fastsatt. Selgeren kan likevel ikke heve
            mens tilleggsfristen løper, med mindre kjøperen har sagt at han eller hun ikke vil
            betale.
          </p>

          <p>
            Erstatning: Selgeren kan kreve erstatning fra kjøperen for økonomisk tap han eller hun
            lider som følge av kontraktsbrudd fra kjøperens side jf. forbrukerkjøpslovens §46.
          </p>

          <p>
            Renter ved forsinket betaling/inkassogebyr: Dersom kjøperen ikke betaler kjøpesummen i
            henhold til avtalen, kan selgeren kreve renter av kjøpesummen etter lov om renter ved
            forsinket betaling. Ved manglende betaling kan kravet, etter forutgående varsel, bli
            sendt til inkasso, og kjøperen kan da bli holdt ansvarlig for gebyrer etter lov om
            inkassovirksomhet og annen inndrivning av forfalte pengekrav.
          </p>

          <p>
            Gebyr ved uavhentede ikke-forskuddsbetalte varer: Dersom kjøperen unnlater å hente
            ubetalte varer, kan selgeren belaste kjøper med et gebyr på kr 100 + frakt tur/retur.
            Gebyret skal maksimalt dekke selgerens faktiske utlegg for å levere varen til kjøperen.
            Et slikt gebyr kan ikke belastes kjøpere under 18 år.NB: Uavhentede pakker
            (forhåndsbetalte) som kommer tilbake til oss vil bli belastet med et gebyr på 250
            kroner.
          </p>

          <p />

          <h2 className='h2 mt-6' id='where-we-send-your-data'>
            15. Faste betalinger/donasjoner
          </h2>

          <p>
            Det er ingen bindingstid på faste donasjoner og dersom du ønsker å avslutte dine faste
            donasjoner kan du enkelt sette donasjonene på pause eller avslutte de helt i appen, ta
            kontakt med oss på {fetchData?.data?.phone_no} eller på {fetchData?.data?.email}. <br />
            Dersom du avslutter støtten vil avtalen bli sagt opp og avsluttet.
          </p>

          <h2 className='h2 mt-6' id='where-we-send-your-data'>
            16. Garanti
          </h2>

          <p>
            Med mindre kjøperen samtykker til noe annet, kan selgeren kun innhente og lagre de
            personopplysninger som er nødvendig for at selgeren skal kunne gjennomføre
            forpliktelsene etter avtalen. Personopplysningene til kjøper under 15 år kan ikke
            innhentes med mindre selgeren har samtykke fra foreldre eller foresatte. Kjøperens
            personopplysninger skal kun utleveres til andre hvis det er nødvendig for at selgeren
            skal få gjennomført avtalen med kjøperen, eller i lovbestemte tilfelle. Selgeren kan kun
            innhente kjøperens personnummer dersom det er saklig behov for sikker identifisering og
            slik innhenting er nødvendig.Hvis selgeren vil benytte kjøperens personopplysninger til
            andre formål, for eksempel til å sende kjøperen reklame eller informasjon ut over det
            som er nødvendig for å få gjennomført avtalen, må selgeren innhente kjøperens samtykke
            ved avtaleinngåelsen. Selgeren må gi kjøperen informasjon om hva personopplysningene
            skal brukes til og om hvem som skal bruke personopplysningene. Kjøperens samtykke må
            være frivillig og avgis ved en aktiv handling, for eksempel ved avkrysning. Kjøperen
            skal enkelt kunne kontakte selgeren, for eksempel pr telefon eller e-post dersom han
            eller hun har spørsmål om selgerens bruk av personopplysninger eller hvis han eller hun
            ønsker at selgeren sletter eller endrer personopplysningene.
          </p>

          <h2 className='h2 mt-6' id='17-konfliktlosning'>
            17. Konfliktløsning
          </h2>

          <p>
            Partene skal forsøke å løse eventuelle tvister i minnelighet. Kjøperen kan ta kontakt
            med Forbrukerrådet for å få bistand i en eventuell tvist med selger. Dersom minnelig
            løsning ikke oppnås etter megling i Forbrukerrådet, kan partene skriftlig begjære at
            Forbrukerrådet fremmer tvisten for Forbrukertvistutvalget. Vedtak av
            Forbrukertvistutvalget er rettskraftig fire uker etter forkynning. Før vedtaket er
            rettskraftig, kan partene, ved innsendelse av stevning til Forbrukertvistutvalget,
            bringe vedtaket inn for tingretten.
          </p>
        </div>
      </main>
      <footer className='sales-footer  mt-6'>
        <div className='container-sm'>
          <img src={toAbsoluteUrl('/media/logos/logo-square-white.svg')} alt='logo' />
        </div>
      </footer>
    </>
  )
}

export default SalesTermsMain
