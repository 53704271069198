import QRCode from 'qrcode'
import { Manifest } from './StudioManifest'

export namespace StudioUtils {
  export const imageUrlToBase64 = async (
    url: string,
    setFetchImageFailed: (state: boolean) => void
  ) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string>(async (resolve) => {
      try {
        const data = await fetch(url, { mode: 'no-cors' })
        const blob = await data.blob()
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = () => {
          const base64 = reader.result as string
          resolve(base64)
        }
      } catch (error) {
        setFetchImageFailed(true)
      }
    })
  }

  export const urlToQRCodeB64 = (url: string) => {
    return QRCode.toDataURL(url, {
      color: {
        dark: Manifest.colors.blue, // Blue dots
        light: '#0000', // Transparent background
      },
    })
  }

  export const encodeText = (text: string) => {
    // Convert the text to a Uint8Array
    const encoder = new TextEncoder()
    const data = encoder.encode(text)

    // Convert the Uint8Array to a normal Base64 string
    let base64String = btoa(String.fromCharCode(...data))

    // Make it URL-safe by replacing characters and removing padding
    base64String = base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '') // Remove padding "="

    return base64String
  }

  export const decodeText = (encodedText: string) => {
    // Replace URL-safe characters back to Base64 standard characters
    let base64String = encodedText.replace(/-/g, '+').replace(/_/g, '/')

    // Add padding back (if necessary)
    const padding = base64String.length % 4 === 2 ? '==' : base64String.length % 4 === 3 ? '=' : ''
    base64String += padding

    // Decode the Base64 string into a Uint8Array
    const binaryString = atob(base64String)
    const bytes = Uint8Array.from(binaryString, (char) => char.charCodeAt(0))

    // Convert the Uint8Array back to a string
    const decoder = new TextDecoder()
    return decoder.decode(bytes)
  }
}
