/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

type Props = {
  className: string
}

const tabelData: any = [
  {
    id: 1,
    src: toAbsoluteUrl('/media/svg/avatars/001-boy.svg'),
    Selskap: 'Helge Sviland',
    role: 'Admin',
    mobile: '47 93 33 37',
    email: 'helge@vikingfotball.no',
    date: '12-09-2022 14:13',
  },
  {
    id: 2,
    src: toAbsoluteUrl('/media/svg/avatars/047-girl-25.svg'),
    Selskap: 'Bente Svarstad',
    role: 'Non Admin',
    mobile: '47 93 33 37',
    email: 'bente@vikingfotball.no',
    date: '12-09-2022 14:13',
  },
  {
    id: 3,
    src: toAbsoluteUrl('/media/svg/avatars/006-girl-3.svg'),
    Selskap: 'Sindre Ulhaug',
    role: 'Non Admin',
    mobile: '47 93 33 37',
    email: 'sindre@vikingfotball.no',
    date: '12-09-2022 14:13',
  },
  {
    id: 4,
    src: toAbsoluteUrl('/media/svg/avatars/014-girl-7.svg'),
    Selskap: 'Natalie Smigard',
    role: 'Non Admin',
    mobile: '47 93 33 37',
    email: 'natalie@vikingfotball.no',
    date: '12-09-2022 14:13',
  },
  {
    id: 5,
    src: toAbsoluteUrl('/media/svg/avatars/020-girl-11.svg'),
    Selskap: 'Stine Ringen',
    role: 'Non Admin',
    mobile: '47 93 33 37',
    email: 'stine@vikingfotball.no',
    date: '12-09-2022 14:13',
  },
]

const Table2: React.FC<Props> = ({ className }) => (
  <div className={`card ${className}`}>
    {/* begin::Header */}
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>Bruker administrasjon</span>
        <span className='text-muted mt-1 fw-bold fs-7'>Opprett og administrer system brukere</span>
      </h3>

      <div className='card-toolbar'>
        {/* <button type='button'  className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Opprett ny bruker
          </button> */}
      </div>
    </div>
    {/* end::Header */}
    {/* begin::Body */}
    <div className='card-body py-3'>
      {/* begin::Table container */}
      <div className='table-responsive'>
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted' style={{ backgroundColor: '#E9F4FF' }}>
              <th className='ps-4 rounded-start'>Bruker</th>
              <th className=''>Rolle</th>
              <th className=''>Mobil</th>
              <th className=''>E-post</th>
              <th className=' text-end rounded-end px-4'>Sist innlogget</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {tabelData.map((tbData: any) => (
              <tr key={tbData.id}>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <img
                        src={toAbsoluteUrl('/media/stock/600x400/img-26.jpg')}
                        className=''
                        alt=''
                      />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        Sant Extreanet Solution
                      </a>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        HTML, JS, ReactJS
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    {tbData.role}
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    {tbData.mobile}
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    {tbData.email}
                  </a>
                </td>

                <td className='text-end'>
                  <a href='#' className='btn btn-sm btn-light-primary' style={{ marginRight: 12 }}>
                    DEL
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      {/* end::Table container */}
    </div>
    {/* begin::Body */}
  </div>
)

export default Table2
