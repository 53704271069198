import { Bitmap } from 'zimjs'
import { AbstractContainer } from './AbstractContainer'

export class Graphic extends AbstractContainer {
  private image: Bitmap | undefined

  private original: Bitmap | undefined

  isReady = false

  constructor(width: number, height: number, image?: string) {
    super(width, height)
    // Hide a original image to get the width and height correctly
    if (image) this.createImage(image)
  }

  private createImage(image: string) {
    this.isReady = false

    const removeOriginal = () => {
      if (this.original) {
        this.original.removeFrom()
        this.original = undefined
      }
    }
    const removeImage = () => {
      if (this.image) {
        this.image.removeFrom()
        this.image = undefined
      }
    }
    removeOriginal()
    removeImage()
    if (image)
      Bitmap.fromData(image, (bitmap: Bitmap) => {
        removeOriginal()
        removeImage()
        this.original = bitmap.addTo(this)
        this.original.vis(false)
        this.image = bitmap.addTo(this)
        Bitmap.fromData(image, (bitmap: Bitmap) => {
          this.image = bitmap.addTo(this)
          if (this.original) {
            const isPortrait = this.original.height > this.original.width
            if (!isPortrait) this.image.sca(this.width / this.original.width)
            else this.image.sca(this.height / this.original.height)
            this.image.center()
            this.isReady = true
            this.image.cache()
            this.original.cache()
          }
        })
      })
  }

  setImage(image: string) {
    this.createImage(image)
  }

  setImageAsync(image: string) {
    return new Promise<void>((resolve) => {
      try {
        this.removeAllChildren()

        this.createImage(image)

        // Poll every 50 ms to check if the image is ready
        const interval = setInterval(() => {
          if (this.isReady && this.image && this.original) {
            clearInterval(interval)
            resolve()
          }
        }, 25)
      } catch (error) {
        console.error(error)
      }
    })
  }
}
