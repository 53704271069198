import axios from 'axios'
import { useMutation } from 'react-query'

export function useDeleteNotification(noti_id?: any) {
  return useMutation(['delete-notification', noti_id], async ({ noti_id }: any) => {
    if (!noti_id) {
      return
    }

    const response = await axios.delete(
      `${import.meta.env.VITE_BACKEND_API_URL}/notification/${noti_id}`
    )

    return response
  })
}
