/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../_metronic/helpers'
import { useMainContext } from '../../../context'
import SweetAlert2 from '../../../sweetalert2'
import toastr from '../../../toaster'
import { useDeleteNotification } from '../../hooks/notifictaions/useDeleteNotification'
import { useUpdateNotification } from '../../hooks/notifictaions/useUpdateNotification'
import { useAuth } from '../../modules/auth'

const NotificationDrawerMain: FC = () => {
  const [PageSize, setPageSize] = useState(5)
  const [currentPage, setCurrentPage] = useState(1)
  const [read, setread] = useState('false')
  const { currentUser } = useAuth()
  const { refetchNoti } = useMainContext()
  const intl = useIntl()
  // const {data: notificationData, isFetching} = useGetNotifications({
  //   receiver_id: currentUser?.user?._id,
  //   limit: PageSize,
  //   page: currentPage,
  //   read: false,
  //   refetchNoti: refetchNoti,
  // })
  // const {data: notificationData2} = useGetNotifications({
  //   receiver_id: currentUser?.user?._id,
  //   limit: PageSize,
  //   page: currentPage,
  //   read: true,
  //   refetchNoti: refetchNoti,
  // })
  const queryClient = useQueryClient()
  const { mutate: deleteNoti } = useDeleteNotification()
  const delTopicRecord = (id: any) => {
    SweetAlert2.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteNoti(
          { noti_id: id },
          {
            onSuccess: () => {
              queryClient.invalidateQueries([
                'get-all-notifictaion-of-receiver',
                {
                  receiver_id: currentUser?.user?._id,
                  limit: PageSize,
                  page: currentPage,
                  refetchNoti,
                },
              ])

              toastr.success('Datapost slettet.')
            },
            onError: (err: any) => {
              toastr.error(JSON.stringify(err.response?.data?.message || err))
            },
          }
        )
      }
    })
  }
  const { mutate: updateNoti, isLoading } = useUpdateNotification()

  const upDateNotiDetails = (id: any) => {
    updateNoti(
      {
        noti_id: id,
        read: true,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            'get-all-notifictaion-of-receiver',
            {
              receiver_id: currentUser?.user?._id,
              limit: PageSize,
              page: currentPage,
              read: true,
              refetchNoti,
            },
          ])

          queryClient.invalidateQueries([
            'get-all-notifictaion-of-receiver',
            {
              receiver_id: currentUser?.user?._id,
              limit: PageSize,
              page: currentPage,
              read: false,
              refetchNoti,
            },
          ])
          toastr.success('Datapost markert.')
        },
        onError: (err: any) => {
          toastr.error(JSON.stringify(err.response?.data?.message || err))
        },
      }
    )
  }
  function isJsonString(str: any) {
    try {
      return JSON.parse(str)
    } catch (e) {
      return false
    }
  }
  return (
    <div
      id='kt_notification'
      className='bg-white'
      data-kt-drawer='true'
      data-kt-drawer-name='notification'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'600px', 'lg': '600px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_notification_toggle'
      data-kt-drawer-close='#kt_notification_close'
    >
      <div className='card shadow-none rounded-0'>
        <div className='card-header border-0' id='kt_notification_header'>
          <div className=' d-flex align-items-center'>
            <div className='btn btn-icon btn-active-light-primary btn-custom border'>
              <KTSVG
                path='/media/icons/duotune/general/gen007.svg'
                className='svg-icon-dark svg-icon-1'
              />
            </div>
            <div className='ms-4'>
              <h3 className='fs-5 fw-bolder text-gray-900 mb-0'>Notifications</h3>
              <span className='fw-bold fs-7 text-gray-400'>And alerts</span>
            </div>
          </div>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_notification_close'
            >
              <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
            </button>
          </div>
        </div>

        <div className='card-body position-relative pt-1' id='kt_activities_body'>
          <div
            id='kt_activities_scroll'
            className=''
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#kt_activities_body'
            data-kt-scroll-dependencies='#kt_activities_header, #kt_activities_footer'
            data-kt-scroll-offset='5px'
          >
            <div className=' align-items-center  min-w-350px  py-3 '>
              {/* {isLoading || isFetching ? (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <Spinner animation='border' />{' '}
                </div>
              ) : (
                ''
              )} */}
              <p className='createTicket'>New notifications</p>
              {/* <Form.Group controlId='formBasicSelect' className='mb-3'>
                <Form.Select value={read} onChange={(e: any) => setread(e.currentTarget.value)}>

                  <option value='false'>not opened</option>
                  <option value='true'>opened</option>
                </Form.Select>
              </Form.Group> */}
              {/* {notificationData?.data?.length > 0 ? (
                notificationData?.data?.map((tbData: any) => (
                  <div
                    key={tbData._id}
                    className='mb-3 d-flex border-primary bg-light-info dz-message needsclick align-items-center notificationAlerts'
                  >
                    <span className='svg-icon svg-icon-2hx svg-icon-primary notificationAlertsradius bg-light-info p-2 shadow-sm'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen007.svg'
                        className='svg-icon-info svg-icon-2hx'
                      />
                    </span>
                    <div className='ms-4'>
                      <h3 className='fs-6  text-gray-900 mb-1'>
                        {tbData?.type === 'meeting' && isJsonString(tbData?.item_id)
                          ? `${isJsonString(tbData?.item_id)?.title} - ${
                              isJsonString(tbData?.item_id)?.company_id?.name
                            }`
                          : ''}

                        {tbData?.type === 'topic' && isJsonString(tbData?.item_id)
                          ? `${isJsonString(tbData?.item_id)?.title}`
                          : ''}

                        {tbData?.type === 'task' && isJsonString(tbData?.item_id)
                          ? `${isJsonString(tbData?.item_id)?.title}`
                          : ''}

                        {tbData?.type === 'transaction' && isJsonString(tbData?.item_id)
                          ? ` ${
                              isJsonString(tbData?.item_id)?.tr_type === 'buy'
                                ? `${isJsonString(tbData?.item_id)?.buyer_id?.first_name} ${
                                    isJsonString(tbData?.item_id)?.buyer_id?.last_name
                                  }`
                                : `${isJsonString(tbData?.item_id)?.seller_id?.first_name} ${
                                    isJsonString(tbData?.item_id)?.seller_id?.last_name
                                  }`
                            }
                     wants to ${
                       isJsonString(tbData?.item_id)?.tr_type === 'buy' ? 'buy' : 'sell'
                     } ${isJsonString(tbData?.item_id)?.total_share_amount}
                     shares of ${
                       isJsonString(tbData?.item_id)?.company_id?.name
                     } with per share value is
                     ${isJsonString(tbData?.item_id)?.value_per_share}`
                          : ''}
                      </h3>
                      <span className=' fs-7 text-gray-400'>
                        {moment(tbData?.createdAt).fromNow(true)} ago
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className='w-600px d-flex text-center w-100 align-content-center justify-content-center'>
                </div>
              )} */}

              {/* <div className='row justify-content-center'>
                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-center'>
                  <CustomPagination
                    className='pagination-bar justify-content-md-center'
                    currentPage={currentPage}
                    totalCount={notificationData ? notificationData?.pagination?.totalRecords : 0}
                    pageSize={PageSize}
                    onPageChange={(page: any) => setCurrentPage(page)}
                  />
                </div>
              </div> */}
              <p className='createTicket'>Previous notifications</p>

              {/* {notificationData2?.data?.length > 0 ? (
                notificationData2?.data?.map((tbData: any) => (
                  <div
                    key={tbData._id}
                    className='mb-3 d-flex border-primary bg-light-info dz-message needsclick align-items-center notificationAlerts'
                  >
                    <span className='svg-icon svg-icon-2hx svg-icon-primary notificationAlertsradius bg-light-info p-2 shadow-sm'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen007.svg'
                        className='svg-icon-info svg-icon-2hx'
                      />
                    </span>
                    <div className='ms-4'>
                      <h3 className='fs-6  text-gray-900 mb-1'>
                        {tbData?.type === 'meeting' && isJsonString(tbData?.item_id)
                          ? `${isJsonString(tbData?.item_id)?.title} - ${
                              isJsonString(tbData?.item_id)?.company_id?.name
                            }`
                          : ''}

                        {tbData?.type === 'topic' && isJsonString(tbData?.item_id)
                          ? `${isJsonString(tbData?.item_id)?.title}`
                          : ''}

                        {tbData?.type === 'task' && isJsonString(tbData?.item_id)
                          ? `${isJsonString(tbData?.item_id)?.title}`
                          : ''}

                        {tbData?.type === 'transaction' && isJsonString(tbData?.item_id)
                          ? ` ${
                              isJsonString(tbData?.item_id)?.tr_type === 'buy'
                                ? `${isJsonString(tbData?.item_id)?.buyer_id?.first_name} ${
                                    isJsonString(tbData?.item_id)?.buyer_id?.last_name
                                  }`
                                : `${isJsonString(tbData?.item_id)?.seller_id?.first_name} ${
                                    isJsonString(tbData?.item_id)?.seller_id?.last_name
                                  }`
                            }
                     wants to ${
                       isJsonString(tbData?.item_id)?.tr_type === 'buy' ? 'buy' : 'sell'
                     } ${isJsonString(tbData?.item_id)?.total_share_amount}
                     shares of ${
                       isJsonString(tbData?.item_id)?.company_id?.name
                     } with per share value is
                     ${isJsonString(tbData?.item_id)?.value_per_share}`
                          : ''}
                      </h3>
                      <span className=' fs-7 text-gray-400'>
                        {moment(tbData?.createdAt).fromNow(true)} ago
                      </span>
                    </div>

                  </div>
                ))
              ) : (
                <div className='w-600px d-flex text-center w-100 align-content-center justify-content-center'>

                </div>
              )} */}

              {/* <div className='row justify-content-center'>
                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-center'>
                  <CustomPagination
                    className='pagination-bar justify-content-md-center'
                    currentPage={currentPage}
                    totalCount={notificationData2 ? notificationData2?.pagination?.totalRecords : 0}
                    pageSize={PageSize}
                    onPageChange={(page: any) => setCurrentPage(page)}
                  />
                </div>
              </div> */}
              {/* <div className='mb-3 d-flex border-primary bg-light-info dz-message needsclick align-items-center notificationAlerts'>
              <span className='svg-icon svg-icon-2hx svg-icon-primary notificationAlertsradius bg-light-warning p-2 shadow-sm'>
                <KTSVG
                  path='/media/icons/duotune/communication/com003.svg'
                  className='svg-icon-warning svg-icon-2hx'
                />
              </span>
              <div className='ms-4'>
                <h3 className='fs-6  text-gray-900 mb-1'>System alert</h3>
                <span className=' fs-7 text-gray-400'>Notification description</span>
              </div>
            </div>

            <div className='mb-3 d-flex border-primary bg-light-danger dz-message needsclick align-items-center notificationAlerts'>
              <span className='svg-icon svg-icon-2hx svg-icon-primary notificationAlertsradius bg-light-danger p-2 shadow-sm'>
                <KTSVG
                  path='/media/icons/duotune/communication/com003.svg'
                  className='svg-icon-danger svg-icon-2hx'
                />
              </span>
              <div className='ms-4'>
                <h3 className='fs-6  text-gray-900 mb-1'>System warning</h3>
                <span className=' fs-7 text-gray-400'>Notification description</span>
              </div>
            </div>

            <p className='createTicket my-7'>LAST 7 DAYS</p>

            <div className='mb-3 d-flex border-primary bg-light-info dz-message needsclick align-items-center notificationAlerts'>
              <span className='svg-icon svg-icon-2hx svg-icon-primary notificationAlertsradius bg-light-info p-2 shadow-sm'>
                <KTSVG
                  path='/media/icons/duotune/general/gen007.svg'
                  className='svg-icon-info svg-icon-2hx'
                />
              </span>
              <div className='ms-4'>
                <h3 className='fs-6  text-gray-900 mb-1'>Notification title</h3>
                <span className=' fs-7 text-gray-400'>Notification description</span>
              </div>
            </div>

            <div className='mb-3 d-flex border-primary bg-light-info dz-message needsclick align-items-center notificationAlerts'>
              <span className='svg-icon svg-icon-2hx svg-icon-primary notificationAlertsradius bg-light-warning p-2 shadow-sm'>
                <KTSVG
                  path='/media/icons/duotune/communication/com003.svg'
                  className='svg-icon-warning svg-icon-2hx'
                />
              </span>
              <div className='ms-4'>
                <h3 className='fs-6  text-gray-900 mb-1'>System alert</h3>
                <span className=' fs-7 text-gray-400'>Notification description</span>
              </div>
            </div>

            <div className='mb-3 d-flex border-primary bg-light-danger dz-message needsclick align-items-center notificationAlerts'>
              <span className='svg-icon svg-icon-2hx svg-icon-primary notificationAlertsradius bg-light-danger p-2 shadow-sm'>
                <KTSVG
                  path='/media/icons/duotune/communication/com003.svg'
                  className='svg-icon-danger svg-icon-2hx'
                />
              </span>
              <div className='ms-4'>
                <h3 className='fs-6  text-gray-900 mb-1'>System warning</h3>
                <span className=' fs-7 text-gray-400'>Notification description</span>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { NotificationDrawerMain }
