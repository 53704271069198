import React from 'react'
import './404.scss' // Assuming you have a separate CSS file for this component

const SmallSize: React.FC = () => (
  <div className='container-res-warn'>
    <div className='copy-container'>
      <p className='p'>
        Vi anbefaler å bruke en PC eller Mac for å få den beste opplevelsen av nettstedet, da
        mobiltilpasningen fremdeles er under utvikling.
      </p>
    </div>
  </div>
)

export default SmallSize
