/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import Page404 from '../../../_metronic/layout/components/404'
import CampaignCompetition from './components/CampaignCompetition'
import CampaignUserCard from './components/CampaignUserCard'
import { ForgotPassword } from './components/ForgotPassword'
import { LinkExpire } from './components/LinkExpire'
import { Login } from './components/Login'
import NifLogin from './components/NifLogin'
import RedirectPage from './components/RedirectPage'
import { Registration } from './components/Registration'
import { ResetPassword } from './components/ResetPassword'
import { SetPassword } from './components/SetPassword'
import { VerifyOTP } from './components/VerifyOTP'

// import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div className='d-flex flex-column flex-lg-row-auto w-xl-500px positon-xl-relative'>
          <div className='d-flex flex-column top-0 bottom-0 scroll-y'>
            <div className='d-flex flex-row-fluid flex-column  p-7'>
              <Outlet />
              <p className='text-dark mb-0' style={{ bottom: '3%', position: 'fixed' }}>
                Støtte AS © 2023 All Rights Reserved.
              </p>

              {/* <p className='text-info'>Cookie Preferences, Privacy, <span style={{color: 'black'}}>and </span> Terms.</p> */}
            </div>
          </div>
        </div>
        <div
          className='d-flex flex-column flex-lg-row-fluid py-10 backgroundUrlAuth'
          style={{
            // backgroundColor: '#E4F7FD',
            background: "url('/media/bg/LoginBG.jpg')",
          }}
        />
      </div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password/:token' element={<ResetPassword />} />
      <Route path='create-account/:token' element={<SetPassword />} />
      <Route path='link-expired' element={<LinkExpire />} />

      <Route path='verify-otp' element={<VerifyOTP />} />
      <Route path='link-expired' element={<LinkExpire />} />
      <Route index element={<Login />} />
    </Route>
    <Route path='nif-login' element={<NifLogin />} />
    <Route path='campaign-competition' element={<CampaignCompetition />} />
    <Route path='campaign-user-card' element={<CampaignUserCard />} />
    <Route path='rd' element={<RedirectPage />} />
    <Route path='*' element={<Page404 />} />
  </Routes>
)

export { AuthPage }
