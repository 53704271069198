import React from 'react'
import { Button } from 'react-bootstrap'
import { IDocStorage, StudioApp } from '../../../Marketing/studio/StudioApp'

type Props = {
  submitForm: () => void
  handleSave: (doc: IDocStorage) => Promise<void>
  campaignId: string
  hasRecruiters: boolean
}

const CampaignFlyerForm: React.FC<Props> = ({
  handleSave,
  submitForm,
  campaignId,
  hasRecruiters,
}) => {
  const [hasSaved, setHasSaved] = React.useState(false)
  return (
    <div>
      <div>
        <span className='text-muted'>
          Her kan du lage flyere for hver deltager. Hver deltager vil få en unik QR-kode som de kan
          dele med venner og familie for å støtte kampanjen.
          <br />
          Velg overskrift for flyeren som tydelig identifiserer kampanjen, og husk å trykke lagre
          etter at du har skrevet inn overskriften, slik at endringene dine blir bevart.
        </span>
      </div>
      <br />
      <StudioApp
        onSave={async (doc) => {
          await handleSave(doc)
          setHasSaved(true)
        }}
        isCampaign
        campaignId={campaignId}
      />
      <div className='d-flex justify-content-end mt-3'>
        <Button variant='primary' onClick={submitForm} disabled={!hasSaved}>
          {hasRecruiters ? 'Fortsett til utsendelse' : 'Fortsett til kampanje'}
        </Button>
      </div>
    </div>
  )
}

export default CampaignFlyerForm
