import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { packagesEn, packagesNO } from '../../constants/constants'
import { useAuth } from '../../modules/auth'
import { handleGetRequest } from '../../services'
import MarketingHeader from '../MarketingHeader'
import MarketingModal from './MarketingModal'

const Marketing: React.FC = () => {
  const [show, setShow] = React.useState(false)
  const [hasOrgVideo, setHasOrgVideo] = React.useState(false)
  const [modalData, setModalData] = React.useState({})
  const intl = useIntl()
  const { setLoading } = useContext(LoadingContext)
  const { currentUser } = useAuth()

  const [orgVideoDetails, setOrgVideoDetails] = useState<any>([])
  const handleClick = (data: {}) => {
    setShow(true)
    setModalData(data)
  }

  const getOrgDetails = async () => {
    const response = await handleGetRequest(
      `/organisation/${currentUser?.user?.organisation_id?._id}`
    )(setLoading)
    if (response?.data) {
      const orgNo = response?.data?.organisation_number

      try {
        const { data } = await handleGetRequest(
          `/organisation/get_org_video_by_org_no?orgNo=${orgNo}`
        )(setLoading)
        if (data?.video) {
          setHasOrgVideo(true)
        }
      } catch (error) {
        console.error('Failed to fetch organization details:', error)
      }
    }
  }

  useEffect(() => {
    getOrgDetails()
  }, [modalData])

  const getLangFile = (locale: any) => {
    if (locale === 'no') {
      return packagesNO(hasOrgVideo)
    }
    return packagesEn()
  }
  const getOrgVideoDetails = async () => {
    // const {data} = await handleGetRequest(`/organisation/get_org_video_descriptions`)(setLoading)
    // if (data) {
    setOrgVideoDetails(getLangFile(intl.locale))

    // }
  }

  useEffect(() => {
    getOrgVideoDetails()
  }, [hasOrgVideo])

  return (
    <>
      <MarketingHeader />

      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>{intl.formatMessage({ id: 'simple.marketing' })}</h3>
      </div>

      <div className='row g-2 '>
        {orgVideoDetails.map((item: any) => (
          <div className='col-md-2  mb-md-0 mb-lg-0 mb-6' key={item?.object?.id}>
            <h5 className='marketing-title-card mb-3'>{item?.object?.title}</h5>

            <div className=' marketing-subtitle-card mb-2' style={{ fontSize: '11px' }}>
              {item?.object?.subTitle}
            </div>

            <div className='card mt-6'>
              <div className='card-body py-6 px-5'>
                {item?.object?.card
                  .filter((c) => !!c)
                  .map((card: any) => (
                    <div className={card.id > 1 ? 'mt-8' : ''} key={card.id}>
                      <strong>
                        <img src={toAbsoluteUrl('/media/icons/Calendar.svg')} alt='calendar' />
                        &nbsp; {card.title}
                      </strong>
                      <br />
                      <div className='mt-2'>
                        <small className='text-gray-400'>{card.description}</small>
                      </div>
                      {card.modalData.cardType === 'card6_1' ? (
                        <div />
                      ) : (
                        <button
                          onClick={() => handleClick(card.modalData)}
                          className='btn btn-sm btn-info w-100 mt-4 h6'
                          style={{ fontSize: '0.920rem' }}
                        >
                          {card.button}
                        </button>
                      )}

                      {card.button1 !== '' ? (
                        <button
                          onClick={() => handleClick(card.modalData1)}
                          className='btn btn-sm btn-info w-100 mt-1 h6'
                          style={{ fontSize: '0.920rem' }}
                        >
                          {card.button1}
                        </button>
                      ) : null}
                      {card.button2 !== '' ? (
                        <button
                          onClick={() => handleClick(card.modalData2)}
                          className='btn btn-sm btn-info w-100 mt-1 h6'
                          style={{ fontSize: '0.920rem' }}
                        >
                          {card.button2}
                        </button>
                      ) : null}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <img
        src={toAbsoluteUrl('/media/misc/marketing-bottom.png')}
        className='col-md-8 offset-md-2 mt-8'
        alt='marketing'
      />
      <h3 className='fs-1 fw-bold text-gray-900 mb-4  text-center'>
        {intl.formatMessage({ id: 'you.can.boost.you.marketing' })}
      </h3>
      <MarketingModal
        show={show}
        modalData={modalData}
        handleClose={() => {
          setShow(false)
        }}
        title={intl.formatMessage({ id: 'send.e-mails.to.the.trainer' })}
      />
    </>
  )
}

export default Marketing
