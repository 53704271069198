/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx'
import { delay } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { useMainContext } from '../../../../context'
import { toAbsoluteUrl } from '../../../helpers'
import TourContext from '../../core/tour'

function Topbar() {
  const { refetchNoti, setrefetchNoti, successMessageSupport, setsuccessMessageSupport } =
    useMainContext()
  const { currentUser } = useAuth()
  const { setTour } = useContext(TourContext)
  const navigate = useNavigate()
  const [notificationData, setnotificationData] = useState({
    data: [],
    pagination: {
      page: 1,
      pages: 1,
      total: 0,
      totalRecords: 0,
      pageSize: 10,
    },
  })

  const [showBurgerMenu, setShowBurgerMenu] = useState(window.innerWidth <= 992)

  useEffect(() => {
    const handleResize = () => {
      setShowBurgerMenu(window.innerWidth <= 992)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getInitials = function (firstName = '', lastName = '') {
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
  }

  const capitalizeRole = function (role = '') {
    return role.charAt(0).toUpperCase() + role.slice(1)
  }

  return (
    <div
      className={clsx(
        'd-flex align-items-center flex-shrink-0  justify-content-end ', //justify-content-between justify-content-sm-end
        {
          'w-100 ': showBurgerMenu,
        }
      )}
    >
      {showBurgerMenu && (
        <div className='w-100'>
          <div
            id='kt_aside_toggle'
            className={clsx('card ms-3')}
            style={{
              borderRadius: '8px',
              height: '45px',
              width: '45px',
              cursor: 'pointer',
            }}
          >
            <i className='fa-duotone fa-bars  m-auto' />
          </div>
        </div>
      )}
      <div className='d-flex align-items-center ms-3 ms-lg-3'>
        <div
          className={clsx('d-flex align-items-center mt-1')}
          id='kt_header_user_menu_toggle'
          style={{ marginLeft: '8px' }}
        >
          <div
            className='align-items-center'
            id='kt_profileMain_toggle'
            style={{ marginRight: '8px', cursor: 'pointer' }}
          >
            <div
              className='fs-7 text-gray-800 text-hover-primary fw-bolder'
              style={{ lineHeight: '14px', textAlign: 'end' }}
            >
              {currentUser?.user.first_name} {currentUser?.user.last_name}
            </div>
            <div className='fs-8 text-muted fw-bold mt-1 float-end'>
              {capitalizeRole(currentUser?.user.user_type)}
            </div>
          </div>
          <div className='d-none d-md-block'>
            {currentUser?.user?.image ? (
              <img
                className='card'
                id='kt_profileMain_toggle'
                style={{
                  borderRadius: '8px',
                  height: '45px',
                  width: '45px',
                  marginLeft: '1px',
                  marginTop: '-2px',
                  cursor: 'pointer',
                }}
                alt='Logo'
                src={currentUser?.user?.image || toAbsoluteUrl('/media/avatars/300-1.jpg')}
              />
            ) : (
              <div
                className='card btn btn-icon btn-active-light-primary btn-custom'
                id='kt_profileMain_toggle'
                style={{
                  borderRadius: '8px',
                  height: '45px',
                  width: '45px',
                  marginLeft: '1px',
                  marginTop: '-2px',
                  cursor: 'pointer',
                }}
              >
                <span
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  {getInitials(currentUser?.user?.first_name, currentUser?.user?.last_name)}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className='card pulse ms-3 d-none d-sm-flex justify-content-center align-items-center'
        title='Take the tour'
        onClick={() => {
          navigate(currentUser?.user?.user_type === 'manager' ? '/home' : '/dashboard')
          delay(() => {
            setTour(true)
          }, 500)
        }}
        style={{
          borderRadius: '8px',
          height: '45px',
          width: '45px',
          cursor: 'pointer',
        }}
      >
        <i className='fa-duotone fa-compass m-auto' />
        <span
          className='pulse-ring'
          style={{
            height: '42px',
            width: '43px',
          }}
        />
      </div>
      <div className='d-flex align-items-center ms-1 ms-lg-1' id='support'>
        <div
          className={clsx('d-flex align-items-center mt-1 support p-0 ps-0 ps-sm-3')}
          style={{
            marginBottom: '3px',
            marginLeft: '8px',
            borderRadius: ' 8px ',
            border: '1px solid #c6e0ec ',
          }}
          onClick={() => setsuccessMessageSupport('')}
          id='kt_activities_toggle'
        >
          <div className='align-items-center' style={{ marginRight: '0px', cursor: 'pointer' }}>
            <div
              className='fs-7 text-gray-800 text-hover-primary fw-bolder d-none d-md-block'
              style={{ lineHeight: '8px', textAlign: 'end' }}
            >
              Support
            </div>
            <div className='fs-8 text-muted fw-bold mt-1 float-end' />
          </div>

          <div className='btn btn-icon  btn-custom  min-w-auto '>
            <i className='fa-solid fa-headset' style={{ color: '#009ef7' }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export { Topbar }
