import { FC, useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { EnableSidebar, PageTitle } from '../../../_metronic/layout/core'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { useMainContext } from '../../../context'
import { useAuth } from '../../modules/auth'
import { handleGetRequest } from '../../services'
import { DashboardChartCell } from './cells/DashboardChartCell'
import { DashboardEditorCell } from './cells/DashboardEditorCell'
import { DashboardMarketingCell } from './cells/DashboardMarketingCell'
import { DashboardCell } from './DashboardCell'
import { ChartData } from './DashboardPage'

function HomePage() {
  const { setLoading } = useContext(LoadingContext)
  const { companyofPortal } = useMainContext()
  const { currentUser } = useAuth()
  const intl = useIntl()
  const [dashboardData, setDashboardData] = useState<{
    payments: ChartData[]
    pending: number
    usersWithExpiredConsentAndActiveGoalSupports: number
    totalSupport: {
      total_active_supports: number
      total_received_supports: number
    }
  }>()

  useEffect(() => {
    const getOrgChartStats = async () => {
      const org_id = currentUser?.user?.organisation_id?._id
      const sport_id = companyofPortal?.company_id
      const queryString = sport_id ? `?sport_id=${sport_id}` : ''
      const { data } = await handleGetRequest(
        `/organisation/dashboard/stats/${org_id}${queryString}`
      )(setLoading)
      setDashboardData(data)
    }
    getOrgChartStats()
  }, [currentUser?.user?.organisation_id?._id, companyofPortal, currentUser, setLoading])
  return (
    <div className='dashboard-grid'>
      <div className='dashboard-cell-1'>
        <Link to='/marketing/studio'>
          <DashboardEditorCell />
        </Link>
      </div>
      <div className='dashboard-cell-2'>
        <Link to='/marketing'>
          <DashboardMarketingCell />
        </Link>
      </div>
      <div className='dashboard-cell-3'>
        <div className='dashboard-sub-grid'>
          <div className='dashboard-sub-cell-1'>
            <Link to='/org/support-player'>
              <DashboardCell
                title={intl.formatMessage({ id: 'amount.supporters' })}
                description={intl.formatMessage({ id: 'for.specific.unit' })}
                numbertext={dashboardData?.totalSupport?.total_active_supports || '0'}
              />
            </Link>
          </div>
          <div className='dashboard-sub-cell-2'>
            <DashboardCell
              title={intl.formatMessage({ id: 'supporter.expired.consent' })}
              description={intl.formatMessage({ id: 'for.specific.unit' })}
              numbertext={dashboardData?.usersWithExpiredConsentAndActiveGoalSupports || '0'}
            />
          </div>
          <div className='dashboard-sub-cell-3'>
            <Link to='/org/goals/'>
              <DashboardCell
                title={intl.formatMessage({ id: 'support.received.this.week' })}
                description={intl.formatMessage({ id: 'for.specific.unit' })}
                numbertext={dashboardData?.pending || '0'}
              />
            </Link>
          </div>
          <div className='dashboard-sub-cell-4'>
            <Link to='/org/goals/'>
              <DashboardCell
                title={intl.formatMessage({ id: 'support.received.last.week' })}
                description={intl.formatMessage({ id: 'for.specific.unit' })}
                numbertext={
                  dashboardData?.payments?.[
                    dashboardData.payments.length - 1
                  ]?.totalAmount.toString() || '0'
                }
              />
            </Link>
          </div>
          <div className='dashboard-sub-cell-5'>
            <Link to='/org/goals/'>
              <DashboardCell
                title={intl.formatMessage({ id: 'support.received' })}
                description={intl.formatMessage({ id: 'for.specific.unit' })}
                numbertext={dashboardData?.totalSupport?.total_received_supports || '0'}
              />
            </Link>
          </div>
        </div>
      </div>
      <div className='dashboard-cell-4'>
        <DashboardChartCell chartData={dashboardData?.payments} />
      </div>
    </div>
  )
}

const HomePageWrapper: FC = () => (
  // const intl = useIntl()
  <EnableSidebar>
    <PageTitle description='' breadcrumbs={[]}>
      {/* {intl.formatMessage({id: 'MENU.DASHBOARD'})} */}
      Hei Saif!
    </PageTitle>
    <HomePage />
  </EnableSidebar>
)

export { HomePageWrapper as HomePage }
