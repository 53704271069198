/* eslint-disable no-new */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable class-methods-use-this */
import { MutableRefObject, useContext, useEffect } from 'react'
import { Frame } from 'zimjs'
import { DocContext } from './StudioApp'
import { Manifest } from './StudioManifest'
import { CampaignTemplate } from './templates/CampaignTemplate'
import { SquareTemplate } from './templates/SquareTemplate'

export const StudioEditor = ({
  frameRef,
  isCampaign,
}: {
  frameRef: MutableRefObject<Frame | null>
  isCampaign?: boolean
}) => {
  const { doc, setDoc, hasBeenInitialized } = useContext(DocContext)
  const isCampaignTemplate = isCampaign || window.location.href.includes('campaign')
  const width = !isCampaignTemplate ? 800 : 1414 / 3
  const height = !isCampaignTemplate ? 800 : 2000 / 3

  useEffect(() => {
    frameRef.current?.dispatchEvent(new CustomEvent('DocChange', { detail: doc }))
  }, [doc])

  useEffect(() => {
    const createEditor = async () => {
      const uneditedImages = Manifest.uneditedImagesArr

      frameRef.current = new Frame({
        scaling: 'holder',
        width,
        height,
        assets: [
          { src: 'https://fonts.googleapis.com/css?family=Dosis:500' },
          { src: 'https://fonts.googleapis.com/css?family=Roboto' },
          Manifest.images.squareBG,
          Manifest.images.squareBGNIF,
          Manifest.images.campaignNeg,
          Manifest.images.campaignPos,
          ...uneditedImages,
        ],
        mouseMoveOutside: true,
        ready: () => {
          // eslint-disable-next-line no-new
          setDoc({
            ...doc,
            getCanvas: () => frameRef.current?.canvas,
          })
          // check url for template type
          if (isCampaignTemplate) new CampaignTemplate(frameRef.current!, width, height, doc)
          else new SquareTemplate(frameRef.current!, width, height, doc)
        },
      })
    }
    if (hasBeenInitialized) createEditor()

    return () => {
      frameRef.current?.dispose()
    }
  }, [hasBeenInitialized])

  return null
}
