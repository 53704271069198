/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import clsx from 'clsx'
import { Field, Form, Formik } from 'formik'
import { FC, useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import LoadingContext from '../../../../_metronic/layout/core/Loading'
import { handleGetRequest } from '../../../services'

export function Registration() {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false)
  const intl = useIntl()
  // eslint-disable-next-line func-names
  const setNifLogin = async function () {
    window.location.href = '/auth/nif-login'
  }

  return (
    <div>
      <div>
        {/* begin::Heading */}
        <div className='d-flex ' style={{ alignItems: 'center' }}>
          <img
            src={toAbsoluteUrl('/media/logos/S-logo-full-dark.png')}
            alt='logo'
            className='svg-icon-cufs mb-14'
            style={{ maxHeight: '40px' }}
          />
        </div>
        {/* begin::Heading */}
        <div className='fv-row'>
          <h1 style={{ color: '#001B5C' }}>{intl.formatMessage({ id: 'sport.id' })}</h1>

          <p className='mb-5'>{intl.formatMessage({ id: 'reg.sport.text' })}</p>

          <button
            type='button'
            id='kt_sign_in_nif_submit'
            onClick={async () => {
              await setNifLogin()
            }}
            style={{ borderRadius: '8px', marginTop: '15px' }}
            className='btn btn-lg btn-primary w-100 authbgcolor mb-5'
          >
            <span className='indicator-label'>
              {intl.formatMessage({ id: 'reg.sport.button' })}
            </span>

            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/Idrettens-ID-negativ.svg')}
              className='h-20px position-absolute ms-3'
            />
          </button>
        </div>
        <div className='separator my-10 border-2' />

        <div className='fv-row'>
          <h1 style={{ color: '#001B5C' }}>{intl.formatMessage({ id: 'reg.sport.text2' })}</h1>
          <p className='mb-5'>{intl.formatMessage({ id: 'reg.sport.text3' })}</p>

          <button
            type='button'
            id='kt_sign_in_nif_submit'
            onClick={async () => {
              setShowRegistrationModal(true)
            }}
            style={{ borderRadius: '8px', marginTop: '15px' }}
            className='btn btn-lg btn-primary w-100 authbgcolor mb-5'
          >
            <span className='indicator-label'>
              {intl.formatMessage({ id: 'reg.sport.button2' })}
            </span>
          </button>
        </div>
      </div>
      <RegistrationModal
        show={showRegistrationModal}
        handleClose={() => {
          setShowRegistrationModal(false)
        }}
      />
    </div>
  )
}

// interface for VipsModalProps

interface IRegistrationModalProps {
  show: boolean
  handleClose?: () => void
}

interface IInitialRegistrationFormValues {
  name: string
  address: string
  city: string
  zip: string
  phone_no: string
  homepage: string
  email: string
  email_invoice?: string
  type: string
  contact: string
  num_members?: number
}

interface IOrgDetails {
  name: string
  orgForm?: string
  homepage?: string
  num_members?: number
  address?: {
    postnummer?: string
    poststed?: string
    adresse?: string[]
  }
}

const RegistrationModal: FC<IRegistrationModalProps> = ({ show, handleClose }) => {
  const { setLoading } = useContext(LoadingContext)
  const [org_number, setOrgNumber] = useState('')
  const intl = useIntl()
  const [isFetchingFromBronnoysund, setIsFetchingFromBronnoysund] = useState(false)
  const [initialFormValues, setInitialFormValues] = useState<
    IInitialRegistrationFormValues | undefined
  >()
  const digitsOnly = (value: string | undefined) => /^\d+$/.test(value || '')

  const onClose = () => {
    setInitialFormValues(undefined)
    setOrgNumber('')
    if (handleClose) handleClose()
  }

  const validationOrgNumberSchema = Yup.object({
    org_number: Yup.string()
      .min(9)
      .max(9)
      .required('Organisasjonsnummer er påkrevd')
      .test('Digits only', 'digits_only', digitsOnly),
  })

  const validationSchemaInfo = Yup.object({
    name: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    zip: Yup.string().required('Required'),
    phone_no: Yup.string().required('Required'),
    homepage: Yup.string(),
    email: Yup.string().required('Required').email(),
    email_invoice: Yup.string().email(),
    num_members: Yup.number(),
    type: Yup.string(),
  })

  useEffect(() => {
    const getOrgBankAccounts = async () => {
      setIsFetchingFromBronnoysund(true)
      const orgDetailsResponse = await handleGetRequest(`/bronnoysund/org_info/${org_number}`)(
        setLoading
      )
      const orgData = orgDetailsResponse?.data as IOrgDetails
      setIsFetchingFromBronnoysund(false)
      if (orgData)
        setInitialFormValues({
          name: orgData.name || '',
          address: orgData.address?.adresse?.join(' ') || '',
          city: orgData.address?.poststed || '',
          zip: orgData.address?.postnummer || '',
          phone_no: '',
          homepage: '',
          email: '',
          email_invoice: '',
          type: orgData.orgForm || '',
          contact: '',
        })
    }
    if (initialFormValues === undefined && show && org_number) getOrgBankAccounts()
  }, [org_number])

  const submitForm = async (values: IInitialRegistrationFormValues) => {
    const body = {
      subject: `Søknad fra org: ${values.name}`,
      body: `Organisasjonsnummer: ${org_number}\nOrg form: ${values.type}\nNavn: ${values.name}\nAntall medlemmer: ${values.num_members || ''}\nTelefonnummer: ${values.phone_no}\nHjemmeside: ${values.homepage}\nE-post: ${values.email}\nE-post faktura: ${values.email_invoice}\nAdresse: ${values.address}, ${values.zip} ${values.city}`,
      contact: values.contact,
      email: values.email,
    }

    setLoading(true)
    const response = await axios.post(
      `${import.meta.env.VITE_BACKEND_API_URL}/zendesk/anonymous_create_ticket`,
      body
    )
    if (response) {
      toast.success(intl.formatMessage({ id: 'application.has.now.been.submitted' }))
      setLoading(false)
      onClose()
    } else {
      toast.error(intl.formatMessage({ id: 'sorry.a.system.error.occurred' }))
      setLoading(false)
    }
  }

  return (
    <Modal
      onHide={onClose}
      keyboard={false}
      className='modal fade customzindexvipps'
      id='kt_modal_select_location'
      tabIndex={-1}
      role='dialog'
      show={show}
      size='lg'
      aria-hidden='true'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'apply.for.access' })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={validationOrgNumberSchema}
          initialValues={{ org_number: org_number || '' }}
          onSubmit={(values) => {
            setOrgNumber(values.org_number)
          }}
        >
          {({ values, errors }) => (
            <Form>
              <div className='row'>
                <div className='col-md-4 d-flex align-items-center'>
                  <label className='col-form-label  fw-bold fs-6'>
                    {intl.formatMessage({ id: 'organization.number' })}
                  </label>
                </div>
                <div className='col-md-8'>
                  <Field
                    name='org_number'
                    type='text'
                    className={clsx('form-control form-control-solid', {
                      'is-invalid': values.org_number && errors.org_number,
                    })}
                    placeholder={intl.formatMessage({ id: 'organization.number' })}
                  />
                </div>
                {isFetchingFromBronnoysund && (
                  <div className='d-flex justify-content-center'>
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>
                        {intl.formatMessage({ id: 'loading.data' })}
                      </span>
                    </div>
                  </div>
                )}
                {!initialFormValues && (
                  <div className='col-md-12 mt-4'>
                    <div className=' d-flex justify-content-between mt-2'>
                      <button
                        form='my-form'
                        type='button'
                        className='btn btn-light'
                        onClick={onClose}
                      >
                        {intl.formatMessage({ id: 'Cancel' })}
                      </button>
                      <button
                        type='submit'
                        className='btn btn-sm btn-primary mx-2 btn-orange-right'
                      >
                        {intl.formatMessage({ id: 'get.data.from.the.registers' })}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
        {initialFormValues && (
          <Formik
            validationSchema={validationSchemaInfo}
            initialValues={initialFormValues}
            onSubmit={(values) => {
              submitForm(values)
            }}
          >
            {({ values, errors }) => (
              <Form>
                <div className='row mt-6'>
                  <div className='col-md-4 d-flex align-items-center'>
                    <label className='col-form-label  fw-bold fs-6'>
                      {intl.formatMessage({ id: 'name' })}
                    </label>
                  </div>
                  <div className='col-md-8'>
                    <Field
                      name='name'
                      type='text'
                      className={clsx('form-control form-control-solid', {
                        'is-invalid': values.name && errors.name,
                      })}
                      placeholder={intl.formatMessage({ id: 'name.of.org' })}
                    />
                  </div>
                </div>
                <div className='row mt-6'>
                  <div className='col-md-4 d-flex align-items-center'>
                    <label className='col-form-label  fw-bold fs-6'>
                      {intl.formatMessage({ id: 'contact.person' })}
                    </label>
                  </div>
                  <div className='col-md-8'>
                    <Field
                      name='contact'
                      type='text'
                      className={clsx('form-control form-control-solid', {
                        'is-invalid': values.contact && errors.contact,
                      })}
                      placeholder={intl.formatMessage({ id: 'general.manager.or.contact.person' })}
                    />
                  </div>
                </div>
                <div className='row mt-6'>
                  <div className='col-md-4 d-flex align-items-center'>
                    <label className='col-form-label  fw-bold fs-6'>
                      {intl.formatMessage({ id: 'email' })}
                    </label>
                  </div>
                  <div className='col-md-8'>
                    <Field
                      name='email'
                      type='text'
                      className={clsx('form-control form-control-solid', {
                        'is-invalid': values.email && errors.email,
                      })}
                      placeholder={intl.formatMessage({ id: 'org.email' })}
                    />
                  </div>
                </div>
                <div className='row mt-6'>
                  <div className='col-md-4 d-flex align-items-center'>
                    <label className='col-form-label  fw-bold fs-6'>
                      {intl.formatMessage({ id: 'email.invoice' })}
                    </label>
                  </div>
                  <div className='col-md-8'>
                    <Field
                      name='email_invoice'
                      type='text'
                      className={clsx('form-control form-control-solid', {
                        'is-invalid': values.email_invoice && errors.email_invoice,
                      })}
                      placeholder={intl.formatMessage({ id: 'org.email.invoice' })}
                    />
                  </div>
                </div>
                <div className='row mt-6'>
                  <div className='col-md-4 d-flex align-items-center'>
                    <label className='col-form-label  fw-bold fs-6'>
                      {intl.formatMessage({ id: 'homepage' })}
                    </label>
                  </div>
                  <div className='col-md-8'>
                    <Field
                      name='homepage'
                      type='text'
                      className={clsx('form-control form-control-solid', {
                        'is-invalid': values.homepage && errors.homepage,
                      })}
                      placeholder={intl.formatMessage({ id: 'homepage.of.org' })}
                    />
                  </div>
                </div>
                <div className='row mt-6'>
                  <div className='col-md-4 d-flex align-items-center'>
                    <label className='col-form-label  fw-bold fs-6'>
                      {intl.formatMessage({ id: 'address' })}
                    </label>
                  </div>
                  <div className='col-md-8'>
                    <Field
                      name='address'
                      type='text'
                      className={clsx('form-control form-control-solid', {
                        'is-invalid': values.address && errors.address,
                      })}
                      placeholder={intl.formatMessage({ id: 'Address.of.head.office' })}
                    />
                  </div>
                </div>
                <div className='row mt-6'>
                  <div className='col-md-4 d-flex align-items-center'>
                    <label className='col-form-label  fw-bold fs-6'>
                      {intl.formatMessage({ id: 'postnumber' })}
                    </label>
                  </div>
                  <div className='col-md-8'>
                    <Field
                      name='zip'
                      type='text'
                      className={clsx('form-control form-control-solid', {
                        'is-invalid': values.zip && errors.zip,
                      })}
                      placeholder={intl.formatMessage({ id: 'postnumber' })}
                    />
                  </div>
                </div>
                <div className='row mt-6'>
                  <div className='col-md-4 d-flex align-items-center'>
                    <label className='col-form-label  fw-bold fs-6'>
                      {intl.formatMessage({ id: 'Post.area' })}
                    </label>
                  </div>
                  <div className='col-md-8'>
                    <Field
                      name='city'
                      type='text'
                      className={clsx('form-control form-control-solid', {
                        'is-invalid': values.city && errors.city,
                      })}
                      placeholder={intl.formatMessage({ id: 'Post.area' })}
                    />
                  </div>
                </div>
                <div className='row mt-6'>
                  <div className='col-md-4 d-flex align-items-center'>
                    <label className='col-form-label  fw-bold fs-6'>
                      {intl.formatMessage({ id: 'tel.phone' })}
                    </label>
                  </div>
                  <div className='col-md-8'>
                    <Field
                      name='phone_no'
                      type='text'
                      className={clsx('form-control form-control-solid', {
                        'is-invalid': values.phone_no && errors.phone_no,
                      })}
                      placeholder={intl.formatMessage({ id: 'tel.phone' })}
                    />
                  </div>
                </div>{' '}
                <div className='row mt-6'>
                  <div className='col-md-4 d-flex align-items-center'>
                    <label className='col-form-label  fw-bold fs-6'>Antall medlemmer</label>
                  </div>
                  <div className='col-md-8'>
                    <Field
                      name='num_members'
                      type='text'
                      className={clsx('form-control form-control-solid')}
                      placeholder='Antall medlemmer'
                    />
                  </div>
                </div>
                <div className='row '>
                  <div className='col-md-12 mt-4'>
                    <div className=' d-flex justify-content-between mt-2'>
                      <button
                        form='my-form'
                        type='button'
                        className='btn btn-light'
                        onClick={onClose}
                      >
                        {intl.formatMessage({ id: 'Cancel' })}
                      </button>
                      <button
                        type='submit'
                        className='btn btn-sm btn-primary mx-2 btn-orange-right'
                      >
                        {intl.formatMessage({ id: 'send.application' })}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default RegistrationModal
