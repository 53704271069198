import clsx from 'clsx'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { handleGetRequest, handlePostRequestWithoutMessage } from '../../../services'

// eslint-disable-next-line no-useless-escape
const specialCharactersRegex = /^(?=.*[!@#\$%\^&\*])/
const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Passord er påkrevd')
    .min(8, 'Passordet må inneholde minst 8 tegn')
    .matches(/^(?=.*[a-z])/, ' Må inneholde en liten bokstav')
    .matches(/^(?=.*[A-Z])/, '  Må inneholde ett stort tegn')
    .matches(/^(?=.*[0-9])/, ' Må inneholde ett tall')
    .matches(specialCharactersRegex, ' Må inneholde ett spesialkarakter'),
  confirmPassword: Yup.string()
    .when('password', {
      is: (password: any) => password && password.length > 0,
      then: () => Yup.string().oneOf([Yup.ref('password')], 'Passordet stemmer ikke'),
    })
    .min(8, 'Passordet må bestå av minst 8 karakterer.')
    .matches(/^(?=.*[a-z])/, ' Må inneholde en liten bokstav')
    .matches(/^(?=.*[A-Z])/, '  Må inneholde ett stort tegn')
    .matches(/^(?=.*[0-9])/, ' Må inneholde ett tall')
    .matches(specialCharactersRegex, ' Må inneholde ett spesialkarakter'),
})

export function SetPassword() {
  const [validationSchema, setValidationSchema] = useState<any>(resetPasswordSchema)
  const { token } = useParams<{ token: string }>()
  const [fieldPass, setfieldPass] = useState(false)
  const navigate = useNavigate()
  // const {data: pData} = useGetPasswordResetDetails(token)
  const [, setData] = useState<any>()
  const intl = useIntl()
  const initialValues = {
    password: '',
    confirmPassword: '',
  }

  const [formVal] = useState(initialValues)
  const [loading, setLoading] = useState(false)

  const getRequestDetails = async () => {
    const { data, success } = await handleGetRequest(
      `/organisation_user/reset-password-request-details/${token}`
    )(setLoading)

    if (!success) {
      setTimeout(() => {
        // window.location.href = '/auth/forgot-password'
        navigate('/auth/link-expired')
      }, 3000)
      return
    }
    setData(data)
  }

  useEffect(() => {
    getRequestDetails()
  }, [token])

  const submitStep = async (values: any) => {
    // eslint-disable-next-line eqeqeq
    const { success } = await handlePostRequestWithoutMessage(
      '/organisation_user/change-password',
      {
        password: values?.password,
        request_id: token,
      }
    )(setLoading)

    if (success) {
      toast.success(intl.formatMessage({ id: 'Ressetpass.toast1' }), { autoClose: 3000 })
      setTimeout(() => {
        navigate('/auth/login')
        //  window.location.href = '/auth'
      }, 3000)
    }
  }
  return (
    <Formik
      initialValues={formVal}
      onSubmit={submitStep}
      enableReinitialize
      encType='multipart/form-data'
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className=' mb-10'>
            {/* begin::Title */}

            <div className=' mb-10 d-flex ' style={{ alignItems: 'center' }}>
              <img
                src={toAbsoluteUrl('/media/logos/S-logo-full-dark.png')}
                alt='logo'
                className='svg-icon-cufs mb-14'
                style={{ maxHeight: '40px' }}
              />
              {/* <h2 className='text-dark mb-14 mx-2'>Støtte AS</h2> */}
            </div>

            <h1 className='authtext'>Fullfør registrering</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400'>
              {intl.formatMessage({ id: 'Enter.your.new.password.and.confirm' })}
            </div>
            {/* end::Link */}
          </div>
          <div className='fv-row mb-4'>
            <label className={clsx('col-lg-4 col-form-label fw-bold fs-6')}>
              {' '}
              {intl.formatMessage({ id: 'new.password' })}
            </label>
            <Field
              style={{ borderRadius: '8px', width: '81%' }}
              type={fieldPass ? 'text' : 'password'}
              name='password'
              className='form-control form-control-lg form-control-solid'
              placeholder='Nytt passord'
              onChange={(e: any) => {
                setFieldValue('password', e.target.value)
                if (e.target.value !== values.confirmPassword) {
                  // set validation scheme and only update the confirmPassword field
                  setValidationSchema(
                    Yup.object().shape({
                      ...validationSchema?.fields,
                      confirmPassword: Yup.string()
                        .required('Vennligst bekreft passordet ditt for å fortsette.')
                        .oneOf([Yup.ref('password'), ''], 'Begge passordene må være like'),
                    })
                  )
                } else {
                  setValidationSchema(
                    Yup.object().shape({
                      ...validationSchema?.fields,
                      confirmPassword: Yup.string().notRequired(),
                    })
                  )
                }
              }}
            />
            <div className='text-danger'>
              <ErrorMessage name='password' />
            </div>
            <span
              className='btn btn-sm btn-icon  end-0 me-n2'
              onClick={() => setfieldPass(!fieldPass)}
              onKeyDown={() => setfieldPass(!fieldPass)}
              style={{ position: 'absolute', left: '71%', top: '275px' }}
            >
              <i className={`fa fa-eye${fieldPass ? '' : '-slash'}`} />
            </span>
          </div>
          <div className='fv-row mb-4'>
            <label
              className={clsx('col-lg-4 col-form-label fw-bold fs-6', {
                required: validationSchema?.fields?.confirmPassword?.exclusiveTests?.required,
              })}
            >
              {intl.formatMessage({ id: 'confirm.password' })}
            </label>
            <Field
              style={{ borderRadius: '8px', width: '81%' }}
              type={fieldPass ? 'text' : 'password'}
              name='confirmPassword'
              className='form-control form-control-lg form-control-solid'
              placeholder='Bekreft passord'
              onChange={(e: any) => {
                setFieldValue('confirmPassword', e.target.value)
                if (e.target.value !== values.password) {
                  // set validation scheme and only update the confirmPassword field
                  setValidationSchema(
                    Yup.object().shape({
                      ...validationSchema?.fields,
                      password: Yup.string().required('Skriv inn ditt nye passord.'),
                    })
                  )
                } else {
                  setValidationSchema(
                    Yup.object().shape({
                      ...validationSchema?.fields,
                      password: Yup.string().notRequired(),
                    })
                  )
                }
              }}
            />
            <div className='text-danger'>
              <ErrorMessage name='confirmPassword' />
            </div>
            <span
              className='btn btn-sm btn-icon  end-0 me-n2'
              onClick={() => setfieldPass(!fieldPass)}
              onKeyDown={() => setfieldPass(!fieldPass)}
              style={{ position: 'absolute', left: '71%', top: '377px' }}
            >
              <i className={`fa fa-eye${fieldPass ? '' : '-slash'}`} />
            </span>
          </div>
          <div className='row' style={{ marginBottom: '140px' }}>
            <div className='col-md-4'>
              <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-light me-3'
                >
                  {intl.formatMessage({ id: 'Cancel' })}
                </button>
              </Link>{' '}
            </div>
            <div className='col-md-8'>
              <button
                style={{ width: '180px', marginLeft: '5.7rem' }}
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-lg btn-primary authbgcolor mb-5'
              >
                <span className='indicator-progress'>
                  Lagre
                  {loading && (
                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                  )}
                </span>
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
