import clsx from 'clsx'
import { useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useMainContext } from '../../../context'
import { useAuth } from '../../modules/auth'
import { handleGetRequest, handlePatchRequest } from '../../services'

// eslint-disable-next-line no-useless-escape
const specialCharactersRegex = /^(?=.*[!@#\$%\^&\*])/

interface FormValues {
  first_name: string
  last_name: string
  dob: string
  password: string
  confirmPassword: string
  email: string
  mobile_number: string
  picture: null | File
}

export const UserSetttingMain: FC = () => {
  const { currentUser, setCurrentUser, saveAuth, saveCurrentUser } = useAuth()
  const { setCustomberdCrum } = useMainContext()
  const [loading, setLoading] = useState(false)
  const [passread, setpassread] = useState(true)
  const intl = useIntl()
  const [image, setimage] = useState('')
  const [initialValues, setInitialValues] = useState<FormValues>({
    first_name: '',
    last_name: '',
    dob: '',
    password: '',
    confirmPassword: '',
    email: '',
    mobile_number: '',
    picture: null,
  })
  const [userSettingSchema, setUserSettingSchema] = useState({})
  useEffect(() => {
    let schema = Yup.object().shape({
      first_name: Yup.string().min(3, 'Minimum 3 symbols').required('first name is required'),
      last_name: Yup.string().min(3, 'Minimum 3 symbols').required('last name  is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      mobile_number: Yup.number().required('required'),
    })
    if (!passread) {
      schema = schema.shape({
        password: Yup.string()
          .notRequired()
          .min(8, 'Passordet må inneholde minst 8 tegn')
          .matches(/^(?=.*[a-z])/, ' Må inneholde en liten bokstav')
          .matches(/^(?=.*[A-Z])/, '  Må inneholde ett stort tegn')
          .matches(/^(?=.*[0-9])/, ' Må inneholde ett tall')
          .matches(specialCharactersRegex, ' Må inneholde ett spesialkarakter'),
        confirmPassword: Yup.string().when('password', {
          is: (password: any) => password && password.length > 0,
          then: () => Yup.string().oneOf([Yup.ref('password')], 'Begge passordene må være like.'),
        }),
      })
    }
    setUserSettingSchema(schema)
  }, [passread])

  const id = currentUser?.user?._id
  const getUserData = async () => {
    try {
      const { data } = await handleGetRequest(`/organisation_user`)(setLoading)
      const receivedData = data
      setCustomberdCrum('', '', 'Eierportalen -  Account settings ')
      setimage(receivedData?.image)
      setInitialValues({
        first_name: receivedData?.first_name,
        last_name: receivedData?.last_name,
        password: '************',
        confirmPassword: '************',
        email: receivedData?.email,
        dob: receivedData?.dob,
        mobile_number: receivedData?.mobile_number,
        picture: receivedData?.image,
      })
    } catch (error: any) {
      toast.error(JSON.stringify(error?.response?.data))
    }
  }
  const delProfilePic = async () => {
    setLoading(true)
    const option = {
      image: '',
    }
    try {
      const resputUser = await handlePatchRequest(
        `/organisation_user/update_profile/${id}`,
        option
      )(setLoading)

      if (resputUser) {
        setLoading(false)
      }
      updateCurrentUser('')
    } catch (error: any) {
      setLoading(false)
      toast.error(JSON.stringify(error?.response?.data?.message))
    }
  }
  const updateCurrentUser = (url: any) => {
    setimage(url)
    currentUser.user.image = url
    setCurrentUser(currentUser)
    saveAuth(currentUser)
    localStorage.setItem('currentUser', JSON.stringify(currentUser))
    saveCurrentUser(currentUser)
  }
  useEffect(() => {
    getUserData()
  }, [])

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      ...{ password: passread ? '************' : '' },
      ...{ confirmPassword: passread ? '************' : '' },
    })
  }, [passread])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: userSettingSchema,
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      const {
        first_name,
        last_name,
        password,
        confirmPassword,
        mobile_number,
        dob,
        email,
        picture,
      } = values
      setLoading(true)
      try {
        const data: any = {
          first_name,
          last_name,
          dob,
          mobile_number,
          email,
          picture,
        }
        if (!confirmPassword && password !== confirmPassword) {
          setErrors({ confirmPassword: 'Passwords do not match' })
          setLoading(false)
          return
        }

        if (password && !passread && confirmPassword) {
          data.new_password = password
        }
        if (picture) {
          data.picture = picture
        }
        currentUser.user.first_name = first_name
        currentUser.user.last_name = last_name
        currentUser.user.dob = dob
        currentUser.user.mobile_number = mobile_number
        currentUser.user.email = email
        currentUser.user.image = image
        setCurrentUser(currentUser)
        saveAuth(currentUser)
        localStorage.setItem('currentUser', JSON.stringify(currentUser))
        saveCurrentUser(currentUser)

        // Convert data to form data
        const formData = new FormData()
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key])
        })
        const response = await handlePatchRequest(
          `/organisation_user/update_profile/${id}`,
          formData
        )(setLoading)

        if (response) {
          updateCurrentUser(response?.data?.data?.image)
          setLoading(false)
          getUserData()
          toast.success(intl.formatMessage({ id: 'User.details.updated' }), { autoClose: 4000 })
        }
      } catch (error: any) {
        setErrors({
          confirmPassword:
            'Passordet skal inneholde både tall, symboler, store og små bokstaver og bestå av minst 8 tegn',
        })
        setStatus(error.message)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const handleImageFileChange = (e: any) => {
    const file = e.target.files[0]
    if (!file) {
      toast.error('Opplastning feilet')
      return
    }

    const fileExtensions = ['png', 'jpg', 'jpeg']
    const fileName = e.target.files[0].name
    const fileExtension = fileName.split('.').pop()
    const fileSize = e.target.files[0].size
    const maxFileSize = 4 * 1024 * 1024 // 2MB in bytes

    if (!fileExtensions.includes(fileExtension)) {
      toast.error('Tillatt filtype: jpg, jpeg, png.', { autoClose: 4000 })
      return
    }
    if (fileSize > maxFileSize) {
      toast.error(
        'Bildet du prøver å laste opp er for stort. Vennligst last opp et bilde som er mindre enn 4MB.',
        { autoClose: 4000 }
      )
      return
    }
    setimage(URL.createObjectURL(e.target.files[0]))
    formik.setFieldValue('picture', e.target.files[0])
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0 cursor-pointer '>
        <div className='card-title m-0 '>{intl.formatMessage({ id: 'account.settings' })}</div>

        <div className='card-title m-0 float-right'>
          {currentUser?.user?.user_type === 'admin' && (
            <Link to='/org/users' className='btn btn-lg btn-primary authbgcolor'>
              {intl.formatMessage({ id: 'back.to.users' })}
            </Link>
          )}
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form
          onSubmit={formik.handleSubmit}
          noValidate
          className='form'
          encType='multipart/form-data'
        >
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-2 col-form-label  fw-bold fs-6'>
                {intl.formatMessage({ id: 'profile.picture' })}
              </label>

              <div className='col-lg-10 fv-row'>
                {loading && (
                  <span className='indicator-progress mb-4' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border  spinner-border-sm align-middle ms-2' />
                  </span>
                )}
                <div
                  className='image-input image-input-outline image-input-placeholder'
                  data-kt-image-input='true'
                >
                  <div
                    className='image-input-wrapper w-125px h-125px p-1'
                    style={{
                      backgroundImage: `url(${
                        image ||
                        toAbsoluteUrl('/media/icons/duotune/abstract/user-astronaut-solid.svg')
                      })`,
                    }}
                  />
                  <label
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    aria-label='Change avatar'
                    data-bs-original-title='Change avatar'
                    data-kt-initialized={1}
                  >
                    <i className='bi bi-pencil-fill fs-7' />
                    <input
                      type='file'
                      name='fileupload'
                      accept='.png, .jpg, .jpeg'
                      onChange={handleImageFileChange}
                    />
                  </label>
                  {initialValues?.picture ? (
                    <label
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      style={{ position: 'absolute', top: 112, left: 112 }}
                      onClick={() => delProfilePic()}
                      onKeyDown={() => delProfilePic()}
                    >
                      <i className='bi bi-x-lg fs-7' />
                    </label>
                  ) : (
                    ''
                  )}
                </div>
                <div className='form-text'>
                  {' '}
                  {intl.formatMessage({ id: 'allowed.file.type' })}: jpg, jpeg, png.
                </div>
                <div className='form-text'>Max størrelse: 4MB.</div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-2 col-form-label fw-bold fs-6'>
                {intl.formatMessage({ id: 'first.name' })}
              </label>

              <div className='col-lg-10 fv-row'>
                <input
                  type='text'
                  className={clsx('form-control form-control-lg form-control-solid', {
                    'is-invalid': formik.touched.first_name && formik.errors.first_name,
                  })}
                  {...formik.getFieldProps('first_name')}
                />
                {formik.touched.first_name && formik.errors.first_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>{formik.errors.first_name}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-2 col-form-label fw-bold fs-6'>
                {intl.formatMessage({ id: 'surname' })}
              </label>

              <div className='col-lg-10 fv-row'>
                <input
                  type='text'
                  className={clsx('form-control form-control-lg form-control-solid', {
                    'is-invalid': formik.touched.last_name && formik.errors.last_name,
                  })}
                  {...formik.getFieldProps('last_name')}
                />
                {formik.touched.last_name && formik.errors.last_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>{formik.errors.last_name}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-2 col-form-label fw-bold fs-6'>
                {intl.formatMessage({ id: 'email' })}
              </label>

              <div className='col-lg-10 fv-row'>
                <input
                  type='text'
                  className={clsx('form-control form-control-lg form-control-solid', {
                    'is-invalid': formik.touched.email && formik.errors.email,
                  })}
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-2 col-form-label fw-bold fs-6'>
                {intl.formatMessage({ id: 'mobile' })}
              </label>
              <div className='col-lg-10 fv-row'>
                <input
                  type='number'
                  className={clsx('form-control form-control-lg form-control-solid', {
                    'is-invalid': formik.touched.mobile_number && formik.errors.mobile_number,
                  })}
                  {...formik.getFieldProps('mobile_number')}
                />
                {formik.touched.mobile_number && formik.errors.mobile_number && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>{formik.errors.mobile_number}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-2 col-form-label  fw-bold fs-6'>
                {intl.formatMessage({ id: 'Passord' })}
              </label>

              <div className='col-lg-10 fv-row'>
                <input
                  type='text'
                  disabled={passread}
                  className={clsx('form-control form-control-lg form-control-solid', {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  })}
                  {...formik.getFieldProps('password')}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>{formik.errors.password}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-2 col-form-label  fw-bold fs-6'>
                {' '}
                {intl.formatMessage({ id: 'confirm.password' })}
              </label>

              <div className='col-lg-10 fv-row'>
                <input
                  type='text'
                  disabled={passread}
                  {...formik.getFieldProps('confirmPassword')}
                  className={clsx('form-control form-control-lg form-control-solid', {
                    'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                  })}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>{formik.errors.confirmPassword}</div>
                  </div>
                )}
                <i
                  className='bi bi-pencil-square text-info'
                  style={{
                    position: 'relative',
                    cursor: 'pointer',
                    top: '-96px',
                    left: '97%',
                  }}
                  onClick={() => setpassread(!passread)}
                  onKeyDown={() => setpassread(!passread)}
                />
              </div>
            </div>
            <Link to='/org/users' className='btn btn-light me-3'>
              {intl.formatMessage({ id: 'Cancel' })}
            </Link>

            <button
              type='submit'
              style={{ float: 'right' }}
              className='btn btn-lg btn-primary authbgcolor'
              disabled={loading}
            >
              {!loading && intl.formatMessage({ id: 'Update' })}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  {intl.formatMessage({ id: 'please.wait' })}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              )}
            </button>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9' />
        </form>
      </div>
    </div>
  )
}
