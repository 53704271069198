import axios from 'axios'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { FC, useState } from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'

const formSchema = Yup.object().shape({
  subject: Yup.string().required('Emne er påkrevd'),
  message: Yup.string().required('Melding er påkrevd'),
})

const initialValues = {
  subject: '',
  message: '',
}

const ActivityDrawerMain: FC = () => {
  const [loading, setLoading] = useState(false)
  const intl = useIntl()
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async (values, { resetForm }) => {
      const body = {
        subject: values.subject,
        body: values.message,
      }

      setLoading(true)
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_API_URL}/zendesk/org_create_ticket`,
        body
      )
      if (response) {
        resetForm()
        toast.success(intl.formatMessage({ id: 'Your.inquiry.has.now.been.registered' }))
        // setsuccessMessageSupport(
        //     'Din henvendelse er nå registrert. Vi tar kontakt på e-post så snart som mulig.'
        //   )
        setLoading(false)
      } else {
        toast.error(intl.formatMessage({ id: 'sorry.a.system.error.occurred' }))
        setLoading(false)
      }
    },
  })

  return (
    <div
      id='kt_activities'
      className='bg-white'
      data-kt-drawer='true'
      data-kt-drawer-name='activities'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'lg': '764px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_activities_toggle'
      data-kt-drawer-close='#kt_activities_close'
    >
      <div className='card shadow-none w-100 rounded-0 border-none'>
        <div className='card-header' id='kt_activities_header'>
          <div className=' d-flex align-items-center'>
            <div
              className='btn btn-icon btn-active-light-primary btn-custom border min-w-auto
            '
            >
              <i
                style={{
                  borderRadius: '8px',
                  color: '#009ef7',
                }}
                className='fa-solid fa-headset'
              />
            </div>

            <div className='ms-4'>
              <h3 className='fs-5 fw-bolder text-gray-900 mb-0'>
                {intl.formatMessage({ id: 'Contact.Support' })}
              </h3>
              <span className='fw-bold fs-7 text-gray-400'>
                {intl.formatMessage({ id: 'or.report.an.error' })}
              </span>
            </div>
          </div>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary min-w-auto'
              id='kt_activities_close'
            >
              <i className='fa-duotone fa-rectangle-xmark fs-2' />
            </button>
          </div>
        </div>

        <div className='card-body position-relative' id='kt_activities_body'>
          <div
            id='kt_activities_scroll'
            className=''
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#kt_activities_body'
            data-kt-scroll-dependencies='#kt_activities_header, #kt_activities_footer'
            data-kt-scroll-offset='5px'
          >
            <div className='timeline'>
              <div className='timeline-item'>
                <div className='timeline-line w-40px' />

                <div className='timeline-content mb-10 mt-n1'>
                  <div className='overflow-auto pb-5'>
                    <div className=' align-items-center  py-3 '>
                      <form
                        onSubmit={formik.handleSubmit}
                        noValidate
                        id='kt_modal_new_ticket_form'
                        className='form fv-plugins-bootstrap5 fv-plugins-framework'
                        action='#'
                      >
                        <div className='d-flex flex-column mb-8 fv-row fv-plugins-icon-container'>
                          <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                            <span className=''>{intl.formatMessage({ id: 'Theme' })}</span>
                          </label>
                          <input
                            type='text'
                            placeholder={intl.formatMessage({ id: 'enter.a.relevant.topic' })}
                            {...formik.getFieldProps('subject')}
                            className={clsx('form-control form-control-lg', {
                              'is-invalid': formik.touched.subject && formik.errors.subject,
                            })}
                          />
                          <div className='fv-plugins-message-container invalid-feedback' />
                        </div>

                        <div className='d-flex flex-column mb-4 fv-row fv-plugins-icon-container'>
                          <label className='fs-6 fw-bold mb-2'>
                            {intl.formatMessage({ id: 'Description' })}
                          </label>
                          <textarea
                            style={{ height: '350px' }}
                            rows={4}
                            placeholder={intl.formatMessage({
                              id: 'describe.the.problem.you.are.experiencing',
                            })}
                            {...formik.getFieldProps('message')}
                            className={clsx('form-control form-control-lg', {
                              'is-invalid': formik.touched.message && formik.errors.message,
                            })}
                          />
                          <div className='fv-plugins-message-container invalid-feedback' />
                        </div>

                        <div>
                          <div className=' d-flex justify-content-between'>
                            <button
                              type='reset'
                              id='kt_activities_close'
                              className='btn btn-light me-3'
                            >
                              {intl.formatMessage({ id: 'Cancel' })}
                            </button>
                            <button
                              type='submit'
                              id='kt_modal_new_ticket_submit'
                              className='btn btn-lg btn-primary authbgcolor'
                            >
                              <span className='indicator-progress'>
                                {intl.formatMessage({ id: 'Send' })}
                                {loading && (
                                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                                )}
                              </span>
                            </button>
                          </div>
                          <div className='d-flex flex-column mt-10'>
                            <div className=' mb-6 fs-2 fw-bolder'>
                              {intl.formatMessage({ id: 'Opening.hours.support' })}:
                            </div>

                            <div className='d-flex flex-wrap fw-bold fs-5'>
                              <div className=' d-flex align-items-center text-gray-400 fw-normal'>
                                {intl.formatMessage({ id: 'opening.1' })} <br />
                                {intl.formatMessage({ id: 'opening.2' })}
                                <br />
                                {intl.formatMessage({ id: 'opening.3' })}
                                <br />
                                {intl.formatMessage({ id: 'opening.4' })}
                                <br />
                              </div>
                            </div>
                          </div>
                          <div className='d-flex flex-column mt-10'>
                            <div className=' mb-6 fs-2 fw-bolder'>
                              {intl.formatMessage({ id: 'Call.us' })}:
                            </div>
                            <div className='d-flex flex-wrap fw-bold fs-5'>
                              <div className=' d-flex align-items-center text-gray-400 fw-normal'>
                                {intl.formatMessage({ id: 'Tel' })} : +47 969 25 999
                              </div>
                            </div>
                          </div>
                        </div>
                        <div />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ActivityDrawerMain }
