/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { MutableRefObject, useContext, useEffect, useState } from 'react'
import { ButtonGroup } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { Frame } from 'zimjs/ts-src/typings/zim'
import LoadingContext from '../../../../_metronic/layout/core/Loading'
import { handlePostRequest } from '../../../services'
import { ICampaign } from '../../campaign/CampaignTable'
import SendCampaignLinkModal from '../../campaign/modal/SendCampaignLinkModal'
import { SelectImageDialog } from './modals/SelectImageDialog'
import { DocContext, IDocStorage } from './StudioApp'
import { StudioUtils } from './StudioUtils'

export const StudioSidebar = (props: {
  template: string
  campaign?: ICampaign
  onSave?: (doc: IDocStorage) => void
  frameRef: MutableRefObject<Frame | null>
}) => {
  const [currentTemplate, setCurrentTemplate] = useState(props.template)

  useEffect(() => {
    setCurrentTemplate(props.template)
  }, [props.template])

  if (currentTemplate === 'campaign' && props.campaign)
    return (
      <CampaignSidebar campaign={props.campaign} frameRef={props.frameRef} onSave={props.onSave} />
    )
  return <StandardSidebar />
}

const StandardSidebar = () => {
  const { doc, setDoc } = useContext(DocContext)
  const [modalShow, setModalShow] = useState(false)
  const [showLogo, setShowLogo] = useState(doc?.displayOrgLogo)

  useEffect(() => {
    setDoc({ ...doc, displayOrgLogo: showLogo })
  }, [showLogo])
  return (
    <div className='editor-controls'>
      <div className='main-controls'>
        <Button variant='secondary' className='mb-3' onClick={() => setModalShow(true)}>
          Velg bilde
        </Button>
        <Form.Group className='mb-3' controlId='form.text1'>
          <Form.Check // prettier-ignore
            type='switch'
            id='club-switch'
            label='Vis organisasjonslogo'
            checked={showLogo}
            className='mb-3'
            onChange={(e) => {
              setShowLogo(e.target.checked)
            }}
          />
          <Form.Label>Støtte logo</Form.Label>
          <div key='inline-radio' className='mb-3'>
            <Form.Check
              inline
              label='Støtte'
              type='radio'
              id='inline-radio-1'
              checked={doc?.logoType === 'støtte'}
              onChange={() => {
                setDoc({ ...doc, logoType: 'støtte' })
              }}
            />
            <Form.Check
              inline
              label='Støtte/NIF'
              type='radio'
              id='inline-radio-2'
              checked={doc?.logoType === 'støtte/nif'}
              onChange={() => {
                setDoc({ ...doc, logoType: 'støtte/nif' })
              }}
            />
          </div>
          <Form.Label>Tekst felt 1</Form.Label>
          <Form.Control
            value={doc?.topHeader}
            className='mb-3'
            onChange={(e) => {
              setDoc({ ...doc, topHeader: e.target.value })
            }}
          />
          <Form.Label>Tekst felt 2</Form.Label>
          <Form.Control
            value={doc?.bottomHeader}
            className='mb-3'
            onChange={(e) => {
              setDoc({ ...doc, bottomHeader: e.target.value })
            }}
          />
        </Form.Group>
      </div>
      <ButtonGroup className='top-controls'>
        <Button
          variant='light'
          className='export'
          onClick={() => {
            const canvas = doc.getCanvas?.()
            if (canvas) {
              const link = document.getElementById('link')
              if (!link) {
                return
              }
              link.setAttribute('download', `${doc.topHeader}.png`)
              link.setAttribute(
                'href',
                canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
              )
              link.click()
            }
          }}
        >
          Last ned
        </Button>
      </ButtonGroup>
      <SelectImageDialog
        show={modalShow}
        onHide={() => setModalShow(false)}
        setImage={(id) => {
          setDoc({ ...doc, image: id })
        }}
      />
    </div>
  )
}
const CampaignSidebar = (props: {
  campaign: ICampaign
  frameRef: MutableRefObject<Frame | null>
  onSave?: (doc: IDocStorage) => void
}) => {
  const { doc, setDoc } = useContext(DocContext)
  const [usePrintVersion] = useState(doc?.printVersion)

  const { setLoading } = useContext(LoadingContext)
  const [showSendDialog, setShowSendDialog] = useState(false)

  const getHeader = () => {
    return doc?.topHeader
  }
  const getSubHeader = () => {
    return doc?.bottomHeader
  }

  useEffect(() => {
    setDoc({ ...doc, printVersion: usePrintVersion })
  }, [usePrintVersion])
  return (
    <div className='editor-controls'>
      <div className='main-controls'>
        <Form.Group className='mb-3' controlId='form.text1'>
          <Form.Label>Overskrift</Form.Label>
          <Form.Control
            value={doc?.topHeader}
            className='mb-3'
            onChange={(e) => {
              setDoc({ ...doc, topHeader: e.target.value })
            }}
          />
          <Form.Label>Formål</Form.Label>
          <Form.Control
            value={doc?.bottomHeader}
            className='mb-3'
            onChange={(e) => {
              setDoc({ ...doc, bottomHeader: e.target.value })
            }}
          />
        </Form.Group>
      </div>
      <ButtonGroup className='bottom-controls mb-3'>
        <Button
          variant='light'
          className='export'
          onClick={async () => {
            // eslint-disable-next-line no-unused-expressions
            props.onSave && props.onSave(doc)
          }}
        >
          Lagre
        </Button>
      </ButtonGroup>

      <a id='link' style={{ display: 'none' }} />
      <SendCampaignLinkModal
        show={showSendDialog}
        recruiters={props.campaign?.recruiters.filter((r) => !!r.email)}
        onHide={async (selectedSendRecruiters) => {
          if (selectedSendRecruiters.length > 0) {
            await handlePostRequest(`/organisation_user/send-campaign-email`, {
              header: StudioUtils.encodeText(getHeader()),
              subheader: StudioUtils.encodeText(getSubHeader()),
              campaignId: props.campaign._id,
              recruiter_short_user_ids: selectedSendRecruiters.map((r) => r.short_user_id),
            })(setLoading)
            setLoading(false)
          }
          setShowSendDialog(false)
        }}
      />
    </div>
  )
}
