/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Sentry from '@sentry/react'
import clsx from 'clsx'
import { Field, Form, Formik } from 'formik'
import { FC, useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import LoadingContext from '../../../../_metronic/layout/core/Loading'
import { useAuth } from '../../../modules/auth'
import { handleGetRequest, handlePutRequest } from '../../../services'
import { IOrganizationDetails } from '../DashboardPage'

interface VippsModalProps {
  show: boolean
  handleClose?: () => void
  prefillID?: string
  orgDetails?: IOrganizationDetails | null
}

interface IBankAccount {
  orgId: number
  orgName: string
  orgTypeId: number
  bankAccount: string
}

interface IInitialVippsFormValues {
  account_no: string
  address: string
  po_number: string
  phone_no: string
}

const VippsModal: FC<VippsModalProps> = ({ show, handleClose, orgDetails, prefillID }) => {
  const { setLoading } = useContext(LoadingContext)
  const [return_url, setReturn_url] = useState('')
  const intl = useIntl()
  const [initialFormValues, setInitialFormValues] = useState<IInitialVippsFormValues | undefined>()
  const { currentUser, setCurrentUser, saveAuth, saveCurrentUser } = useAuth()
  const validationSchema = Yup.object({
    account_no: Yup.string().required('Required').min(11).max(11),
    address: Yup.string().required('Required'),
    po_number: Yup.string().required('Required'),
    phone_no: Yup.string().required('Required'),
  })

  useEffect(() => {
    const getOrgBankAccounts = async () => {
      // Org details can be undefined if the user enters the dialog via the tour
      if (!orgDetails) {
        const orgDetailsResponse = await handleGetRequest(
          `/organisation/${currentUser?.user?.organisation_id?._id}`
        )(setLoading)
        orgDetails = orgDetailsResponse.data
      }
      try {
        const { data } = await handleGetRequest(
          `/organisation/bank_accounts/${orgDetails?.org_id}`
        )(setLoading)
        // Get the main bank account, ie top level org type
        const mainBankAccount = data?.find((acc: IBankAccount) => acc.orgTypeId === 5)
        setInitialFormValues({
          account_no: mainBankAccount?.bankAccount || '',
          address: '',
          po_number: `${orgDetails?.post_code || ''} ${orgDetails?.city || ''}`,
          phone_no: '',
        })
      } catch (error) {
        setInitialFormValues({
          account_no: '',
          address: '',
          po_number: '',
          phone_no: '',
        })
      }
    }
    if (initialFormValues === undefined && show) getOrgBankAccounts()
  }, [setLoading, show])

  const onSubmit = async (values: any) => {
    const body = {
      account_no: values?.account_no,
      address: `${values?.address || ''} ${values?.po_number || ''}`,
      phone_no: values?.phone_no,
    }
    let data
    try {
      const dataFromMsnOrder = await handlePutRequest(
        `/organisation/msn_order/${currentUser?.user?.organisation_id?._id}`,
        body
      )(setLoading)
      if (dataFromMsnOrder && dataFromMsnOrder.success) data = dataFromMsnOrder.data
      else throw dataFromMsnOrder
    } catch (error) {
      Sentry.captureException(error, { tags: { page: 'VipsModal', function: 'onSubmit' } })
    }
    if (data) {
      setTimeout(() => {
        // window.location.reload()
      }, 3000)
    } else {
      toast.error(intl.formatMessage({ id: 'vips.onboarding.something.went.wrong' }))
    }
    if (currentUser?.user?.organisation_id) {
      currentUser.user.organisation_id.msn_status = 'in-progress'
      setCurrentUser(currentUser)
      saveAuth(currentUser)
      localStorage.setItem('currentUser', JSON.stringify(currentUser))
      saveCurrentUser(currentUser)
    }
    window.open(data.return_url, '_blank')
    setReturn_url(data.return_url)
  }

  const vippsPopupBlocked = (
    <div>
      <div>{intl.formatMessage({ id: 'vipps.Popup.Blocked' })}</div>
      <a href={return_url} target='_blank' rel='noreferrer'>
        {return_url}
      </a>
    </div>
  )
  const vippsApplicationSent = (
    <div>
      <div>{intl.formatMessage({ id: 'vipps.sent.application' })}</div>
      <a
        href={`https://portal.vipps.no/register/prefilled/${prefillID}`}
        target='_blank'
        rel='noreferrer noopener'
      >
        {`https://portal.vipps.no/register/prefilled/${prefillID}`}
      </a>
    </div>
  )
  return (
    <Modal
      onHide={handleClose}
      keyboard={false}
      className='modal fade customzindexvipps'
      id='kt_modal_select_location'
      tabIndex={-1}
      role='dialog'
      show={show}
      size='lg'
      aria-hidden='true'
    >
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'start.vipps.onboarding' })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {return_url !== '' || !!prefillID ? (
          return_url !== '' ? (
            vippsPopupBlocked
          ) : (
            vippsApplicationSent
          )
        ) : (
          <div className='row text-gray-600' id='vipps-right'>
            <div className='col-md-12'>
              <div className='vips-desc h-100'>
                <p className='mt-4'>
                  {intl.formatMessage({ id: 'start.vipps.onboarding.body.text1' })}
                </p>
                <p className='text-info'>
                  {intl.formatMessage({ id: 'start.vipps.onboarding.body.text2' })}
                </p>
              </div>
            </div>

            <div className='col-md-12'>
              <div className=''>
                <div className='d-flex align-items-center mt-8'>
                  <span className='vips-bullet'>1</span>
                  <span className='vips-bullet-text mx-2'>
                    {intl.formatMessage({ id: 'start.vipps.onboarding.body.text3' })}
                  </span>
                </div>
                <div className='d-flex align-items-center mt-8'>
                  <span className='vips-bullet'>2</span>
                  <span className='vips-bullet-text mx-2'>
                    {intl.formatMessage({ id: 'start.vipps.onboarding.body.text4' })}
                  </span>
                </div>

                <div className='d-flex align-items-center mt-8 mb-8'>
                  <span className='vips-bullet'>3</span>
                  <span className='vips-bullet-text mx-2'>
                    {intl.formatMessage({ id: 'start.vipps.onboarding.body.text5' })}
                  </span>
                </div>

                <div className='d-flex align-items-center mt-4 mb-8'>
                  <span className='vips-bullet'>4</span>
                  <span className='vips-bullet-text mx-2'>
                    {intl.formatMessage({ id: 'start.vipps.onboarding.body.text6' })}
                  </span>
                </div>

                <div className='d-flex align-items-center mt-8 mb-8'>
                  <span className='vips-bullet'>5</span>
                  <span className='vips-bullet-text mx-2'>
                    {intl.formatMessage({ id: 'start.vipps.onboarding.body.text7' })}

                    <br />
                    {intl.formatMessage({ id: 'start.vipps.onboarding.body.text8' })}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        <hr />
        {return_url === '' && initialFormValues && !prefillID && (
          <Formik
            validationSchema={validationSchema}
            initialValues={initialFormValues}
            onSubmit={onSubmit}
          >
            {({ values, errors }) => (
              <Form>
                <div className='row mt-8'>
                  <div className='col-md-4 d-flex align-items-center'>
                    <label className='col-form-label  fw-bold fs-6'>
                      {intl.formatMessage({ id: 'account.no.payment' })}
                    </label>
                  </div>
                  <div className='col-md-8'>
                    <Field
                      name='account_no'
                      type='text'
                      className={clsx('form-control form-control-solid', {
                        'is-invalid': values.account_no && errors.account_no,
                      })}
                      placeholder={intl.formatMessage({ id: 'account.no.payment' })}
                    />
                    {/* <div className='text-danger'>
                      <ErrorMessage name='account_no' />
                    </div> */}
                  </div>
                </div>
                {/* Adresse */}
                <div className='row mt-4'>
                  <div className='col-md-4 d-flex align-items-center'>
                    <label className='col-form-label  fw-bold fs-6'>
                      {intl.formatMessage({ id: 'address.for.club' })}
                    </label>
                  </div>
                  <div className='col-md-8'>
                    <Field
                      name='address'
                      type='text'
                      className={clsx('form-control form-control-solid', {
                        'is-invalid': values.address && errors.address,
                      })}
                      placeholder={intl.formatMessage({ id: 'address.for.club' })}
                    />
                    {/* <div className='text-danger'>
                      <ErrorMessage name='address' />
                    </div> */}
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col-md-4 d-flex align-items-center'>
                    <label className='col-form-label  fw-bold fs-6'>
                      {intl.formatMessage({ id: 'Postal.code.and.postal.address' })}
                    </label>
                  </div>
                  <div className='col-md-8'>
                    <Field
                      name='po_number'
                      type='text'
                      className={clsx('form-control form-control-solid', {
                        'is-invalid': values.po_number && errors.po_number,
                      })}
                      placeholder={intl.formatMessage({ id: 'Postal.code.and.postal.address' })}
                    />
                  </div>
                </div>
                {/* Tlf nr til kontaktperson */}
                <div className='row mt-4'>
                  <div className='col-md-4 d-flex align-items-center'>
                    <label className='col-form-label  fw-bold fs-6'>
                      {intl.formatMessage({ id: 'Telephone.number.of.contact.person' })}
                    </label>
                  </div>
                  <div className='col-md-8'>
                    <Field
                      name='phone_no'
                      type='text'
                      className={clsx('form-control form-control-solid', {
                        'is-invalid': values.phone_no && errors.phone_no,
                      })}
                      placeholder={intl.formatMessage({ id: 'Telephone.number.of.contact.person' })}
                    />
                    {/* <div className='text-danger'>
  
                      <ErrorMessage name='phone_no' />
                    </div> */}
                  </div>
                </div>
                <div className='row '>
                  <div className='col-md-12 mt-4'>
                    <div className=' d-flex justify-content-between mt-2'>
                      <button
                        form='my-form'
                        type='button'
                        className='btn btn-light'
                        onClick={handleClose}
                      >
                        {intl.formatMessage({ id: 'Cancel' })}
                      </button>
                      <button type='submit' className='btn btn-sm btn-orange mx-2 btn-orange-right'>
                        {intl.formatMessage({ id: 'Start.Vipps.connection' })}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
        {initialFormValues === undefined && (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>
                {intl.formatMessage({ id: 'Loading.bank.account' })}
              </span>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default VippsModal
