/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useMainContext } from '../../../context'

import { MixedWidget13 } from '../../../_metronic/partials/widgets'
import { useAuth } from '../../modules/auth'

import LoadingContext from '../../../_metronic/layout/core/Loading'
import { handleGetRequest } from '../../services'
// import { CPTable1 } from './CPTable1'
// import { UserInfoDiscloseTable } from './UserInfoDiscloseTable'
import { SupportPlayersTable } from './SupportPlayersTable'

const SupportPlayerMainPage: FC = () => {
  const { companyofPortal } = useMainContext()
  const { currentUser } = useAuth()
  const intl = useIntl()
  const { setLoading, loading } = useContext(LoadingContext)
  const [statsData, setStatsData] = useState<any>([])
  const [tableLoaded, setTableLoaded] = useState(false)

  useEffect(() => {
    const getStatsData = async () => {
      // eslint-disable-next-line no-extra-boolean-cast
      const sportId = !!companyofPortal?.company_id
        ? companyofPortal?.company_id
        : currentUser?.user?.sports_list?.map((item: any) => item._id)
      const params = {
        organisationId: currentUser?.user?.organisation_id?._id,
        sportId,
      }
      const { data } = await handleGetRequest(`/organisation/sport_stats`, { params })(setLoading)
      if (data) {
        setStatsData(data)
      }
      if (!tableLoaded) {
        setLoading(true)
      }
    }
    getStatsData()
  }, [currentUser?.user?.organisation_id?._id, companyofPortal?.company_id])

  useEffect(() => {
    if (tableLoaded && !!statsData) {
      setLoading(false)
    }
  }, [tableLoaded, statsData?.length, loading])

  return (
    <>
      <div className='row'>
        {/* begin::Col */}

        <div className='col-xl-4 padding-reduce'>
          <MixedWidget13
            className='card-xl-stretch mb-xl-4 card_borderC card-mobile'
            backGroundColor='#ffff'
            chartHeight='100px'
            hideChart
            title={intl.formatMessage({ id: 'amount.supporters' })}
            description={intl.formatMessage({ id: 'amount.supporters' })}
            numbertext={statsData?.total_unique_active_supporters}
          />
        </div>

        <div className='col-xl-4 padding-reduce'>
          <MixedWidget13
            className='card-xl-stretch mb-xl-4 card_borderC card-mobile'
            backGroundColor='#ffff'
            chartHeight='100px'
            hideChart
            title={intl.formatMessage({ id: 'total.support.received' })}
            description={intl.formatMessage({ id: 'total.support.received' })}
            numbertext={statsData?.total_received_supports}
          />
        </div>
        <div className='col-xl-4 padding-reduce'>
          <MixedWidget13
            className='card-xl-stretch mb-xl-4 card_borderC card-mobile'
            backGroundColor='#ffff'
            chartHeight='100px'
            hideChart
            description={intl.formatMessage({ id: 'outstanding.support' })}
            title={intl.formatMessage({ id: 'outstanding.support' })}
            numbertext={statsData?.total_pending_supports}
          />
        </div>
      </div>
      <SupportPlayersTable className='mb-5 mb-xl-8' setTableLoaded={setTableLoaded} />
      {/* The below commented lines should be deleted after the above component is tested and working
      fine. */}
      {/* <CPTable1 className='mb-5 mb-xl-8' /> */}
      {/* <UserInfoDiscloseTable className='mb-5 mb-xl-8' /> */}
    </>
  )
}

export { SupportPlayerMainPage as SupportPlayerMiansp }
