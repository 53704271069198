import { FC } from 'react'

const CampaignInfo: FC = () => {
  return (
    <div className='card campaign-info mb-3'>
      <div className='text-section'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column' style={{ maxWidth: '70%' }}>
            <span className='card-label fw-bold fs-3 mb-1'>
              Viktig info før du oppretter en kampanje!
            </span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <p>
            Med en intern kampanje kan dere booste antall støttespillere til din organisasjon. Før
            du oppretter kampanjen anbefaler vi at du tenker gjennom følgende:
          </p>

          <div className='d-flex mt-8 mb-8'>
            <span className='vips-bullet'>1</span>
            <span className=' mx-4'>
              <b>Sett et mål</b> – Bestem hvor mange støttespillere kampanjen skal generere, og
              planlegg kampanjeperioden.
            </span>
          </div>
          <div className='d-flex mt-8 mb-8'>
            <span className='vips-bullet'>2</span>
            <span className=' mx-4'>
              <b>Velg kampanjemodell</b> - Skal deltakerne konkurrere individuelt (alle mot alle), i
              lag (lag mot lag), eller jobbe sammen for å nå et felles mål?
            </span>
          </div>
          <div className='d-flex mt-8 mb-8'>
            <span className='vips-bullet'>3</span>
            <span className=' mx-4'>
              <b>Premie</b> – Organisasjonen bestemmer premien. Skal vinneren være den som vervet
              flest (alle mot alle) med for eksempel en iPad som premie, eller laget med flest
              vervinger (lag mot lag) som kan få en pizzakveld? Skal kun vinneren(e) få premie,
              eller får alle noe hvis målet nås? Husk å gjøre premien synlig for å skape
              engasjement.
            </span>
          </div>
          <div className='d-flex mt-8 mb-8'>
            <span className='vips-bullet'>4</span>
            <span className=' mx-4'>
              <b>Forbered deltakerlisten</b> -
              <a
                href='https://s3.eu-north-1.amazonaws.com/org.stotte.no/assets/Mal+kampanje.xlsx'
                download
              >
                Last ned denne excelfilen{' '}
              </a>
              og fyll ut excelfilen med navnene på deltakerne (enten individuelle navn eller
              lag/årskull). Eksempelvis kan en deltaker være “Ola Nordmann” eller “Gutter født
              2015”. E-postadressene kan tilhøre deltakerne direkte eller en lagleder som
              distribuerer informasjon videre.
            </span>
          </div>
          <div className='d-flex mt-8 mb-8'>
            <span className='vips-bullet'>5</span>
            <span className=' mx-4'>
              <b>Opprett kampanjen</b> – Når du er klar, trykker du på Opprett kampanje. Følg
              veiledningen for å lage flyers med individuelle QR-koder/lenker slik at støttespillere
              kan knyttes til riktig deltaker. Flyersene sendes automatisk til e-postadressene i
              excelfilen.
            </span>
          </div>
          <div className='d-flex mt-8 mb-8'>
            <span className='vips-bullet'>6</span>
            <span className=' mx-4'>
              <b>Følg utviklingen</b> - Klikk på kampanjenavnet for å følge fremgangen. Bruk denne
              informasjonen til å motivere deltakerne underveis.
            </span>
          </div>
        </div>
      </div>
      <div className='campaign-image'>
        <img src='/media/campaign-preview.png' alt='Campaign info' />
      </div>
    </div>
  )
}

export default CampaignInfo
