import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { handleGetRequest } from '../../../services'

const RedirectPage: React.FC = () => {
  const { short_url } = useParams()
  const redirect = async (surl: string) => {
    const data = await handleGetRequest(`/shorturl/redirect/${surl}`)(() => {})
    if (data.data) {
      window.location.href = data.data
    }
  }

  useEffect(() => {
    if (short_url) redirect(short_url)
  }, [short_url])

  return <div />
}

export default RedirectPage
