import { Circle, Label, Rectangle } from 'zimjs'
import { AbstractContainer } from './AbstractContainer'

interface ITextConfig {
  color: string
  text: string
  size: number
  font: 'Dosis' | 'Roboto'
  y?: number
  label: string
}
export class RoundedRectangleWithText extends AbstractContainer {
  private rectangle: Rectangle

  private circle: Circle

  private text: Label | undefined

  private textConfig: ITextConfig | undefined

  private textStart = 30

  constructor(width: number, height: number, color: string, textConfig?: ITextConfig) {
    super(width, height)
    this.textConfig = textConfig
    const radius = height / 2
    this.rectangle = new Rectangle(this.width - radius, this.height, color).addTo(this)
    this.circle = new Circle(radius, color).addTo(this)
    this.circle.pos(this.rectangle.width - radius, 0)
  }

  private updateTextLayout(textConfig: ITextConfig) {
    const textStart = 30
    this.text!.center()
    if (textConfig.y) this.text!.pos(textStart, textConfig.y)
    else this.text!.x = textStart
    this.updateLayout()
  }

  private updateLayout() {
    const radius = this.height / 2

    this.rectangle.siz(
      this.text?.width ? this.text.width + this.textStart : this.width - radius,
      this.height
    )
    this.circle.pos(this.rectangle.width - this.circle.radius, 0)
  }

  setText(text: string) {
    if (this.textConfig) {
      if (this.text) this.text.text = text
      else
        this.text = new Label({
          text,
          color: this.textConfig.color,
          font: this.textConfig.font,
          align: 'left',
          valign: 'center',
          padding: 0,
        }).addTo(this)

      this.updateTextLayout(this.textConfig)
    }
  }
}
