/* eslint-disable @typescript-eslint/no-unused-vars */
// import clsx from 'clsx'
// import {Link} from 'react-router-dom'
// import {useLayout} from '../../../core/LayoutProvider'
// import {usePageData} from '../../../core/PageData'

import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../../../../app/modules/auth'
import { useMainContext } from '../../../../../context'
import { toAbsoluteUrl } from '../../../../helpers'
function DefaultTitle() {
  const { brtitle, brpath, brential, companyofPortal, organisationLogo } = useMainContext()
  const location = useLocation()
  const { currentUser } = useAuth()
  const intl = useIntl()
  const getInitials = function (string: any) {
    if (string) {
      const names = string?.split(' ')
      let initials = names[0]?.substring(0, 1)?.toUpperCase()
      if (names.length > 1) {
        initials += names && names[names.length - 1]?.substring(0, 1).toUpperCase()
      }
      return initials
    }
    return ''
  }
  const getShortName = (name: any) => {
    if (name?.length > 20) {
      return `${name?.substring(0, 18)}...`
    }
    return name
  }
  let breadcrumbsValue = ''
  let division: any
  if (localStorage.getItem('division') !== null) {
    division = localStorage.getItem('division')?.toString()
  } else {
    division = getShortName(companyofPortal?.name)
  }

  if (location.pathname.includes('/dashboard')) {
    breadcrumbsValue = `${currentUser?.user?.organisation_id?.org_name} -  ${intl.formatMessage({ id: 'Overview' })}`
  }
  if (location.pathname.includes('/home')) {
    breadcrumbsValue = `${currentUser?.user?.organisation_id?.org_name} - ${division} -${intl.formatMessage({ id: 'Overview' })}`
  }
  if (location.pathname.includes('/org/goals/')) {
    breadcrumbsValue = `${currentUser?.user?.organisation_id?.org_name} - ${division} - ${intl.formatMessage({ id: 'Purpose' })}`
  }
  if (location.pathname.includes('/formalSingle')) {
    breadcrumbsValue = `${currentUser?.user?.organisation_id?.org_name} - ${division} - ${intl.formatMessage({ id: 'Purpose' })}`
  }
  if (location.pathname.includes('/campaignSingle')) {
    breadcrumbsValue = `${currentUser?.user?.organisation_id?.org_name} -${intl.formatMessage({ id: 'campaign' })}`
  }
  if (location.pathname.includes('/formalEdit')) {
    breadcrumbsValue = `${currentUser?.user?.organisation_id?.org_name} - ${division} - ${intl.formatMessage({ id: 'Purpose' })}`
  }

  if (location.pathname.includes('/formalAdd')) {
    breadcrumbsValue = `${currentUser?.user?.organisation_id?.org_name} - ${division} - ${intl.formatMessage({ id: 'Purpose' })}`
  }
  if (location.pathname.includes('/org/support-player')) {
    breadcrumbsValue = `${currentUser?.user?.organisation_id?.org_name} - ${division} - ${intl.formatMessage({ id: 'support.players.small' })}`
  }
  if (location.pathname.includes('/marketing')) {
    breadcrumbsValue = `${currentUser?.user?.organisation_id?.org_name} - ${division} - ${intl.formatMessage({ id: 'Marketing' })} `
  }
  if (location.pathname.includes('/marketing/videos')) {
    breadcrumbsValue = `${currentUser?.user?.organisation_id?.org_name} - ${division} - ${intl.formatMessage({ id: 'Marketing' })} - ${intl.formatMessage({ id: 'goal.videos' })}`
  }
  if (location.pathname.includes('/marketing/branding')) {
    breadcrumbsValue = `${currentUser?.user?.organisation_id?.org_name} - ${division} - ${intl.formatMessage({ id: 'Marketing' })} - ${intl.formatMessage({ id: 'Støtte.branding' })}`
  }
  if (location.pathname.includes('/marketing/studio')) {
    breadcrumbsValue = `${currentUser?.user?.organisation_id?.org_name} - ${division} - Markedsføring - Lag profilmateriell`
  }
  if (location.pathname.includes('/org/settings')) {
    breadcrumbsValue = `${currentUser?.user?.organisation_id?.org_name} - ${intl.formatMessage({ id: 'Org.settings' })}`
  }
  if (location.pathname.includes('/org/users')) {
    breadcrumbsValue = `${currentUser?.user?.organisation_id?.org_name} - ${intl.formatMessage({ id: 'USER' })}`
  }
  if (location.pathname.includes('/org/reporting')) {
    breadcrumbsValue = `${currentUser?.user?.organisation_id?.org_name} - ${intl.formatMessage({ id: 'reporting' })}`
  }
  if (location.pathname.includes('org/campaign')) {
    breadcrumbsValue = `${currentUser?.user?.organisation_id?.org_name} - ${intl.formatMessage({ id: 'campaign' })}`
  }
  if (location.pathname.includes('/usersettting')) {
    breadcrumbsValue = `${currentUser?.user?.organisation_id?.org_name} - ${intl.formatMessage({ id: 'account.settings' })}`
  }

  useEffect(() => {
    if (breadcrumbsValue) {
      document.title = `Støtte AS - ${breadcrumbsValue}`
    }
  }, [breadcrumbsValue])
  return (
    <div
      // data-kt-swapper='true'
      // data-kt-swapper-mode='prepend'
      // data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
      className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2 pb-5 pb-lg-0'
    >
      <div className='d-flex align-items-center'>
        {currentUser?.user?.user_type == 'manager' ? (
          <div className='img-wrapper org-details    me-2'>
            <img
              src={
                currentUser?.user?.organisation_id?.logo ||
                toAbsoluteUrl('/media/misc/logo-missing.jpeg')
              }
              className='card_borderC '
              alt='org logo'
              style={{ maxWidth: 64, padding: 3, objectFit: 'contain' }}
            />
          </div>
        ) : (
          <div className='symbol symbol-circle symbol-40px me-2 '>
            {organisationLogo ? (
              <img
                className='card'
                style={{
                  borderRadius: '8px',
                  height: '45px',
                  width: '45px',
                  marginLeft: '1px',
                  marginTop: '-2px',
                }}
                alt='Logo'
                src={organisationLogo}
              />
            ) : (
              <div className='symbol-label fs-6 fw-bold bg-light-orangebck text-white'>
                {location.pathname === '/dashboard'
                  ? getInitials(currentUser?.user?.organisation_id?.org_name)
                  : getInitials(division)}
              </div>
            )}
          </div>
        )}

        <div className='d-flex justify-content-start flex-column'>
          <div
            className={`text-dark fw-bolder text-hover-primary  ${currentUser?.user?.user_type == 'manager' ? 'fs-2' : 'fs-6'}  text-capitalize`}
          >
            {brtitle || `${currentUser?.user?.organisation_id?.org_name}`}
          </div>
          <span className='text-muted fw-bold text-muted d-block fs-7 text-capitalize'>
            {breadcrumbsValue}
            {/* {brpath ? brpath : 'Selected - Comapny'} */}
          </span>
        </div>
      </div>

      {/* begin::Heading */}
      {/* {pageTitle && ( */}
      {/* <h1 className='d-flex flex-column text-dark fw-bolder my-0 fs-1'> */}
      {/* {pageTitle} */}
      {/* Hei Saif! */}
      {/* {pageDescription && (
            <small className='text-muted fs-6 fw-bold ms-1 pt-1'>{pageDescription}</small>
          )} */}
      {/* </h1> */}
      {/* )} */}
      {/* end::Heading */}

      {/* {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <ul className='breadcrumb breadcrumb-dot fw-bold fs-base my-1'>
            {Array.from(pageBreadcrumbs)
              .filter((b) => !b.isSeparator)
              .map((item, index) => (
                <li
                  className={clsx('breadcrumb-item', {
                    'text-dark': !item.isSeparator && item.isActive,
                    'text-muted': !item.isSeparator && !item.isActive,
                  })}
                  key={`${item.path}${index}`}
                >
                  <Link className='text-muted' to={item.path}>
                    {item.title}
                  </Link>
                </li>
              ))}
            <li className='breadcrumb-item text-dark'>{pageTitle}</li>
          </ul>
        )} */}
    </div>
  )
}

export { DefaultTitle }

// // import clsx from 'clsx'
// // import {Link} from 'react-router-dom'
// // import {useLayout} from '../../../core/LayoutProvider'

// import { useAuthContext } from "../../../../../context"

// // import {usePageData} from '../../../core/PageData'

// const DefaultTitle = () => {
//   const {currentUser} = useAuthContext()
//   // const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
//   // const {config} = useLayout()
//   return (
//     <div
//       data-kt-swapper='true'
//       data-kt-swapper-mode='prepend'
//       data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
//       className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2 pb-5 pb-lg-0'
//     >
//       {/* begin::Heading */}
//       {/* {pageTitle && ( */}
//         <h1 className='d-flex flex-column text-dark fw-bolder my-0 fs-1'>
//           {/* {pageTitle} */}
//           Hei  {currentUser ? currentUser.user?.first_name : ''}{' '}
//                 {currentUser ? currentUser?.user?.last_name : ''}!
//           {/* {pageDescription && (
//             <small className='text-muted fs-6 fw-bold ms-1 pt-1'>{pageDescription}</small>
//           )} */}
//         </h1>
//       {/* )} */}
//       {/* end::Heading */}

//       {/* {pageBreadcrumbs &&
//         pageBreadcrumbs.length > 0 &&
//         config.pageTitle &&
//         config.pageTitle.breadCrumbs && (
//           <ul className='breadcrumb breadcrumb-dot fw-bold fs-base my-1'>
//             {Array.from(pageBreadcrumbs)
//               .filter((b) => !b.isSeparator)
//               .map((item, index) => (
//                 <li
//                   className={clsx('breadcrumb-item', {
//                     'text-dark': !item.isSeparator && item.isActive,
//                     'text-muted': !item.isSeparator && !item.isActive,
//                   })}
//                   key={`${item.path}${index}`}
//                 >
//                   <Link className='text-muted' to={item.path}>
//                     {item.title}
//                   </Link>
//                 </li>
//               ))}
//             <li className='breadcrumb-item text-dark'>{pageTitle}</li>
//           </ul>
//         )} */}
//     </div>
//   )
// }

// export {DefaultTitle}
