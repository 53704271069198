import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const DashboardMarketingCell = () => {
  return (
    <div
      className='card card card-xl-stretchcard_borderC'
      style={{ backgroundColor: '#ffff', width: '100%', height: '100%', textAlign: 'center' }}
    >
      {/* begin::Body */}
      <div className='card-body d-flex flex-column align-items-center'>
        <span className='text-dark fs-2hx mb-3'>Spre budskapet</span>
        <span className='fs-3'>Vi har gjort det enkelt å skape engasjement!</span>
        <span className='fs-3 mb-3'>
          Trykke her for å se forslag og tips til hvordan du kan dele informasjon om Støtte til dine
          medlemmer.
        </span>
        <img
          src={toAbsoluteUrl('/media/misc/dashboard-marketing-cell.png')}
          style={{ objectFit: 'contain', width: '80%' }}
          alt='marketing cell'
        />
      </div>
    </div>
  )
}

export { DashboardMarketingCell }
