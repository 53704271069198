import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'
import '@formatjs/intl-relativetimeformat/polyfill'
import { IntlProvider } from 'react-intl'
import { useLang } from './Metronici18n'

import daMessages from './messages/da.json'
import enMessages from './messages/en.json'
import fiMessages from './messages/fi.json'
import noMessages from './messages/no.json'
import svMessages from './messages/sv.json'

const allMessages = {
  fi: fiMessages,
  en: enMessages,
  da: daMessages,
  no: noMessages,
  sv: svMessages,
}

const I18nProvider = ({ children }: any) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export { I18nProvider }
