/* eslint-disable prefer-const */
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import clsx from 'clsx'
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import { FaPen } from 'react-icons/fa'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useGetSingleOrg } from '../../../hooks/org/useGetSingleOrg'
import { useAuth } from '../../../modules/auth'
import { GalleryDrawer1 } from '../../Drawer/GalleryDrawerAddGoal'

interface GoalCreationModalProps {
  showModal: boolean
  editorData: string
  setEditorData: (editorData: string) => void
  setShowModal: (showModal: boolean) => void
  submitStep: (values: FormikValues, actions: any) => void
  initialValues: {
    title: string
    short_description: string
    target_amount: any
    start_date: any | null
    due_date: string | null
    status?: string
  }
}

const GoalCreationModal: React.FC<GoalCreationModalProps> = ({
  showModal,
  setShowModal,
  submitStep,
  initialValues,
}) => {
  const numberRegExp = /^[0-9]\d*$/
  const memberSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    short_description: Yup.string().required('Required'),
    target_amount: Yup.string().matches(
      numberRegExp,
      'Kun tall er tillatt i dette feltet. Bokstaver og andre symboler er ikke tillatt.'
    ),
    start_date: Yup.string().required('Required'),
    // add status to the schema if it exists
    // status: initialValues.status ? Yup.string().required('Required') : Yup.string(),
  })

  const [loading] = React.useState(false)
  const [banner, setBanner] = useState<string>('')

  const { currentUser } = useAuth()
  const { data: fetchData } = useGetSingleOrg(currentUser?.user?.organisation_id?._id)

  useEffect(() => {
    const handleStorageChange = () => {
      if (localStorage.getItem('banner')) {
        setBanner(localStorage.getItem('banner') || '')
      } else {
        setBanner('')
      }
    }
    window.addEventListener('storage', handleStorageChange)
    return () => {
      localStorage.removeItem('banner')
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size='xl'
        keyboard={false}
        dialogClassName='goalCreation'
      >
        <div className='modal-body'>
          <div
            className={`  card card-xl-stretch mb-xl-10 card_borderC
                
                `}
            style={{
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${toAbsoluteUrl(banner)})`,
              borderRadius: '8px',
            }}
          >
            <div
              className='position-absolute top-0 end-0 m-3
             p-3 rounded-circle
              border border-gray-300 cursor-pointer
              card
              '
              id='kt_gallery_toggle_2'
              style={{ zIndex: 1 }}
            >
              <FaPen size={15} />
            </div>
            {/* begin::Body */}
            <div className='p-5 d-flex flex-column'>
              {/* begin::Wrapper */}
              <div
                className='d-flex flex-column flex-grow-1'
                style={{ height: 100, minHeight: 100, width: '100%' }}
              />

              <div className='pt-5'>
                <div className=' mb-3 d-flex ' style={{ alignItems: 'center' }}>
                  <div className='card bg-white me-3 p-3'>
                    <img
                      src={toAbsoluteUrl('/media/logos/SlogoNew.jpg')}
                      alt='logo'
                      className=' h-60px w-60px logo svg-icon-cufs '
                      style={{ maxWidth: '60px' }}
                    />
                  </div>

                  <div className='card bg-white me-3 p-3' style={{ marginLeft: '0.45rem' }}>
                    <img
                      src={fetchData?.logo ? fetchData?.logo : fetchData?.org_logo_base64}
                      alt='logo'
                      className=' h-60px w-60px logo svg-icon-cufs '
                      style={{ maxWidth: '60px', objectFit: 'contain' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            // onSubmit={submitStep}
            enableReinitialize
            // encType='multipart/form-data'
            validationSchema={memberSchema}
            onSubmit={(values, actions) => {
              const reFactorValue = `'${values?.target_amount}'`
              values.target_amount = Number(
                reFactorValue?.replace(/[~`!@#$%^&*()+={}[\];:'"<>.,/\\?-_]/g, '')
              )
              if (banner) submitStep({ ...values, banner_image: banner }, actions.setFieldError)
            }}
          >
            {({ values, errors, touched }) => (
              <Form>
                <div className=''>
                  <div>
                    <h3 className='fw-bolder my-2'>Opprett nytt formål</h3>
                    <div
                      className=' fontsizechfslogtime d-flex align-items-center text-gray-600  me-5 '
                      style={{ color: '#7E8299 !important' }}
                    >
                      Bruk skjemaet under til å opprette et nytt formål. Gi formålet en tittel og
                      oppgi totalbeløpet for ønsket støtte. Lag en god beskrivelse av formålet, slik
                      at støttespillere forstår hva støtten går til.
                    </div>
                  </div>
                  <div className='row mb-3 mt-7'>
                    {/* <div className='col-lg-12'> */}
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Formål tittel</label>
                    {/* <label className='col-form-label required fw-bold fs-6'>Formål tittel</label> */}
                    <div className='col-lg-9 fv-row'>
                      <Field
                        style={{ borderRadius: '8px' }}
                        type='text'
                        name='title'
                        className={clsx(
                          'form-control form-control-lg form-control-solid inputClass',
                          {
                            'is-invalid': touched.title && errors.title,
                          }
                        )}
                        placeholder='Angi tittel på formåle'
                      />
                      {/* <div className='text-danger'>
                      <ErrorMessage name={`title`} />
                    </div> */}
                    </div>

                    {/* </div> */}
                  </div>

                  <div className='row mb-3 mt-7'>
                    {/* <div className='col-lg-12'> */}
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Innsamlingsmål</label>
                    {/* <label className='col-form-label required fw-bold fs-6'>Formål tittel</label> */}
                    <div className='col-lg-9 fv-row' id='ck-edit'>
                      <Field
                        style={{ borderRadius: '8px' }}
                        type='text'
                        name='target_amount'
                        className={clsx(
                          'form-control form-control-lg form-control-solid inputClass',
                          {
                            'is-invalid': touched.target_amount && errors.target_amount,
                          }
                        )}
                        placeholder='Skriv inn beløpet du ønsker å oppnå'
                      />
                      <div className='text-danger'>
                        <ErrorMessage name='target_amount' />
                      </div>
                    </div>

                    {/* </div> */}
                  </div>
                  {/* <div className='row mb-3'>
                  <div className='col-lg-12'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Innsamlingsmål</label>

                    <Field
                      type='number'
                      name={`target_amount`}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Skriv inn beløpet du ønsker å oppnå'
                    />
                    <div className='text-danger'>
                      <ErrorMessage name={`target_amount`} />
                    </div>
                  </div>
                </div> */}
                  <div className='row mb-3 mt-7'>
                    {/* <div className='col-lg-12'> */}
                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Beskrivelse</label>
                    {/* <label className='col-form-label required fw-bold fs-6'>Formål tittel</label> */}
                    <div className='col-lg-9 fv-row'>
                      <CKEditor
                        editor={ClassicEditor}
                        data={initialValues.short_description}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          // initialValues.short_description = data;
                          // setDescription(initialValues.short_description);
                          values.short_description = data
                        }}
                        config={{
                          toolbar: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'blockQuote',
                            'numberedList',
                            'bulletedList',
                            '|',
                            'undo',
                            'redo',
                          ],
                        }}
                      />
                      {/* <MyTextArea
                    style={{borderRadius: '8px', borderColor: '#C6E0EC', paddingLeft: '17px', paddingTop: '7px', width: '100%'}}
                    className={clsx(
                      'form-control inputClass',
                      {
                        'is-invalid': touched.short_description && errors.short_description,
                      }
                    )}
                    name='short_description'
                    rows='10'
                    placeholder='Skriv en beskrivelse av formålet'
                  /> */}
                    </div>

                    {/* </div> */}
                  </div>
                  {'status' in initialValues && (
                    <div className='row mb-3'>
                      <label className='col-lg-3 col-form-label fw-bold fs-6'>Formål status</label>
                      <div className='col-lg-9 fv-row'>
                        <Field
                          style={{ borderRadius: '8px', marginLeft: '-5px' }}
                          name='status'
                          className={clsx('form-select form-select-solid form-select-lg fw-bold', {
                            // 'is-invalid': formik.touched.password && formik.errors.password,
                          })}
                          as='select'
                        >
                          {/* "active", "completed", "paused", "canceled" */}
                          <option value='active'>Aktiv</option>
                          <option value='completed'>Fullført</option>
                          <option value='paused'>Pauset</option>
                          <option value='canceled'>Avbrutt</option>
                        </Field>
                        {/* <div className='text-danger'>
                        <ErrorMessage name={`status`} />
                      </div> */}
                      </div>
                    </div>
                  )}
                </div>

                {/* <div className='row mb-3'>
                <div className='col-lg-12'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                    Beskrivelse
                  </label>

                  <MyTextArea
                    name='short_description'
                    rows='2'
                    placeholder='Skriv en beskrivelse av formålet'
                  />
                </div>
              </div> */}
                {/* <div className='modal-footer'> */}
                <div className='row' style={{ marginBottom: '15px' }}>
                  <div className='col-md-3' />
                  <div className='col-md-4'>
                    <button
                      type='button'
                      className='btn btn-light me-3'
                      style={{ color: '#3F4254', marginLeft: '-4px' }}
                      onClick={() => setShowModal(false)}
                    >
                      Avbryt
                    </button>
                  </div>
                  {initialValues?.title !== '' ? (
                    <div className='col-md-5'>
                      <button
                        type='submit'
                        className='btn btn-lg btn-primary authbgcolor'
                        style={{ marginLeft: '59%' }}
                        disabled={loading}
                      >
                        {!loading && <span className='indicator-label'>Oppdater formål</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2' />
                          </span>
                        )}
                      </button>
                    </div>
                  ) : (
                    <div className='col-md-5'>
                      <button
                        type='submit'
                        className='btn btn-lg btn-primary authbgcolor'
                        style={{ marginLeft: '61%' }}
                        disabled={loading}
                      >
                        {!loading && <span className='indicator-label'>Opprett formål</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2' />
                          </span>
                        )}
                      </button>
                    </div>
                  )}
                </div>
                {/* </div> */}

                {/* <div className='modal-footer'>
                <button type='button' className='btn btn-light' onClick={() => setShowModal(false)}>
                  Avbryt
                </button>

                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading && <span className='indicator-label'>Opprett formål</span>}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div> */}
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      <GalleryDrawer1 />
    </>
  )
}

export default React.memo(GoalCreationModal)
