/* eslint-disable no-script-url */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { useContext, useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useIntl } from 'react-intl'
import CustomPagination from '../../../CustomPagination'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { useMainContext } from '../../../context'
import { useAuth } from '../../modules/auth'
import { handleGetRequest } from '../../services'
import { ReportingUtils } from './ReportingUtils'

type Props = {
  className: string
  selectedSport?: string
  selectedSportName?: string
  startDate?: Date
  endDate?: Date
}

const ReportGoalTable: React.FC<Props> = (props: Props) => {
  const { companyofPortal } = useMainContext()
  const { currentUser } = useAuth()
  const { setLoading } = useContext(LoadingContext)
  const [goals, setGoals] = useState<any>()
  const intl = useIntl()
  const [PageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [startDate, setStartDate] = useState(
    props.startDate || new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
  )
  const [endDate, setEndDate] = useState(props.endDate || new Date())
  const [sportsData, setSportsData] = useState<any[]>()
  const [showDetails, setShowDetails] = useState<boolean>(false)

  const getGoalsByOrg = async () => {
    // eslint-disable-next-line no-extra-boolean-cast
    const sportId = props.selectedSport || ''
    const params = {
      organisationId: currentUser?.user?.organisation_id?._id,
      sportId,
      limit: PageSize,
      page: currentPage,
    }
    const res = await handleGetRequest('/goal/by_organisation', {
      params,
    })(setLoading)

    setGoals(res)
    await getPayments()
  }

  const getPayments = async () => {
    const data = await handleGetRequest(
      `/organisation/reporting/goal/${currentUser?.user?.organisation_id?._id}?start_date=${startDate?.toISOString() || new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)}&end_date=${endDate?.toISOString() || new Date(Date.now()).toISOString()}&sportId=${props?.selectedSport || ''}`
    )(setLoading)
    setSportsData(data?.data || [])
  }

  function GetStatus(status = 'active') {
    const badgeClass = {
      active: 'badge-light-warning',
      completed: 'badge-light-success',
      paused: 'badge-light-info',
      canceled: 'badge-light-danger',
    }[status]

    return (
      <span className={`badge status-button badge-sm ${badgeClass}`}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </span>
    )
  }

  useEffect(() => {
    getGoalsByOrg()
  }, [
    PageSize,
    currentPage,
    companyofPortal.company_id,
    currentUser?.user?.organisation_id._id,
    props?.selectedSport,
  ])
  useEffect(() => {
    getPayments()
  }, [startDate, endDate])

  return (
    <div className={`card`} style={{ borderRadius: '0px !important' }}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{props.selectedSportName || ''}</span>
        </h3>
        <h3 className='card-title flex-row w-30'>
          <div>
            <span className='card-label fw-bold fs-6'>Vis detaljer</span>
            <input
              className='form-check-input'
              type='checkbox'
              checked={showDetails}
              onChange={() => {
                setShowDetails(!showDetails)
              }}
            />
          </div>
        </h3>
        <h3 className='card-title flex-row w-30'>
          <span className='card-label fw-bold fs-6'>Tidsintervall</span>
          <ReactDatePicker
            dateFormat='dd.MM.yyyy'
            calendarStartDay={1}
            selected={startDate}
            className={clsx('form-control form-control-lg w-100  inputClass')}
            onChange={(date: Date) => {
              setStartDate(date)
            }}
          />
          <ReactDatePicker
            dateFormat='dd.MM.yyyy'
            calendarStartDay={1}
            selected={endDate}
            className={clsx('form-control form-control-lg w-100  inputClass')}
            onChange={(date: Date) => {
              setEndDate(date)
            }}
          />
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted' style={{ backgroundColor: '#E9F4FF' }}>
                <th className='ps-4 rounded-start'>{intl.formatMessage({ id: 'goal' })}</th>
                <th>{intl.formatMessage({ id: 'status' })}</th>
                <th style={{ textAlign: 'center' }}>{intl.formatMessage({ id: 'gross' })}</th>
                {showDetails && (
                  <th style={{ textAlign: 'center' }}>
                    {intl.formatMessage({ id: 'number.of.vipps.transactions' })}
                  </th>
                )}
                {showDetails && (
                  <th style={{ textAlign: 'center' }}>
                    {intl.formatMessage({ id: 'net.vipps.price' })}
                  </th>
                )}
                {showDetails && (
                  <th style={{ textAlign: 'center' }}>
                    <div>{intl.formatMessage({ id: 'net.vipps' })}</div>
                    <div>{intl.formatMessage({ id: 'net.vipps.info' })}</div>
                  </th>
                )}
                {showDetails && (
                  <th style={{ textAlign: 'center' }}>
                    <div>{intl.formatMessage({ id: 'net.stotte.price' })}</div>
                    <div>({intl.formatMessage({ id: 'mva.excluded' })})</div>
                  </th>
                )}
                {showDetails && (
                  <th style={{ textAlign: 'center' }}>
                    <div>{intl.formatMessage({ id: 'net.stotte.price' })}</div>
                    <div>({intl.formatMessage({ id: 'mva.included' })})</div>
                  </th>
                )}
                <th style={{ textAlign: 'center' }}>
                  <div>{intl.formatMessage({ id: 'net.stotte' })}</div>
                  <div>{intl.formatMessage({ id: 'net.stotte.info' })}</div>
                </th>
                {showDetails && (
                  <th style={{ textAlign: 'center' }}>
                    <div>{intl.formatMessage({ id: 'net.stotte' })}</div>
                    <div>{intl.formatMessage({ id: 'net.stotte.mva' })}</div>
                  </th>
                )}
                <th className=' text-end rounded-end px-4' />
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {goals?.data?.length > 0 ? (
                goals?.data?.map((tbData: any) => (
                  <tr key={tbData?._id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-circle left-text '>
                          <i className=' fa-light fa-bullseye-pointer' />
                        </div>
                        <div className='mx-2 text-dark fw-bolder text-hover-primary d-block fs-6 left-text'>
                          {tbData?.title}
                        </div>
                      </div>
                    </td>
                    <td className=''>{tbData?.status && GetStatus(tbData?.status)}</td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold d-block mb-1 fs-6'
                        style={{ cursor: 'auto', textAlign: 'center' }}
                      >
                        {ReportingUtils.getSportTotalAmount(sportsData || [], tbData?._id)}
                      </a>
                    </td>
                    {showDetails && (
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-6'
                          style={{ cursor: 'auto', textAlign: 'center' }}
                        >
                          {ReportingUtils.getSportVippsCount(sportsData || [], tbData?._id)}
                        </a>
                      </td>
                    )}
                    {showDetails && (
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-6'
                          style={{ cursor: 'auto', textAlign: 'center' }}
                        >
                          {ReportingUtils.getVippsPriceSport(sportsData || [], tbData?._id)}
                        </a>
                      </td>
                    )}
                    {showDetails && (
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-6'
                          style={{ cursor: 'auto', textAlign: 'center' }}
                        >
                          {ReportingUtils.getVippsTotal(
                            ReportingUtils.getSportTotalAmount(sportsData || [], tbData?._id),
                            ReportingUtils.getSportVippsCount(sportsData || [], tbData?._id)
                          )}
                        </a>
                      </td>
                    )}
                    {showDetails && (
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-6'
                          style={{ cursor: 'auto', textAlign: 'center' }}
                        >
                          {ReportingUtils.getStottePrice(
                            ReportingUtils.getSportTotalAmount(sportsData || [], tbData?._id)
                          )}
                        </a>
                      </td>
                    )}{' '}
                    {showDetails && (
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-6'
                          style={{ cursor: 'auto', textAlign: 'center' }}
                        >
                          {ReportingUtils.getStottePriceMVA(
                            ReportingUtils.getSportTotalAmount(sportsData || [], tbData?._id)
                          )}
                        </a>
                      </td>
                    )}
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold d-block mb-1 fs-6'
                        style={{ cursor: 'auto', textAlign: 'center' }}
                      >
                        {ReportingUtils.getStotteTotal(
                          ReportingUtils.getSportTotalAmount(sportsData || [], tbData?._id),
                          ReportingUtils.getSportVippsCount(sportsData || [], tbData?._id)
                        )}
                      </a>
                    </td>
                    {showDetails && (
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold d-block mb-1 fs-6'
                          style={{ cursor: 'auto', textAlign: 'center' }}
                        >
                          {ReportingUtils.getStotteTotalMVA(
                            ReportingUtils.getSportTotalAmount(sportsData || [], tbData?._id),
                            ReportingUtils.getSportVippsCount(sportsData || [], tbData?._id)
                          )}
                        </a>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr />
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>

        <div className='row'>
          <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start' />
          <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
            <CustomPagination
              className='pagination-bar justify-content-md-end'
              currentPage={currentPage}
              totalCount={goals ? goals?.pagination?.totalRecords : 0}
              pageSize={PageSize}
              onPageChange={(page: any) => setCurrentPage(page)}
            />
          </div>
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { ReportGoalTable }
