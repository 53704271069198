import { useCallback, useEffect, useRef, useState } from 'react'
import { HexColorInput, HexColorPicker } from 'react-colorful'

export const ColorPopoverPicker = ({ color, onChange, text }) => {
  const popover = useRef<any>()
  const [isOpen, toggle] = useState(false)

  const close = useCallback(() => toggle(false), [])
  useClickOutside(popover, close)

  return (
    <div className='colour-picker'>
      <div
        className='colour-swatch'
        style={{ backgroundColor: color }}
        onClick={() => toggle(true)}
      />

      {isOpen && !text && (
        <div className='colour-popover' ref={popover}>
          <HexColorPicker color={color} onChange={onChange} />
          <HexColorInput color={color} onChange={onChange} />{' '}
        </div>
      )}
      {isOpen && text && (
        <div className='colour-popover' ref={popover}>
          <button
            key='#FFFFFF'
            className='picker__swatch'
            style={{ background: '#FFFFFF' }}
            onClick={() => onChange('#FFFFFF')}
          />
          <button
            key='#000000'
            className='picker__swatch'
            style={{ background: '#000000' }}
            onClick={() => onChange('#000000')}
          />
        </div>
      )}
    </div>
  )
}

// Improved version of https://usehooks.com/useOnClickOutside/
const useClickOutside = (ref, handler) => {
  useEffect(() => {
    let startedInside = false
    let startedWhenMounted = false

    const listener = (event) => {
      // Do nothing if `mousedown` or `touchstart` started inside ref element
      if (startedInside || !startedWhenMounted) return
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) return

      handler(event)
    }

    const validateEventStart = (event) => {
      startedWhenMounted = ref.current
      startedInside = ref.current && ref.current.contains(event.target)
    }

    document.addEventListener('mousedown', validateEventStart)
    document.addEventListener('touchstart', validateEventStart)
    document.addEventListener('click', listener)

    return () => {
      document.removeEventListener('mousedown', validateEventStart)
      document.removeEventListener('touchstart', validateEventStart)
      document.removeEventListener('click', listener)
    }
  }, [ref, handler])
}
