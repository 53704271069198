import axios from 'axios'
import { useQuery } from 'react-query'

export function useGetSalesOrg(id: any) {
  return useQuery(
    ['get-sales-org', id],
    async () => {
      if (!id) {
        return
      }
      const response = await axios.get(
        `${import.meta.env.VITE_BACKEND_API_URL}/organisation/detail/${id}`
      )

      if (response.status === 200) {
        return response.data
      }

      throw Promise.reject('Something went wrong')
    },
    {
      keepPreviousData: true,
    }
  )
}
