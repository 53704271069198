/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import * as xlsx from 'xlsx'
import CustomPagination from '../../../CustomPagination'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { useMainContext } from '../../../context'
import { useAuth } from '../../modules/auth'
import { handleGetRequest } from '../../services'

type Props = {
  className: string
  setTableLoaded: (value: boolean) => void
}

const SupportPlayersTable: React.FC<Props> = ({ className, setTableLoaded }) => {
  const { currentUser } = useAuth()
  const [PageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const { companyofPortal } = useMainContext()
  const { setLoading } = useContext(LoadingContext)
  const [tableData, setTableData] = useState<any>()
  const intl = useIntl()
  const [onlyConsentedUser, setOnlyConsentedUser] = useState<Boolean>(false)
  useEffect(() => {
    const getSportsStats = async () => {
      // eslint-disable-next-line no-extra-boolean-cast
      const sport_id = !!companyofPortal?.company_id
        ? [companyofPortal?.company_id]
        : currentUser?.user?.sports_list?.map((item: any) => item._id)
      const params = {
        organisation_id: currentUser?.user?.organisation_id?._id,
        sport_id,
        only_consented_users: onlyConsentedUser,
        limit: PageSize,
        page: currentPage,
        only_active: false,
        only_active_expired: true,
        organisation_has_enabled_consent:
          !!currentUser?.user?.organisation_id?.user_consent_sharing_enabled,
      }
      const data = await handleGetRequest(`/goal_support/support_players`, { params })(setLoading)
      setTableData(data)
      setTableLoaded(true)
    }
    getSportsStats()
  }, [
    PageSize,
    companyofPortal?.company_id,
    currentPage,
    currentUser?.user?.organisation_id?._id,
    onlyConsentedUser,
    setLoading,
  ])

  const generateReport = async () => {
    // eslint-disable-next-line no-extra-boolean-cast
    const sport_id = !!companyofPortal?.company_id
      ? [companyofPortal?.company_id]
      : currentUser?.user?.sports_list?.map((item: any) => item._id)
    const params = {
      organisation_id: currentUser?.user?.organisation_id?._id,
      sport_id,
      only_consented_users: true,
      limit: 9999,
      page: 1,
      only_active: true,
      organisation_has_enabled_consent: true,
    }
    const data = await handleGetRequest(`/goal_support/support_players`, { params })(setLoading)
    const sheetData: {
      name: string
      support_start_date: string
      total_goal_support_count: number
      support_total_amount: number
      number_of_charges: number
      email: string
      mobile_number: string
    }[] = []
    data.data.forEach((element: any) => {
      sheetData.push({
        name: `${element.first_name} ${element.last_name}`,
        number_of_charges: element.number_of_charges,
        support_start_date: moment(element.support_start_date).format('DD.MM.YYYY'),
        support_total_amount: element.support_total_amount,
        total_goal_support_count: element.total_goal_support_count,
        email: element.email,
        mobile_number: element.mobile_number,
      })
    })
    const header = [
      intl.formatMessage({ id: 'support.player.small' }),
      intl.formatMessage({ id: 'Number.of.vipps.deducted' }),
      intl.formatMessage({ id: 'startup' }),
      intl.formatMessage({ id: 'support.received' }),
      intl.formatMessage({ id: 'Number.of.active.purposes' }),
      intl.formatMessage({ id: 'email' }),
      intl.formatMessage({ id: 'tel.phone' }),
    ]

    const worksheet = xlsx.utils.json_to_sheet(sheetData)
    const workbook = xlsx.utils.book_new()
    xlsx.utils.sheet_add_aoa(worksheet, [header], { origin: 'A1' })
    const wscols = header.map((_arr, i) => ({ wch: i === 0 ? 20 : 15 }))
    worksheet['!cols'] = wscols as any
    xlsx.utils.book_append_sheet(workbook, worksheet, 'Støtte')

    const fileName = `Stottespillere rapport.xlsx`
    xlsx.writeFile(workbook, fileName, { compression: true })
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function GetStatus(status = 'active') {
    const badgeClass =
      {
        active: 'badge-light-info',
        consent_expired: 'badge-light-danger',
      }[status] || 'badge-light-default'
    const label =
      {
        active: 'Aktiv',
        consent_expired: 'Tilgang utgått',
      }[status] || 'Ukjent'
    return <span className={`badge status-button badge-sm ${badgeClass}`}>{label}</span>
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1 '>
            {intl.formatMessage({ id: 'support.player' })}
          </span>
          <span className='text-muted mt-1 fw-bold fs-7'>
            {intl.formatMessage({ id: 'overview.of.all.registered.supporters' })}
          </span>
        </h3>
        {currentUser?.user?.organisation_id?.user_consent_sharing_enabled && (
          <div style={{ display: 'flex' }}>
            {' '}
            {onlyConsentedUser && (
              <div className='card-toolbar'>
                <div
                  onClick={async () => {
                    await generateReport()
                  }}
                  className='btn btn-lg btn-primary authbgcolor'
                >
                  Last ned oversikt
                </div>
              </div>
            )}
            <label
              className='form-check form-switch form-check-custom mt-2'
              style={{ fontSize: '12px', maxWidth: 240 }}
            >
              <input
                type='checkbox'
                className='form-check-input ms-3'
                id='onlyConsentedUser'
                style={{ marginRight: '8px' }}
                checked={onlyConsentedUser as boolean}
                onChange={() => {
                  setCurrentPage(1)
                  setOnlyConsentedUser(!onlyConsentedUser)
                }}
              />
              {intl.formatMessage({ id: 'Show.consented.users.only' })}
            </label>
          </div>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted' style={{ backgroundColor: '#E9F4FF' }}>
                <th style={{ width: '25%' }} className=' ps-4 rounded-start'>
                  {intl.formatMessage({ id: 'support.players' })}
                </th>
                <th style={{ width: '15%' }} className=''>
                  {intl.formatMessage({ id: 'status' })}
                </th>
                <th style={{ width: '15%' }} className=''>
                  {intl.formatMessage({ id: 'startup' })}
                </th>
                <th style={{ width: '15%' }} className=''>
                  {intl.formatMessage({ id: 'Number.of.active.purposes' })}
                </th>
                <th style={{ width: '15%' }} className=''>
                  {intl.formatMessage({ id: 'Number.of.vipps.deducted' })}
                </th>
                <th className=' text-end rounded-end px-4 text-uppercase'>
                  {intl.formatMessage({ id: 'support.received' })}
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {tableData?.data &&
                tableData?.data?.length > 0 &&
                tableData?.data.map((tbData: any) => (
                  <tr key={tbData._id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <span
                          className='mx-2 fw-bolder d-block fs-6 '
                          style={{
                            color:
                              tbData?.gave_consent && tbData?.first_name
                                ? '#181c32'
                                : 'transparent',
                            textShadow:
                              tbData?.gave_consent && tbData?.first_name
                                ? 'unset'
                                : '0 0 6px rgba(0,0,0,0.5)',
                            userSelect:
                              tbData?.gave_consent && tbData?.first_name ? 'unset' : 'none',
                          }}
                        >
                          {tbData?.gave_consent && tbData?.first_name
                            ? `${tbData?.first_name} ${tbData?.last_name}`
                            : `${intl.formatMessage({ id: 'Anonymous' })}`}
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold d-block mb-1 fs-6'
                        style={{ cursor: 'auto' }}
                      >
                        {GetStatus(tbData?.status)}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold d-block mb-1 fs-6'
                        style={{ cursor: 'auto' }}
                      >
                        {moment(tbData?.support_start_date).format('DD.MM.YYYY')}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold d-block mb-1 fs-6'
                        style={{ cursor: 'auto' }}
                      >
                        {tbData?.total_goal_support_count}
                      </a>
                    </td>

                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold d-block mb-1 fs-6'
                        style={{ cursor: 'auto' }}
                      >
                        {tbData?.number_of_charges}
                      </a>
                    </td>

                    <td className='text-end' style={{ paddingRight: '1rem' }}>
                      <a
                        href='#'
                        className='text-dark fw-bold d-block mb-1 fs-6'
                        style={{ cursor: 'auto' }}
                      >
                        {tbData?.support_total_amount}
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        <div className='row'>
          <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start' />
          <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
            <CustomPagination
              className='pagination-bar justify-content-md-end'
              currentPage={currentPage}
              totalCount={tableData ? tableData?.pagination?.totalRecords : 0}
              pageSize={PageSize}
              onPageChange={(page: any) => setCurrentPage(page)}
            />
          </div>
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { SupportPlayersTable }
