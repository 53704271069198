/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

// import {useSportsStats} from '../../hooks/dashboard/useSportsStats'
import { SportsTable } from './SportsTable'

const SportsMain: FC = () => {
  return <SportsTable className='mb-5 mb-xl-8' />
}

export { SportsMain }
