import clsx from 'clsx'
import { useFormik } from 'formik'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { requestPassword } from '../core/_requests'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})
export function ForgotPassword() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(() => {
            setHasErrors(false)
            setTimeout(() => {
              navigate('/auth/login')
            }, 3000)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setTimeout(() => {
              navigate('/auth/login')
            }, 3000)
            setSubmitting(false)
            setStatus('Innloggingsinformasjonen du har gitt er feil. Vennligst prøv igjen.')
          })
      }, 1000)
    },
  })
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className=' mb-10'>
        {/* begin::Title */}

        <div className=' mb-10 d-flex ' style={{ alignItems: 'center' }}>
          <img
            src={toAbsoluteUrl('/media/logos/S-logo-full-dark.png')}
            alt='logo'
            className='svg-icon-cufs mb-14'
            style={{ maxHeight: '40px' }}
          />
          {/* <h2 className='text-dark mb-14 mx-2'>Støtte AS</h2> */}
        </div>

        <h1 className='authtext'>{intl.formatMessage({ id: 'forgot.password' })}</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400'>{intl.formatMessage({ id: 'forgot.password.p' })}</div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-10 bg-light-success p-8 rounded' style={{ width: '81%' }}>
          <div className='text-dark'>{intl.formatMessage({ id: 'forgot.password.msg1' })}</div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-success p-8 rounded' style={{ width: '81%' }}>
          <div className='text-dark'>{intl.formatMessage({ id: 'forgot.password.msg2' })}</div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>
          {' '}
          {intl.formatMessage({ id: 'Username' })}
        </label>
        <input
          style={{ width: '81%' }}
          type='email'
          placeholder={intl.formatMessage({ id: 'Username' })}
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.email && formik.errors.email,
            }
            // {
            //   'is-invalid': formik.touched.password && formik.errors.password,
            // },
            // {
            //   'is-valid': formik.touched.password && !formik.errors.password,
            // }
          )}
        />
        {/* {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )} */}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='row' style={{ marginBottom: '140px' }}>
        <div className='col-md-4'>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-light me-3'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {intl.formatMessage({ id: 'Cancel' })}
            </button>
          </Link>{' '}
        </div>
        <div className='col-md-8'>
          <button
            style={{ width: '215px', marginLeft: '3.1rem' }}
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary authbgcolor mb-5'
          >
            <span className='indicator-label'>
              {intl.formatMessage({ id: 'change.password' })}
              {loading && <span className='spinner-border spinner-border-sm align-middle ms-2' />}
            </span>
          </button>
        </div>
      </div>

      {/* end::Form group */}
    </form>
  )
}
