import { Pic } from 'zimjs'
import { AbstractContainer } from './AbstractContainer'

export class EditorImage extends AbstractContainer {
  private image: Pic | undefined

  private original: Pic | undefined

  constructor(width: number, height: number, image?: string) {
    super(width, height)
    // Hide a original image to get the width and height correctly
    if (image) this.createImage(image)
  }

  private createImage(image: string) {
    if (this.original && this.image) {
      this.original.removeFrom()
      this.image.removeFrom()
      this.image = undefined
      this.original = undefined
    }
    this.original = new Pic(image, undefined, undefined, true).addTo(this)
    this.original.vis(false)
    this.image = new Pic(image, undefined, undefined, true).addTo(this)
    const isPortrait = this.original.height > this.original.width
    if (isPortrait) this.image.sca(this.width / this.original.width)
    else this.image.sca(this.height / this.original.height)
    this.image.center()
  }

  setImage(image: string) {
    this.createImage(image)
  }
}
