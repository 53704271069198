import { useMutation } from 'react-query'

import axios from 'axios'

export function useUpdateNotification() {
  return useMutation(['update-notification'], async (data: any) => {
    const response = await axios.put(
      `${import.meta.env.VITE_BACKEND_API_URL}/notification/${data.noti_id}`,
      data,
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
      }
    )

    return response
  })
}
