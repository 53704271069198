import React, { useEffect } from 'react'
import QRCode from 'react-qr-code'
import { useParams } from 'react-router-dom'
import { Textfit } from 'react-textfit'
import { StudioUtils } from '../../../pages/Marketing/studio/StudioUtils'
import { handlePostRequest } from '../../../services'

const CampaignUserCard: React.FC = () => {
  const { userCode, orgID, header, subheader } = useParams()
  const decodedHeader = StudioUtils.decodeText(header || '')
  const decodedSubHeader = StudioUtils.decodeText(subheader || '')
  const [orgImage, setOrgImage] = React.useState<string | undefined>(undefined)
  const fetchOrgImage = async (orgID: string) => {
    const data = await handlePostRequest(
      `/organisation/org-logo-b64`,
      {
        id: orgID,
      },
      undefined,
      true
    )(() => {})
    setOrgImage(data.data.image)
  }

  useEffect(() => {
    if (orgID) fetchOrgImage(orgID)
  }, [orgID])

  return (
    <div className='campaign-recruitment-card'>
      <div className='card-container'>
        <div className='card-header'>
          <img src={orgImage} alt='Campaign logo' className='campaign-logo' />
          <div className='campaign-header-border'>
            <Textfit mode='single' max={60} className='campaign-header '>
              {decodedHeader}
            </Textfit>
          </div>
          <h2>Formål</h2>
          <div className='campaign-name-border'>
            <Textfit mode='single' max={32} className='campaign-name'>
              {decodedSubHeader}
            </Textfit>
          </div>
        </div>
        <div className='card-body'>
          <div className='qr-code'>
            <div style={{ height: 'auto', margin: '0 auto', maxWidth: '325px', width: '100%' }}>
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={`https://app.stotte.no/login/kode/${userCode}`}
                viewBox='0 0 256 256'
                fgColor='#21285c'
              />
            </div>
          </div>
          <h3>Scan koden eller skriv inn referansekoden nedenfor ved opprettelse av Støtte:</h3>

          <div className='campaign-name-border'>
            <Textfit max={32} mode='single' className='campaign-name'>
              {userCode}
            </Textfit>
          </div>
        </div>
        <footer className='card-footer'>
          <img src='/media/logos/SlogoNew.jpg' alt='Støtte AS' style={{ maxHeight: '60px' }} />
        </footer>
      </div>
    </div>
  )
}

export default CampaignUserCard
