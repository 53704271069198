/* eslint-disable no-script-url */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { Field, Form, Formik } from 'formik'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaInfoCircle } from 'react-icons/fa'
import { useIntl } from 'react-intl'
import Select from 'react-select'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import CustomPagination from '../../../CustomPagination'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { useMainContext } from '../../../context'
import { useAuth } from '../../modules/auth'
import {
  handleDeleteRequest,
  handleGetRequest,
  handlePatchRequest,
  handlePostRequest,
} from '../../services'

type Props = {
  className: string
}

const multiSelectStyles = {
  control: (base: any) => ({
    ...base,
    fontSize: '0.95rem',
    height: '40px',
    backgroundColor: '#ffffff',
    border: '1px solid #e4e6ef',
    minHeight: '40px',
    borderRadius: '0.85rem',
  }),
  option: (provided: any) => ({
    ...provided,
    borderBottom: '1px solid #ccc',
    color: '#424242',
    fontSize: '0.9rem',
    padding: '10px',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '5px',
    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: '#DDEEFF',
    borderRadius: '20px',
    color: '#0F2A5F',
    fontSize: '0.9rem',
    margin: '2px',
    padding: '2px 6px',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#BADAFF',
      borderRadius: '20px',
      color: '#0F2A5F',
    },
  }),
  input: (provided: any) => ({
    ...provided,
    color: '#424242',
  }),
  // placeholder
  placeholder: (provided: any) => ({
    ...provided,
    paddingLeft: '10px',
    color: '#424242',
  }),
}

const memberSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  roles: Yup.string().required('Required'),
  email: Yup.string().required('Required').email(),
  // password: Yup.string().required('Required'),
  // if empty then return required else check if password and confirm password are same
  // confirmPassword: Yup.string().when('password', {
  //   is: (password: any) => {
  //     return password && password.length > 0
  //   },
  //   then: Yup.string().oneOf([Yup.ref('password')], 'Password does not match'),
  //   otherwise: Yup.string().required('Required'),
  // }),
  phone: Yup.number().required('Required'),
  // sports: Yup.array().when('roles', {
  //   is: (roles: any) => {
  //     return roles === 'manager'
  //   },
  //   then: Yup.array().min(1, 'Required'),
  // }),
})

let userId = ''

const CPTable1: React.FC<Props> = ({ className }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
  const { loading, setLoading } = useContext(LoadingContext)
  const [mode, setMode] = useState('add')
  const intl = useIntl()
  const { currentUser } = useAuth()
  // eslint-disable-next-line eqeqeq
  if (!currentUser || currentUser.user.user_type !== 'admin') {
    toast.error('Du har ikke autorisasjon til å få tilgang til denne siden.')
    setTimeout(() => {
      window.location.href = '/home'
    }, 1000)
  }

  function numberSpacingBetweenTwodDigits(value: number | string) {
    // add a space between every 3 digits in a number from right to left
    if (value) return value.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ' ')
    return value
  }
  const [picture, setpicture] = useState<any>(null)
  const { companyofPortal, setCustomberdCrum } = useMainContext()
  setCustomberdCrum('', '', `Eierportalen - ${companyofPortal?.name} - Members`)
  const [PageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [memberData, setMemberData] = useState<any>()
  const [sportsList, setSportsList] = useState<any>()

  const getOrgUsers = async () => {
    const params = {
      limit: PageSize,
      page: currentPage,
    }
    const { data, pagination } = await handleGetRequest(
      `/organisation_user/by_org/${currentUser?.user?.organisation_id?._id}`,
      { params }
    )(setLoading)
    if (data) {
      setMemberData({
        data,
        pagination,
      })
    }
  }

  useEffect(() => {
    getOrgUsers()
  }, [currentPage, currentUser?.user?.organisation_id._id])

  const [validationSchema, setValidationSchema] = useState<any>(memberSchema)

  const initialValues = {
    first_name: '',
    last_name: '',
    roles: '',
    email: '',
    // password: '',
    // confirmPassword: '',
    phone: '',
    picture: '',
    sports: [],
  }

  const [formVal, setFormVal] = useState(initialValues)
  const ChangeProfilePic = async (e: any) => {
    const fileExtensions = ['png', 'jpg', 'jpeg']
    const fileName = e.target.files[0].name
    const arraycontains = fileExtensions.indexOf(fileName.split('.').pop()) > -1

    if (!arraycontains) {
      toast.error('Tillatt filtype: jpg, jpeg, png.')
      return
    }
    // eslint-disable-next-line eqeqeq
    if (currentUser?.user?.user_type !== 'admin') {
      toast.error('Kun admin kan endre bildet.')
      return
    }
    setpicture(e.target.files[0])
  }
  const submitStep = async (values: any) => {
    setLoading(true)
    const sportsArray = values?.sports?.map((item: any) => item?.value)

    if (mode === 'add') {
      // form data
      const formData = new FormData()
      formData.append('organisation_id', currentUser?.user?.organisation_id?._id)
      formData.append('first_name', values?.first_name)
      formData.append('last_name', values?.last_name)
      formData.append('user_type', values?.roles)
      formData.append('email', values?.email)
      //  formData.append('new_password', values?.password)
      formData.append('mobile_number', values?.phone)
      formData.append('picture', picture!)
      sportsArray.forEach((item: any) => formData.append('sports_list[]', item))

      // })

      const { success } = await handlePostRequest('/organisation_user/signup', formData)(setLoading)
      if (success) getOrgUsers()
    } else {
      // append only updated values
      const formData = new FormData()
      if (values?.first_name !== formVal?.first_name) {
        formData.append('first_name', values?.first_name)
      }
      if (values?.last_name !== formVal?.last_name) {
        formData.append('last_name', values?.last_name)
      }
      if (values?.roles !== formVal?.roles) {
        formData.append('user_type', values?.roles)
      }
      if (values?.email !== formVal?.email) {
        formData.append('email', values?.email)
      }
      // if (values?.password !== formVal?.password) {
      //   formData.append('new_password', values?.password)
      // }
      if (values?.phone !== formVal?.phone) {
        formData.append('mobile_number', values?.phone)
      }
      if (
        picture &&
        // picture is of type file
        picture instanceof File
      ) {
        formData.append('picture', picture)
      }
      if (sportsArray !== formVal?.sports) {
        sportsArray.forEach((item: any) => formData.append('sports_list[]', item))
      }
      // currentUser.user.first_name = values?.first_name;
      // currentUser.user.last_name = values?.last_name;
      // currentUser.user.dob = values?.dob;
      // currentUser.user.mobile_number = values?.mobile_number;
      // currentUser.user.email = values?.email;
      // setCurrentUser(currentUser)
      // saveAuth(currentUser);
      // localStorage.setItem('currentUser', JSON.stringify(currentUser))
      // saveCurrentUser(currentUser);

      const { success } = await handlePatchRequest(
        `/organisation_user/${userId}`,
        formData
      )(setLoading)
      if (success) {
        getOrgUsers()
      }
      setFormVal(initialValues)
      setValidationSchema(memberSchema)
      userId = ''
    }
    setShowModal(false)
  }

  const getSportsArray = (sportsIds: any) => {
    let sportsArray: any = []

    sportsIds?.forEach(({ _id }: any) => {
      const sport = sportsList?.find((s: any) => s._id === _id)
      if (sport) {
        sportsArray = [...sportsArray, { value: sport._id, label: sport.sports_category_name }]
      }
    })

    return sportsArray
  }

  const [deleteUserData, setDeleteUserData] = useState<any>(null)
  const [modalTitle, setModalTitle] = useState<string>('')

  const handleDelete = async () => {
    const { success } = await handleDeleteRequest(`/organisation_user/${deleteUserData._id}`)(
      setLoading
    )
    if (success) {
      getOrgUsers()
      setShowModalConfirm(false)
    }
  }

  const handleUserEdit = (data: any) => {
    // eslint-disable-next-line eqeqeq
    if (currentUser?.user?.user_type !== 'admin') {
      toast.error('Kun admin kan utføre denne endringen.')
      return
    }
    setMode('edit')
    setShowModal(true)
    userId = data?._id
    // set form values
    setFormVal({
      first_name: data?.first_name,
      last_name: data?.last_name,
      roles: data?.user_type,
      email: data?.email,
      // password: '',
      // confirmPassword: '',
      phone: data?.mobile_number,
      picture: data?.picture,
      sports: getSportsArray(data?.sports_list),
    })

    // remove password required
    const schema: any = Yup.object().shape({
      first_name: Yup.string().required('Required'),
      last_name: Yup.string().required('Required'),
      roles: Yup.string().required('Required'),
      email: Yup.string().required('Required').email(),
      //  password: Yup.string().notRequired(), // if empty then return required else check if password and confirm password are same
      // if empty then return required else check if password and confirm password are same
      // confirmPassword: Yup.string().when('password', {
      //   is: (password: any) => {
      //     return password && password.length > 0
      //   },
      //   then: Yup.string().oneOf([Yup.ref('password')], 'Password does not match'),
      // }),

      phone: Yup.string().required('Required'),
      // sports: Yup.array().when('roles', {
      //   is: (roles: any) => {
      //     return roles === 'manager'
      //   },
      //   then: Yup.array().min(1, 'Required'),
      // }),
    })

    setValidationSchema(schema)

    setpicture(data?.image)
  }

  const getPicture = () => {
    // picture can be file or url
    if (typeof picture === 'string' && picture.includes('http')) {
      return picture
    }
    if (picture instanceof File) {
      return URL.createObjectURL(picture)
    }
    return toAbsoluteUrl('/media/icons/duotune/abstract/user-astronaut-solid.svg')
  }
  const checkAuth = () => {
    // eslint-disable-next-line eqeqeq
    if (currentUser?.user?.user_type !== 'admin') {
      toast.error('Kun admin kan slette denne brukeren.')
      return
    }
    setShowModalConfirm(true)
  }
  const getInitials = (firstName = '', lastName = '') => {
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
  }

  useEffect(() => {
    const getSportsList = async () => {
      const req = await handleGetRequest(
        `/organisation/get_sport_list?organisationId=${currentUser?.user?.organisation_id?._id}&limit=1000`
      )(setLoading)
      if (req?.data) {
        setSportsList(req.data)
      }
    }
    getSportsList()
  }, [currentUser?.user?.organisation_id?._id])

  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {intl.formatMessage({ id: 'user.administration' })}
            </span>
            <span className='text-muted mt-1 fw-bold fs-7'>
              {intl.formatMessage({ id: 'create.and.manage.system.users' })}
            </span>
          </h3>

          <div className='card-toolbar'>
            <button
              // style={{backgroundColor: '#009ef7', color: 'white'}}
              type='button'
              className='btn btn-lg btn-primary authbgcolor'
              onClick={() => {
                setShowModal(true)
                setMode('add')
                setModalTitle(`${intl.formatMessage({ id: 'create.new.user' })}`)
                userId = ''
                setpicture('')
                setValidationSchema(memberSchema)
                setFormVal(initialValues)
              }}
            >
              {/* <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' /> */}

              {intl.formatMessage({ id: 'create.new.user' })}
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr
                  className='fw-bold text-muted'
                  style={{ backgroundColor: '#E9F4FF', borderRadius: '8px' }}
                >
                  <th className='ps-4 rounded-start text-uppercase'>
                    {intl.formatMessage({ id: 'USER' })}
                  </th>
                  <th className='text-uppercase'>{intl.formatMessage({ id: 'ROLE' })}</th>
                  <th className='text-uppercase'>{intl.formatMessage({ id: 'ACCESS' })}</th>
                  <th className='text-uppercase'>{intl.formatMessage({ id: 'MOBILE' })}</th>
                  <th className='text-uppercase'>{intl.formatMessage({ id: 'EMAIL' })}</th>
                  <th className='text-uppercase'>{intl.formatMessage({ id: 'LAST.LOGGED.IN' })}</th>
                  <th className=' text-end rounded-end px-4' />
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {memberData?.data?.length > 0 ? (
                  memberData?.data?.map((tbData: any) => (
                    <tr key={tbData?._id}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className=' position-relative'>
                            <div
                              className='symbol symbol-50px me-5'
                              style={{ backgroundColor: 'white' }}
                            >
                              {tbData?.image === '' ? (
                                <div
                                  className='btn btn-icon btn-custom border'
                                  style={{ width: '50px', height: '50px', cursor: 'none' }}
                                >
                                  {getInitials(tbData?.first_name, tbData?.last_name)}
                                </div>
                              ) : (
                                //   <span className='symbol-label' style={{backgroundColor: 'white'}}>
                                //   <img
                                //   style={{borderRadius: '8px'}}
                                //   src={toAbsoluteUrl('/media/svg/avatars/001-boy.svg')}
                                //   className='h-75 align-self-end'
                                //   alt=''
                                // />
                                // </span>
                                // <span className='symbol-label' style={{backgroundColor: 'white'}}>
                                //    {capitalizeName(tbData?.first_name, tbData?.last_name)}
                                //   </span>

                                <img
                                  style={{
                                    borderRadius: '8px',
                                    width: '50px',
                                    height: '50px',
                                    border: '1px solid #eff2f5 !important',
                                    objectFit: 'contain',
                                  }}
                                  src={tbData?.image}
                                  className='align-self-end border'
                                  alt=''
                                />
                              )}
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              handleUserEdit(tbData)
                              setModalTitle('Oppdater bruker')
                            }}
                            className='d-flex flex-column justify-content-center'
                            style={{ cursor: 'pointer' }}
                          >
                            <div className='fs-6 fw-bold text-dark text-gray-800 text-hover-primary fs-6'>
                              {tbData?.first_name} {tbData?.last_name}
                            </div>
                            {/* <div className='fw-semibold text-gray-400'>{tbData?.email}</div> */}
                          </div>
                        </div>
                      </td>
                      <td>
                        <a
                          className='text-dark text-gray-800 text-hover-primary d-block mb-1 fs-6'
                          href='#'
                          onClick={() => {
                            handleUserEdit(tbData)
                            setModalTitle('Oppdater bruker')
                          }}
                        >
                          {tbData?.user_type}
                        </a>
                      </td>
                      <td>
                        <a
                          className='text-dark text-gray-800 text-hover-primary d-block mb-1 fs-6'
                          href='#'
                          onClick={() => {
                            handleUserEdit(tbData)
                            setModalTitle('Oppdater bruker')
                          }}
                        >
                          {tbData?.user_type === 'admin'
                            ? 'Full'
                            : tbData?.sports_list.map((sport: any, index: number) => (
                                <React.Fragment key={index}>
                                  {sport?.sports_category_name}
                                  {index !== tbData.sports_list.length - 1 && ', '}
                                </React.Fragment>
                              ))}
                        </a>
                      </td>
                      <td>
                        <a
                          className='text-dark text-gray-800 text-hover-primary d-block mb-1 fs-6'
                          href='#'
                          onClick={() => {
                            handleUserEdit(tbData)
                            setModalTitle('Oppdater bruker')
                          }}
                        >
                          {numberSpacingBetweenTwodDigits(tbData?.mobile_number)}
                        </a>
                      </td>
                      <td>
                        <a
                          className='text-dark text-gray-800 text-hover-primary d-block mb-1 fs-6'
                          href='#'
                          onClick={() => {
                            handleUserEdit(tbData)
                            setModalTitle('Oppdater bruker')
                          }}
                        >
                          {tbData?.email}
                        </a>
                      </td>

                      <td>
                        <a
                          className='text-dark text-hover-primary d-block mb-1 fs-6'
                          style={{ marginRight: 12 }}
                          href='#'
                          onClick={() => {
                            handleUserEdit(tbData)
                            setModalTitle('Oppdater bruker')
                          }}
                        >
                          {moment(tbData?.last_login).format('DD.MM.YYYY HH:mm')}
                        </a>
                      </td>
                      <td style={{ float: 'right' }}>
                        <div className='d-flex flex-shrink-0'>
                          <a
                            onClick={() => {
                              handleUserEdit(tbData)
                              setModalTitle('Oppdater bruker')
                            }}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-lg me-1'
                          >
                            <i className='fa-duotone fa-pencil' />
                          </a>
                          {tbData.user_type !== 'admin' && (
                            <a
                              onClick={() => {
                                checkAuth()
                                setDeleteUserData(tbData)
                              }}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-lg'
                            >
                              <i className='fa-duotone fa-trash' />
                            </a>
                          )}
                        </div>
                        {/* <button
                          className='btn btn-sm btn-light-danger'
                          onClick={() => handleDelete(tbData?._id)}
                        >
                          Slette
                        </button> */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {intl.formatMessage({ id: 'simple.marketing' })}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start' />
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <CustomPagination
                className='pagination-bar justify-content-md-end'
                currentPage={currentPage}
                totalCount={memberData ? memberData?.pagination?.totalRecords : 0}
                pageSize={PageSize}
                onPageChange={(page: any) => setCurrentPage(page)}
              />
            </div>
          </div>
        </div>
        {/* begin::Body */}
      </div>
      <Modal
        show={showModalConfirm}
        onHide={() => setShowModalConfirm(false)}
        size='lg'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className='modal-title'>
              {intl.formatMessage({ id: 'Delete.user' })}: {deleteUserData?.first_name}{' '}
              {deleteUserData?.last_name}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <div className='modal-body'>
          {intl.formatMessage({ id: 'are.you.sure.Delete.user' })}

          <br />
          <br />
          <br />
          <button
            type='button'
            className='btn btn-light me-3'
            onClick={() => {
              setShowModalConfirm(false)
            }}
          >
            {intl.formatMessage({ id: 'Cancel' })}
          </button>
          <button
            type='button'
            style={{ float: 'right' }}
            onClick={() => {
              handleDelete()
            }}
            className='btn btn-primary'
          >
            {intl.formatMessage({ id: 'yes.delete' })}
          </button>
        </div>
      </Modal>
      <Modal show={showModal} onHide={() => setShowModal(false)} size='lg' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className='modal-title'>{modalTitle}</h5>
          </Modal.Title>
        </Modal.Header>
        <div className='modal-body'>
          <Formik
            initialValues={formVal}
            onSubmit={submitStep}
            enableReinitialize
            encType='multipart/form-data'
            validationSchema={validationSchema}
          >
            {({ values, errors, touched, setFieldValue }) => (
              <Form>
                <div className=''>
                  <div className='row mb-3'>
                    <div className='col-lg-12'>
                      <div
                        className='image-input image-input-outline'
                        data-kt-image-input='true'
                        style={{
                          backgroundImage: `url(${toAbsoluteUrl(
                            '/media/avatars/user-astronaut-solid.svg'
                          )})`,
                        }}
                      >
                        <img
                          className='image-input-wrapper w-125px h-125px p-1'
                          style={{
                            objectFit: 'cover',
                            border: '1px solid #c6e0ec',
                            padding: '15px',
                          }}
                          src={getPicture()}
                          alt='avatar'
                        />
                        <label
                          className='btn-active-color-primary bg-body shadow h-5 w-5'
                          data-kt-image-input-action='change'
                          data-bs-toggle='tooltip'
                          aria-label='Change avatar'
                          data-bs-original-title='Change avatar'
                          data-kt-initialized={1}
                          style={{
                            borderRadius: '8px',
                            width: '2.5rem',
                            height: '2.5rem',
                          }}
                        >
                          <i
                            className='bi bi-pencil-fill fs-7'
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                            }}
                          />
                          <input
                            type='file'
                            name='picture'
                            accept='.png, .jpg, .jpeg'
                            onChange={(e) => ChangeProfilePic(e)}
                          />

                          {/* <Field
                                type='file'
                                name={`picture`}
                                accept='.png, .jpg, .jpeg'

                              />
                              <div className='text-danger'>
                                <ErrorMessage name={`picture`} />
                              </div> */}
                        </label>
                      </div>
                      <div className='form-text'>Tillatt filtype: jpg, jpeg, png.</div>
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <div className='col-lg-6 '>
                      <label className='col-form-label fw-bold fs-6'>
                        {intl.formatMessage({ id: 'first.name' })}
                      </label>
                      <Field
                        style={{ borderRadius: '8px' }}
                        type='text'
                        name='first_name'
                        className={clsx('form-control form-control-lg form-control-solid', {
                          'is-invalid': touched.first_name && errors.first_name,
                        })}
                        placeholder={intl.formatMessage({ id: 'first.name' })}
                      />
                      {/* <div className='text-danger'>
                        <ErrorMessage name={`first_name`} />
                      </div> */}
                    </div>

                    <div className='col-lg-6 '>
                      <label className='col-form-label fw-bold fs-6'>
                        {intl.formatMessage({ id: 'surname' })}
                      </label>
                      <Field
                        style={{ borderRadius: '8px' }}
                        type='text'
                        name='last_name'
                        className={clsx('form-control form-control-lg form-control-solid', {
                          'is-invalid': touched.last_name && errors.last_name,
                        })}
                        placeholder={intl.formatMessage({ id: 'surname' })}
                      />
                      {/* <div className='text-danger'>
                        <ErrorMessage name={`last_name`} />
                      </div> */}
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <div className='col-lg-6 '>
                      <label className='col-form-label fw-bold fs-6'>
                        {' '}
                        {intl.formatMessage({ id: 'email' })}
                      </label>
                      <Field
                        style={{ borderRadius: '8px' }}
                        name='email'
                        type='email'
                        className={clsx('form-control form-control-lg form-control-solid', {
                          'is-invalid': touched.email && errors.email,
                        })}
                        placeholder={intl.formatMessage({ id: 'email' })}
                      />
                      {/* <div className='text-danger'>
                        <ErrorMessage name={`email`} />
                      </div> */}
                    </div>

                    <div className='col-lg-6 '>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        {' '}
                        {intl.formatMessage({ id: 'mobile' })}
                      </label>
                      <Field
                        style={{ borderRadius: '8px' }}
                        type='number'
                        name='phone'
                        className={clsx('form-control form-control-lg form-control-solid', {
                          'is-invalid': touched.phone && errors.phone,
                        })}
                        placeholder={intl.formatMessage({ id: 'mobile' })}
                      />
                      {/* <div className='text-danger'>
                        <ErrorMessage name={`phone`} />
                      </div> */}
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <div className='col-lg-12'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        Rolle
                        <OverlayTrigger
                          trigger='hover'
                          overlay={
                            <Tooltip id='my-tooltip-id'>
                              <div className='py-1'>
                                <h6 className='mb-4'> {intl.formatMessage({ id: 'ROLE' })}</h6>
                                <div className='d-flex mb-2'>
                                  <div className='title-container'>
                                    <div className='title fw-bold fs-7'>Admin:</div>
                                  </div>
                                  <div className='desc-container'>
                                    <div className='desc'>
                                      {intl.formatMessage({ id: 'full.admin.entire.system' })}
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex'>
                                  <div className='title-container'>
                                    <div className='itle fw-bold fs-7'>Manager:</div>
                                  </div>
                                  <div className='desc-container'>
                                    <div className='desc'>
                                      {intl.formatMessage({ id: 'full.manger.entire.system' })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tooltip>
                          }
                          delay={{ show: 250, hide: 400 }}
                          placement='right'
                        >
                          <Button variant='link' className='p-0 ms-2'>
                            <FaInfoCircle />
                          </Button>
                        </OverlayTrigger>
                      </label>
                      <Field
                        style={{ borderRadius: '8px', borderColor: '1px solid #C6E0EC' }}
                        as='select'
                        name='roles'
                        className={clsx('form-select form-select-lg form-select-solid', {
                          'is-invalid': touched.roles && errors.roles,
                        })}
                      >
                        <option disabled value=''>
                          {intl.formatMessage({ id: 'ROLE' })}
                        </option>

                        <option value='admin'>Admin</option>
                        <option value='manager'>Manager</option>
                      </Field>
                      {/* <div className='text-danger'>
                        <ErrorMessage name={`roles`} />
                      </div> */}
                    </div>
                  </div>

                  {/* When user selects Role as manager show the multiselect sports */}
                  {values.roles === 'manager' && (
                    <div className='row mb-3'>
                      <div className='col-lg-12'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                          {intl.formatMessage({ id: 'select.device' })}
                        </label>
                        {/* <Field
                      style={{borderRadius: '8px', borderColor: '#C6E0EC !important'}}
                        as='select'
                        name={`sports`}
                        className={clsx(
                          'form-select form-select-lg form-select-solid',
                          {
                            'is-invalid': touched.sports && errors.sports,
                          }
                        )}
                      >
                        <option>Velg enhet</option>
                        {sportsList.map((e : any, key: any) => {
                              return <option value={e._id}>{e.sports_category_name}</option>;
                          })}

                      </Field> */}

                        <Field
                          style={{ borderRadius: '8px' }}
                          name='sports'
                          component={Select}
                          options={
                            sportsList &&
                            Array.isArray(sportsList) &&
                            sportsList.map(
                              (sport: {
                                _id: number
                                sports_category_name: string
                                status: string
                              }) => ({
                                value: sport._id,
                                label:
                                  sport.sports_category_name +
                                  (sport.status === 'disabled' ? ' (Inaktiv)' : ''),
                              })
                            )
                          }
                          onChange={(e: any) => {
                            setFieldValue('sports', e)
                          }}
                          value={values.sports}
                          isMulti
                          styles={multiSelectStyles}
                          className='basic-multi-select'
                          classNamePrefix='select'
                          placeholder='Velg enhet...'
                        />
                        {/* <div className='text-danger'>
                          <ErrorMessage name={`sports`} />
                        </div> */}
                      </div>
                    </div>
                  )}
                </div>

                {/* <div className='row mb-3'>
                  <div className='col-lg-12'>
                    <label className={clsx('col-lg-4 col-form-label fw-bold fs-6')}>Passord</label>
                    <Field
                      style={{borderRadius: '8px'}}
                      type='text'
                      name={`password`}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Passord'
                      onChange={(e: any) => {
                        setFieldValue('password', e.target.value)
                        if (e.target.value !== values.confirmPassword) {
                          setValidationSchema(
                            Yup.object().shape({
                              ...validationSchema?.fields,
                              confirmPassword: Yup.string()
                                .required('Confirm Password is required')
                                .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
                            })
                          )
                        } else {
                          setValidationSchema(
                            Yup.object().shape({
                              ...validationSchema?.fields,
                              confirmPassword: Yup.string().notRequired(),
                            })
                          )
                        }
                      }}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name={`password`} />
                    </div>
                  </div>
                </div> */}

                {/* <div className='row mb-3'>
                  <div className='col-lg-12' style={{marginBottom: '20px'}}>
                    <label
                      className={clsx('col-lg-4 col-form-label fw-bold fs-6', {
                        required:
                          validationSchema?.fields?.confirmPassword?.exclusiveTests?.required,
                      })}
                    >
                      Bekreft passord
                    </label>
                    <Field
                      style={{borderRadius: '8px'}}
                      type='text'
                      name={`confirmPassword`}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Bekreft passord'
                      onChange={(e: any) => {
                        setFieldValue('confirmPassword', e.target.value)
                        if (e.target.value !== values.password) {
                          setValidationSchema(
                            Yup.object().shape({
                              ...validationSchema?.fields,
                              password: Yup.string().required('Password is required'),
                            })
                          )
                        } else {
                          setValidationSchema(
                            Yup.object().shape({
                              ...validationSchema?.fields,
                              password: Yup.string().notRequired(),
                            })
                          )
                        }
                      }}
                    />
                    <div className='text-danger'>
                      <ErrorMessage name={`confirmPassword`} />
                    </div>
                  </div>
                </div> */}
                <button
                  type='button'
                  className='btn btn-light me-3'
                  onClick={() => {
                    setShowModal(false)
                  }}
                >
                  {intl.formatMessage({ id: 'Cancel' })}
                </button>
                <button
                  type='submit'
                  style={{ float: 'right' }}
                  className='btn btn-lg btn-primary authbgcolor'
                  disabled={loading}
                >
                  {!loading && (
                    <span className='indicator-label'>{intl.formatMessage({ id: 'Save' })}</span>
                  )}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      {intl.formatMessage({ id: ' please.wait' })}...
                      <span className='spinner-border spinner-border-sm align-middle ms-2' />
                    </span>
                  )}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  )
}

export default CPTable1
