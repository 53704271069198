import React, { ReactNode } from 'react'
import { Textfit } from 'react-textfit'
import { numberSpacing } from '../../../_metronic/helpers'

type Props = {
  title?: ReactNode
  description?: ReactNode
  numbertext?: string | number
}

const DashboardCell: React.FC<Props> = ({ title, description, numbertext }) => {
  return (
    <div
      className='card card-xl-stretchcard_borderC'
      style={{ backgroundColor: '#ffff', width: '100%', height: '100%' }}
    >
      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        {/* begin::Wrapper */}
        <div className='d-flex flex-column flex-grow-1'>
          {/* begin::Title                    */}
          <Textfit mode='single' max={16} className='text-dark fw-bolder '>
            {title}
          </Textfit>

          <p className='text-muted mt-1 fw-bold fs-6 d-none d-xxl-block'>{description}</p>
          {/* end::Title */}
        </div>
        <span className='text-dark fw-bolder' style={{ fontSize: '3.5vh' }}>
          {numberSpacing(numbertext)}
        </span>
      </div>
    </div>
  )
}

export { DashboardCell }
