/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useMainContext } from '../../../context'

import { MixedWidget13 } from '../../../_metronic/partials/widgets'
// import {useSportsStats} from '../../hooks/dashboard/useSportsStats'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { useAuth } from '../../modules/auth'
import { handleGetRequest } from '../../services'
import { GoalTable } from '../dashboard/GoalTable'

const GoalMain: FC = () => {
  const { companyofPortal } = useMainContext()
  const { currentUser } = useAuth()
  const { setLoading } = useContext(LoadingContext)
  const [statsData, setStatsData] = useState<any>([])
  const intl = useIntl()

  useEffect(() => {
    const getStatsData = async () => {
      // eslint-disable-next-line no-extra-boolean-cast
      const sportId = !!companyofPortal?.company_id
        ? companyofPortal?.company_id
        : currentUser?.user?.sports_list?.map((item: any) => item._id)
      const params = {
        organisationId: currentUser?.user?.organisation_id?._id,
        sportId,
      }
      const { data } = await handleGetRequest(`/organisation/sport_stats`, { params })(setLoading)
      if (data) {
        setStatsData(data)
      }
    }
    getStatsData()
  }, [currentUser?.user?.organisation_id?._id, companyofPortal?.company_id])

  return (
    <>
      {/* <ContentHeader
        titleMain={welcomeName}
        description='I støtte-plattformen kan du enkelt administrere formål, støtter og støttespillere.'
        date='Søndag, 18 September 2022'
        loginTime='Siste innlogging 03.09.22 | 21:47'
      /> */}
      <div className='row'>
        {/* begin::Col */}
        <div className='col-xl-3 padding-reduce'>
          <MixedWidget13
            className='card-xl-stretch mb-xl-4 card_borderC card-mobile'
            backGroundColor='#ffff'
            chartHeight='100px'
            title={intl.formatMessage({ id: 'available.goals' })}
            description={intl.formatMessage({ id: 'from.selected.unit' })}
            numbertext={statsData?.total_active_goals}
            hideChart
          />
        </div>

        <div className='col-xl-3 padding-reduce'>
          <MixedWidget13
            className='card-xl-stretch mb-xl-4 card_borderC card-mobile'
            backGroundColor='#ffff'
            chartHeight='100px'
            title={intl.formatMessage({ id: 'number.of.supporters' })}
            description={intl.formatMessage({ id: 'from.selected.unit' })}
            numbertext={statsData?.total_unique_active_supporters}
            hideChart
          />
        </div>

        <div className='col-xl-3 padding-reduce'>
          <MixedWidget13
            className='card-xl-stretch mb-xl-4 card_borderC card-mobile'
            backGroundColor='#ffff'
            chartHeight='100px'
            title={intl.formatMessage({ id: 'total.support.received' })}
            description={intl.formatMessage({ id: 'from.selected.unit' })}
            numbertext={statsData?.total_received_supports}
            hideChart
          />
        </div>

        <div className='col-xl-3 padding-reduce'>
          <MixedWidget13
            className='card-xl-stretch mb-xl-4 card_borderC card-mobile'
            backGroundColor='#ffff'
            chartHeight='100px'
            title={intl.formatMessage({ id: 'outstanding.support' })}
            description={intl.formatMessage({ id: 'for.the.current.period' })}
            numbertext={statsData?.total_pending_supports}
            hideChart
          />
        </div>
      </div>
      {/* <div className='row'>

        <div className='col-xl-3'>
          <MixedWidget13
            className='card-xl-stretch mb-xl-10 card_borderC card-mobile'
            backGroundColor='#ffff'
            chartHeight='100px'
            title='Available goals'
            numbertext={statsData?.published_goals}
          />
        </div>

        <div className='col-xl-3'>
          <MixedWidget13
            className='card-xl-stretch mb-xl-10 card_borderC card-mobile'
            backGroundColor='#ffff'
            chartHeight='100px'
            title='No. of support players'
            numbertext={statsData?.total_supporters}
          />
        </div>

        <div className='col-xl-3'>
          <MixedWidget13
            className='card-xl-stretch mb-xl-10 card_borderC card-mobile'
            backGroundColor='#ffff'
            chartHeight='100px'
            title='Active supports'
            numbertext={statsData?.total_active_supports}
          />
        </div>

        <div className='col-xl-3'>
          <MixedWidget13
            className='card-xl-stretch mb-xl-10 card_borderC card-mobile'
            backGroundColor='#ffff'
            chartHeight='100px'
            title='Total support received'
            numbertext={statsData?.total_received_supports}
          />
        </div>
      </div> */}

      <GoalTable className='mb-5 mb-xl-8' />
    </>
  )
}

export { GoalMain }
